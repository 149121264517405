import { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Select } from '@/components/Shared/Forms';

class BogoDiscountSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { discountType, amount } = props;
    this.state = {
      discountType: discountType || 'free',
      amount
    };
  }

  handleAmountChange = (event) => {
    const { onChange } = this.props;
    this.setState({ amount: event.target.value });
    onChange &&
      onChange({
        amount: event.target.value,
        discount_type: this.state.discountType
      });
  };

  handleOfferTypeChange = (event) => {
    const { onChange } = this.props;
    this.setState({ discountType: event.target.value });
    onChange &&
      onChange({
        amount: this.state.amount,
        discount_type: event.target.value
      });
  };

  render() {
    const { discountType } = this.state;
    return (
      <div id="bogo-discount-container" className="form-segment">
        <label>Discount</label>
        <div className="flex">
          <div className="flex-3">
            <Select
              required
              options={[
                { value: 'free', label: 'Free' },
                { value: 'fixed_amount', label: '$ Off' },
                { value: 'fixed_percentage', label: '% Off' }
              ]}
              name="discount_type"
              placeholder="Select..."
              onChange={this.handleOfferTypeChange}
            />
          </div>
          <div className="flex-3">
            {discountType !== 'free' && (
              <InputGroup
                name="amount"
                placeholder={discountType === 'fixed_percentage' ? '0' : '0.00'}
                addonPosition={discountType === 'fixed_percentage' ? 'right' : 'left'}
                onChange={this.handleAmountChange}
              >
                <span>{discountType === 'fixed_percentage' ? '%' : '$'}</span>
              </InputGroup>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BogoDiscountSelector;
