import { Component, ReactNode, ErrorInfo } from 'react';

// Plugins
import * as Sentry from '@sentry/browser';
import { FullStory, isInitialized as getIsFullstoryInitialized } from '@fullstory/browser';

// Helpers
import * as storage from '@/utils/storage';
import entitiesToMap from '@/utils/entitiesToMap';

// Images
import browser_outdated from '@/assets/images/browser_out_of_date.png';

// Styles
import './style.css';

const APP_STUDIO_URL = import.meta.env.VITE_STUDIO_URL;
const LOGIN_ENTITIES = import.meta.env.VITE_LOGIN_ENTITIES;

Sentry.init({
  ignoreErrors: ['Non-Error exception captured'],
  dsn: 'https://c7a233084d7d4150a1c56cc028c433cd@sentry.io/1415708'
});

interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
  children?: ReactNode;
}

/*
  Error boundaries do not catch errors inside event handlers.
  React doesn’t need error boundaries to recover from errors in event handlers.
  Unlike the render method and lifecycle methods, the event handlers don’t happen
  during rendering. So if they throw, React still knows what to display on the screen.
*/
export default class ErrorBoundary extends Component<{ children?: ReactNode }, ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  isFullstoryInitialized = getIsFullstoryInitialized();

  componentDidCatch(error: Error, info: ErrorInfo | any) {
    Sentry.withScope((scope) => {
      let user = null;

      try {
        const json = storage.get(LOGIN_ENTITIES || '');
        const entities = json && JSON.parse(json);

        if (entities) {
          const { user: userEntities } = entities;

          user = entitiesToMap(userEntities)[0];
        }
      } finally {
        if (user) {
          scope.setUser(user);
        }
      }

      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });

      if (this.isFullstoryInitialized) {
        const fullstorySessionURL = FullStory('getSession', { format: 'url' });

        if (fullstorySessionURL) {
          // Add fullstory session URL to the scope for easier debugging
          scope.setExtra('fullstorySessionURL', fullstorySessionURL);
        }
      }

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h2>Oops! Something went wrong</h2>
          <img className="error-boundary__image" src={browser_outdated} alt="sad computer" />
          <h3 className="text--nomargin">
            <a href={APP_STUDIO_URL} className="error-boundary__link">
              Click here to refresh the screen
            </a>
          </h3>
        </div>
      );
    }

    // If there is no error just render the children component.
    return this.props.children;
  }
}
