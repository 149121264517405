import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Pagination from '@/components/Shared/Pagination';
import TableLoadingState from './TableLoadingState';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import { Tooltip } from 'react-tippy';

// Helpers
import Dropdown from './Dropdown';

// Styles
import 'react-tippy/dist/tippy.css';

class PhotoDayGrid extends Component {
  constructor(props) {
    super(props);
    const { headers } = props;
    this.state = { headers, sortable: null };
  }

  componentDidUpdate(nextProps) {
    if (this.props.requesting && !nextProps.requesting && this.state.sortable && this.state.filterable) {
      this.setState({ sortable: null, filterable: null });
    }
  }

  handlePagerOnClick = (page) => {
    const { pager, fetchRecordsPage } = this.props;
    const newPager = Object.assign({}, pager, { page });

    fetchRecordsPage(newPager);
  };

  setNewHeadersState = (cell) => {
    const headers = this.resetHeaders(this.state.headers);
    const idx = headers.findIndex((h) => h.fieldName === cell.fieldName);
    const newHeaders = [...headers.slice(0, idx), (headers[idx] = cell), ...headers.slice(idx + 1)];

    this.setState({ headers: newHeaders });
  };

  resetHeaders = (headers) => headers.map((h) => Object.assign({}, h, { direction: '', filter: '' }));

  handleFilterOnClick = (event) => {
    const { pager, fetchRecordsPage } = this.props;
    const newPager = Object.assign({}, pager, { page: 1, filter: event });

    fetchRecordsPage(newPager);
  };

  sortRecords = (cell) => {
    const { fieldName: order, direction: dir } = cell;
    const { pager, fetchRecordsPage } = this.props;
    const newPager = Object.assign({}, pager, { page: 1, order, dir });

    fetchRecordsPage(newPager);
  };

  handleSortOnClick = (cell) => {
    if (!cell.sortable || this.props.requesting) return;

    const newCell = Object.assign({}, cell, {
      direction: this.nextSortDirection(cell.direction)
    });

    this.setState({ sortable: newCell.fieldName }, () => {
      this.setNewHeadersState(newCell);
      this.sortRecords(newCell);
    });
  };

  nextSortDirection = (direction) => {
    const dir = direction || '';
    switch (dir) {
      case '':
        return 'ASC';
      case 'ASC':
        return 'DESC';
      default:
        return '';
    }
  };

  onClick = (td) => {
    if (td.sortable === true) {
      this.handleSortOnClick(td);
    }
  };

  get content() {
    const {
      children,
      requesting,
      loadingContent,
      defaultContent,
      pager: { perPage }
    } = this.props;

    const { headers } = this.state;

    if (!children || (Array.isArray(children) && !children.length)) {
      if (requesting && typeof loadingContent !== 'string')
        return React.cloneElement(loadingContent, {
          cells: headers.length,
          rows: perPage
        });

      return (
        <tr>
          <td colSpan={headers.length}>{requesting ? loadingContent : defaultContent}</td>
        </tr>
      );
    }
    return children;
  }

  render() {
    const { table_id, extraClasses, pager, requesting } = this.props;
    const { headers, sortable: sortBy, filterable: filterBy } = this.state;

    const sortClass = (direction) => {
      const dir = direction || '';
      return classNames({
        fa: true,
        'fa-long-arrow-up': dir === 'DESC',
        'fa-long-arrow-down': dir === 'ASC'
      });
    };
    const headerStyle = (sortable, filterable) =>
      sortable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : filterable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : null;

    const headerCells = headers.map((td, i) => {
      let { fieldName, displayName, sortable, filterable, direction, tooltip } = td;

      if (fieldName === pager.order) direction = pager.dir;

      const sortStyle = {
        fontSize: 12,
        marginLeft: 2
      };

      const sortIcon = sortable ? <i className={sortClass(direction)} style={sortStyle} /> : null;

      const filterDropdown = filterable?.length ? (
        <Dropdown buttonName={'filter'} buttonExtraClass={'button--filter'}>
          <ul className="panel panel-dropdown panel-dropdown--small panel-dropdown--left">
            {filterable.map((filter) => (
              <li
                key={filter.value}
                className={`panel-dropdown__item ${filter.value === pager.filter ? 'panel-dropdown__item--active' : ''}`}
                onClick={this.handleFilterOnClick}
              >
                {filter.name}
              </li>
            ))}
          </ul>
        </Dropdown>
      ) : null;

      if (fieldName === 'price_sheet_type') {
        return false;
      }

      return (
        <th style={headerStyle(sortable, filterable)} onClick={() => this.onClick(td)} key={i}>
          {displayName} {filterDropdown} {sortIcon}
          {sortBy && sortBy === fieldName} {filterBy && filterBy === fieldName}
          {tooltip && (
            <Tooltip {...{ title: tooltip, position: 'bottom', trigger: 'mouseenter', size: 'big' }}>
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          )}
        </th>
      );
    });
    const tableStyle = {
      width: '100%'
    };
    const tableClasses = (extraClasses ? `table-container ${extraClasses}` : 'table-container') + (requesting ? ' requesting' : '');

    return (
      <>
        <article className={`table-box ${tableClasses}`}>
          <table id={table_id} className={`table ${tableStyle}`}>
            <thead className="table__header">
              <tr>{headerCells}</tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan="10">
                    <TableLoader rows={extraClasses === 'shipping-table' ? 2 : 15} rowHeight={60} />
                  </td>
                </tr>
              ) : (
                this.content
              )}
            </tbody>
          </table>
        </article>
        {pager.total > pager.perPage && <Pagination pagination={pager} onPagination={this.handlePagerOnClick} />}
      </>
    );
  }
}

PhotoDayGrid.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      displayName: PropTypes.string,
      sortable: PropTypes.bool,
      direction: PropTypes.string
    })
  ).isRequired,
  table_id: PropTypes.string,
  extraClasses: PropTypes.string,
  pager: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number,
    order: PropTypes.string,
    dir: PropTypes.string,
    search: PropTypes.string
  }),
  fetchRecordsPage: PropTypes.func,
  defaultContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  loadingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  requesting: PropTypes.bool
};

PhotoDayGrid.defaultProps = {
  table_id: `table_${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`,
  extraClasses: null,
  defaultContent: 'There is no data to be displayed.',
  loadingContent: <TableLoadingState />,
  requesting: false
};

export default PhotoDayGrid;
