import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatCurrency } from '@/utils/displayFormats';

class OrderRow extends Component {
  render() {
    const { order } = this.props;

    const styles = {
      dateCell: {
        whiteSpace: 'nowrap'
      }
    };

    return (
      <tr>
        <td style={styles.dateCell}>{order.submitted_at && moment(order.submitted_at).format('L')}</td>
        <td>
          <Link to={order.advance_pay ? `orders/advance-pay/credits/${order.id}` : `orders/${order.id}`}>
            <b>{order.has_reprint ? `${order.num} (RP)` : `${order.num}`}</b>
          </Link>
        </td>
        <td>{order.advance_pay ? 'AdvancePay' : 'Product'}</td>
        <td>{order.job_name}</td>
        <td>{order.customer_name}</td>
        <td className="text--capitalize">{{ paid: 'Paid', fulfilled: `Fulfilled ${order.bulk_ship ? '- Bulk' : ''}` }[order.status] || ''}</td>
        <td>
          {order.bulk_shipped_at ? (
            <Link to={`/jobs/${order.job_id}/shipping`}>Shipped - {moment(order.bulk_shipped_at).format('L')}</Link>
          ) : (
            <>
              {order.tracking_url ? (
                <a href={order.tracking_url} target="_blank" rel="noopener noreferrer">
                  {order.tracking_number}
                </a>
              ) : (
                order.tracking_number || <i>N/P</i>
              )}
            </>
          )}
        </td>
        <td>{order.credit > 0 ? 'Yes' : 'No'}</td>
        <td>
          ${formatCurrency(order.total_with_credit)}
          {order.credit > 0 ? '*' : ''}
        </td>
      </tr>
    );
  }
}

OrderRow.propTypes = {
  order: PropTypes.shape({
    submitted_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    job_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tracking_number: PropTypes.string,
    total_with_credit: PropTypes.number.isRequired
  }).isRequired
};

export default OrderRow;
