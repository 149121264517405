import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';
import { Input } from '@/components/Shared/Forms';

import StatesDDL from '@/components/Shared/StatesDDL';

class RegisterFormStudio extends Component {
  render() {
    const {
      handleSubmit,
      parentHandleChange,
      state,
      addressDeliverability,
      onPreviousStage,
      onAddressValidate,
      isStudioNameValid,
      onStudioNameValidate,
      isPhoneNumberValid,
      onPhoneNumberValidate
    } = this.props;

    const stateDDLOptions = {
      elementName: 'studio.address_attributes.state',
      country: 'USA',
      selectedState: state,
      autocomplete: 'state',
      parentHandleChange: parentHandleChange
    };

    return (
      <Form onSubmit={handleSubmit} className="sign-up-form">
        <>
          <div className="flex mb-20">
            <div className="flex-12">
              <Input
                label="Studio Name"
                name="studio.name"
                required
                onBlur={onStudioNameValidate}
                error={isStudioNameValid ? '' : 'Invalid Studio Name! Please remove all special characters before proceeding.'}
              />
            </div>
          </div>
          <div className="flex mb-20">
            <div className="flex-12">
              <Input
                label="Studio Phone Number"
                name="studio.phone"
                type="tel"
                required
                onBlur={onPhoneNumberValidate}
                error={isPhoneNumberValid ? '' : 'Invalid Phone Number! Please enter a valid US phone number.'}
              />
            </div>
          </div>
          <div className="flex flex-row mb-20">
            <div className="flex-8">
              <Input label="Address" name="studio.address_attributes.line1" autoComplete="address-line1" required />
            </div>
            <div className="flex-4">
              <Input label="Apt/Unit #" name="studio.address_attributes.line2" autoComplete="address-line2" />
            </div>
          </div>
          <div className="flex flex-row mb-20">
            <div className="flex-3">
              <Input label="Zip" name="studio.address_attributes.zip" required autoComplete="postal-code" onBlur={onAddressValidate} />
            </div>
            <div className="flex-5 text--capitalize">
              <Input label="City" name="studio.address_attributes.city" required autoComplete="address-level2" onBlur={onAddressValidate} />
            </div>
            <div className="flex-4">
              <div>
                <label>State</label>
                <StatesDDL {...stateDDLOptions} />
              </div>
            </div>
          </div>
          {addressDeliverability && addressDeliverability !== 'deliverable' && (
            <p className="text--center text--danger">Undeliverable address! Please enter a valid address before proceeding.</p>
          )}
        </>
        <div className="flex">
          <div className="flex-12 text--center">
            <button className="button button--block login-btn text--center" type="submit" disabled={addressDeliverability !== 'deliverable'}>
              Next
            </button>
            <button className="button button--link" type="button" onClick={onPreviousStage}>
              Go back
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({ form: 'RegisterFormStudio', destroyOnUnmount: false })(RegisterFormStudio);
