import { FC, useState, useEffect } from 'react';

// Plugins
import { debounce } from 'lodash';
import Cropper from 'react-cropper';

// Redux
import { useSelector } from 'react-redux';

// Styles
import 'cropperjs/dist/cropper.css';
import './style.css';

// Types
import { ExportItem } from '@/types';

interface CropData {
  crop_width: number;
  crop_height: number;
  crop_x: number;
  crop_y: number;
}

interface ReviewCropperProps {
  aspectRatio: number;
  exportItem: ExportItem;
  onShowAutoCropToggle: () => void;
  onUpdateCrop: (data: CropData) => void;
}

interface CropperData {
  width: number;
  height: number;
  x: number;
  y: number;
}

const ReviewCropper: FC<ReviewCropperProps> = ({ aspectRatio, exportItem, onUpdateCrop, onShowAutoCropToggle }) => {
  const { requesting } = useSelector((state: any) => state.jobs);

  const [cropper, setCropper] = useState<any>();

  const [isCropperReady, setIsCropperReady] = useState(false);
  const [isWindowResized, setisWindowResized] = useState(false);

  const [initialCropData, setInitialCropData] = useState<CropperData | null>(null);
  const photoUrl = exportItem?.photo?.retouched_image_url ?? exportItem?.photo?.image_url;

  const handleSetCropperData = () => {
    const { crop_width, crop_height, crop_x, crop_y } = exportItem;

    if (crop_width && crop_height && crop_x !== null && crop_y !== null) {
      const cropData = {
        height: Number(crop_height),
        width: Number(crop_width),
        x: Number(crop_x),
        y: Number(crop_y)
      };

      cropper.setData(cropData);
      setInitialCropData(cropData);
    } else {
      cropper.setAspectRatio(aspectRatio);
    }
  };

  const handleUpdateCrop = () => {
    const cropperData = cropper.getData(true);

    const cropData = {
      crop_height: Math.round(cropperData?.height),
      crop_width: Math.round(cropperData?.width),
      crop_x: Math.round(cropperData?.x),
      crop_y: Math.round(cropperData?.y)
    };

    onUpdateCrop(cropData);
  };

  const handleResetCrop = () => {
    if (cropper && initialCropData) {
      cropper.reset();
      cropper.setData(initialCropData);
    }
  };

  const handleZoom = (action: string) => {
    if (action === 'increase' && cropper) {
      cropper.zoom(0.05);
    }
    if (action === 'decrease' && cropper) {
      cropper.zoom(-0.05);
    }
  };

  const windowResized = debounce(() => {
    setisWindowResized(true);
    setIsCropperReady(false);
  }, 200);

  useEffect(() => {
    if (photoUrl && isCropperReady && cropper) {
      handleSetCropperData();
    }
  }, [photoUrl, isCropperReady, cropper]);

  useEffect(() => {
    if (isWindowResized) {
      // Cropper is removed and reinitialized to ensure initialCropData is based off new dimensions
      setisWindowResized(false);
    }
  }, [isWindowResized]);

  useEffect(() => {
    window.addEventListener('resize', windowResized);

    return () => {
      window.removeEventListener('resize', windowResized);
    };
  }, []);

  return (
    <aside className="review-crop__modal modal animate">
      <div className="modal__box modal__box--medium">
        <header className="modal__header text--left">
          <button className="button button--action modal__close" name="close" type="button" onClick={onShowAutoCropToggle}>
            <i className="icon-close"></i>
          </button>
          <h3>Edit Crop</h3>
          <p>Manually scale and adjust the crop to a different position.</p>
        </header>

        <main className="modal__content">
          <main className="modal__content">
            {!isWindowResized && (
              <div className="review-crop__cropper-container">
                <Cropper
                  src={photoUrl}
                  checkCrossOrigin={false}
                  checkOrientation={false}
                  wheelZoomRatio={0.05}
                  viewMode={1}
                  dragMode={'move'}
                  center={false}
                  rotatable={false}
                  background={false}
                  cropBoxResizable={false}
                  cropBoxMovable={false}
                  ready={() => {
                    setIsCropperReady(true);
                  }}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
              </div>
            )}
          </main>
        </main>
        <footer className="flex between bottom column-sm stretch-sm modal__footer">
          <div className="flex middle start between-sm">
            <div className="review-crop__reset-container">
              <span className="review-crop__action-title">Reset</span>
              <button className="button button--outline button--small" onClick={handleResetCrop}>
                <i className="icon-reset" />
              </button>
            </div>
            <div className="review-crop__zoom-container">
              <span className="review-crop__action-title">Zoom</span>
              <div className="button-group review-crop__action">
                <button className="button button--outline button--small" onClick={() => handleZoom('decrease')}>
                  <i className="icon-minus-circle" />
                </button>
                <button className="button button--outline button--small" onClick={() => handleZoom('increase')}>
                  <i className="icon-add-circle" />
                </button>
              </div>
            </div>
          </div>

          <button
            className="button button--medium button--block-sm"
            name="update"
            type="button"
            onClick={handleUpdateCrop}
            disabled={requesting}
            data-loading={requesting}
          >
            Update
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default ReviewCropper;
