import { useState, useEffect } from 'react';

// Plugins
import { isEqual } from 'lodash';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationList } from '../../../../Organizations/actions';
import { getStatesListRequest } from '@/components/Shared/StatesDDL/actions';
import { getPeopleFieldOptions } from '../../../actions';

// Components
import ShippingTable from '@/components/Shared/ShippingTable';

const ORG_ITEMS_ORDER = 'name';
const ORG_ITEMS_PER_PAGE = 10000;

const BulkShip = function BulkShip({ information, onSetInformation, onSave, onResetGroupList, onRemoveGroupField }) {
  const dispatch = useDispatch();

  const { organizations, requesting: organizationsRequesting } = useSelector((state) => state.organizations);
  const {
    result: { USA: states },
    requesting: statesRequesting
  } = useSelector((state) => state.states);
  const {
    job,
    people: { fields },
    requesting
  } = useSelector((state) => state.jobs);

  const { lab_id: jobLabId, lab_name: jobLabName } = job && job.meta;

  const [deliveryTo, setDeliveryTo] = useState({});
  const [showAddressConfirmationModal, setShowAddressConfirmationModal] = useState(false);

  const handleAddressConfirmationModalClose = () => {
    setShowAddressConfirmationModal(false);
    onSetInformation({ ...information, addressDeliverability: '' });
  };

  const handleAddressConfirmation = () => {
    setShowAddressConfirmationModal(false);
    onSetInformation({ ...information, addressDeliverability: '' });
    onSave({ isAddressConfirmed: true });
  };

  useEffect(() => {
    if (states.length === 0 && !statesRequesting) {
      dispatch(getStatesListRequest());
    }

    if (!Object.keys(fields).length && job.setup_steps.uploaded_subjects && !requesting) {
      dispatch(getPeopleFieldOptions({ id: job.id }));
    }
  }, []);

  useEffect(() => {
    if (!organizations.length && !organizationsRequesting) {
      dispatch(getOrganizationList({ studioId: job?.studio_id, order: ORG_ITEMS_ORDER, per_page: ORG_ITEMS_PER_PAGE }));
    }

    if (information.recipient && organizations.length > 0) {
      const newDeliveryTo = organizations.find((organization) => organization.name?.toLowerCase() === information.recipient?.toLowerCase());

      newDeliveryTo ? setDeliveryTo({ value: newDeliveryTo.id, label: newDeliveryTo.name }) : setDeliveryTo({ value: '', label: 'Other' });
    }
  }, [organizations, information.recipient]);

  useEffect(() => {
    setShowAddressConfirmationModal(information?.addressDeliverability === 'undeliverable');
  }, [information?.addressDeliverability]);

  return (
    <>
      <ShippingTable labId={jobLabId} labName={jobLabName} shippingType={'bulk'} />
      <section className="panel panel--section animate">
        <div className="fieldset">
          <h3>Bulk Deadline Date</h3>
          <p>This is the last day customers can place an order and qualify for bulk delivery. Orders will process at 12:00 am the next day.</p>

          <DatePicker
            className="input--date"
            name="expires_at"
            isClearable={true}
            autoComplete="off"
            dateFormat="MM/dd/yy"
            strictParsing={true}
            placeholderText="MM/DD/YY"
            selected={information.expires_at}
            onChange={(date) => onSetInformation({ ...information, expires_at: date })}
          />
        </div>

        {job.access_mode === 'access_per_subject' && (
          <fieldset className="flex column ">
            <h3>Sort By</h3>
            <p>{`Orders will be sorted by last name, first name${
              information.bulk_ship_group && information.bulk_ship_group.length > 0 ? ', order number, and by the items listed below. ' : ' and order number.'
            }`}</p>

            {information.bulk_ship_group &&
              information.bulk_ship_group.map((group, i) => (
                <div className="flex nowrap middle job-settings-shipping_group-item" key={i}>
                  <span>{i + 1 + '. '}</span>
                  <input
                    className="job-settings-shipping_group-field"
                    name="bulkShipGroupItem"
                    data-group-field={group}
                    value={`${group.replace('subjects.', '').replace('session_start', 'check-in_date').replace('_', ' ')}`}
                    readOnly={true}
                  ></input>
                  <button className="button button--link" type="button" onClick={() => onRemoveGroupField(group)}>
                    <i className="icon-trash"></i>
                    Remove
                  </button>
                </div>
              ))}

            {!isEqual(information.bulk_ship_group, information.bulk_ship_group_fields) && (
              <>
                {information.bulk_ship_group.length === 0 && (
                  <span className="job-settings-shipping__fields-message">You've removed all of the additional sort fields</span>
                )}
                <button className="button button--link" type="button" onClick={onResetGroupList}>
                  Reset List
                </button>
              </>
            )}
          </fieldset>
        )}

        <h3>Delivery Information</h3>
        <p>This is where all orders will be delivered. We cannot bulk ship to a P.O. Box address.</p>

        <fieldset className="flex column flex-4 flex-12-sm">
          <label htmlFor="delivery">Delivery to</label>
          <Select
            id="delivery"
            className="select"
            classNamePrefix="select"
            placeholder="Select..."
            value={deliveryTo.label ? deliveryTo : ''}
            options={[...organizations.map((organization) => ({ value: organization.id, label: organization.name })), { value: '', label: 'Other' }]}
            onChange={(select) => {
              onSetInformation({ ...information, recipient: select.label !== 'Other' ? select.label : '' });
              setDeliveryTo(select);
            }}
          />
        </fieldset>

        <fieldset className="flex column flex-6 flex-12-sm">
          <label htmlFor="recipient">Name</label>
          <input
            id="recipient"
            type="text"
            name="recipient"
            value={information.recipient}
            maxLength="50"
            readOnly={deliveryTo.value}
            required
            onChange={({ target }) => onSetInformation({ ...information, recipient: target.value })}
          />
        </fieldset>

        <div className="flex flex-row fieldset">
          <div className="flex column flex-4 flex-12-sm">
            <label htmlFor="line1">Address</label>
            <input
              id="line1"
              type="text"
              name="line1"
              value={information.line1}
              maxLength="100"
              required
              onChange={({ target }) => onSetInformation({ ...information, line1: target.value })}
            />
          </div>
          <div className="flex column flex-2 flex-12-sm">
            <label htmlFor="line2">Apt/Unit #</label>
            <input
              id="line2"
              type="text"
              name="line2"
              value={information.line2}
              maxLength="80"
              onChange={({ target }) => onSetInformation({ ...information, line2: target.value })}
            />
          </div>
        </div>

        <div className="flex flex-row fieldset">
          <div className="flex column flex-2 flex-12-sm">
            <label htmlFor="zip">Zip</label>
            <input
              id="zip"
              type="text"
              name="zip"
              value={information.zip}
              maxLength="10"
              required
              onChange={({ target }) => onSetInformation({ ...information, zip: target.value })}
            />
          </div>
          <div className="flex column flex-2 flex-12-sm">
            <label htmlFor="city">City</label>
            <input
              id="city"
              className="text--capitalize"
              type="text"
              name="city"
              value={information.city}
              maxLength="100"
              required
              onChange={({ target }) => onSetInformation({ ...information, city: target.value })}
              disabled={!(information.line1 && information.zip)}
            />
          </div>
          <div className="flex column flex-2 flex-12-sm">
            <label htmlFor="state">State</label>
            <Select
              id="state"
              name="state"
              className="select"
              classNamePrefix="select"
              placeholder="Select..."
              isDisabled={!(information.line1 && information.zip)}
              value={states.filter((state) => state.abbreviation === information.state).map((state) => ({ value: state.abbreviation, label: state.name }))}
              options={states.map((state) => ({ value: state.abbreviation, label: state.name }))}
              onChange={(select) => onSetInformation({ ...information, state: select.value })}
            />
          </div>
        </div>

        <fieldset className="flex column">
          <label htmlFor="notes">Delivery Note</label>
          <p className="job-settings-shipping__label-details">
            PhotoDay will not display the above address to the customer. If you would like an address to appear, please add the address below along with
            detailed instructions on how the customer will receive their order. These instructions will appear at checkout and in their order receipt and
            shipment confirmation emails.
          </p>
          <div className="flex-5 flex-12-sm">
            <textarea
              id="notes"
              name="notes"
              value={information.notes}
              rows="8"
              maxLength="1400"
              onChange={({ target }) => onSetInformation({ ...information, notes: target.value })}
            />
          </div>
        </fieldset>
      </section>
      {showAddressConfirmationModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--large">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleAddressConfirmationModalClose}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content">
              <h4>We couldn’t find this address for validation. Are you sure this address is correct?</h4>
            </main>
            <footer className="flex gap-20 center modal__footer">
              <button className="button" name="yes" type="button" onClick={handleAddressConfirmation}>
                Yes
              </button>
              <button className="button button--outline" name="no" type="button" onClick={handleAddressConfirmationModalClose}>
                No
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default BulkShip;
