import { useState } from 'react';

// Plugins
import { useIntercom } from 'react-use-intercom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { getNpsScoreDownload } from '../actions';

// Components
import Navigation from '@/components/Shared/Navigation';

const TOUR_ID = import.meta.env.VITE_INTERCOM_TOUR_ID;

const Header = ({ path, studioId }) => {
  const dispatch = useDispatch();
  const { startTour: intercomStartTour } = useIntercom();

  const [navigationIsOpen, setNavigationIsOpen] = useState(false);

  const { summary } = useSelector((state) => state.reports);

  const summaryNpsScore = summary && summary.nps_score;

  const items = [
    { name: 'Summary', link: '/home/summary', icon: 'icon-summary' },
    { name: 'Academy', link: '/home/academy', icon: 'icon-academy' }
  ];

  const currentItem = items.find((item) => path.includes(item.link)) || items[0];

  const handleToggleNavigation = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  const handleTourStart = () => {
    intercomStartTour(TOUR_ID);
  };

  const handleNpsScoreDownload = () => dispatch(getNpsScoreDownload({ studioId }));

  return (
    <header className="home__header">
      <div className="flex flex-3 flex-12-md middle between-md gap-10">
        <h1 className="text--nomargin">Welcome Home</h1>
        <button className="button button--pill home__tour middle" onClick={handleTourStart}>
          Tour
        </button>
        <button className="button button--outline home__mobile-action" onClick={handleToggleNavigation}>
          <i className={currentItem.icon} />
          {currentItem.name}
        </button>
      </div>

      <div className="flex flex-5 middle between">
        <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />
      </div>

      <div className="flex flex-4 flex-12-md end">
        <aside className="home__nps">
          <span className="text--large text--bold">
            NPS: {summaryNpsScore}%{' '}
            <a href="https://support.photoday.io/en/articles/5701127-what-is-nps" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-question-circle-o" />
            </a>
          </span>
          <button className="button" type="button" onClick={handleNpsScoreDownload}>
            Download
          </button>
        </aside>
      </div>
    </header>
  );
};

export default Header;
