import { Component } from 'react';
import PropTypes from 'prop-types';

class EmptyState extends Component {
  static propTypes = {
    roles: PropTypes.array,
    type: PropTypes.string.isRequired,
    requesting: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired
  };

  orderEmptyState = () => {
    const { isEmpty, requesting } = this.props;

    if (!isEmpty || requesting) return null;

    return (
      <tr className="orders-empty">
        <td colSpan="2" className="empty-table">
          <h4 className="text--center text--normal">No orders have been placed</h4>
        </td>
      </tr>
    );
  };

  jobEmptyState = () => {
    const { isEmpty } = this.props;

    if (!isEmpty) return null;

    return (
      <tr className="jobs-empty">
        <td colSpan="3" className="empty-table">
          <h4 className="text--center text--normal">You have no upcoming jobs</h4>
        </td>
      </tr>
    );
  };

  salesEmptyState = () => {
    const { isEmpty } = this.props;

    if (!isEmpty) return null;

    return (
      <tr className="sales-empty">
        <td colSpan="3">
          <p className="text--center" />
        </td>
      </tr>
    );
  };

  render() {
    const { type } = this.props;
    return this[`${type}EmptyState`]();
  }
}

export default EmptyState;
