import React from 'react';

const StudioTaxes = () => {
  const articles = [
    {
      title: '1099-K Forms Issued by Stripe via PhotoDay',
      url: 'https://support.photoday.io/en/articles/6705607-1099-k-forms-issued-by-stripe-via-photoday'
    },
    {
      title: 'Taxes on Items Sold Through PhotoDay',
      url: 'https://support.photoday.io/en/articles/6705669-taxes-on-items-sold-through-photoday'
    },
    {
      title: 'Marketplace Tax Collection',
      url: 'https://support.photoday.io/en/articles/6705684-marketplace-tax-collection'
    }
  ];

  return (
    <section className="studio-settings-item__nexus">
      <header className="flex middle between">
        <h2>Taxes</h2>
      </header>
      <section className="panel panel--section">
        <p>
          PhotoDay is a marketplace facilitator and must remit sales taxes to each respective state. You may be provided with a 1099-K from Stripe that reflects
          your studio income for the previous year. Check out these articles to learn more about taxes in PhotoDay:
        </p>
        <ul className="list--bullet">
          {articles.map((article, index) => (
            <li key={index}>
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                {article.title}
              </a>
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
};

export default StudioTaxes;
