import { Link } from 'react-router-dom';

const QuicktipItem = ({ title, description, learnMoreLink, image, url = '', linkLabel, backgroundColor }) => {
  return (
    <div className="quicktip__item">
      <figure>
        <div className={`flex middle center quicktip__image-container quicktip__image-background-${backgroundColor}`}>{image}</div>
        <figcaption className="panel panel--nomargin">
          <h4>{title}</h4>
          <p>
            {description}{' '}
            {learnMoreLink && (
              <a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            )}
          </p>
          <Link to={url} className="button button--outline button--large quicktip__link-button">
            {linkLabel}
          </Link>
        </figcaption>
      </figure>
    </div>
  );
};

export default QuicktipItem;
