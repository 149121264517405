import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';

// Redux
import { createAdminSms } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Styles
import './style.css';

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;

const defaultMessage = "Time's almost up to order your pictures from %{job_name}! View photos here: %{job_url}";

const helperKeys = [
  { key: '%{job_name}', description: 'Job Name' },
  { key: '%{job_access_code}', description: 'Job Access Code' },
  { key: '%{job_url}', description: 'Gallery Url' },
  { key: '%{studio_name}', description: 'Studio Name' }
];

const Sms = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;
  const { job } = useSelector((state) => state.jobs);
  const { studio } = useSelector((state) => state.login);

  const [target, setTarget] = useState('not_ordered');
  const [message, setMessage] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');

  const transformSmsText = (job, studio, text) => {
    const replaceAll = (str, str1, str2) => {
      let rep = str;

      while (rep.indexOf(str1) !== -1) {
        rep = rep.replace(str1, str2);
      }

      return rep;
    };

    const isPrivateJob = job.access_mode === 'access_per_subject';
    const accessCode = isPrivateJob ? '{{access_code}}' : job.access_code;
    const galleryUrl = job.gallery_v4 ? `${MY_PHOTODAY_URL}g/${accessCode}` : GALLERY_URL;

    let studioOrBlank = studio || { name: '' };
    let newText = replaceAll(text, '%{job_name}', job.name);
    newText = replaceAll(newText, '%{job_access_code}', job.access_code);

    newText = replaceAll(newText, '%{job_url}', galleryUrl);
    newText = replaceAll(newText, '%{studio_name}', studioOrBlank.name);

    return newText;
  };

  const onMessageChange = (value) => {
    if (!job || !studio) return;

    if (job.access_mode === 'access_per_job' && value.indexOf('%{job_access_code}') === -1) {
      value = value.replace('%{job_url}', '%{job_url} \n\nAccess code is %{job_access_code}');
    }

    const transformed = transformSmsText(job, studio, value);

    setMessage(value);
    setPreviewMessage(transformed);
  };

  const handleSelectChange = (event) => {
    setTarget(event.target.value);
  };

  const handleMessageChange = (event) => {
    const value = event.target.value;

    onMessageChange(value);
  };

  const handleKeyCopy = (key) => {
    navigator.clipboard.writeText(key);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onMessageChange(message);
    dispatch(createAdminSms({ jobId, target, body: previewMessage }));
  };

  useEffect(() => {
    onMessageChange(defaultMessage);
  }, []);

  useEffect(() => {
    if (job?.id) {
      onMessageChange(defaultMessage);
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Admin" />

      <main className="box flex">
        <Sidebar jobId={jobId} title={'SMS Sending'} />

        <section className="flex-9 flex-12-md job-admin-sms">
          <header className="flex middle between job-admin-sms__header">
            <h2 className="text--nomargin">SMS Sending</h2>
          </header>

          <div className="flex panel panel--section">
            <div className="editable-container">
              <form onSubmit={handleSubmit}>
                <div className="create-sms">
                  <div className="share-edit-content ">
                    <label>Helper Keys</label>
                    <table className="helper-keys-table">
                      <thead>
                        <tr>
                          <th>Input</th>
                          <th>Output</th>
                        </tr>
                      </thead>
                      <tbody>
                        {helperKeys.map((pair) => (
                          <tr key={pair.key}>
                            <td>
                              {pair.key}
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleKeyCopy(pair.key);
                                }}
                                className=""
                              >
                                {' '}
                                <i className="fa fa-clipboard" aria-hidden="true" />{' '}
                              </button>
                            </td>
                            <td>{pair.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className=" share-edit-content">
                    <label htmlFor="target">Send to recipients</label>
                    <div className="select-caret">
                      <select className="input--block" name="target" value={target} onChange={handleSelectChange}>
                        <option label="Not Ordered" value="not_ordered" />
                        <option label="All" value="all" />
                        <option label="Unused AP Credits" value="unused_credits" />
                      </select>
                    </div>
                  </div>
                  <div className="share-edit-content">
                    <label>Message</label>
                    <div className="message-container">
                      <textarea name="message" className="message-container" value={message} rows="5" onChange={handleMessageChange} />
                    </div>
                  </div>
                  <div className="edit-content-footer">
                    <button className="button" type="submit">
                      Send SMS
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="preview-container">
              <div className="fake-phone">
                <header className="flex center middle">
                  <span className="left">Back</span>
                  <h2 className="text--nomargin">Preview</h2>
                </header>
                <div className="messages-wrapper">
                  <div className="message from">{previewMessage}</div>
                  <div className="message to">Great!</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

Sms.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

Sms.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  history: {}
};

export default Sms;
