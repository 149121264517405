import { Form, reduxForm } from 'redux-form';
import ReduxSelect from '@/components/Shared/Forms/ReduxSelect';

// Helpers
import { Input, PercentOrFixed } from '@/components/Shared/Forms';
import { integerValidate } from '@/utils/validateHelpers';
import { convertToText } from '@/utils/currency';

const PriceSheetForm = ({ labs, invalid, submitting, requesting, disableLabs, handleSubmit, priceSheetId, labSelected, initialValues, change }) => {
  const {
    shipping_type,
    markup_attributes: { amount, markup_type },
    jobs_count
  } = initialValues;

  const onMarkupChange = (values) => {
    const { markup_type, amount } = values;

    const num = parseFloat(amount);
    const markupTypeValue = markup_type && markup_type?.value ? markup_type.value : markup_type;

    if (markupTypeValue === 'fixed_amount') {
      change('markup_attributes[amount]', num);
      change('markup_attributes[fixed_percentage]', null);
      change('markup_attributes[fixed_amount_cents]', !isNaN(num) ? Math.round(num * 100) : null);
    } else if (markupTypeValue === 'fixed_percentage') {
      change('markup_attributes[amount]', num);
      change('markup_attributes[fixed_amount_cents]', null);
      change('markup_attributes[fixed_percentage]', !isNaN(num) ? num : null);
    }
  };

  const onChangeLab = (select) => {
    const value = select.value;

    change('lab_id', value);

    if (!select.allowBulkShipping) {
      change('shipping_type', { value: 'drop_shipping', label: 'Drop' });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input className="pricesheets-add-modal__input" type="text" name="name" required="true" placeholder="Price Sheet Name" />
      <ReduxSelect
        className="select pricesheets-add-modal__select"
        disabled={disableLabs}
        classNamePrefix="select"
        name="lab_id"
        required={!disableLabs}
        placeholder="Lab"
        options={labs}
        onChange={onChangeLab}
      />
      <hr />
      <PercentOrFixed
        amount={amount}
        label="Markup"
        labSelected={labSelected}
        labelType="Markup Type"
        markupType={markup_type}
        shippingType={shipping_type}
        key="field-PercentOrFixed"
        priceSheetId={priceSheetId}
        jobsCount={jobs_count}
        onChange={onMarkupChange}
        name="markup_attributes[markup_type]"
        amountName="markup_attributes[amount]"
      />

      <div className="modal-footer flex middle">
        <button className="button" type="submit" disabled={submitting || requesting || invalid} data-loading={submitting || requesting}>
          {priceSheetId ? 'Update' : 'Create'}
        </button>
      </div>
    </Form>
  );
};

const validate = (values) => {
  const errors = { markup_attributes: {} };
  // Validation logic remains the same

  if (values?.markup_attributes) {
    const {
      markup_attributes: { markup_type, amount }
    } = values;

    if (markup_type && markup_type.value === 'fixed_amount') {
      if (amount) {
        const minFixedMarkupCents = 500;
        const maxFixedMarkupCents = 1000000;
        let number = parseFloat('' + amount);

        if (isNaN(number)) {
          errors.markup_attributes.amount = 'Please enter a number';
        } else if (number < minFixedMarkupCents / 100) {
          errors.markup_attributes.amount = `Min markup value is ${convertToText(minFixedMarkupCents, '$')}`;
        } else if (number > maxFixedMarkupCents / 100) {
          errors.markup_attributes.amount = `Max markup value is ${convertToText(maxFixedMarkupCents, '$')}`;
        } else {
          const pattern = /^(0|([1-9][0-9]*))(\.[0-9]{1,2})?$/; // positive with 2 decimals max

          if (!pattern.test('' + amount)) {
            errors.markup_attributes.amount = 'Please enter a valid number';
          }
        }
      }
    } else if (markup_type && markup_type.value === 'fixed_percentage') {
      const minPercentMarkup = 20;
      const maxPercentMarkup = 1500;

      if (amount) {
        if (!integerValidate(amount)) {
          errors.markup_attributes.amount = `Please enter a number between ${minPercentMarkup} and ${maxPercentMarkup}`;
        } else {
          let number = parseInt('' + amount, 10);

          if (number < minPercentMarkup || number > maxPercentMarkup) {
            errors.markup_attributes.amount = `Please enter a number between ${minPercentMarkup} and ${maxPercentMarkup}`;
          }
        }
      }
    }

    return errors;
  }

  return;
};

export default reduxForm({ form: 'PriceSheetForm', enableReinitialize: true, destroyOnUnmount: false, validate })(PriceSheetForm);
