import { Component } from 'react';
import { Switch } from 'react-router-dom';

// Components
import Support from './Support/';
import Header from './Header';
import Collateral from './Collateral';
import TopNav from '@/components/Shared/TopNav';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './resources.css';

class StudioCollateral extends Component {
  state = {
    section: 'Collateral',
    active: false
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSectionTitle(this.props.location.pathname);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'sales' && this.state.section !== 'Collateral') {
      this.setState({ section: 'Collateral' });
    }
  };

  collapseMenu = (event) => {
    const innerHTML = event.target.innerHTML;
    const newSection = `${innerHTML.includes('Collateral') ? 'Collateral' : innerHTML.includes('Support') ? 'Support' : 'Webinars & Videos'}`;
    this.setState({ active: false, section: newSection });
  };

  toggleMenu = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    return (
      <div id="studio__collateral">
        <TopNav />
        <Header path={this.props.location.pathname} />

        <div className="box flex">
          <Switch>
            <PrivateRoute path="/collateral" type="sales" component={Collateral} />
            <PrivateRoute path="/support" type="support" component={Support} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default StudioCollateral;
