import { Link } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';

// Redux
import { createJobExport, getJobExportList, updateJobExportFromCable, deleteJobExport } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Plugins
import moment from 'moment';
import FileSaver from 'file-saver';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Components
import ExportCreate from '../Create';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Types
import { Export } from '@/types';

interface ExportsListProps {
  match: {
    params: {
      jobId: string;
    };
  };
  history: { location: { search: string }; push: (location: string | { search: string }) => void };
}

const ExportsList: FC<ExportsListProps> = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;

  const { location } = history;

  const {
    job,
    exports: { list: exportsList, pagination: exportsPagination, requesting }
  } = useSelector((state: any) => state.jobs);

  const { page: queryPage } = queryString.parse(location.search);

  const [showExportCreate, setShowExportCreate] = useState<boolean>(false);
  const [selectExportItemId, setSelectExportItemId] = useState<string>('');

  const [tableFilter, setTableFilter] = useState<{ order: string; dir: string; per_page?: number; page?: number }>({
    order: 'created_at',
    dir: 'DESC',
    per_page: 15,
    page: Number(queryPage) || 1
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleOrderBy = (order: string, dir: string) => {
    setTableFilter({ ...tableFilter, order, dir });
  };

  const handlePagination = (page: number = 0) => {
    history.push({ search: `?page=${page}` });
    setTableFilter({ ...tableFilter, page });
  };

  const handleExportMessageReceive = {
    received(message: any) {
      if (message.type === 'export') {
        dispatch(updateJobExportFromCable({ data: message['export'] }));
      }
    }
  };

  const handleExportDownload = (exportURl: string) => {
    const fileName = exportURl
      .substring(exportURl.lastIndexOf('/') + 1)
      .replace(/\s+/g, '')
      .toLowerCase();

    FileSaver.saveAs(exportURl, fileName, { autoBom: true });
  };

  const handleModalDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setSelectExportItemId(itemId);
  };

  const handleExportDelete = () => {
    dispatch(
      deleteJobExport({ jobId, exportId: selectExportItemId }, () => {
        setShowDeleteModal(false);

        // Send pagination back if last item is deleted
        if (exportsPagination.page > 1 && exportsList.length === 1) {
          setTableFilter({ ...tableFilter, page: exportsPagination.page - 1 });
        }
      })
    );
  };

  const handleExportItemDuplicate = (exportItem: Export) => {
    dispatch(
      createJobExport({
        jobId,
        name: `${exportItem.name} (duplicate)`,

        export_preference: exportItem.export_preference,
        export_type: exportItem.export_type,
        export_format: exportItem.export_format,

        photo_filename_filter: exportItem.photo_filename_filter,

        group_by: exportItem.group_by,
        field_options: exportItem.field_options,
        subject_filter: exportItem.subject_filter,
        subject_ids: exportItem.subject_ids,
        subject_count: exportItem.subject_count,

        width: exportItem.width,
        height: exportItem.height,

        crop: exportItem.crop,
        crop_preset: exportItem.crop_preset,
        cropper_height: exportItem.cropper_height,
        cropper_width: exportItem.cropper_width,
        cropper_x: exportItem.cropper_x,
        cropper_y: exportItem.cropper_y,

        head_size: exportItem.head_size,
        x_position: exportItem.x_position,
        y_position: exportItem.y_position
      })
    );
  };

  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handleExportMessageReceive);

  useEffect(() => {
    dispatch(getJobExportList({ jobId, ...tableFilter }));
  }, [tableFilter, queryPage]);

  return (
    <>
      <header className="flex between middle job-exports__header">
        <h2 className="text--nomargin">Exports</h2>
        <aside>
          <Tooltip {...{ title: 'Create Export', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
            <button className="button button--small button--lean" name="create" type="button" onClick={() => setShowExportCreate(true)}>
              <i className="icon-add"></i>
            </button>
          </Tooltip>
        </aside>
      </header>

      {requesting ? (
        <TableLoader rows={4} rowHeight={76} />
      ) : (
        <>
          {exportsList?.length ? (
            <>
              <article className="table-box animate">
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th>
                        <Dropdown {...{ buttonName: 'Date', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'created_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('created_at', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'created_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('created_at', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th>
                        <Dropdown {...{ buttonName: 'Name', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('name', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('name', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th>
                        <Dropdown {...{ buttonName: 'Type', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'export_type' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('export_type', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'export_type' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('export_type', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th>Format</th>
                      <th>Subjects Included</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {exportsList.map((item: any) => (
                      <tr key={item.id}>
                        <td data-header="Date">{moment(item.created_at).format('MM/DD/YY')}</td>
                        <td data-header="Name">
                          {/* Created or Type CSV or Compositing Data */}
                          {(item.status === 'created' || (item.status === 'download' && item.export_type?.match(/subject_data|compositing/))) && (
                            <Link className="button button--link" to={`/jobs/${jobId}/services/exports/${item.id}/settings`}>
                              {item.name}
                            </Link>
                          )}

                          {/* Review or Download (exclude type CSV and Compositing Data) */}
                          {item.status?.match(/review|download/) && item.export_type?.match(/subject_data|compositing/) === null && (
                            <Link className="button button--link" to={`/jobs/${jobId}/services/exports/${item.id}/review`}>
                              {item.name}
                            </Link>
                          )}

                          {/* Cropping, Preparing, Ready or Failed (exclude type CSV and Compositing Data) */}
                          {item.status?.match(/cropping|preparing|failed|ready/) && item.export_type?.match(/subject_data|compositing/) === null && item.name}

                          {/* Preparing, Failed and Type CSV or Compositing Data */}
                          {item.status?.match(/failed|preparing|ready/) && item.export_type?.match(/subject_data|compositing/) && item.name}
                        </td>
                        <td data-header="Type" className="text--capitalize">
                          {item?.export_type?.replace('_', ' ') ?? '-'}
                        </td>
                        <td data-header="Format" className="text--uppercase">
                          {item?.export_format ?? '-'}
                        </td>
                        <td data-header="Subjects Included">{item?.subject_count ? `${item.subject_count} of ${job?.photo_stats?.subjects}` : '-'}</td>
                        <td data-header="Status" className="text--capitalize">
                          {item.status === 'review'
                            ? 'Ready for review'
                            : item.status === 'download'
                              ? 'Complete'
                              : item.status === 'ready'
                                ? 'Processing'
                                : item.status}
                        </td>
                        <td data-header="Action" className="flex">
                          <Tooltip {...{ title: 'Download export', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
                            {item.export_type?.match(/subject_data|compositing/) || item.download_url === null ? (
                              <button
                                className="button button--clean button--small"
                                name="button"
                                type="button"
                                disabled={item.download_url === null}
                                onClick={() => handleExportDownload(item.download_url)}
                              >
                                <i className="icon-download-box" />
                              </button>
                            ) : (
                              <a
                                href={item.download_url}
                                className="button button--clean button--small"
                                type="button"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="icon-download-box" />
                              </a>
                            )}
                          </Tooltip>
                          <Tooltip {...{ title: 'Delete export', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
                            <button className="button button--clean button--small" name="button" type="button" onClick={() => handleModalDeleteShow(item.id)}>
                              <i className="icon-trash" />
                            </button>
                          </Tooltip>
                          <Tooltip {...{ title: 'Duplicate export', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
                            <button
                              className="button button--clean button--small"
                              name="button"
                              type="button"
                              disabled={item.status === 'failed'}
                              onClick={() => handleExportItemDuplicate(item)}
                            >
                              <i className="icon-duplicate" />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
              {exportsPagination.total > exportsPagination.perPage && (
                <Pagination pagination={exportsPagination} onPagination={handlePagination} showPagesCount={4} />
              )}
            </>
          ) : (
            <aside className="flex column middle center panel panel--secondary panel--tall">
              <h2>
                Add your first export{' '}
                <button className="button button--link button--lean" name="add" type="button" onClick={() => setShowExportCreate(true)}>
                  here{' '}
                </button>
                .
              </h2>
            </aside>
          )}
        </>
      )}

      {/* Delete modal */}
      <aside className={`modal ${showDeleteModal ? '' : 'transparent'} text--left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowDeleteModal(false)}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete item</h3>
          </header>
          <main className="modal__content">
            <p>Are you sure you want to delete this item?</p>
          </main>
          <footer className="flex center modal__footer button-group">
            <button className="button button--danger" name="delete" type="button" onClick={handleExportDelete} disabled={requesting}>
              Delete
            </button>
            <button className="button button--lean" name="cancel" type="button" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>

      {showExportCreate && <ExportCreate history={history} jobId={jobId} onExportCreateModalClose={() => setShowExportCreate(false)} />}
    </>
  );
};

export default ExportsList;
