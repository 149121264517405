import React from 'react';
import ReactDOM from 'react-dom/client';

// Components
import Routes from '@/routes.tsx';

// Plugins
import TagManager from 'react-gtm-module';
import { init as initFullStory } from '@fullstory/browser';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

// Styles
import '@/index.css';

const PRODUCTION_ENV = 'production';
const DEVELOPMENT_ENV = 'development';
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const GOOGLE_TAG_ID = import.meta.env.VITE_GOOGLE_TAG ?? '';
const FULLSTORY_ORG_ID = import.meta.env.VITE_FULLSTORY_ORG_ID;
const FLAGSMITH_ENV_KEY = import.meta.env.VITE_FLAGSMITH_ENV_KEY;

// Initialize Google Tag Manager
if (ENVIRONMENT === PRODUCTION_ENV) {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_ID,
    dataLayerName: 'PageDataLayer'
  });

  (window as any).dataLayer?.push({ event: 'pageview' });
}

// Initialize FullStory
if (FULLSTORY_ORG_ID) {
  initFullStory({
    orgId: FULLSTORY_ORG_ID,
    devMode: ENVIRONMENT !== PRODUCTION_ENV
  });
}

// Initialize flagsmith before rendering
flagsmith.init({
  environmentID: FLAGSMITH_ENV_KEY,
  cacheFlags: ENVIRONMENT !== DEVELOPMENT_ENV,
  enableAnalytics: ENVIRONMENT === PRODUCTION_ENV
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <FlagsmithProvider flagsmith={flagsmith}>
      <Routes />
    </FlagsmithProvider>
  </React.StrictMode>
);
