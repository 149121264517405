import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';

import { Input } from '@/components/Shared/Forms';

class SecurityForm extends Component {
  state = { showPassword: false };

  handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    const { loading, requesting, handleSubmit } = this.props;
    const inputType = this.state.showPassword ? 'text' : 'password';

    return (
      <section className="flex-12">
        <Form onSubmit={handleSubmit}>
          <header className="flex middle between user-container__header">
            <h2 className="text--nomargin">Update Password</h2>
            <button type="submit" className="button button--medium" disabled={requesting || loading}>
              Save
            </button>
          </header>

          <main className="panel panel--section account-settings-item">
            <div className="flex gap-10">
              <div className="flex-4">
                <Input label="Current Password" name="old_password" type={inputType} loading={loading} autoComplete="new-password" />
              </div>
              <div className="flex-4">
                <Input label="New Password" name="password" type={inputType} autoComplete="new-password" loading={loading} />
              </div>
            </div>
            <div>
              <fieldset>
                <input type="checkbox" className="hidden" name="password" id="password" checked={this.state.showPassword} onChange={this.handleShowPassword} />
                <label htmlFor="password" className="label--checkbox">
                  Show Password
                </label>
              </fieldset>
            </div>
          </main>
        </Form>
      </section>
    );
  }
}

export default reduxForm({ form: 'SecurityForm', enableReinitialize: true, destroyOnUnmount: false })(SecurityForm);
