import { FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { restoreJob } from '../actions';

// Images
import imageJobArchived from '@/assets/images/job-illustration-archived.svg';

const NotFoundArchived: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { jobId: string } = useParams();
  const { jobId } = params;

  const handleRestoreJob = () => {
    dispatch(restoreJob({ id: jobId }, () => history.push('/jobs')));
  };

  return (
    <main className="box flex column middle center nowrap h-80">
      <img className="mb-30" src={imageJobArchived} width="382" alt="Job Archived" />
      <h1 className="text--xxxlarge">Whoops...it looks like this job has been archived</h1>
      <button className="button button--link text--large" type="button" name="restore" onClick={handleRestoreJob}>
        Restore this job
      </button>
    </main>
  );
};

export default NotFoundArchived;
