import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { createPricesheetItemByProductsRequest, getPricesheetRequest, createPriceSheetItemDigitalBundle } from './actions';

// Components
import ProfitCalculator from './ProfitCalculator';
import ProductsSelector from './ProductsSelector';

// Helpers
import { convertToCents, convertToText } from '@/utils/currency';
import { getProductCostByShippingType } from '@/utils/productCostByShippingType';

import { DIGITAL_PRICE_MINIMUM, DIGITAL_BUNDLE_MIN_PHOTOS, DIGITAL_BUNDLE_MAX_PHOTOS } from './constants';

const DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT = 10; // %

const AddProductModal = ({ isVisible, requesting, markup, pdFee, onClose, priceSheetId, labId, currentSection, existingProductsIds, shippingType }) => {
  const dispatch = useDispatch();

  const { entities } = useSelector((state) => state.productCategoriesDDL);
  const currentLabProducts = entities[labId] && entities[labId].products;

  const [errors, setErrors] = useState({});
  const [confirmClose, setConfirmClose] = useState(false);

  const [productsAdded, setProductsAdded] = useState([]);
  const [bulkProductsAdded, setBulkProductsAdded] = useState([]);
  const [digitalProductBundleAdded, setDigitalProductBundleAdded] = useState(null);

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [excludeProducts, setExcludeProducts] = useState([]);

  const [selectedContentIndex, setSelectedContentIndex] = useState(null);

  const handleSelectedContentIndex = (index) => setSelectedContentIndex(index);

  const handleProductsAdd = (products) => {
    const newProducts = products.map((product) => {
      const baseCostCents = product.price_cents + product.photoday_price_cents;
      const baseCostCentsByShipping = getProductCostByShippingType(product.price_cents, product.bulk_price_cents, shippingType) + product.photoday_price_cents;
      const minDownloadMarkupCents = DIGITAL_PRICE_MINIMUM;
      const markupCents =
        markup.markup_type === 'fixed_amount'
          ? product.is_digital_download
            ? Math.max(markup.fixed_amount_cents, minDownloadMarkupCents)
            : markup.fixed_amount_cents
          : product.is_digital_download
            ? Math.max(Math.floor((Math.max(baseCostCentsByShipping, minDownloadMarkupCents) * markup.fixed_percentage) / 100.0), minDownloadMarkupCents)
            : Math.floor((baseCostCentsByShipping * markup.fixed_percentage) / 100);

      const minimumPriceCents = product.is_digital_download ? minDownloadMarkupCents : Math.ceil(baseCostCentsByShipping / ((100 - pdFee) / 100)) + 1;
      const formattedValue = convertToText(baseCostCentsByShipping + markupCents, '$');

      return {
        ...product,
        baseCostCents,
        baseCostCentsByShipping,
        markupCents,
        formattedValue,
        retailPrice: baseCostCentsByShipping + markupCents,
        minimumPriceCents
      };
    });

    setEnableSubmit(true);
    setProductsAdded([...productsAdded, ...newProducts]);
  };

  const handleProductRemove = (index) => {
    const newProducts = productsAdded;
    productsAdded.splice(index, 1);

    setProductsAdded([...newProducts]);
    setSelectedContentIndex(null);
    handleValidate();
  };

  const handleQuantityChange = ({ index, quantity }) => {
    const newProducts = productsAdded;
    const currentProduct = newProducts[index];

    const labPrice = currentProduct.price_cents * quantity;
    const labPriceByShipping = getProductCostByShippingType(currentProduct.price_cents, currentProduct.bulk_price_cents, shippingType) * quantity;
    const photodayPrice = currentProduct.photoday_price_cents * quantity;
    const baseCostCentsByShipping = labPriceByShipping + photodayPrice;
    const digitalProductsCount = currentProduct && currentProduct.is_digital_download ? quantity : 0;

    const calc = new ProfitCalculator({
      digitalProductsCount: digitalProductsCount,
      baseCostCents: baseCostCentsByShipping,
      markup: markup,
      pdFee: pdFee
    });

    calc.calculate();

    currentProduct.quantity = quantity;
    currentProduct.formattedValue = convertToText(calc.priceCents, '$');
    currentProduct.retailPrice = calc.priceCents;
    currentProduct.baseCostCents = labPrice + photodayPrice;
    currentProduct.baseCostCentsByShipping = calc.baseCostCents;
    currentProduct.markupCents = calc.markupCents;
    currentProduct.minimumPriceCents = calc.minimumPriceCents;

    setProductsAdded([...newProducts]);
    handleValidate();
  };

  const handlePriceChange = (input) => {
    const price = convertToCents(input.value);
    const newProducts = productsAdded;
    const currentProduct = newProducts[selectedContentIndex];
    const digitalProductsCount = currentProduct && currentProduct.is_digital_download ? currentProduct.quantity : 0;

    if (currentProduct) {
      const baseCostCents = currentProduct.baseCostCentsByShipping;

      const calc = new ProfitCalculator({
        digitalProductsCount: digitalProductsCount,
        baseCostCents: baseCostCents,
        priceCents: price,
        pdFee: pdFee
      });

      calc.calculate();

      currentProduct.formattedValue = convertToText(calc.priceCents, '$');
      currentProduct.retailPrice = calc.priceCents;
      currentProduct.baseCostCentsByShipping = calc.baseCostCents;
      currentProduct.markupCents = calc.markupCents;
      currentProduct.minimumPriceCents = calc.minimumPriceCents;

      setProductsAdded([...newProducts]);

      if (digitalProductBundleAdded?.digital_bundle_pricing_type === 'digitals_tiered_pricing') {
        // recalculate tier prices
        const updatedDigitalBundleTiers = digitalProductBundleAdded.digital_bundle_tiers_attributes.map((tier) => {
          const price_cents = convertToText(price - (price * Number(tier.discount)) / 100);
          return { ...tier, price_cents };
        });
        // call handleValidate on the useEffect in order to validate every single field change
        setDigitalProductBundleAdded((prevState) => ({ ...prevState, digital_bundle_tiers_attributes: updatedDigitalBundleTiers }));
      } else {
        handleValidate();
      }
    }
  };

  const handleBundlePricingTypeChange = ({ bundleType }) => {
    const currentProduct = productsAdded[selectedContentIndex];

    if (bundleType === 'digitals_tiered_pricing') {
      setDigitalProductBundleAdded({
        product_id: currentProduct?.id,
        markup_cents: currentProduct?.markupCents,
        digital_bundle_pricing_type: 'digitals_tiered_pricing',
        digital_bundle_tiers_attributes: [
          {
            min_qty: DIGITAL_BUNDLE_MIN_PHOTOS,
            max_qty: DIGITAL_BUNDLE_MIN_PHOTOS + 2,
            price_cents: convertToText(currentProduct?.retailPrice - (currentProduct?.retailPrice * DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT) / 100),
            discount: DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT
          }
        ]
      });
    } else if (bundleType === 'digitals_buy_all_pricing') {
      setDigitalProductBundleAdded({
        product_id: currentProduct?.id,
        markup_cents: currentProduct?.markupCents,
        digital_bundle_pricing_type: 'digitals_buy_all_pricing',
        digital_bundle_max_price_cents: convertToText(currentProduct?.retailPrice + 100),
        digital_bundle_max_qty: DIGITAL_BUNDLE_MAX_PHOTOS
      });
    } else {
      setDigitalProductBundleAdded(null);
    }

    // Reset errors
    setErrors({});
    setEnableSubmit(true);
  };

  const handleBundlePricingChange = ({ bundleProp, bundleValue, bundleTierValue }) => {
    let updatedBundleValue = bundleValue;

    if (bundleTierValue) {
      updatedBundleValue = digitalProductBundleAdded?.digital_bundle_tiers_attributes.map((tier, index) => {
        if (index === bundleTierValue.index) {
          const retailPrice = Number(productsAdded[selectedContentIndex]?.retailPrice / 100);

          // Update PPP or Discount if anyone gets changed
          if (bundleTierValue.prop === 'price_cents') {
            const discountApplied = Math.max(((retailPrice - bundleTierValue.value) / retailPrice) * 100, 0);

            return { ...tier, price_cents: bundleTierValue.value, discount: discountApplied };
          }

          if (bundleTierValue.prop === 'discount') {
            const pppApplied = retailPrice - (retailPrice * bundleTierValue.value) / 100;

            return { ...tier, price_cents: pppApplied, discount: bundleTierValue.value };
          }

          return { ...tier, [bundleTierValue.prop]: bundleTierValue.value };
        }

        return tier;
      });
    }

    setDigitalProductBundleAdded({ ...digitalProductBundleAdded, [bundleProp]: updatedBundleValue });

    // Reset errors
    setErrors({});
    setEnableSubmit(true);
  };

  const handleBundleTierAddRemove = ({ action, tierIndex }) => {
    const bundleTiers = digitalProductBundleAdded?.digital_bundle_tiers_attributes;

    if (action === 'add') {
      const bundleTiersLength = bundleTiers?.length ?? 0;
      const lastBundleTier = bundleTiers[bundleTiersLength - 1];

      const retailPrice = convertToText(productsAdded[selectedContentIndex]?.retailPrice);
      const newPriceCents = lastBundleTier?.price_cents - lastBundleTier?.price_cents * 0.1; // 10%
      const newDiscountApplied = Math.round(((retailPrice - newPriceCents) / retailPrice) * 100);

      const newBundleTier = {
        min_qty: lastBundleTier.max_qty + 1,
        max_qty: Math.min(lastBundleTier?.max_qty + 5, DIGITAL_BUNDLE_MAX_PHOTOS),
        price_cents: newPriceCents,
        discount: newDiscountApplied
      };

      setDigitalProductBundleAdded({ ...digitalProductBundleAdded, digital_bundle_tiers_attributes: [...bundleTiers, newBundleTier] });
    }

    if (action === 'remove') {
      const updatedBundleTiers = bundleTiers.filter((_, index) => index !== tierIndex);

      setDigitalProductBundleAdded({ ...digitalProductBundleAdded, digital_bundle_tiers_attributes: updatedBundleTiers });
    }

    // Reset errors
    setErrors({});
    setEnableSubmit(true);
  };

  const handleClose = () => {
    productsAdded.length === 0 && onClose();
    !confirmClose && setConfirmClose(true);
    confirmClose && onClose();
  };

  const handleValidate = (saveOnComplete) => {
    const newErrors = {};
    const newProducts = productsAdded.map((product) => {
      if (product.retailPrice < product.minimumPriceCents) {
        newErrors.profit = `Minimum retail price must be ${convertToText(product.minimumPriceCents, '$')} or higher.`;
        newErrors.contents = 'Please review the products marked red.';
        return { ...product, error: true };
      }

      if (product.is_digital_download) {
        if (product.markupCents < DIGITAL_PRICE_MINIMUM) {
          newErrors.digital = 'The retail price must be at least $5.00.';
          newErrors.contents = 'Please review the products marked red.';
          return { ...product, error: true };
        }

        // Digital bundle validations
        if (digitalProductBundleAdded) {
          if (digitalProductBundleAdded.digital_bundle_pricing_type === 'digitals_tiered_pricing') {
            const digitalBundleTiers = digitalProductBundleAdded.digital_bundle_tiers_attributes ?? [];

            for (const i in digitalBundleTiers) {
              const currentTier = digitalBundleTiers[i];
              const previousTier = digitalBundleTiers[i - 1];

              const { min_qty: currMinQty, max_qty: currMaxQty, price_cents: currPPP } = currentTier;

              // #1 Quantity should be greater than 1
              if (Math.min(currMinQty, currMaxQty) < DIGITAL_BUNDLE_MIN_PHOTOS) {
                newErrors.digital = 'Bundle tiers minimum quantity must be greater than 1.';
                return { ...product, error: true };
              }

              // #2 Min quantity should be less than Max quantity
              if (currMinQty >= currMaxQty) {
                newErrors.digital = 'Minimum quantity should be less than maximum quantity in each tier.';
                return { ...product, error: true };
              }

              // #3 PPP should be less than Retail Price
              if (currPPP >= Number(product.retailPrice / 100)) {
                newErrors.digital = 'Tier prices should be less than the retail price.';
                return { ...product, error: true };
              }

              if (previousTier) {
                const { max_qty: prevMaxQty, price_cents: prevPriceCents } = previousTier;

                // #4 Tier quantities should always show progression
                if (prevMaxQty >= currMinQty) {
                  newErrors.digital = 'Each tier must have greater quantities than its preceding tier.';
                  return { ...product, error: true };
                }

                // #5 Tiers PPP should always show regression in pricing
                if (Number(currPPP) >= Number(prevPriceCents)) {
                  newErrors.digital = 'Each tier must be cheaper than its preceding tier.';
                  return { ...product, error: true };
                }
              }
            }
          }

          if (digitalProductBundleAdded.digital_bundle_pricing_type === 'digitals_buy_all_pricing') {
            // #1 Max retail price should be greater than retail price.
            if (
              digitalProductBundleAdded.digital_bundle_max_qty < DIGITAL_BUNDLE_MIN_PHOTOS ||
              digitalProductBundleAdded.digital_bundle_max_qty > DIGITAL_BUNDLE_MAX_PHOTOS
            ) {
              newErrors.digital = `Max allowed digitals should be greater or equal to ${DIGITAL_BUNDLE_MIN_PHOTOS} and less or equal to ${DIGITAL_BUNDLE_MAX_PHOTOS}.`;
              return { ...product, error: true };
            }

            // #2 Max retail price should be greater than retail price.
            if (Number(digitalProductBundleAdded.digital_bundle_max_price_cents * 100) < product.retailPrice) {
              newErrors.digital = 'Max retail price should be greater than retail price.';
              return { ...product, error: true };
            }
          }
        }
      }

      return { ...product, error: false };
    });

    setErrors(newErrors);
    setProductsAdded([...newProducts]);

    if (Object.values(newErrors).length === 0) {
      saveOnComplete ? handleSave() : setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  };

  const checkForBulkProducts = () => {
    const bulkProducts = [];

    productsAdded.forEach((added) => {
      const bulkProduct = Object.values(currentLabProducts).find(
        (product) => product.id === added.id && product.bulk_price_cents > 0 && product.bulk_price_cents !== product.price_cents
      );

      bulkProduct && bulkProducts.push(bulkProduct);
    });

    setBulkProductsAdded(bulkProducts);
  };

  const handleSave = () => {
    if (digitalProductBundleAdded) {
      // Convert necessary numbers to cents
      const updatedDigitalProductBundleAdded = {
        ...digitalProductBundleAdded,
        ...(digitalProductBundleAdded?.digital_bundle_tiers_attributes
          ? {
              digital_bundle_tiers_attributes: digitalProductBundleAdded?.digital_bundle_tiers_attributes.map((tier) => ({
                ...tier,
                price_cents: convertToCents(tier.price_cents)
              }))
            }
          : {}),
        digital_bundle_max_price_cents: convertToCents(digitalProductBundleAdded.digital_bundle_max_price_cents)
      };

      dispatch(
        createPriceSheetItemDigitalBundle({ priceSheetId, ...updatedDigitalProductBundleAdded }, () => {
          // Refresh pricesheet list
          dispatch(getPricesheetRequest(priceSheetId, () => onClose()));
        })
      );
    } else {
      const addProducts = productsAdded.map((product) => ({ id: product.id, quantity: product.quantity, markup_cents: product.markupCents }));

      dispatch(
        createPricesheetItemByProductsRequest({ priceSheetId, products: addProducts }, () => {
          // Refresh pricesheet list
          dispatch(getPricesheetRequest(priceSheetId, () => onClose()));
        })
      );
    }
  };

  useEffect(() => {
    const addedIds = productsAdded.map((product) => product.id);
    setExcludeProducts([...existingProductsIds, ...addedIds]);

    const newErrors = productsAdded.some((product) => product.error);
    !newErrors && setErrors({});

    checkForBulkProducts();
  }, [productsAdded]);

  useEffect(() => {
    setExcludeProducts([...existingProductsIds]);
  }, []);

  useEffect(() => {
    handleValidate();
  }, [digitalProductBundleAdded?.digital_bundle_tiers_attributes]);

  return (
    <>
      <aside className={`modal add-product-modal ${isVisible ? '' : 'transparent'} animate`}>
        <div className="modal__box modal__box--secondary modal__box--xlarge">
          <button className="button button--action modal__close" name="close" type="button" onClick={handleClose}>
            <i className="icon-close"></i>
          </button>

          <ProductsSelector
            pdFee={pdFee}
            labId={labId}
            errors={errors}
            isPackage={false}
            requesting={requesting}
            shippingType={shippingType}
            currentSection={currentSection}
            productsAdded={productsAdded}
            digitalProductBundleAdded={digitalProductBundleAdded}
            bulkProductsAdded={bulkProductsAdded}
            excludeProducts={excludeProducts}
            onPriceChange={handlePriceChange}
            onBundlePricingTypeChange={handleBundlePricingTypeChange}
            onBundlePricingChange={handleBundlePricingChange}
            onBundleTierChange={handleBundleTierAddRemove}
            onProductsAdd={handleProductsAdd}
            onProductRemove={handleProductRemove}
            onQuantityChange={handleQuantityChange}
            onSelectedContentIndex={handleSelectedContentIndex}
          />

          <footer className="flex end modal__footer modal__footer--secondary">
            <button className="button button--medium" disabled={requesting || !enableSubmit} onClick={() => handleValidate(true)} data-loading={requesting}>
              Save
            </button>
          </footer>
        </div>
      </aside>

      {/* Confirm close modal */}
      <aside className={`modal modal__confirm-close ${confirmClose ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--secondary modal__box--xlarge">
          <main className="modal__content flex column center middle">
            <p>Are you sure you want to close the window?</p>
            <small>The product(s) will not be saved.</small>
            <button className="button button--medium button--outline" onClick={() => setConfirmClose(false)}>
              No, take me back
            </button>
            <button className="button button--medium button--clean" onClick={handleClose}>
              Yes, I'm sure
            </button>
          </main>
        </div>
      </aside>
    </>
  );
};

AddProductModal.propTypes = {
  isVisible: PropTypes.bool,
  requesting: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  priceSheetId: PropTypes.string.isRequired,
  markup: PropTypes.object.isRequired,
  labId: PropTypes.string.isRequired,
  currentSection: PropTypes.string.isRequired,
  existingProductsIds: PropTypes.array.isRequired
};

AddProductModal.defaultProps = {
  isVisible: true,
  requesting: true,
  onClose: () => {},
  initialValues: {},
  priceSheetId: '',
  markup: {},
  labId: '',
  currentSection: '',
  existingProductsIds: []
};

export default AddProductModal;
