import { FC, useRef, useState, useEffect } from 'react';

// Plugins
import Cropper from 'react-cropper';
import Select, { SingleValue, OnChangeValue } from 'react-select';

// Helpers
import validator from '@/utils/validator';

// Images
import imageSilhouette from '@/assets/images/image-silhouette-auto-crop.jpg';

// Styles
import './style.css';
import 'cropperjs/dist/cropper.css';

// Types
import { SelectOptionType, Export, Crop } from '@/types';

interface ExportCroppingProps {
  jobExport: Export;
  onExportStepModalClose: () => void;
  onExportStepComplete: (newValues: Export, step: string) => void;
}

const cropPresets = [
  {
    id: 'default',
    width: 640,
    height: 800,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 302,
        cropper_y: 317,
        cropper_width: 1061,
        cropper_height: 1327,
        x_position: 26,
        y_position: 10,
        head_size: 4.946
      },
      {
        id: 'tight_fitting',
        cropper_x: 383,
        cropper_y: 376,
        cropper_width: 898,
        cropper_height: 1123,
        x_position: -26,
        y_position: -29,
        head_size: 5.844
      },
      {
        id: 'loose_fitting',
        cropper_x: 184,
        cropper_y: 247,
        cropper_width: 1297,
        cropper_height: 1622,
        x_position: 79,
        y_position: 43,
        head_size: 4.046
      }
    ]
  },
  {
    id: 'spoa',
    width: 180,
    height: 225,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 502,
        cropper_y: 515,
        cropper_width: 682,
        cropper_height: 852,
        x_position: -41,
        y_position: -48,
        head_size: 2.17
      }
    ]
  },
  {
    id: 'id_card_1',
    width: 450,
    height: 450,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 169,
        cropper_y: 317,
        cropper_width: 1327,
        cropper_height: 1327,
        x_position: 60,
        y_position: 6,
        head_size: 2.781
      },
      {
        id: 'tight_fitting',
        cropper_x: 271,
        cropper_y: 376,
        cropper_width: 1123,
        cropper_height: 1123,
        x_position: 30,
        y_position: -16,
        head_size: 3.286
      },
      {
        id: 'loose_fitting',
        cropper_x: 21,
        cropper_y: 247,
        cropper_width: 1622,
        cropper_height: 1622,
        x_position: 90,
        y_position: 24,
        head_size: 2.275
      }
    ]
  },
  {
    id: 'id_card_2',
    width: 400,
    height: 600,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 390,
        cropper_y: 317,
        cropper_width: 884,
        cropper_height: 1327,
        x_position: -19,
        y_position: 8,
        head_size: 3.709
      },
      {
        id: 'tight_fitting',
        cropper_x: 458,
        cropper_y: 376,
        cropper_width: 748,
        cropper_height: 1123,
        x_position: -59,
        y_position: -21,
        head_size: 4.384
      },
      {
        id: 'loose_fitting',
        cropper_x: 292,
        cropper_y: 247,
        cropper_width: 1081,
        cropper_height: 1622,
        x_position: 19,
        y_position: 32,
        head_size: 3.034
      }
    ]
  },
  {
    id: 'id_card_3',
    width: 400,
    height: 500,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 302,
        cropper_y: 317,
        cropper_width: 1061,
        cropper_height: 1327,
        x_position: 16,
        y_position: 6,
        head_size: 3.091
      },
      {
        id: 'tight_fitting',
        cropper_x: 383,
        cropper_y: 376,
        cropper_width: 898,
        cropper_height: 1123,
        x_position: -16,
        y_position: -18,
        head_size: 3.652
      },
      {
        id: 'loose_fitting',
        cropper_x: 184,
        cropper_y: 247,
        cropper_width: 1297,
        cropper_height: 1622,
        x_position: 49,
        y_position: 27,
        head_size: 2.529
      }
    ]
  },
  {
    id: 'custom',
    width: 640,
    height: 800,
    presets: [
      {
        id: 'standard_fitting',
        cropper_x: 302,
        cropper_y: 317,
        cropper_width: 1061,
        cropper_height: 1327,
        x_position: 26,
        y_position: 10,
        head_size: 4.946
      }
    ]
  }
];

const cropAspectRatioOptions = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: '1:1',
    value: 'id_card_1'
  },
  {
    label: '2:3',
    value: 'id_card_2'
  },
  {
    label: '4:5',
    value: 'id_card_3'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

const cropTypeOptions = [
  {
    label: 'Standard',
    value: 'standard_fitting'
  },
  {
    label: 'Tight',
    value: 'tight_fitting'
  },
  {
    label: 'Loose',
    value: 'loose_fitting'
  }
];

const cropResolutionOptions = [
  {
    label: 'Standard',
    value: 'standard_resolution'
  },
  {
    label: 'Custom',
    value: 'custom_resolution'
  }
];

const ExportCropping: FC<ExportCroppingProps> = ({ jobExport, onExportStepModalClose, onExportStepComplete }) => {
  const cropperRef = useRef<HTMLImageElement>(null);

  const [autoCrop, setAutoCrop] = useState<boolean>(false);

  const [cropAspectRatio, setCropAspectRatio] = useState<SingleValue<SelectOptionType>>(null);
  const [cropType, setCropType] = useState<SingleValue<SelectOptionType>>(null);
  const [cropResolution, setCropResolution] = useState<SingleValue<SelectOptionType>>(null);

  const [cropWidth, setCropWidth] = useState<number>(0);
  const [cropHeight, setCropHeight] = useState<number>(1);
  const [cropDimensionsValidate, setCropDimensionsValidate] = useState<boolean>(true);

  const getSelectedPreset = (presets: any, value: string) => presets.find((preset: any) => preset.id === value) || presets[0];
  const getSelectedFitting = (preset: any, value: string) => preset.presets.find((fitting: any) => fitting.id === value) || preset.presets[0];
  const getOptionByValue = (options: any, value: string) => options.find((option: SingleValue<SelectOptionType>) => option?.value === value);

  const setCropValues = (cropValues: Crop) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    // Fix aspect ratio
    cropper.setAspectRatio(Number((cropValues.cropper_width || 0) / (cropValues.cropper_height || 1)));

    cropper.setData({
      width: cropValues.cropper_width,
      height: cropValues.cropper_height,
      x: cropValues.cropper_x,
      y: cropValues.cropper_y
    });
  };

  const handleSetInitialCropValues = () => {
    if (jobExport?.crop_preset) {
      setCropValues({
        cropper_width: jobExport.cropper_width,
        cropper_height: jobExport.cropper_height,
        cropper_x: jobExport.cropper_x,
        cropper_y: jobExport.cropper_y
      });
    } else {
      const defaultPreset =
        cropPresets.find((preset: any) => (jobExport.export_type === 'spoa' ? preset.id === 'spoa' : preset.id === 'standard')) ?? cropPresets[0];
      const defaultPresetFitting = defaultPreset.presets[0];

      setCropValues({
        cropper_width: defaultPresetFitting.cropper_width,
        cropper_height: defaultPresetFitting.cropper_height,
        cropper_x: defaultPresetFitting.cropper_x,
        cropper_y: defaultPresetFitting.cropper_y
      });
    }
  };

  const handleAspectRatioChange = (select: OnChangeValue<SelectOptionType, false>) => {
    // Set new values accordingly to selected preset
    const selectedPreset = getSelectedPreset(cropPresets, select?.value ?? '');
    const selectedFitting = selectedPreset.presets[0];

    setCropValues({
      cropper_width: selectedFitting.cropper_width,
      cropper_height: selectedFitting.cropper_height,
      cropper_x: selectedFitting.cropper_x,
      cropper_y: selectedFitting.cropper_y
    });

    // Reset dropdown state
    setCropAspectRatio(select);
    setCropType(getOptionByValue(cropTypeOptions, 'standard_fitting'));
    setCropResolution(getOptionByValue(cropResolutionOptions, select?.value === 'custom' ? 'custom_resolution' : 'standard_resolution'));

    setCropWidth(selectedPreset.width);
    setCropHeight(selectedPreset.height);
  };

  const handleTypeChange = (select: OnChangeValue<SelectOptionType, false>) => {
    // Set new values accordingly to selected preset fitting
    const selectedPreset = getSelectedPreset(cropPresets, cropAspectRatio?.value ?? '');
    const selectedFitting = getSelectedFitting(selectedPreset, select?.value ?? '');

    setCropValues({
      cropper_width: selectedFitting.cropper_width,
      cropper_height: selectedFitting.cropper_height,
      cropper_x: selectedFitting.cropper_x,
      cropper_y: selectedFitting.cropper_y
    });

    setCropType(select);
    setCropResolution(getOptionByValue(cropResolutionOptions, 'standard_resolution'));

    setCropWidth(selectedPreset.width);
    setCropHeight(selectedPreset.height);
  };

  const handleResolutionChange = (select: OnChangeValue<SelectOptionType, false>) => {
    // Reset cropper width and height values
    const selectedPreset = getSelectedPreset(cropPresets, cropAspectRatio?.value ?? '');
    const selectedFitting = getSelectedFitting(selectedPreset, select?.value ?? '');

    setCropValues({
      cropper_width: selectedFitting.cropper_width,
      cropper_height: selectedFitting.cropper_height,
      cropper_x: selectedFitting.cropper_x,
      cropper_y: selectedFitting.cropper_y
    });

    setCropWidth(selectedPreset.width);
    setCropHeight(selectedPreset.height);

    setCropResolution(select);
  };

  const handleDimensionsChange = (dimension: string, dimensionValue: number) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    const cropperData = cropper.getData(true);

    if (dimension === 'width') {
      const dimensionDiff = Math.abs(dimensionValue - cropWidth);

      setCropWidth(dimensionValue);

      cropper.setAspectRatio(dimensionValue / cropHeight);
      cropper.setData({ width: dimensionValue > cropWidth ? cropperData.width + dimensionDiff : cropperData.width - dimensionDiff });
    } else if (dimension === 'height') {
      const dimensionDiff = Math.abs(dimensionValue - cropHeight);

      setCropHeight(dimensionValue);

      cropper.setAspectRatio(cropWidth / dimensionValue);
      cropper.setData({ height: dimensionValue > cropHeight ? cropperData.height + dimensionDiff : cropperData.height - dimensionDiff });
    }
  };

  const handleDimensionsValidate = (e: any, validationOne: any, validationTwo: any) => {
    const input = e.target;
    const valid = validator({ ...validationOne, value: Number(input.value) }) && validator({ ...validationTwo, value: Number(input.value) });

    input.classList[`${valid ? 'remove' : 'add'}`]('input--invalid');

    setCropDimensionsValidate(valid);
  };

  const handleCropConfirm = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    const cropperData = cropper.getData(true);
    const cropperImageData = cropper.getImageData();

    // Desired output Width and Height
    const outputWidth = cropWidth;
    const outputHeight = cropHeight;

    // First make sure the image is what we think it is
    console.assert(cropperImageData.naturalWidth === 1704 && cropperImageData.naturalHeight === 2304, 'Are you using the right bg image? It should 1704x2304');

    // If in the future we decided to change the background image we should update the below information regarding the virtual red bounding box.
    const redBoxInfo = [346, 335, 1330, 1645]; // [x1, y1, x2, y2]
    const redBoxOriginalScale = ((redBoxInfo[2] - redBoxInfo[0] + 1) / 120 + (redBoxInfo[3] - redBoxInfo[1] + 1) / 160) / 2;

    // Calculate the red-box's relative scale average to reduce error.
    const selectionScale = (outputWidth / cropperData.width + outputHeight / cropperData.height) / 2;
    const translationX = redBoxInfo[0] - cropperData.x;
    const translationY = redBoxInfo[1] - cropperData.y;

    const cropValues = {
      width: outputWidth,
      height: outputHeight,
      x_position: Math.round(translationX * selectionScale),
      y_position: Math.round(translationY * selectionScale),
      head_size: Number((selectionScale * redBoxOriginalScale).toFixed(2)),
      cropper_height: cropperData.height,
      cropper_width: cropperData.width,
      cropper_x: cropperData.x,
      cropper_y: cropperData.y
    };

    onExportStepComplete(
      {
        ...cropValues,
        crop_preset: cropAspectRatio?.value as any,
        crop_preset_fitting: cropType?.value as any,
        crop_preset_resolution: cropResolution?.value as any,
        crop: autoCrop
      },
      'cropping'
    );
  };

  useEffect(() => {
    // Set apply auto-crop on/off
    if (typeof jobExport?.crop === 'boolean') {
      setAutoCrop(jobExport.crop);
    }

    // Set initial crop values
    let initialAspectRatio: string;
    let initialCropType: string;
    let initialCropResolution: string;
    let initialCropWidth: number;
    let initialCropHeight: number;

    if (jobExport?.crop_preset) {
      initialAspectRatio = jobExport.crop_preset;
      initialCropType = jobExport.crop_preset_fitting || 'standard_fitting';
      initialCropResolution = jobExport.crop_preset_resolution || 'standard_resolution';

      initialCropWidth = jobExport.width || 0;
      initialCropHeight = jobExport.height || 0;
    } else {
      const initialPreset =
        cropPresets.find((preset: any) => (jobExport.export_type === 'spoa' ? preset.id === 'spoa' : preset.id === 'standard')) ?? cropPresets[0];
      const initialPresetFitting = initialPreset.presets[0];

      initialAspectRatio = initialPreset.id || 'default';
      initialCropType = initialPresetFitting.id;
      initialCropResolution = 'standard_resolution';

      initialCropWidth = initialPreset.width;
      initialCropHeight = initialPreset.height;
    }

    setCropAspectRatio(getOptionByValue(cropAspectRatioOptions, initialAspectRatio));
    setCropType(getOptionByValue(cropTypeOptions, initialCropType));
    setCropResolution(getOptionByValue(cropResolutionOptions, initialCropResolution));

    setCropWidth(initialCropWidth);
    setCropHeight(initialCropHeight);
  }, [jobExport]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--secondary">
        <button className="button button--action modal__close" name="button" type="button" onClick={onExportStepModalClose}>
          <i className="icon-close"></i>
        </button>
        <main className="flex modal__content">
          <section className="flex-5 flex-12-sm modal__content-section">
            <h3>Crop Settings</h3>
            <p>
              <small>
                You can adjust the position of the crop by clicking and holding anywhere within the blue box and dragging it to the desired position.
              </small>
            </p>
            <p>
              <small>Photos will be cropped to the blue box.</small>
            </p>
            <p>
              <small>
                <b>Note:</b> Cropping will not work on photos that contain more than one subject or any other photo compositions.
              </small>
            </p>
            <p>
              <small>If you’ve cropped your own images, you can keep the auto crop setting turned off.</small>
            </p>
          </section>
          <section className="flex-7 flex-12-sm modal__content-section">
            <header className="job-exports-cropping__header">
              <fieldset className="flex middle between">
                <h4 className="text--nomargin">Set AutoCrop</h4>
                <input
                  id="autocrop"
                  className="hidden"
                  name="autocrop"
                  type="checkbox"
                  checked={autoCrop}
                  onChange={({ target }) => setAutoCrop(target.checked)}
                />
                <label className="label-switch" htmlFor="autocrop" />
              </fieldset>
            </header>
            <div className={`flex gap-20 nowrap panel panel--dark ${autoCrop ? '' : 'disabled'}`}>
              <figure>
                <Cropper
                  ref={cropperRef}
                  src={imageSilhouette}
                  style={{
                    width: 200,
                    height: 271,
                    margin: '0 auto'
                  }}
                  viewMode={1}
                  center={false}
                  movable={false}
                  scalable={false}
                  zoomable={false}
                  dragMode={'move'}
                  rotatable={false}
                  minCropBoxWidth={100}
                  minCropBoxHeight={100}
                  ready={handleSetInitialCropValues}
                />
                <figcaption className="job-exports-cropping__cropper-message">This is an example image for cropping adjustments.</figcaption>
              </figure>
              <aside className="flex-5">
                {!['yearbook', 'spoa'].includes(jobExport.export_type ?? '') && (
                  <fieldset>
                    <label>Aspect Ratio</label>
                    <Select
                      className="select"
                      classNamePrefix="select"
                      value={cropAspectRatio}
                      options={cropAspectRatioOptions}
                      onChange={handleAspectRatioChange}
                    />
                    <label>Type of Crop</label>
                    <Select
                      className="select"
                      classNamePrefix="select"
                      value={cropType}
                      options={cropTypeOptions}
                      isDisabled={cropAspectRatio?.value === 'custom'}
                      onChange={handleTypeChange}
                    />
                    <label>Resolution</label>
                    <Select
                      className="select"
                      classNamePrefix="select"
                      value={cropResolution}
                      options={cropResolutionOptions}
                      isDisabled={cropAspectRatio?.value === 'custom'}
                      onChange={handleResolutionChange}
                    />
                  </fieldset>
                )}
                <h6>Crop Dimensions</h6>
                {cropResolution?.value === 'custom_resolution' ? (
                  <>
                    <dl className="dl--inline animate">
                      <div className="job-exports-cropping__divider">
                        <dt>Width:&nbsp;</dt>
                        <dd>
                          <input
                            className="input--clean"
                            type="number"
                            name="width"
                            value={cropWidth}
                            onChange={({ target }) => handleDimensionsChange('width', Number(target.value))}
                            onBlur={(e) => handleDimensionsValidate(e, { name: 'minNum', rule: 100 }, { name: 'maxNum', rule: 1600 })}
                            min="100"
                            max="1600"
                          />
                          px
                        </dd>
                      </div>
                      <div className="job-exports-cropping__divider">
                        <dt>Height: </dt>
                        <dd>
                          <input
                            className="input--clean"
                            type="number"
                            name="height"
                            value={cropHeight}
                            onChange={({ target }) => handleDimensionsChange('height', Number(target.value))}
                            onBlur={(e) => handleDimensionsValidate(e, { name: 'minNum', rule: 100 }, { name: 'maxNum', rule: 1600 })}
                            min="100"
                            max="1600"
                          />
                          px
                        </dd>
                      </div>
                    </dl>
                    <span className="text--xsmall text--grey">(min: 100px, max: 1600px)</span>
                  </>
                ) : (
                  <dl className="dl--inline animate">
                    <dt>Width: </dt>
                    <dd>{cropWidth}px</dd>
                    <br />
                    <dt>Height:</dt>
                    <dd>{cropHeight}px</dd>
                  </dl>
                )}
              </aside>
            </div>
            <footer className="center">
              <button className="button button--large" name="confirm" type="button" onClick={handleCropConfirm} disabled={cropDimensionsValidate === false}>
                Confirm
              </button>
              <hr />
              <p className="text--nomargin">
                <small>
                  Need help? Check out our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support article on export files
                  </a>
                  .
                </small>
              </p>
            </footer>
          </section>
        </main>
      </div>
    </aside>
  );
};

export default ExportCropping;
