import { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Fields } from 'redux-form';

class ImagePicker extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    label: PropTypes.string,
    accept: PropTypes.string,
    defaultValues: PropTypes.object,
    allowDelete: PropTypes.bool
  };

  static defaultProps = {
    type: 'input',
    label: null,
    description: null,
    defaultValues: {},
    accept: 'image/png, image/jpeg',
    allowDelete: null
  };

  renderFields = (fields) => {
    const {
      defaultValues: { url, filename },
      allowDelete,
      name
    } = this.props;
    const delete_image = fields[`delete_${name}`];
    const {
      input,
      meta: { touched, error }
    } = fields[name];
    const file = input.value;
    const imageName = (file && file.filename) || filename;
    const imageUrl = (file && file.content) || url;
    const handleDrop = (filesToUpload) => {
      const image = filesToUpload[0];
      const { name, type } = image;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          input.onChange({
            content: reader.result,
            filename: name,
            content_type: type
          });
        },
        false
      );

      reader.readAsDataURL(image);
    };

    const deleteImage = () => {
      input.onChange(null);
      delete_image.input.onChange(true);
    };

    return (
      <div className="package-image-contents">
        <div className="product-image" style={imageName ? { backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' } : {}} />
        <div>
          <p className="image-name">{imageName || 'default image'}</p>
          <Dropzone name={name} onDrop={handleDrop} className="button">
            {() => <div>{imageName ? 'Edit Image' : 'Add Image'}</div>}
          </Dropzone>
          {allowDelete && (
            <span onClick={deleteImage} className="button button--dark">
              <i className="fa fa-trash-o" aria-hidden="true" />
            </span>
          )}
          {touched && error && <span className="error">{error}</span>}
        </div>
      </div>
    );
  };

  render() {
    const { name, label, description } = this.props;

    return (
      <div>
        <label> {label} </label>
        <p> {description} </p>
        <Fields names={[name, `delete_${name}`]} component={this.renderFields} />
      </div>
    );
  }
}

export default ImagePicker;
