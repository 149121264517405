import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import JobItem from '../job-item';
import OrderItem from '../order-item';
import SalesItem from '../sales-item';
import NotificationItem from '../notification-item';

import entitiesToMap from '@/utils/entitiesToMap';
import FlipContainer from '@/components/Shared/FlipContainer';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

import EmptyState from './EmptyState';

const QuickGlanceTable = ({ history, items, type, title, route, roles, requesting, onAddNewToggle }) => {
  const itemTypes = {
    notification: NotificationItem,
    job: JobItem,
    order: OrderItem,
    sales: SalesItem
  };

  const ItemComponent = itemTypes[type];

  const handleOrdersSearch = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    history.push({ pathname: '/storefront/orders', search: `?search=${formData.get('search')}` });
  };

  return (
    <table className="table table--noborder">
      <thead className="table__header">
        <tr>
          <th>
            <h3 className="text--nomargin">{title}</h3>
          </th>
          <th className="end">
            <Link to={route} className="button button--clean">
              <i className="icon-panel"></i>
            </Link>
          </th>
        </tr>
      </thead>
      <FlipContainer className="table__body" component="tbody">
        {requesting ? (
          <tr>
            <td colSpan="2">
              <TableLoader rows={11} />
            </td>
          </tr>
        ) : (
          <>
            <EmptyState
              roles={roles}
              requesting={requesting}
              isEmpty={!requesting && Object.keys(items).length === 0}
              type={type}
              onAddNewToggle={onAddNewToggle}
            />
            {type === 'order' && Object.keys(items).length > 0 && (
              <tr>
                <td className="quickglance-table__search" colSpan="2">
                  <form onSubmit={handleOrdersSearch}>
                    <fieldset className="fieldset--clean">
                      <input className="input--clean" type="search" name="search" placeholder="Search Orders" maxLength="50" />
                      <button className="button button--icon" name="button" type="submit">
                        <i className="icon-search" />
                      </button>
                    </fieldset>
                  </form>
                </td>
              </tr>
            )}
            {entitiesToMap(items).map((item) => (
              <ItemComponent key={item.id} item={item} roles={roles} />
            ))}
          </>
        )}
      </FlipContainer>

      {type === 'order' && items.length > 0 && (
        <tfoot className="table__footer">
          <tr>
            <td colSpan="2">* Order total before applied AP credit.</td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

QuickGlanceTable.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  requesting: PropTypes.bool
};

export default QuickGlanceTable;
