import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Helpers
import Select from 'react-select';
import matchValidatePropToValidators from './validators';

class ReduxSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      })
    ),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    options: [],
    label: null
  };

  renderSelect = ({ input, meta: { touched, error } }) => {
    const { options, placeholder, disabled } = this.props;

    return (
      <div>
        <Select
          {...input}
          className={`select mb-10 ${input.className ? input.className : ''}`}
          classNamePrefix="select"
          placeholder={placeholder}
          isDisabled={disabled}
          isMulti={false}
          onBlur={() => false}
          onChange={(value) => input.onChange(value)}
          options={options}
        />
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  render() {
    const { name, label, onChange, disabled } = this.props;

    return (
      <div>
        {label && <label>{label}</label>}
        <Field validate={matchValidatePropToValidators(this.props)} name={name} component={this.renderSelect} onChange={onChange} disabled={disabled} />
      </div>
    );
  }
}

export default ReduxSelect;
