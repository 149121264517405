import { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class SkeletonGallery extends Component {
  static propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
  };

  static defaultProps = {
    height: 'calc(100vh - 320px)',
    width: '100%'
  };

  render() {
    const { height, width } = this.props;
    const style = { height, ...(width ? { width } : {}) };
    return (
      <div style={style}>
        {Array(10)
          .fill(null)
          .map((_, i) => (
            <div key={i} className="shared__skeleton_photo photo-container" />
          ))}
      </div>
    );
  }
}

export default SkeletonGallery;
