// Jobs
export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';

export const GET_JOB_LIST_REQUEST = 'GET_JOB_LIST_REQUEST';
export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS';
export const GET_JOB_LIST_ERROR = 'GET_JOB_LIST_ERROR';

export const GET_JOB_LIST_BY_ORGANIZATION_REQUEST = 'GET_JOB_LIST_BY_ORGANIZATION_REQUEST';
export const GET_JOB_LIST_BY_ORGANIZATION_SUCCESS = 'GET_JOB_LIST_BY_ORGANIZATION_SUCCESS';
export const GET_JOB_LIST_BY_ORGANIZATION_ERROR = 'GET_JOB_LIST_BY_ORGANIZATION_ERROR';

export const GET_JOB_TIMEZONE_LIST_REQUEST = 'GET_JOB_TIMEZONE_LIST_REQUEST';
export const GET_JOB_TIMEZONE_LIST_SUCCESS = 'GET_JOB_TIMEZONE_LIST_SUCCESS';
export const GET_JOB_TIMEZONE_LIST_ERROR = 'GET_JOB_TIMEZONE_LIST_ERROR';

export const GET_JOB_SMS_TIME_LIST_REQUEST = 'GET_JOB_SMS_TIME_LIST_REQUEST';
export const GET_JOB_SMS_TIME_LIST_SUCCESS = 'GET_JOB_SMS_TIME_LIST_SUCCESS';
export const GET_JOB_SMS_TIME_LIST_ERROR = 'GET_JOB_SMS_TIME_LIST_ERROR';

export const GET_JOB_REQUEST = 'GET_JOB_REQUEST';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_ERROR = 'GET_JOB_ERROR';

export const GET_JOB_PRICESHEETS_REQUEST = 'GET_JOB_PRICESHEETS_REQUEST';
export const GET_JOB_PRICESHEETS_SUCCESS = 'GET_JOB_PRICESHEETS_SUCCESS';
export const GET_JOB_PRICESHEETS_ERROR = 'GET_JOB_PRICESHEETS_ERROR';

export const GET_JOB_UNSUBSCRIBE_LIST_REQUEST = 'GET_JOB_UNSUBSCRIBE_LIST_REQUEST';
export const GET_JOB_UNSUBSCRIBE_LIST_SUCCESS = 'GET_JOB_UNSUBSCRIBE_LIST_SUCCESS';
export const GET_JOB_UNSUBSCRIBE_LIST_ERROR = 'GET_JOB_UNSUBSCRIBE_LIST_ERROR';

export const GET_JOB_SUBJECTS_SUMMARY_REQUEST = 'GET_JOB_SUBJECTS_SUMMARY_REQUEST';
export const GET_JOB_SUBJECTS_SUMMARY_SUCCESS = 'GET_JOB_SUBJECTS_SUMMARY_SUCCESS';
export const GET_JOB_SUBJECTS_SUMMARY_ERROR = 'GET_JOB_SUBJECTS_SUMMARY_ERROR';

export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_ERROR = 'UPDATE_JOB_ERROR';

export const RESTORE_JOB_REQUEST = 'RESTORE_JOB_REQUEST';
export const RESTORE_JOB_SUCCESS = 'RESTORE_JOB_SUCCESS';
export const RESTORE_JOB_ERROR = 'RESTORE_JOB_ERROR';

export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_ERROR = 'DELETE_JOB_ERROR';

export const RESET_JOB_REQUEST = 'RESET_JOB_REQUEST';
export const RESET_JOB_SUCCESS = 'RESET_JOB_SUCCESS';

// Photos
export const CREATE_PHOTO_CHECK_DUPLICATE_REQUEST = 'CREATE_PHOTO_CHECK_DUPLICATE_REQUEST';
export const CREATE_PHOTO_CHECK_DUPLICATE_SUCCESS = 'CREATE_PHOTO_CHECK_DUPLICATE_SUCCESS';
export const CREATE_PHOTO_CHECK_DUPLICATE_ERROR = 'CREATE_PHOTO_CHECK_DUPLICATE_ERROR';

export const CREATE_PHOTO = 'CREATE_PHOTO';

export const GET_PHOTO_LIST_REQUEST = 'GET_PHOTO_LIST_REQUEST';
export const GET_PHOTO_LIST_SUCCESS = 'GET_PHOTO_LIST_SUCCESS';
export const GET_PHOTO_LIST_ERROR = 'GET_PHOTO_LIST_ERROR';

export const GET_PHOTO_REQUEST = 'GET_PHOTO_REQUEST';
export const GET_PHOTO_SUCCESS = 'GET_PHOTO_SUCCESS';
export const GET_PHOTO_ERROR = 'GET_PHOTO_ERROR';

export const GET_PHOTO_TYPE_OPTIONS_REQUEST = 'GET_PHOTO_TYPE_OPTIONS_REQUEST';
export const GET_PHOTO_TYPE_OPTIONS_SUCCESS = 'GET_PHOTO_TYPE_OPTIONS_SUCCESS';
export const GET_PHOTO_TYPE_OPTIONS_ERROR = 'GET_PHOTO_TYPE_OPTIONS_ERROR';

export const UPDATE_PHOTO_REQUEST = 'UPDATE_PHOTO_REQUEST';
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS';
export const UPDATE_PHOTO_ERROR = 'UPDATE_PHOTO_ERROR';

export const UPDATE_PHOTOS_REQUEST = 'UPDATE_PHOTOS_REQUEST';
export const UPDATE_PHOTOS_SUCCESS = 'UPDATE_PHOTOS_SUCCESS';
export const UPDATE_PHOTOS_ERROR = 'UPDATE_PHOTOS_ERROR';

export const UPDATE_PHOTO_GALLERY = 'UPDATE_PHOTO_GALLERY';
export const UPDATE_PHOTO_TRAINED_AND_REMOVE_QR_IMAGES = 'UPDATE_PHOTO_TRAINED_AND_REMOVE_QR_IMAGES';
export const UPDATE_PHOTO_STATS = 'UPDATE_PHOTO_STATS';
export const UPDATE_PHOTO_TAGS = 'UPDATE_PHOTO_TAGS';

export const UPDATE_PHOTO_DROPZONE = 'UPDATE_PHOTO_DROPZONE';

export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_ERROR = 'DELETE_PHOTO_ERROR';

export const SET_PHOTO_QUERY_COMPONENT = 'SET_PHOTO_QUERY_COMPONENT';

// Tags
export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR';

export const CREATE_TAG_ASSOCIATION_REQUEST = 'CREATE_TAG_ASSOCIATION_REQUEST';
export const CREATE_TAG_ASSOCIATION_SUCCESS = 'CREATE_TAG_ASSOCIATION_SUCCESS';
export const CREATE_TAG_ASSOCIATION_ERROR = 'CREATE_TAG_ASSOCIATION_ERROR';

export const GET_TAG_LIST_REQUEST = 'GET_TAG_LIST_REQUEST';
export const GET_TAG_LIST_SUCCESS = 'GET_TAG_LIST_SUCCESS';
export const GET_TAG_LIST_ERROR = 'GET_TAG_LIST_ERROR';

export const RENAME_TAG_REQUEST = 'RENAME_TAG_REQUEST';
export const RENAME_TAG_SUCCESS = 'RENAME_TAG_SUCCESS';
export const RENAME_TAG_ERROR = 'RENAME_TAG_ERROR';

export const SORT_TAG_REQUEST = 'SORT_TAG_REQUEST';
export const SORT_TAG_SUCCESS = 'SORT_TAG_SUCCESS';
export const SORT_TAG_ERROR = 'SORT_TAG_ERROR';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';

export const DELETE_TAG_ASSOCIATION_REQUEST = 'DELETE_TAG_ASSOCIATION_REQUEST';
export const DELETE_TAG_ASSOCIATION_SUCCESS = 'DELETE_TAG_ASSOCIATION_SUCCESS';
export const DELETE_TAG_ASSOCIATION_ERROR = 'DELETE_TAG_ASSOCIATION_ERROR';

// People
export const CREATE_PEOPLE_REQUEST = 'CREATE_PEOPLE_REQUEST';
export const CREATE_PEOPLE_SUCCESS = 'CREATE_PEOPLE_SUCCESS';
export const CREATE_PEOPLE_ERROR = 'CREATE_PEOPLE_ERROR';

export const CREATE_PEOPLE_ASSOCIATION_REQUEST = 'CREATE_PEOPLE_ASSOCIATION_REQUEST';
export const CREATE_PEOPLE_ASSOCIATION_SUCCESS = 'CREATE_PEOPLE_ASSOCIATION_SUCCESS';
export const CREATE_PEOPLE_ASSOCIATION_ERROR = 'CREATE_PEOPLE_ASSOCIATION_ERROR';

export const CREATE_PEOPLE_ASSOCIATION_PHOTOS_REQUEST = 'CREATE_PEOPLE_ASSOCIATION_PHOTOS_REQUEST';
export const CREATE_PEOPLE_ASSOCIATION_PHOTOS_SUCCESS = 'CREATE_PEOPLE_ASSOCIATION_PHOTOS_SUCCESS';
export const CREATE_PEOPLE_ASSOCIATION_PHOTOS_ERROR = 'CREATE_PEOPLE_ASSOCIATION_PHOTOS_ERROR';

export const CREATE_PEOPLE_CSV_REQUEST = 'CREATE_PEOPLE_CSV_REQUEST';
export const CREATE_PEOPLE_CSV_SUCCESS = 'CREATE_PEOPLE_CSV_SUCCESS';
export const CREATE_PEOPLE_CSV_ERROR = 'CREATE_PEOPLE_CSV_ERROR';

export const CREATE_PEOPLE_CSV_DETAILS_REQUEST = 'CREATE_PEOPLE_CSV_DETAILS_REQUEST';
export const CREATE_PEOPLE_CSV_DETAILS_SUCCESS = 'CREATE_PEOPLE_CSV_DETAILS_SUCCESS';
export const CREATE_PEOPLE_CSV_DETAILS_ERROR = 'CREATE_PEOPLE_CSV_DETAILS_ERROR';

export const CREATE_PEOPLE_BULK_FEATURE_REQUEST = 'CREATE_PEOPLE_BULK_FEATURE_REQUEST';
export const CREATE_PEOPLE_BULK_FEATURE_SUCCESS = 'CREATE_PEOPLE_BULK_FEATURE_SUCCESS';
export const CREATE_PEOPLE_BULK_FEATURE_ERROR = 'CREATE_PEOPLE_BULK_FEATURE_ERROR';

export const CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_REQUEST = 'CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_REQUEST';
export const CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_SUCCESS = 'CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_SUCCESS';
export const CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_ERROR = 'CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_ERROR';

export const CREATE_PEOPLE_YEARBOOK_SELECTION_REQUEST = 'CREATE_PEOPLE_YEARBOOK_SELECTION_REQUEST';
export const CREATE_PEOPLE_YEARBOOK_SELECTION_SUCCESS = 'CREATE_PEOPLE_YEARBOOK_SELECTION_SUCCESS';
export const CREATE_PEOPLE_YEARBOOK_SELECTION_ERROR = 'CREATE_PEOPLE_YEARBOOK_SELECTION_ERROR';

export const GET_PEOPLE_LIST_REQUEST = 'GET_PEOPLE_LIST_REQUEST';
export const GET_PEOPLE_LIST_SUCCESS = 'GET_PEOPLE_LIST_SUCCESS';
export const GET_PEOPLE_LIST_ERROR = 'GET_PEOPLE_LIST_ERROR';

export const GET_SIDEBAR_PEOPLE_LIST_REQUEST = 'GET_SIDEBAR_PEOPLE_LIST_REQUEST';
export const GET_SIDEBAR_PEOPLE_LIST_SUCCESS = 'GET_SIDEBAR_PEOPLE_LIST_SUCCESS';
export const GET_SIDEBAR_PEOPLE_LIST_ERROR = 'GET_SIDEBAR_PEOPLE_LIST_ERROR';

export const GET_PEOPLE_FACES_LIST_REQUEST = 'GET_PEOPLE_FACES_LIST_REQUEST';
export const GET_PEOPLE_FACES_LIST_SUCCESS = 'GET_PEOPLE_FACES_LIST_SUCCESS';
export const GET_PEOPLE_FACES_LIST_ERROR = 'GET_PEOPLE_FACES_LIST_ERROR';

export const GET_PEOPLE_PHOTO_FACES_LIST_REQUEST = 'GET_PEOPLE_PHOTO_FACES_LIST_REQUEST';
export const GET_PEOPLE_PHOTO_FACES_LIST_SUCCESS = 'GET_PEOPLE_PHOTO_FACES_LIST_SUCCESS';
export const GET_PEOPLE_PHOTO_FACES_LIST_ERROR = 'GET_PEOPLE_PHOTO_FACES_LIST_ERROR';

export const GET_PEOPLE_FIELD_OPTIONS_REQUEST = 'GET_PEOPLE_FIELD_OPTIONS_REQUEST';
export const GET_PEOPLE_FIELD_OPTIONS_SUCCESS = 'GET_PEOPLE_FIELD_OPTIONS_SUCCESS';
export const GET_PEOPLE_FIELD_OPTIONS_ERROR = 'GET_PEOPLE_FIELD_OPTIONS_ERROR';

export const FILTER_PEOPLE_FIELD_OPTIONS_BY_SUBJECTS = 'FILTER_PEOPLE_FIELD_OPTIONS_BY_SUBJECTS';

export const GET_PEOPLE_BY_PHOTO_ID_REQUEST = 'GET_PEOPLE_BY_PHOTO_ID_REQUEST';
export const GET_PEOPLE_BY_PHOTO_ID_SUCCESS = 'GET_PEOPLE_BY_PHOTO_ID_SUCCESS';
export const GET_PEOPLE_BY_PHOTO_ID_ERROR = 'GET_PEOPLE_BY_PHOTO_ID_ERROR';

export const GET_SUBJECT_REQUEST = 'GET_SUBJECT_REQUEST';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_ERROR = 'GET_SUBJECT_ERROR';

export const UPDATE_PEOPLE_REQUEST = 'UPDATE_PEOPLE_REQUEST';
export const UPDATE_PEOPLE_SUCCESS = 'UPDATE_PEOPLE_SUCCESS';
export const UPDATE_PEOPLE_ERROR = 'UPDATE_PEOPLE_ERROR';

export const UPDATE_PEOPLE_FACES_REQUEST = 'UPDATE_PEOPLE_FACES_REQUEST';
export const UPDATE_PEOPLE_FACES_SUCCESS = 'UPDATE_PEOPLE_FACES_SUCCESS';
export const UPDATE_PEOPLE_FACES_ERROR = 'UPDATE_PEOPLE_FACES_ERROR';

export const DELETE_PEOPLE_REQUEST = 'DELETE_PEOPLE_REQUEST';
export const DELETE_PEOPLE_SUCCESS = 'DELETE_PEOPLE_SUCCESS';
export const DELETE_PEOPLE_ERROR = 'DELETE_PEOPLE_ERROR';

export const DELETE_PHOTO_PEOPLE_REQUEST = 'DELETE_PHOTO_PEOPLE_REQUEST';
export const DELETE_PHOTO_PEOPLE_SUCCESS = 'DELETE_PHOTO_PEOPLE_SUCCESS';
export const DELETE_PHOTO_PEOPLE_ERROR = 'DELETE_PHOTO_PEOPLE_ERROR';

export const DELETE_PEOPLE_PHOTO_REQUEST = 'DELETE_PEOPLE_PHOTO_REQUEST';
export const DELETE_PEOPLE_PHOTO_SUCCESS = 'DELETE_PEOPLE_PHOTO_SUCCESS';
export const DELETE_PEOPLE_PHOTO_ERROR = 'DELETE_PEOPLE_PHOTO_ERROR';

export const CREATE_PEOPLE_LOOKUP_CONFIG_REQUEST = 'CREATE_PEOPLE_LOOKUP_CONFIG_REQUEST';
export const CREATE_PEOPLE_LOOKUP_CONFIG_SUCCESS = 'CREATE_PEOPLE_LOOKUP_CONFIG_SUCCESS';
export const CREATE_PEOPLE_LOOKUP_CONFIG_ERROR = 'CREATE_PEOPLE_LOOKUP_CONFIG_ERROR';

export const CREATE_PEOPLE_LOOKUP_VALIDATE_REQUEST = 'CREATE_PEOPLE_LOOKUP_VALIDATE_REQUEST';
export const CREATE_PEOPLE_LOOKUP_VALIDATE_SUCCESS = 'CREATE_PEOPLE_LOOKUP_VALIDATE_SUCCESS';
export const CREATE_PEOPLE_LOOKUP_VALIDATE_ERROR = 'CREATE_PEOPLE_LOOKUP_VALIDATE_ERROR';

export const GET_PEOPLE_LOOKUP_CONFIG_REQUEST = 'GET_PEOPLE_LOOKUP_CONFIG_REQUEST';
export const GET_PEOPLE_LOOKUP_CONFIG_SUCCESS = 'GET_PEOPLE_LOOKUP_CONFIG_SUCCESS';
export const GET_PEOPLE_LOOKUP_CONFIG_ERROR = 'GET_PEOPLE_LOOKUP_CONFIG_ERROR';

export const GET_PEOPLE_CSV_COLUMN_CONFIG_REQUEST = 'GET_PEOPLE_CSV_COLUMN_CONFIG_REQUEST';
export const GET_PEOPLE_CSV_COLUMN_CONFIG_SUCCESS = 'GET_PEOPLE_CSV_COLUMN_CONFIG_SUCCESS';
export const GET_PEOPLE_CSV_COLUMN_CONFIG_ERROR = 'GET_PEOPLE_CSV_COLUMN_CONFIG_ERROR';

export const RESET_PEOPLE_LOOKUP_VALIDATE = 'RESET_PEOPLE_LOOKUP_VALIDATE ';

export const SET_PEOPLE_DETAILS = 'SET_PEOPLE_DETAILS';

export const SET_PEOPLE_MAIL = 'SET_PEOPLE_MAIL';

export const SET_PEOPLE_PHOTOS_COUNT = 'SET_PEOPLE_PHOTOS_COUNT';

export const SET_PEOPLE_LABELS = 'SET_PEOPLE_LABELS';

// Insights
export const GET_INSIGHTS_CUSTOMERS_REQUEST = 'GET_INSIGHTS_CUSTOMERS_REQUEST';
export const GET_INSIGHTS_CUSTOMERS_SUCCESS = 'GET_INSIGHTS_CUSTOMERS_SUCCESS';
export const GET_INSIGHTS_CUSTOMERS_ERROR = 'GET_INSIGHTS_CUSTOMERS_ERROR';

export const GET_INSIGHTS_CUSTOMERS_SUMMARY_REQUEST = 'GET_INSIGHTS_CUSTOMERS_SUMMARY_REQUEST';
export const GET_INSIGHTS_CUSTOMERS_SUMMARY_SUCCESS = 'GET_INSIGHTS_CUSTOMERS_SUMMARY_SUCCESS';
export const GET_INSIGHTS_CUSTOMERS_SUMMARY_ERROR = 'GET_INSIGHTS_CUSTOMERS_SUMMARY_ERROR';

export const GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_REQUEST = 'GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_REQUEST';
export const GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_SUCCESS = 'GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_SUCCESS';
export const GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_ERROR = 'GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_ERROR';

export const GET_INSIGHTS_CUSTOMERS_CSV_REQUEST = 'GET_INSIGHTS_CUSTOMERS_CSV_REQUEST';
export const GET_INSIGHTS_CUSTOMERS_CSV_SUCCESS = 'GET_INSIGHTS_CUSTOMERS_CSV_SUCCESS';
export const GET_INSIGHTS_CUSTOMERS_CSV_ERROR = 'GET_INSIGHTS_CUSTOMERS_CSV_ERROR';

export const GET_INSIGHTS_SALES_REQUEST = 'GET_INSIGHTS_SALES_REQUEST';
export const GET_INSIGHTS_SALES_SUCCESS = 'GET_INSIGHTS_SALES_SUCCESS';
export const GET_INSIGHTS_SALES_ERROR = 'GET_INSIGHTS_SALES_ERROR';

export const GET_INSIGHTS_SALES_SUMMARY_REQUEST = 'GET_INSIGHTS_SALES_SUMMARY_REQUEST';
export const GET_INSIGHTS_SALES_SUMMARY_SUCCESS = 'GET_INSIGHTS_SALES_SUMMARY_SUCCESS';
export const GET_INSIGHTS_SALES_SUMMARY_ERROR = 'GET_INSIGHTS_SALES_SUMMARY_ERROR';

export const GET_INSIGHTS_SALES_CSV_REQUEST = 'GET_INSIGHTS_SALES_CSV_REQUEST';
export const GET_INSIGHTS_SALES_CSV_SUCCESS = 'GET_INSIGHTS_SALES_CSV_SUCCESS';
export const GET_INSIGHTS_SALES_CSV_ERROR = 'GET_INSIGHTS_SALES_CSV_ERROR';

// Access Code
export const GET_ACCESS_CODE_REQUEST = 'GET_ACCESS_CODE_REQUEST';
export const GET_ACCESS_CODE_SUCCESS = 'GET_ACCESS_CODE_SUCCESS';
export const GET_ACCESS_CODE_ERROR = 'GET_ACCESS_CODE_ERROR';

// Flyers
export const CREATE_FLYER_REQUEST = 'CREATE_FLYER_REQUEST';
export const CREATE_FLYER_SUCCESS = 'CREATE_FLYER_SUCCESS';
export const CREATE_FLYER_ERROR = 'CREATE_FLYER_ERROR';

export const CREATE_FLYER_EMAIL_REQUEST = 'CREATE_FLYER_EMAIL_REQUEST';
export const CREATE_FLYER_EMAIL_SUCCESS = 'CREATE_FLYER_EMAIL_SUCCESS';
export const CREATE_FLYER_EMAIL_ERROR = 'CREATE_FLYER_EMAIL_ERROR';

export const GET_FLYER_LIST_REQUEST = 'GET_FLYER_LIST_REQUEST';
export const GET_FLYER_LIST_SUCCESS = 'GET_FLYER_LIST_SUCCESS';
export const GET_FLYER_LIST_ERROR = 'GET_FLYER_LIST_ERROR';

export const GET_FLYER_REQUEST = 'GET_FLYER_REQUEST';
export const GET_FLYER_SUCCESS = 'GET_FLYER_SUCCESS';
export const GET_FLYER_ERROR = 'GET_FLYER_ERROR';

export const GET_FLYER_PDF_REQUEST = 'GET_FLYER_PDF_REQUEST';
export const GET_FLYER_PDF_SUCCESS = 'GET_FLYER_PDF_SUCCESS';
export const GET_FLYER_PDF_ERROR = 'GET_FLYER_PDF_ERROR';

export const UPDATE_FLYER_REQUEST = 'UPDATE_FLYER_REQUEST';
export const UPDATE_FLYER_SUCCESS = 'UPDATE_FLYER_SUCCESS';
export const UPDATE_FLYER_ERROR = 'UPDATE_FLYER_ERROR';

export const DELETE_FLYER_REQUEST = 'DELETE_FLYER_REQUEST';
export const DELETE_FLYER_SUCCESS = 'DELETE_FLYER_SUCCESS';
export const DELETE_FLYER_ERROR = 'DELETE_FLYER_ERROR';

export const CLEAR_FLYER = 'CLEAR_FLYER';

// Admin
export const CREATE_ADMIN_SMS_REQUEST = 'CREATE_ADMIN_SMS_REQUEST';
export const CREATE_ADMIN_SMS_SUCCESS = 'CREATE_ADMIN_SMS_SUCCESS';
export const CREATE_ADMIN_SMS_ERROR = 'CREATE_ADMIN_SMS_ERROR';

// Exports
export const CREATE_JOB_EXPORT_REQUEST = 'CREATE_JOB_EXPORT_REQUEST';
export const CREATE_JOB_EXPORT_SUCCESS = 'CREATE_JOB_EXPORT_SUCCESS';
export const CREATE_JOB_EXPORT_ERROR = 'CREATE_JOB_EXPORT_ERROR';

export const CREATE_JOB_EXPORT_READY_REQUEST = 'CREATE_JOB_EXPORT_READY_REQUEST';
export const CREATE_JOB_EXPORT_READY_SUCCESS = 'CREATE_JOB_EXPORT_READY_SUCCESS';
export const CREATE_JOB_EXPORT_READY_ERROR = 'CREATE_JOB_EXPORT_READY_ERROR';

export const CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_REQUEST = 'CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_REQUEST';
export const CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_SUCCESS = 'CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_SUCCESS';
export const CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_ERROR = 'CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_ERROR';

export const CREATE_JOB_EXPORT_PREPARE_REQUEST = 'CREATE_JOB_EXPORT_PREPARE_REQUEST';
export const CREATE_JOB_EXPORT_PREPARE_SUCCESS = 'CREATE_JOB_EXPORT_PREPARE_SUCCESS';
export const CREATE_JOB_EXPORT_PREPARE_ERROR = 'CREATE_JOB_EXPORT_PREPARE_ERROR';

export const GET_JOB_EXPORT_LIST_REQUEST = 'GET_JOB_EXPORT_LIST_REQUEST';
export const GET_JOB_EXPORT_LIST_SUCCESS = 'GET_JOB_EXPORT_LIST_SUCCESS';
export const GET_JOB_EXPORT_LIST_ERROR = 'GET_JOB_EXPORT_LIST_ERROR';

export const GET_JOB_EXPORT_REQUEST = 'GET_JOB_EXPORT_REQUEST';
export const GET_JOB_EXPORT_SUCCESS = 'GET_JOB_EXPORT_SUCCESS';
export const GET_JOB_EXPORT_ERROR = 'GET_JOB_EXPORT_ERROR';

export const GET_JOB_EXPORT_ITEMS_REQUEST = 'GET_JOB_EXPORT_ITEMS_REQUEST';
export const GET_JOB_EXPORT_ITEMS_SUCCESS = 'GET_JOB_EXPORT_ITEMS_SUCCESS';
export const GET_JOB_EXPORT_ITEMS_ERROR = 'GET_JOB_EXPORT_ITEMS_ERROR';

export const GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_REQUEST = 'GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_REQUEST';
export const GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_SUCCESS = 'GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_SUCCESS';
export const GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_ERROR = 'GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_ERROR';

export const UPDATE_JOB_EXPORT_REQUEST = 'UPDATE_JOB_EXPORT_REQUEST';
export const UPDATE_JOB_EXPORT_SUCCESS = 'UPDATE_JOB_EXPORT_SUCCESS';
export const UPDATE_JOB_EXPORT_ERROR = 'UPDATE_JOB_EXPORT_ERROR';

export const UPDATE_JOB_EXPORT_ITEM_REQUEST = 'UPDATE_JOB_EXPORT_ITEM_REQUEST';
export const UPDATE_JOB_EXPORT_ITEM_SUCCESS = 'UPDATE_JOB_EXPORT_ITEM_SUCCESS';
export const UPDATE_JOB_EXPORT_ITEM_ERROR = 'UPDATE_JOB_EXPORT_ITEM_ERROR';

export const DELETE_JOB_EXPORT_REQUEST = 'DELETE_JOB_EXPORT_REQUEST';
export const DELETE_JOB_EXPORT_SUCCESS = 'DELETE_JOB_EXPORT_SUCCESS';
export const DELETE_JOB_EXPORT_ERROR = 'DELETE_JOB_EXPORT_ERROR';

export const DELETE_JOB_EXPORT_ITEM_REQUEST = 'DELETE_JOB_EXPORT_ITEM_REQUEST';
export const DELETE_JOB_EXPORT_ITEM_SUCCESS = 'DELETE_JOB_EXPORT_ITEM_SUCCESS';
export const DELETE_JOB_EXPORT_ITEM_ERROR = 'DELETE_JOB_EXPORT_ITEM_ERROR';

// Knockouts
export const CREATE_JOB_KNOCKOUT_REQUEST = 'CREATE_JOB_KNOCKOUT_REQUEST';
export const CREATE_JOB_KNOCKOUT_SUCCESS = 'CREATE_JOB_KNOCKOUT_SUCCESS';
export const CREATE_JOB_KNOCKOUT_ERROR = 'CREATE_JOB_KNOCKOUT_ERROR';

export const CREATE_JOB_KNOCKOUT_READY_REQUEST = 'CREATE_JOB_KNOCKOUT_READY_REQUEST';
export const CREATE_JOB_KNOCKOUT_READY_SUCCESS = 'CREATE_JOB_KNOCKOUT_READY_SUCCESS';
export const CREATE_JOB_KNOCKOUT_READY_ERROR = 'CREATE_JOB_KNOCKOUT_READY_ERROR';

export const CREATE_JOB_KNOCKOUT_PREPARE_REQUEST = 'CREATE_JOB_KNOCKOUT_PREPARE_REQUEST';
export const CREATE_JOB_KNOCKOUT_PREPARE_SUCCESS = 'CREATE_JOB_KNOCKOUT_PREPARE_SUCCESS';
export const CREATE_JOB_KNOCKOUT_PREPARE_ERROR = 'CREATE_JOB_KNOCKOUT_PREPARE_ERROR';

export const CREATE_JOB_KNOCKOUT_TRANSFER_REQUEST = 'CREATE_JOB_KNOCKOUT_TRANSFER_REQUEST';
export const CREATE_JOB_KNOCKOUT_TRANSFER_SUCCESS = 'CREATE_JOB_KNOCKOUT_TRANSFER_SUCCESS';
export const CREATE_JOB_KNOCKOUT_TRANSFER_ERROR = 'CREATE_JOB_KNOCKOUT_TRANSFER_ERROR';

export const GET_JOB_KNOCKOUT_LIST_REQUEST = 'GET_JOB_KNOCKOUT_LIST_REQUEST';
export const GET_JOB_KNOCKOUT_LIST_SUCCESS = 'GET_JOB_KNOCKOUT_LIST_SUCCESS';
export const GET_JOB_KNOCKOUT_LIST_ERROR = 'GET_JOB_KNOCKOUT_LIST_ERROR';

export const GET_JOB_KNOCKOUT_REQUEST = 'GET_JOB_KNOCKOUT_REQUEST';
export const GET_JOB_KNOCKOUT_SUCCESS = 'GET_JOB_KNOCKOUT_SUCCESS';
export const GET_JOB_KNOCKOUT_ERROR = 'GET_JOB_KNOCKOUT_ERROR';

export const UPDATE_JOB_KNOCKOUT_REQUEST = 'UPDATE_JOB_KNOCKOUT_REQUEST';
export const UPDATE_JOB_KNOCKOUT_SUCCESS = 'UPDATE_JOB_KNOCKOUT_SUCCESS';
export const UPDATE_JOB_KNOCKOUT_ERROR = 'UPDATE_JOB_KNOCKOUT_ERROR';

export const DELETE_JOB_KNOCKOUT_REQUEST = 'DELETE_JOB_KNOCKOUT_REQUEST';
export const DELETE_JOB_KNOCKOUT_SUCCESS = 'DELETE_JOB_KNOCKOUT_SUCCESS';
export const DELETE_JOB_KNOCKOUT_ERROR = 'DELETE_JOB_KNOCKOUT_ERROR';

export const UPDATE_KNOCKOUT_DROPZONE = 'UPDATE_KNOCKOUT_DROPZONE';
export const UPDATE_KNOCKOUT_UPLOAD_FROM_CABLE = 'UPDATE_KNOCKOUT_UPLOAD_FROM_CABLE';

// Knockout Items
export const CREATE_JOB_KNOCKOUT_ITEM = 'CREATE_JOB_KNOCKOUT_ITEM';

export const GET_JOB_KNOCKOUT_ITEMS_REQUEST = 'GET_JOB_KNOCKOUT_ITEMS_REQUEST';
export const GET_JOB_KNOCKOUT_ITEMS_SUCCESS = 'GET_JOB_KNOCKOUT_ITEMS_SUCCESS';
export const GET_JOB_KNOCKOUT_ITEMS_ERROR = 'GET_JOB_KNOCKOUT_ITEMS_ERROR';

export const DELETE_JOB_KNOCKOUT_ITEMS_REQUEST = 'DELETE_JOB_KNOCKOUT_ITEMS_REQUEST';
export const DELETE_JOB_KNOCKOUT_ITEMS_SUCCESS = 'DELETE_JOB_KNOCKOUT_ITEMS_SUCCESS';
export const DELETE_JOB_KNOCKOUT_ITEMS_ERROR = 'DELETE_JOB_KNOCKOUT_ITEMS_ERROR';

// Marketing Messages
export const GET_JOB_MARKETING_MESSAGES_REQUEST = 'GET_JOB_MARKETING_MESSAGES_REQUEST';
export const GET_JOB_MARKETING_MESSAGES_SUCCESS = 'GET_JOB_MARKETING_MESSAGES_SUCCESS';
export const GET_JOB_MARKETING_MESSAGES_ERROR = 'GET_JOB_MARKETING_MESSAGES_ERROR';

// Capture QR
export const GET_JOB_QR_SESSIONS_REQUEST = 'GET_JOB_QR_SESSIONS_REQUEST';
export const GET_JOB_QR_SESSIONS_SUCCESS = 'GET_JOB_QR_SESSIONS_SUCCESS';
export const GET_JOB_QR_SESSIONS_ERROR = 'GET_JOB_QR_SESSIONS_ERROR';

export const GET_JOB_QR_CODE_BY_SESSION_REQUEST = 'GET_JOB_QR_CODE_BY_SESSION_REQUEST';
export const GET_JOB_QR_CODE_BY_SESSION_SUCCESS = 'GET_JOB_QR_CODE_BY_SESSION_SUCCESS';
export const GET_JOB_QR_CODE_BY_SESSION_ERROR = 'GET_JOB_QR_CODE_BY_SESSION_ERROR';

export const CREATE_JOB_QR_CODE_RESOLUTION_REQUEST = 'CREATE_JOB_QR_CODE_RESOLUTION_REQUEST';
export const CREATE_JOB_QR_CODE_RESOLUTION_SUCCESS = 'CREATE_JOB_QR_CODE_RESOLUTION_SUCCESS';
export const CREATE_JOB_QR_CODE_RESOLUTION_ERROR = 'CREATE_JOB_QR_CODE_RESOLUTION_ERROR';

export const DELETE_JOB_QR_SESSION_PHOTOS_REQUEST = 'DELETE_JOB_QR_SESSION_PHOTOS_REQUEST';
export const DELETE_JOB_QR_SESSION_PHOTOS_SUCCESS = 'DELETE_JOB_QR_SESSION_PHOTOS_SUCCESS';
export const DELETE_JOB_QR_SESSION_PHOTOS_ERROR = 'DELETE_JOB_QR_SESSION_PHOTOS_ERROR';

export const CREATE_JOB_QR_IGNORE_ERRORS_REQUEST = 'CREATE_JOB_QR_IGNORE_ERRORS_REQUEST';
export const CREATE_JOB_QR_IGNORE_ERRORS_SUCCESS = 'CREATE_JOB_QR_IGNORE_ERRORS_SUCCESS';
export const CREATE_JOB_QR_IGNORE_ERRORS_ERROR = 'CREATE_JOB_QR_IGNORE_ERRORS_ERROR';

export const CREATE_JOB_QR_SESSION_MATCHING_REQUEST = 'CREATE_JOB_QR_SESSION_MATCHING_REQUEST';
export const CREATE_JOB_QR_SESSION_MATCHING_SUCCESS = 'CREATE_JOB_QR_SESSION_MATCHING_SUCCESS';
export const CREATE_JOB_QR_SESSION_MATCHING_ERROR = 'CREATE_JOB_QR_SESSION_MATCHING_ERROR';

// Storage
export const SIDEBAR_COLLAPSED = 'sidebarCollapsed';
