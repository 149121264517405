import { Component } from 'react';
import PropTypes from 'prop-types';

export default class QuantityInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number
  };

  static defaultProps = {
    value: 1,
    className: 'quantity-container'
  };

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  };

  onIncrement = (e) => {
    e.preventDefault();
    const val = this.state.value;
    this.setState({ value: val + 1 });
    if (this.props.onChange) this.props.onChange(val + 1);
  };

  onDecrement = (e) => {
    e.preventDefault();
    const val = this.state.value;
    this.setState({ value: val - 1 });
    if (this.props.onChange) this.props.onChange(val - 1);
  };

  render() {
    const { className } = this.props;
    const { value } = this.state;
    const minusDisabled = value === 1;

    return (
      <div className={className}>
        <p className="text--bold">Product Quantity</p>
        <p>Please edit the amount if you want the customer to purchase the product in specific quantities.</p>
        <div className="flex middle">
          <button className="button button--outline button--small" disabled={minusDisabled} type="button" onClick={this.onDecrement}>
            -
          </button>
          <span className="quantity-number">{value}</span>
          <button className="button button--outline button--small" type="button" onClick={this.onIncrement}>
            +
          </button>
        </div>
      </div>
    );
  }
}
