import { Link } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';

// Components
import AddNew from './AddNew';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import Navigation from '@/components/Shared/Navigation';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';
import BusinessVerificationBanner from '@/components/Shared/BusinessVerification/VerificationBanner';
import StripeExpressMigrationBanner from '@/components/Shared/StripeExpressMigration/MigrationBanner';

// Redux
import { getJobList, getJobListByOrganization, deleteJob, restoreJob } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

// Styles
import './style.css';

// Types
import { Job, Organization, TableFilter } from '@/types';

interface ListProps {
  match: { path: string };
  history: { location: { pathname: string; search: string }; push: (location: string | { search: string }) => void };
}

const BUSINESS_VERIFICATION_ENABLED = import.meta.env.VITE_BUSINESS_VERIFICATION_ENABLED === '1';

const List: FC<ListProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { user, studio } = useSelector((state: any) => state.login);
  const {
    jobs: { list, pagination },
    requesting,
    jobTypes
  } = useSelector((state: any) => state.jobs);
  const { organizations } = useSelector((state: any) => state.organizations);

  const userRoles = user?.roles || [];
  const shouldSeeStudioBanners = studio.id !== 'all-studios' && userRoles?.some((role: string) => role === 'manage_studio_settings');

  const { organization: organizationId, page: queryPage } = queryString.parse(history.location.search);
  const organization = organizations?.find((organization: Organization) => organization.id === organizationId);

  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [navigationIsOpen, setNavigationIsOpen] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [selectJob, setSelectJob] = useState<string>('');

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [tableFilter, setTableFilter] = useState<TableFilter>({
    filter: '',
    order: 'date',
    dir: 'DESC',
    per_page: 15,
    search: '',
    page: Number(queryPage) || 1
  });

  const showArchived: boolean = history.location?.pathname?.includes('archived') ? true : false;
  const items = [
    { name: 'Current', link: '/jobs', icon: 'icon-current' },
    { name: 'Archived', link: '/jobs/archived', icon: 'icon-archive' }
  ];

  const currentItem = items.find((item) => history.location?.pathname === item.link) || items[0];

  const handleAddNewToggle = (): void => setShowAddNew(!showAddNew);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value);

  const handleJobSearch = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setTableFilter({ search, filter: '', order: 'date', dir: 'DESC', per_page: Number(pagination.perPage) || 100 });
  };

  const handleSearchClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setSearch('');
    setTableFilter({ search: '', filter: '', order: 'date', dir: 'DESC', per_page: Number(pagination.perPage) || 100 });
  };

  const handleArchive = (): void => {
    dispatch(
      deleteJob({ id: selectJob }, () => {
        setSelectJob('');
        setShowDelete(false);
        dispatch(getJobList({ ...tableFilter }));
      })
    );
  };
  const handleDeleteCancel = (): void => setShowDelete(false);
  const handleDeleteShow = (id: string): void => {
    setSelectJob(id);
    setShowDelete(true);
  };

  const handleRestoreJob = (id: string) => dispatch(restoreJob({ id }));

  const handlePagination = (page: number): void => {
    if (organizationId) {
      history.push({ search: `?organization=${organizationId}&page=${page}` });
    } else {
      history.push({ search: `?page=${page}` });
      setTableFilter({ ...tableFilter, page });
    }
  };

  const handleFilterBy = (filter: string): void => setTableFilter({ ...tableFilter, filter, page: 1 });

  const handleOrderBy = (order: string, dir: TableFilter['dir']): void => setTableFilter({ ...tableFilter, order, dir, page: 1 });

  const handleToggleNavigation = (): void => setNavigationIsOpen(!navigationIsOpen);

  useEffect(() => {
    if (organizationId) {
      dispatch(getJobListByOrganization({ id: organizationId, page: queryPage || 1, per_page: Number(pagination.perPage) || 100 }));
    } else {
      dispatch(getJobList({ ...tableFilter, ...(showArchived ? { only_archived: true } : {}) }));
    }
  }, [tableFilter, organizationId, showArchived, queryPage]);

  useEffect(() => {
    if (organization?.name) {
      setSearch(organization.name);
    }
  }, [organization]);

  return (
    <>
      <header className="job-header">
        <div className="box flex middle">
          <div className="flex-3 flex-12-md middle job__mobile-header">
            <h1 className="text--nomargin">Jobs</h1>
            <button className="job-header__mobile-action button button--outline" onClick={handleToggleNavigation}>
              <i className={currentItem.icon} />
              {currentItem.name}
            </button>
          </div>

          <div className="flex flex-9 between">
            <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} enforceExact={true} />
          </div>
        </div>
      </header>

      {/* Business Verification Banner */}
      {BUSINESS_VERIFICATION_ENABLED && shouldSeeStudioBanners && (
        <div className="box">
          <BusinessVerificationBanner />
        </div>
      )}

      {/* Stripe Express Migration Banner */}
      {shouldSeeStudioBanners && (
        <div className="box">
          <StripeExpressMigrationBanner />
        </div>
      )}

      <main className="box jobs-list">
        <aside className="flex top between">
          {pagination.total > pagination.perPage && <Pagination position="top" pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}

          <div className="flex end start-md button-group">
            <form onSubmit={handleJobSearch}>
              <fieldset className="jobs-list__fieldset">
                <input type="search" name="search" placeholder="Search Jobs" maxLength={50} value={search} onChange={handleSearchChange} />
                <button
                  className={`button button--clear button--small ${tableFilter.search ? '' : 'hidden'}`}
                  name="button"
                  type="button"
                  onClick={handleSearchClear}
                >
                  Clear
                </button>
                <button className="button button--icon" name="button" type="submit">
                  <i className="icon-search" />
                </button>
              </fieldset>
            </form>
            {userRoles.includes('manage_jobs') && studio?.id !== 'all-studios' && (
              <button className="button" name="add" type="button" onClick={handleAddNewToggle}>
                Add New
              </button>
            )}
          </div>
        </aside>
        <article className="table-box">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th>
                  <Dropdown {...{ buttonName: 'Date of Shoot', buttonExtraClass: 'button--filter' }}>
                    <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                      <li
                        className={`panel-dropdown__item ${tableFilter.order === 'date' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => {
                          handleOrderBy('date', 'ASC');
                        }}
                      >
                        Ascending
                      </li>
                      <li
                        className={`panel-dropdown__item ${tableFilter.order === 'date' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => {
                          handleOrderBy('date', 'DESC');
                        }}
                      >
                        Descending
                      </li>
                    </ul>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown {...{ buttonName: 'Job Name', buttonExtraClass: 'button--filter' }}>
                    <ul className="panel panel-dropdown panel-dropdown--small">
                      <li
                        className={`panel-dropdown__item ${tableFilter.order === 'name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => {
                          handleOrderBy('name', 'ASC');
                        }}
                      >
                        Ascending
                      </li>
                      <li
                        className={`panel-dropdown__item ${tableFilter.order === 'name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => {
                          handleOrderBy('name', 'DESC');
                        }}
                      >
                        Descending
                      </li>
                    </ul>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown {...{ buttonName: 'Category', buttonExtraClass: 'button--filter' }}>
                    <ul className="panel panel-dropdown">
                      <li
                        className={`panel-dropdown__item panel-dropdown__item--lean ${tableFilter.filter === '' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => {
                          handleFilterBy('');
                        }}
                      >
                        All
                      </li>
                      {jobTypes.map((jobType: { value: string; label: string }, index: number) => {
                        return (
                          <li
                            className={`panel-dropdown__item panel-dropdown__item--lean ${
                              tableFilter.filter === jobType.value ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleFilterBy(jobType.value);
                            }}
                            key={index}
                          >
                            {jobType.label}
                          </li>
                        );
                      })}
                    </ul>
                  </Dropdown>
                </th>
                <th>Access</th>
                <th>Access Code</th>
                <th>Price sheet</th>
                {currentItem.name === 'Current' ? (
                  <th>
                    <Dropdown {...{ buttonName: 'Status', buttonExtraClass: 'button--filter' }}>
                      <ul className="panel panel-dropdown panel-dropdown--small">
                        <li
                          className={`panel-dropdown__item ${tableFilter.filter === 'draft' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleFilterBy('draft');
                          }}
                        >
                          Draft
                        </li>
                        <li
                          className={`panel-dropdown__item ${tableFilter.filter === 'onsale' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleFilterBy('onsale');
                          }}
                        >
                          Published
                        </li>
                        <li
                          className={`panel-dropdown__item ${tableFilter.filter === 'presale' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleFilterBy('presale');
                          }}
                        >
                          Advance Pay
                        </li>
                        <li
                          className={`panel-dropdown__item ${tableFilter.filter === 'expired' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleFilterBy('expired');
                          }}
                        >
                          Expired
                        </li>
                        <li
                          className={`panel-dropdown__item ${tableFilter.filter === '' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleFilterBy('');
                          }}
                        >
                          All
                        </li>
                      </ul>
                    </Dropdown>
                  </th>
                ) : (
                  <th>Status</th>
                )}
                <th>
                  <Dropdown {...{ buttonName: 'Expiration Date', buttonExtraClass: 'button--filter' }}>
                    <ul className="panel panel-dropdown panel-dropdown--small">
                      <li
                        className={`panel-dropdown__item ${
                          tableFilter.order === 'expires_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                        }`}
                        onClick={() => {
                          handleOrderBy('expires_at', 'ASC');
                        }}
                      >
                        Ascending
                      </li>
                      <li
                        className={`panel-dropdown__item ${
                          tableFilter.order === 'expires_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                        }`}
                        onClick={() => {
                          handleOrderBy('expires_at', 'DESC');
                        }}
                      >
                        Descending
                      </li>
                    </ul>
                  </Dropdown>
                </th>
                {userRoles.includes('view_sales') && (
                  <th>
                    <Dropdown {...{ buttonName: 'Sales', buttonExtraClass: 'button--filter' }}>
                      <ul className="panel panel-dropdown panel-dropdown--small">
                        <li
                          className={`panel-dropdown__item ${
                            tableFilter.order === 'gross_sales' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                          }`}
                          onClick={() => {
                            handleOrderBy('gross_sales', 'ASC');
                          }}
                        >
                          Ascending
                        </li>
                        <li
                          className={`panel-dropdown__item ${
                            tableFilter.order === 'gross_sales' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                          }`}
                          onClick={() => {
                            handleOrderBy('gross_sales', 'DESC');
                          }}
                        >
                          Descending
                        </li>
                      </ul>
                    </Dropdown>
                  </th>
                )}
                {userRoles.includes('manage_jobs') && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan={10}>
                    <TableLoader rows={8} />
                  </td>
                </tr>
              ) : list.length > 0 ? (
                <>
                  {list.map((job: Job) => (
                    <tr key={job.id}>
                      <td data-header="Date of shoot">{moment.utc(job.date).format('L')}</td>
                      <td data-header="Job name">
                        {userRoles.includes('manage_jobs') && currentItem.name === 'Current' ? (
                          <Link to={`/jobs/${job.id}/dashboard`}>
                            <b>{job.reporting_code ? `${job.name} (${job.reporting_code})` : job.name}</b>
                          </Link>
                        ) : (
                          <span>{job.reporting_code ? `${job.name} (${job.reporting_code})` : job.name}</span>
                        )}
                      </td>
                      <td data-header="Category" className="text--capitalize">
                        {job.job_type && job.job_type.replace(/_/g, ' ')}
                      </td>
                      <td data-header="Access" className="text--capitalize">
                        {{ access_per_subject: 'Private', access_per_job: 'Group', access_public: 'Public' }[job.access_mode] || 'N/A'}
                      </td>
                      <td data-header="Access Code" className={`text--capitalize ${job.access_mode === 'access_per_subject' ? 'text--center' : ''}`}>
                        {job.access_mode === 'access_per_subject' ? <i className="icon-lock" /> : job.access_code}
                      </td>
                      <td data-header="Price sheet">
                        {userRoles.includes('manage_price_sheets') && currentItem.name === 'Current' ? (
                          <Link to={`/storefront/price-sheets/${job.price_sheet_id}`}>
                            <b>{job.price_sheet_name}</b>
                          </Link>
                        ) : (
                          <span>{job.price_sheet_name}</span>
                        )}
                      </td>
                      <td data-header="Status">
                        <span className={`pill jobs-list__pill--${job.job_status}`}>
                          {{ draft: 'Draft', expired: 'Expired', onsale: 'Published', presale: 'AdvancePay', archived: 'Archived' }[job.job_status]}
                        </span>
                      </td>
                      <td data-header="Expiration date">{job.expires_at ? moment(job.expires_at).format('L') : 'not set'}</td>
                      {userRoles.includes('view_sales') && (
                        <td data-header="Sales">
                          {currentItem.name === 'Current' ? (
                            <Link className="text--normalized" to={`/jobs/${job.id}/insights/sales`}>
                              ${(job.gross_sales / 100).toFixed(2)}
                            </Link>
                          ) : (
                            <span className="text--normalized">{`${(job.gross_sales / 100).toFixed(2)}`}</span>
                          )}
                        </td>
                      )}
                      {userRoles.includes('manage_jobs') && (
                        <td data-header="Action">
                          {currentItem.name === 'Current' ? (
                            <>
                              <Tooltip {...{ title: 'Settings', position: 'bottom', arrow: true, distance: 30 }}>
                                <Link className="button button--clean" to={`/jobs/${job.id}/settings`}>
                                  <i className="icon-gear" />
                                </Link>
                              </Tooltip>
                              <Tooltip {...{ title: 'Archive', position: 'bottom', arrow: true, distance: 30 }}>
                                <button className="button button--clean" name="archive" type="button" onClick={() => handleDeleteShow(job.id)}>
                                  <i className="icon-archive" />
                                </button>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Tooltip {...{ title: 'Restore', position: 'bottom', arrow: true, distance: 30 }}>
                                <button className="button button--clean" name="button" type="button" onClick={() => handleRestoreJob(job.id)}>
                                  <i className="icon-restore" />
                                </button>
                              </Tooltip>
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </>
              ) : (
                <tr className="text--center">
                  <td colSpan={9}>No items were found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </article>
        {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
      </main>

      {/* Archive modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text--left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Archive Job</h3>
          </header>
          <main className="modal__content">
            <p>
              Archiving will hide this job from the current list of jobs. It will NOT be deleted. You can access and restore this job by viewing the archives
              and selecting the restore button.
            </p>
          </main>
          <footer className="center modal__footer">
            <button className="button button--danger button--large" name="button" type="button" onClick={handleArchive} disabled={requesting}>
              Archive
            </button>
          </footer>
        </div>
      </aside>

      {showAddNew && <AddNew history={history} showAddNew={showAddNew} onAddNewToggle={handleAddNewToggle} />}
    </>
  );
};

export default List;
