import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { CompatRouter, CompatRoute, Routes, Route } from 'react-router-dom-v5-compat';

// Helpers
import { INIT } from '@/components/Login/constants';
import PrivateRoute from '@/components/Shared/PrivateRoute';
import ReturningUserRoute from '@/components/Shared/ReturningUserRoute';

// Jobs
import Jobs from '@/components/Jobs';
import JobsMarketingFlyersPreview from '@/components/Jobs/Marketing/Flyers/Preview';
import JobsUploadWidget from '@/components/Jobs/Gallery/Upload/Widget';
import JobPostProcessingUploadWidget from '@/components/Jobs/Services/PostProcessing/Upload/Widget';

// SignUp
import RegisterRoute from '@/components/SignUp';
import VerifyRoute from '@/components/SignUp/Verify';
import WhccConnectRoute from '@/components/SignUp/WHCC/Connect';
import WhccCallbackRoute from '@/components/SignUp/WHCC/Connect/callback';

// Login
import LoginRoute from '@/components/Login';
import ForgotPasswordRoute from '@/components/Login/ForgotPassword';

// Settings
import SettingsRoute from '@/components/Settings';
import StripeContainer from '@/components/Settings/Studio/Payment/Stripe';

// Shared
import Footer from '@/components/Shared/Footer';
import ScrollToTop from '@/components/Shared/ScrollToTop';
import PhotoDayIntercom from '@/components/Shared/PhotoDayIntercom';

// Other Routes
import HomeRoute from '@/components/Home';
import Capture from '@/components/Capture';
import Resources from '@/components/Resources';
import ReportsRoute from '@/components/Reports';
import NotFound404 from '@/components/NotFound404';
import StorefrontRoute from '@/components/Storefront';
import ErrorBoundary from '@/components/ErrorBoundary';
import OrganizationRoute from '@/components/Organizations';

// Utils
import * as labKeys from '@/components/SignUp/constants';
import CableProvider from '@/components/Shared/ActionCableProvider';

// Images
import reedy_logo from '@/assets/images/reedy_logo.png';
import aps_logo from '@/assets/images/aps_logo.png';
import millers_logo from '@/assets/images/millers_logo.png';
import whcc_logo_white from '@/assets/images/whcc_logo_white.png';
import bay_logo from '@/assets/images/lab-bay-circle.png';
import richmond_logo from '@/assets/images/richmond-logo-square.png';

// Redux Store
import store from '@/store';
store.dispatch({ type: INIT });

function PDRoutes() {
  // For public flyers we only include routes for that
  // This should eventually be moved to a separate project
  // Current flyers sites are my-dev.photoday.io my-staging.photoday.io and my.photoday.io
  if (window.location.host.split('.')[0].startsWith('my')) {
    return (
      <Provider store={store}>
        <Router>
          <CompatRouter>
            <ScrollToTop>
              <Routes>
                <Route path="/flyer/:flyerId" element={<JobsMarketingFlyersPreview />} />
                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </ScrollToTop>
          </CompatRouter>
        </Router>
      </Provider>
    );
  }

  // Regular studio site routes
  return (
    <Provider store={store}>
      <Router>
        <CompatRouter>
          <ErrorBoundary>
            <CableProvider>
              <PhotoDayIntercom>
                <ScrollToTop>
                  <Switch>
                    <ReturningUserRoute exact path="/" component={LoginRoute} />

                    <CompatRoute exact path="/capture/register" component={Capture} />
                    <CompatRoute exact path="/register/verify/:token" component={VerifyRoute} />
                    <CompatRoute exact path="/register/resend-verification" component={VerifyRoute} />
                    <CompatRoute exact path="/forgot-password/:token" component={ForgotPasswordRoute} />
                    <CompatRoute
                      exact
                      path="/whcc-connect"
                      render={(props: object) => <WhccConnectRoute {...props} lab={{ key: labKeys.REGISTER_WHCC_LAB_KEY, image: whcc_logo_white }} />}
                    />
                    <CompatRoute
                      exact
                      path="/whcc-callback"
                      render={(props: object) => <WhccCallbackRoute {...props} lab={{ key: labKeys.REGISTER_WHCC_LAB_KEY, image: whcc_logo_white }} />}
                    />
                    <CompatRoute
                      exact
                      path="/whcc-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_WHCC_LAB_KEY, image: whcc_logo_white }} />}
                    />
                    <CompatRoute
                      exact
                      path="/reedy-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_REEDY_LAB_KEY, image: reedy_logo }} />}
                    />
                    <CompatRoute
                      exact
                      path="/aps-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_APS_LAB_KEY, image: aps_logo }} />}
                    />
                    <CompatRoute
                      exact
                      path="/millers-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_MILLERS_LAB_KEY, image: millers_logo }} />}
                    />
                    <Redirect exact from="/bri-register" to="/bayphoto-register" />
                    <CompatRoute
                      exact
                      path="/bayphoto-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_BAY_PHOTO_LAB_KEY, image: bay_logo }} />}
                    />
                    <CompatRoute
                      exact
                      path="/richmond-register"
                      render={(props: object) => <RegisterRoute {...props} lab={{ key: labKeys.REGISTER_RICHMOND_LAB_KEY, image: richmond_logo }} />}
                    />

                    <Redirect from="/academy**" to="/home/academy**" />
                    <PrivateRoute path="/home" component={HomeRoute} />

                    <PrivateRoute path="/organizations" component={OrganizationRoute} requiredPermission={'view_clients'} />
                    <PrivateRoute path="/storefront" component={StorefrontRoute} />

                    {/* Jobs */}
                    <PrivateRoute path="/jobs" component={Jobs} requiredPermission={'view_jobs'} />

                    <Redirect exact from="/user" to="/user/account" />
                    <PrivateRoute path="/user/account" component={SettingsRoute} />
                    <PrivateRoute path="/user/my-studio" component={SettingsRoute} />

                    <PrivateRoute path="/reports" component={ReportsRoute} requiredPermission={'view_sales'} />

                    <PrivateRoute path="/stripe/oauth/connect" component={StripeContainer} requiredPermission={'manage_stripe'} />
                    <Redirect from="/stripe/express**" to={{ pathname: '/user/my-studio/payment', state: { expressCallbackStatus: 'complete' } }} />

                    <PrivateRoute path="/collateral" component={Resources} />
                    <PrivateRoute path="/webinars" component={Resources} />
                    <PrivateRoute path="/support" component={Resources} />

                    <CompatRoute component={NotFound404} />
                  </Switch>
                </ScrollToTop>
              </PhotoDayIntercom>
              <JobsUploadWidget />
              <JobPostProcessingUploadWidget />
              <Footer />
            </CableProvider>
          </ErrorBoundary>
        </CompatRouter>
      </Router>
    </Provider>
  );
}

export default PDRoutes;
