import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyAccountStudioRequest } from '../../Settings/actions';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Images
import imageStripe from '@/assets/images/stripe-logo.svg';

// Styles
import './style.css';

// Types
import { Studio, StudioStripeMigrationStatus } from '@/types';

interface BannerContent {
  title: string;
  description: string;
  link?: string;
  buttonText: string;
}

const bannerContent: Record<string, BannerContent> = {
  [StudioStripeMigrationStatus.Available]: {
    title: 'Switch to Stripe Express!',
    description:
      'As PhotoDay continues to grow as a marketplace facilitator, we are now required to migrate all of our users from their current Stripe Standard accounts to new Stripe Express accounts.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  },
  [StudioStripeMigrationStatus.Approaching]: {
    title: 'Please migrate to Stripe Express',
    description:
      'The deadline is approaching! To ensure a seamless payment transfer experience, follow the steps to migrate to Stripe Express by <b>12/31/2024</b>. This change is part of PhotoDay’s continued development as a marketplace facilitator.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  },
  [StudioStripeMigrationStatus.Past]: {
    title: 'PhotoDay is unable to transfer your funds',
    description:
      'As PhotoDay continues to grow as a marketplace facilitator, we are now required to migrate all of our users from their current Stripe Standard accounts to new Stripe Express accounts.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  }
};

const MigrationBanner: FC = () => {
  const dispatch = useDispatch();

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio, requesting: usersRequesting } = useSelector((state: any) => state.users);
  const { id: studioId, stripe_migration_deadline: studioMigrationDeadline } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const [currentStatus, setCurrentStatus] = useState<StudioStripeMigrationStatus>();

  useEffect(() => {
    dispatch(
      getMyAccountStudioRequest({ studioId }, ({ data }: { data: Studio }) => {
        const { resolved_stripe_migration_status: studioMigrationStatus } = data;

        if (
          studioMigrationStatus === StudioStripeMigrationStatus.Available ||
          studioMigrationStatus === StudioStripeMigrationStatus.Approaching ||
          studioMigrationStatus === StudioStripeMigrationStatus.Past
        ) {
          setCurrentStatus(studioMigrationStatus);
        }
      })
    );
  }, []);

  if (!currentStatus || usersRequesting) return null;

  const { title: bannerTittle, description: bannerDescription, link: bannerLink, buttonText: bannerButtonTitle } = bannerContent[currentStatus];

  return (
    <aside
      className={`flex between nowrap middle wrap-md gap-20 panel panel--spacious animate stripe-migration__banner stripe-migration__banner--${currentStatus}`}
    >
      <figure>
        <img src={imageStripe} height="64" alt="Stripe" />
      </figure>
      <hgroup className="flex-10 flex-12-md">
        <h1 className={`flex gap-10 middle text--bold stripe-migration__title--${currentStatus}`}>{bannerTittle}</h1>
        <h5 className="text--normal text--nomargin">
          <span dangerouslySetInnerHTML={{ __html: bannerDescription }} />{' '}
          {bannerLink && (
            <a href={bannerLink} className="text--bold" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </h5>
      </hgroup>
      <aside className="flex-2 flex-12-md flex column center gap-10">
        <Link className={`button text--bold button--block stripe-migration__button--${currentStatus}`} to="/user/my-studio/payment">
          {bannerButtonTitle}
        </Link>
        {studioMigrationDeadline && (
          <span className={`text--small text--italic ${currentStatus === StudioStripeMigrationStatus.Past ? 'text--danger' : ''}`}>
            Due {shortDate(studioMigrationDeadline)}
          </span>
        )}
      </aside>
    </aside>
  );
};

export default MigrationBanner;
