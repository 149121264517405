import { FC } from 'react';

// Types
interface InputCharacterRemainerProps {
  inputCharsCount: number;
  inputMaxLength: number;
}

const InputCharacterRemainer: FC<InputCharacterRemainerProps> = ({ inputCharsCount, inputMaxLength }) => {
  return <span className="text--block text--italic text--right text--small mt-4">Characters remaining: {inputMaxLength - Number(inputCharsCount ?? 0)}</span>;
};

export default InputCharacterRemainer;
