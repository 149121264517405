import { FC } from 'react';

interface UnavailableProductsProps {
  onToggleUnavailable: () => void;
  data: {
    product_name: string;
    type: string;
    price_sheet_item_name: string;
  }[];
}

const UnavailableProducts: FC<UnavailableProductsProps> = ({ onToggleUnavailable, data }) => {
  return (
    <aside className="modal unavailable-products animate">
      <div className="modal__box">
        <header className="modal__header">
          <button className="button button--action modal__close" name="close" type="button" onClick={onToggleUnavailable}>
            <i className="icon-close"></i>
          </button>
          <h3 className="text--left">Unavailable Items</h3>
          <p className="text--left">
            The products listed below are missing themes and cannot be sold in your storefront. If they’ve also been added to a package, that package will be
            unavailable. Please try adding another activity or color to your price sheet.
          </p>
        </header>
        <main className="modal__content">
          <table className="table table--lean">
            <thead className="table__header">
              <tr>
                <th>Item</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {data?.map((item, index) => (
                <tr key={index}>
                  <td data-header="Item" className="text--capitalize">
                    {item.product_name}
                  </td>
                  <td data-header="Detail">{item.type === 'product' ? 'Product' : `Package - ${item.price_sheet_item_name}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
      </div>
    </aside>
  );
};

export default UnavailableProducts;
