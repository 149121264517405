import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isEqual } from 'lodash';
import { Component } from 'react';

import Select from 'react-select';

import matchValidatePropToValidators from '../validators';

// Styles
import './style.css';

class ReactSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      })
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    resetOnUpdate: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    multi: PropTypes.bool,
    required: PropTypes.bool,
    showRefresh: PropTypes.bool,
    onRefresh: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    options: [],
    label: null,
    resetOnUpdate: false,
    disabled: false,
    loading: false,
    multi: false,
    required: false,
    showRefresh: false,
    onRefresh: null
  };

  input = null;

  componentDidUpdate(nextProps) {
    const { options, resetOnUpdate, multi } = nextProps;

    // Reset selected state when options list is changed
    if (this.input && !isEqual(this.props.options, options)) {
      this.input.onChange(resetOnUpdate ? null : this.filteredOptions(options, multi));
    }
  }

  filteredOptions = (options, multi) => {
    const filtered = options.filter((item) => {
      if (typeof this.input.value === 'string') {
        return multi ? this.input.value.includes(item.value) : item.value === this.input.value;
      } else {
        if (multi) {
          return this.input.value.map(({ value }) => value).includes(item.value);
        }
        return item.value === this.input.value && this.input.value.value;
      }
    });
    return multi ? filtered : filtered[0];
  };

  handleChange = (input) => (value) => {
    const { onChange } = this.props;

    onChange && onChange(value);
    input.onChange(value);
  };

  renderSelect = ({ input, meta: { touched, error } }) => {
    const { options, disabled, multi, required, loading, showRefresh, onRefresh, placeholder } = this.props;

    this.input = input;

    return (
      <div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Select
            {...this.props}
            {...input}
            className="select flex-12"
            classNamePrefix="select"
            onChange={this.handleChange(input)}
            isDisabled={disabled || loading}
            isMulti={multi}
            isLoading={loading}
            onBlur={() => input.onBlur(undefined)}
            clearable={!required}
            options={options}
            placeholder={placeholder}
          />
          {showRefresh && <i role="button" className="icon-refresh" style={{ padding: '10px' }} onClick={onRefresh} />}
        </div>
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  render() {
    const { name, label, loading } = this.props;
    return (
      <div id="shared__forms__react_select">
        <div>
          {label && <label>{label}</label>}
          <Field loading={loading} name={name} component={this.renderSelect} validate={matchValidatePropToValidators(this.props)} />
        </div>
      </div>
    );
  }
}

export default ReactSelect;
