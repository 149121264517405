// Plugins
import moment from 'moment';

// Styles
import './style.css';

const APP_VERSION = import.meta.env.VITE_VERSION;

const Footer = () => {
  const currentYear = moment().format('YYYY');

  return (
    <footer className="box footer">
      <nav className="flex gap-20">
        <span>&copy; {`${currentYear} PhotoDay`}</span>
        <a className="text--normal" href="https://photoday.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <a className="text--normal" href="https://photoday.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a className="text--normal" href="https://photoday.com/brand-guidelines" target="_blank" rel="noopener noreferrer">
          Brand Guidelines
        </a>
        <a className="text--normal" href="https://photoday.com/version-updates" target="_blank" rel="noopener noreferrer">
          v{APP_VERSION}
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
