import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { string, array, object, objectOf, shape, func } from 'prop-types';

// Plugins
import Select from 'react-select';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { orderBy } from 'lodash';
import { convertToText } from '@/utils/currency';

// Helpers
import { useStudioTimeZone } from '@/hooks';
import { shortDate, pickerDate } from '@/utils/displayFormats';

// Images
import ReactImagePromosRefresh from '@/assets/images/image-seasonal-promos.svg?react';

const SEASONAL_GRAPHICS_LINK = import.meta.env.VITE_SEASONAL_GRAPHICS_LINK === '1';

PromosCurrent.propTypes = {
  section: string,
  request: string,
  audience: string,
  promos: shape({
    all: shape({ list: array, pagination: object }),
    select: object
  }),
  promotions: shape({
    all: shape({ list: array, pagination: object }),
    select: object
  }),
  dateFilteredJobs: array,
  jobs: objectOf(array),
  offers: shape({
    all: array,
    select: string
  }),
  sms: string,
  onPromotionChange: func.isRequired,
  onRefreshOffers: func.isRequired,
  onRequestChange: func.isRequired,
  onJobDateFilter: func.isRequired,
  onJobChange: func.isRequired,
  onJobAdd: func.isRequired,
  onJobRemove: func.isRequired,
  onOfferChange: func.isRequired,
  onAudienceChange: func.isRequired,
  onSmsChange: func.isRequired,
  onSave: func.isRequired,
  onStartDateChange: func.isRequired,
  onEndDateChange: func.isRequired,
  onRequestCustomPromo: func.isRequired
};

function PromosCurrent({
  section,
  request,
  audience,
  promos,
  promotions,
  dateFilteredJobs,
  jobs,
  offers,
  sms,
  startDate,
  endDate,
  onPromotionChange,
  onRefreshOffers,
  onRequestChange,
  onJobDateFilter,
  onJobChange,
  onJobAdd,
  onJobRemove,
  onOfferChange,
  onAudienceChange,
  onSmsChange,
  onSave,
  onStartDateChange,
  onEndDateChange,
  onRequestCustomPromo,
  firstJob,
  currentOffer
}) {
  const studioTimeZone = useStudioTimeZone();

  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  const newCustomPromoRequest = section === 'Custom' && request === section;
  const isNewCustomPromo = newCustomPromoRequest && !promos.select;
  const hasPromo = (promotions.select && Object.keys(promotions.select).length > 0) || (newCustomPromoRequest && !!promotions.all?.list?.length);
  const audienceOptions = [
    { label: 'All', value: 'all' },
    { label: 'Not Ordered', value: 'without_orders' }
  ];

  const formatTime = (time, timeZone) => {
    return `${moment(time)?.tz(timeZone)?.format('dddd, MMMM DD [at] ha')} ${moment.tz(timeZone)?.zoneAbbr()}`;
  };

  const formatStudioTimeZone = (message) => {
    const time = message.send_at;

    if (time && studioTimeZone) {
      return formatTime(time, studioTimeZone);
    }

    if (startDate) {
      const startAt = moment(startDate).add(message.send_in_days, 'days');
      const newTime = moment(`${pickerDate(startAt)} ${message.send_time}`).utc(true);

      return formatTime(newTime, studioTimeZone);
    }

    return '';
  };

  const calculateMinDate = () => {
    const minDate = new Date();

    if (studioTimeZone) {
      const studioTime = moment(minDate).tz(studioTimeZone);
      minDate.setDate(minDate.getDate() + (studioTime.hours() < 17 ? 1 : 2)); // 6PM = 18 hours
    }

    return minDate;
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleStartDayChange = (date) => setStartDay(date);
  const handleEndDayChange = (date) => setEndDay(date);

  const handleFilter = () => {
    const start_date = moment(startDay).isValid() ? moment(startDay) : false;
    const end_date = moment(endDay).isValid() ? moment(endDay) : false;

    let filtered = jobs.all;

    if (start_date) {
      filtered = filtered.filter((job) => moment(job.opened_at) >= start_date);
    }
    if (end_date) {
      filtered = filtered.filter((job) => moment(job.opened_at) <= end_date);
    }
    // custom promos have no start_at
    if (promotions.select && !promotions.select.custom) {
      filtered = filtered.filter((job) => moment(job.opened_at) >= moment(promotions.select.start_at).subtract(1, 'year'));
    }
    // consider only onsale jobs
    filtered = filtered.filter((job) => job.job_status === 'onsale');
    // most recent jobs will be displayed first
    const sortedJobs = orderBy(filtered, (job) => moment(job.opened_at), ['desc']);

    onJobDateFilter(sortedJobs);
  };

  useEffect(() => {
    if (section === 'Custom') {
      onAudienceChange({ value: audienceOptions[1].value });
    } else {
      onAudienceChange({ value: promos?.select?.audience ?? audienceOptions[0].value });
    }
  }, [section, promos?.select]);

  return (
    <section className={`flex-9 flex-12-md promos-section ${section === 'Seasonal' || newCustomPromoRequest ? 'promos-section--active' : ''}`}>
      {/* Select promo */}
      <header className="flex middle between promos-section__header">
        <h2 className="text--nomargin">{newCustomPromoRequest ? 'Custom Promos' : 'Seasonal Promos'}</h2>
        {section !== 'Seasonal' && hasPromo && (
          <aside className="button-group animate">
            <button
              className="button button--medium"
              name="start"
              type="button"
              onClick={() => onSave(promos && promos.select ? promos.select.is_enabled : false)}
              disabled={
                promotions.requesting ||
                !audience ||
                (isNewCustomPromo && (!startDate || !jobs?.add?.length || !promotions?.select)) ||
                (!isNewCustomPromo && (!offers?.select || !jobs?.add?.length || !promotions?.select))
              }
            >
              {newCustomPromoRequest ? (promos.select ? 'Update' : 'Create') : 'Save'}
            </button>

            {newCustomPromoRequest ? (
              <button
                className="button button--danger button--medium"
                name="cancel-custom-promo"
                type="button"
                onClick={(e) => onRequestChange(e, undefined)}
                disabled={promotions.requesting}
              >
                Cancel
              </button>
            ) : promos && promos.select && promos.select.is_enabled ? (
              <button
                className="button button--danger button--medium"
                name="start"
                type="button"
                onClick={() => onSave(false)}
                disabled={promotions.requesting}
              >
                Stop Promo
              </button>
            ) : (
              <button
                className="button button--success"
                name="start"
                type="button"
                onClick={() => onSave(true)}
                disabled={promotions.requesting || !offers?.select || !audience}
              >
                Save and Start Promo
              </button>
            )}
          </aside>
        )}
      </header>

      {section === 'Seasonal' && (
        <>
          <aside className="flex between panel panel--light-blue">
            <h1 className="promos-banner__title text--nomargin">Try out Custom Promos!</h1>
            <aside className="button-group animate">
              <button className="button button--medium" name="custom" type="button" onClick={onRequestCustomPromo}>
                Request Custom Promo
              </button>
            </aside>
          </aside>
          <aside className="flex gap-40 between nowrap panel panel--gray">
            <div>
              <h2 className="text--xxlarge text--navy">Seasonal Promos are getting a refresh.</h2>
              <h4 className="text--normal">
                We’re transforming our Seasonal Promos feature by creating fresh, engaging content to boost your sales and encompass all major shopping
                milestones and occasions.
              </h4>
            </div>
            <figure className="flex-end middle center">
              <ReactImagePromosRefresh />
            </figure>
          </aside>
          {SEASONAL_GRAPHICS_LINK && (
            <aside className="flex between middle panel panel--gray">
              <h4 className="text--normal text--nomargin">
                <strong>NEW!</strong> We have you covered with new holiday-specific graphics to use in your email marketing.
              </h4>
              <a
                className="button button--medium animate"
                href="https://support.photoday.io/en/articles/8395561-holiday-marketing-graphics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Holiday Graphics
              </a>
            </aside>
          )}
        </>
      )}

      {section !== 'Seasonal' && (
        <div className="panel panel--section">
          {hasPromo ? (
            <div className=" animate">
              <h5 className="promo-section__subtitle">Select Promo</h5>
              <Select
                className="select"
                classNamePrefix="select"
                isLoading={promotions.requesting}
                value={promotions?.all?.list?.filter((item) => item.id === promotions?.select?.id).map((item) => ({ value: item.id, label: item.name }))}
                options={promotions?.all?.list?.map((promotion) => ({
                  value: promotion.id,
                  label: promotion.name,
                  className: `${
                    promos.all?.list?.some((promo) => promo.is_enabled && promo.promotion?.id === promotion.id) &&
                    moment() >= moment(promotion.start_at) &&
                    moment() <= moment(promotion.end_at)
                      ? 'promo-live'
                      : ''
                  }`
                }))}
                onChange={onPromotionChange}
              />
            </div>
          ) : (
            <p className="animate">There are currently no promos available.</p>
          )}

          {promotions?.select?.urgent && (
            <p className="promo-urgent">
              Urgency series is usually covered by expiration series that automatically send. If you are sure you need this you may continue.
            </p>
          )}

          {hasPromo && promotions.select && <p>{promotions.select.description}</p>}

          {hasPromo &&
            promos.select &&
            promos.select.is_enabled &&
            moment() >= moment(promotions?.select?.start_at) &&
            moment() <= moment(promotions?.select?.end_at) && (
              <aside className="flex-8 panel panel--dark panel--lean">
                <small>
                  <i>
                    The promo is already in progress, however, there is still time! Your customers will still receive any remaining texts as part of this promo.
                  </i>
                </small>
              </aside>
            )}

          {hasPromo && promos.select && newCustomPromoRequest && (
            <aside className="flex-8 panel panel--dark panel--lean">
              <small>
                <i>The custom promo request is already in progress, however, there is still time to change a few things of this promo.</i>
              </small>
            </aside>
          )}
        </div>
      )}

      {section !== 'Seasonal' && hasPromo && (
        <>
          {/* Summary */}
          {!newCustomPromoRequest &&
            promos.select &&
            promos.select.is_enabled &&
            moment() >= moment(promotions?.select?.start_at) &&
            moment() <= moment(promotions?.select?.end_at) && (
              <article className="promos-summary panel panel--section">
                <h4 className="promos-summary__title">Summary</h4>
                <ul className="promos-summary__panel flex-8 flex-12-md">
                  <li className="promos-summary__item">
                    <span>Used</span>
                    <span className="promos-summary__value">{promos.select.orders_count}</span>
                  </li>
                  <li className="promos-summary__item">
                    <span>Sales from Promo</span>
                    <span className="promos-summary__value">{convertToText(promos.select.sales_cents, '$')}</span>
                  </li>
                </ul>
              </article>
            )}

          {/* Select Offer */}
          {(!newCustomPromoRequest || promotions?.select?.with_offer) && (
            <div className="panel panel--section">
              <h5 className="promo-section__subtitle">Select Offer</h5>
              <p>
                Select an offer for this promo or set one up{' '}
                <Link to="/storefront/offers-and-credits" target="_blank">
                  here
                </Link>
                .
              </p>
              <div className="flex">
                <Select
                  className="select select--nomargin"
                  classNamePrefix="select"
                  isLoading={offers.requesting}
                  value={offers.all.filter((item) => item.value === offers.select)}
                  options={offers.all}
                  onChange={onOfferChange}
                />
                <button className="button button--clean" name="refresh" type="button" onClick={onRefreshOffers}>
                  <i className="icon-refresh" />
                </button>
              </div>
              <p className="promos-tip mt-10">
                Tip: We recommend you create a unique offer code with a name like <strong>HOLIDAY</strong>. Need some inspiration? Check out{' '}
                <a rel="noopener noreferrer" target="_blank" href="https://blog.photoday.io/2020/10/07/offer-codes-ideas-creative-incentives">
                  Offer Codes, Ideas + Creative Incentives
                </a>
                !
              </p>
            </div>
          )}

          {/* Select Audience */}
          <div className="panel panel--section">
            <h5 className="promo-section__subtitle">Select Audience</h5>
            <p>Select which customers will receive the message(s).</p>
            <div className="flex ">
              <Select
                className="select select--nomargin"
                classNamePrefix="select"
                isLoading={offers.requesting}
                value={audienceOptions.filter((item) => item.value === audience)}
                options={audienceOptions}
                onChange={onAudienceChange}
              />
            </div>
          </div>

          {/* Target Job(s) */}
          <div className="panel panel--section">
            <h5 className="promo-section__subtitle">Target Job(s)</h5>
            <p>
              For each job you select, all opted-in customers will see the current photos and price sheet. Filter jobs by selecting a published date range
              below.
            </p>
            <div className="flex middle button-group">
              <DatePicker
                className="input--date"
                selected={startDay}
                onChange={handleStartDayChange}
                selectsStart
                startDate={startDay}
                endDate={endDay}
                isClearable={true}
                placeholderText="Start date"
                strictParsing
              />
              <i className="icon-arrow-long-right"></i>
              <DatePicker
                className="input--date"
                selected={endDay}
                onChange={handleEndDayChange}
                selectsEnd
                endDate={endDay}
                minDate={startDay}
                isClearable={true}
                placeholderText="End date"
                strictParsing
              />
              <button className="button" name="filter" type="button" onClick={handleFilter} disabled={promotions.requesting}>
                Filter
              </button>
            </div>
            <div className="table-selector">
              <ul className="table-selector__list" onClick={onJobChange}>
                <li className={`table-selector__item ${dateFilteredJobs?.length === jobs.select?.length ? 'table-selector__item--active' : ''}`} data-job="all">
                  All Jobs
                </li>
                {dateFilteredJobs
                  ?.filter((job) => jobs.add.indexOf(job.id) === -1)
                  .map((job) => (
                    <li
                      className={`table-selector__item ${jobs.select.some((select) => select === job.id) ? 'table-selector__item--active' : ''}`}
                      key={job.id}
                      data-job={job.id}
                    >
                      {job.name}
                    </li>
                  ))}
              </ul>
              <button className="button table-selector__button table-selector__button--active" name="button" onClick={onJobAdd} />
              <ul className="table-selector__list">
                {dateFilteredJobs
                  ?.filter((job) => jobs.add.indexOf(job.id) > -1)
                  .map((job) => (
                    <li className="table-selector__item" key={job.id}>
                      {job.name}
                      <button className="close table-selector__remove" type="button" aria-hidden="true" data-job={job.id} onClick={onJobRemove}>
                        <i className="icon-close" data-job={job.id}></i>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <p className="promos-tip">Eligible Jobs: Jobs that were published within a year of the start of the promotion and are not expired.</p>
          </div>

          {newCustomPromoRequest && promotions.select && (
            <div className="panel panel--section">
              <div className=" animate">
                {promotions?.select?.custom_end_date === true ? (
                  <>
                    <h5 className="promo-section__subtitle">Start and End Dates</h5>
                    <p>Please select a start and an end date before previewing SMS messages.</p>
                  </>
                ) : (
                  <>
                    <h5 className="promo-section__subtitle">Start Date</h5>
                    <p>Please select a start date before previewing SMS messages.</p>
                  </>
                )}
                <div className="flex nowrap middle button-group">
                  <DatePicker
                    className="input--date"
                    selected={startDate}
                    onChange={onStartDateChange}
                    startDate={startDate}
                    minDate={calculateMinDate()}
                    isClearable={true}
                    filterDate={isWeekday}
                    placeholderText="Promo Start date"
                    strictParsing
                  />
                  {promotions?.select?.custom_end_date === true && (
                    <>
                      <i className="icon-arrow-long-right"></i>
                      <DatePicker
                        className="input--date"
                        selected={endDate}
                        onChange={onEndDateChange}
                        selectsEnd
                        endDate={endDate}
                        minDate={startDate}
                        isClearable={true}
                        filterDate={isWeekday}
                        placeholderText="Promo End date"
                        strictParsing
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* SMS */}
          <div className={`panel panel--section ${newCustomPromoRequest && (!startDate || !promotions?.select) ? 'hidden' : ''}`}>
            <h6 className="promo-section__subtitle">SMS</h6>
            <p>These texts will go out as follows:</p>
            <div className="promos-sms">
              <div className="promos-timing">
                <h5 className="promo-section__subtitle">Timing</h5>
                <ul className="promos-timing__list" onClick={onSmsChange}>
                  {promotions?.select?.promotion_messages?.map((message) => (
                    <li className={`promos-timing__item ${message.id === sms ? 'promos-timing__item--active' : ''}`} key={message.id} data-sms={message.id}>
                      {formatStudioTimeZone(message)}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="promos-preview">
                <h6 className="promo-section__subtitle">Preview*</h6>
                <div className="promos-preview__bubble">
                  {promotions?.select?.promotion_messages?.map((message) => (
                    <p className={`promos-preview__text ${message.id === sms ? 'promos-preview__text--active' : ''}`} key={message.id}>
                      {message.text
                        .replace('%{job_name}', firstJob ? firstJob.name : '<job name>')
                        .replace('%{end_date}', promotions?.select?.custom_end_date === true ? shortDate(endDate) : '<promo end date>')
                        .replace('%{studio_name}', firstJob ? firstJob.studio_name : '<studio name>')
                        .replace('%{offer_code}', currentOffer ? currentOffer.code : '<offer code>')
                        .replace('%{offer_description}', currentOffer ? currentOffer.description : '<offer description>')
                        .replace('%{offer_description_2}', currentOffer ? currentOffer.description?.toLowerCase()?.slice(0, -1) : '<offer description>')
                        .replace('%{job_url}', '<gallery link>')}
                    </p>
                  ))}
                </div>
                <small>
                  <i>*All text previews contain variable information for the first job. Actual texts will contain the relevant variables when they are sent.</i>
                </small>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default PromosCurrent;
