import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import { Tooltip } from 'react-tippy';

// Redux
import { getJob, getInsightsSales, getInsightsSalesSummary, getInsightsSalesCsv } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { formatCurrency, pickerDate, shortDate } from '@/utils/displayFormats';

// Styles
import '../style.css';

const Sales = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;
  const { job, insights, requesting } = useSelector((state) => state.jobs);
  const {
    sales: { list, pagination },
    salesSummary,
    requesting: insightsRequesting
  } = insights;

  const [search, setSearch] = useState('');
  const [tableFilter, setTableFilter] = useState({
    id: jobId,
    order: 'purchased_date',
    dir: 'DESC',
    per_page: Number(pagination.perPage) || 100,
    search: '',
    page: pagination.page
  });

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSearch = (e) => {
    e.preventDefault();
    setTableFilter({ ...tableFilter, search });
    dispatch(getInsightsSales({ ...tableFilter, search }));
  };

  const handleSearchClear = (e) => {
    e.stopPropagation();
    setSearch('');
    setTableFilter({ ...tableFilter, search: '' });
    dispatch(getInsightsSales({ id: jobId }));
  };

  const handleOrderBy = (order, dir) => {
    setTableFilter({ ...tableFilter, order, dir, page: 1 });
  };

  const handlePagination = (page) => {
    dispatch(getInsightsSales({ ...tableFilter, page, search }));
  };

  const handleDownloadCSV = () => {
    dispatch(getInsightsSalesCsv({ id: jobId, name: job.name, date: pickerDate(new Date()) }));
  };

  useEffect(() => {
    dispatch(getJob({ id: jobId }));
    dispatch(getInsightsSalesSummary({ id: jobId }));
    dispatch(getInsightsSales({ id: jobId, page: pagination.page, per_page: Number(pagination.perPage) || 100, order: 'purchased_date', dir: 'DESC' }));
  }, []);

  useEffect(() => {
    dispatch(getInsightsSales(tableFilter));
  }, [tableFilter]);

  return (
    <>
      <Header history={history} jobId={jobId} title="insights" />

      <main className="box flex">
        <Sidebar jobId={jobId} title="Sales" />

        <section className="flex-9 flex-12-md job-insights-report">
          <header className="flex middle between job-insights-report__header">
            <h2 className="text--nomargin">Sales</h2>
            {list.length > 0 && (
              <button className="button button--medium" onClick={handleDownloadCSV} disabled={requesting}>
                Download CSV
              </button>
            )}
          </header>

          <div className="panel panel--section">
            <h4 className="text--tall">Summary</h4>

            <div className="insights-summary">
              <div className="insights-summary__numbers insights-summary__numbers--left">
                <small>
                  Unique Views
                  <Tooltip
                    title="Unique views are the number of the unique customers that click on a job’s gallery link. If a single customer views the link 5 times, the system will record 5 views and 1 unique view."
                    position="top"
                    trigger="mouseenter"
                  >
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>{salesSummary.view_count}</h3>}
              </div>
              <div className="insights-summary__numbers">
                <small>Order Sales Count</small>
                {insightsRequesting ? <TextLoader /> : <h3>{salesSummary.order_count}</h3>}
              </div>
              <div className="insights-summary__numbers">
                <small>Credit Sales Count</small>
                {insightsRequesting ? <TextLoader /> : <h3>{salesSummary.gift_card_count}</h3>}
              </div>
              <div className="insights-summary__numbers insights-summary__numbers--right">
                <small>Unused AdvancePay Credits</small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.unused_credits)}</h3>}
              </div>
            </div>

            <div className="insights-summary">
              <div className="insights-summary__numbers insights-summary__numbers--left">
                <small>
                  Gross Order Sales
                  <Tooltip
                    title="Total collected from orders after credits have been applied and offers have been deducted."
                    position="top"
                    trigger="mouseenter"
                  >
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.order_sales)}</h3>}
              </div>

              <div className="insights-summary__numbers">
                <small>
                  Average Order Value
                  <Tooltip title="Average total from orders before credits applied and offers deducted." position="top" trigger="mouseenter">
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.avg_order_value)}</h3>}
              </div>

              <div className="insights-summary__numbers">
                <small>
                  {' '}
                  Gross Credit Sales
                  <Tooltip title="Sales from credits purchased while gallery is in AdvancePay status." position="top" trigger="mouseenter">
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.credit_sales)}</h3>}
              </div>
              <div className="insights-summary__numbers">
                <small>
                  {' '}
                  Used Credits
                  <Tooltip title="Credits that have been used at checkout." position="top" trigger="mouseenter">
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.used_credits)}</h3>}
              </div>
              <div className="insights-summary__numbers">
                <small>
                  {' '}
                  Total Gross Sales
                  <Tooltip title="Includes both gross order sales and gross credit sales." position="top" trigger="mouseenter">
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.total_sales)}</h3>}
              </div>
              <div className="insights-summary__numbers insights-summary__numbers--right">
                <small>
                  Studio Payout
                  <Tooltip
                    title="Total payout to the studio through Stripe. Payouts prior to 2023 include sales tax. Payouts in 2023 and after exclude sales tax."
                    position="top"
                    trigger="mouseenter"
                  >
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>${formatCurrency(salesSummary.studio_payouts)}</h3>}
              </div>
            </div>
          </div>

          <div className="panel panel--section">
            <div className="flex between">
              <h4 className="text--nomargin">Details</h4>

              <form onSubmit={handleSearch}>
                <fieldset>
                  <input type="search" name="search" placeholder="Search Sales" maxLength="50" value={search} onChange={handleSearchChange} />
                  <button
                    className={`button button--clear button--small ${tableFilter.search || (list.length === 0 && search.length) ? '' : 'hidden'}`}
                    name="button"
                    type="button"
                    onClick={handleSearchClear}
                  >
                    Clear
                  </button>
                  <button className="button button--icon" name="button" type="submit">
                    <i className="icon-search"></i>
                  </button>
                </fieldset>
              </form>
            </div>

            <article className="table-box">
              <table className="table">
                <thead className="table__header">
                  <tr>
                    <th>
                      <Dropdown buttonName={'Purchase Date'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'purchased_date' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('purchased_date', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'purchased_date' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('purchased_date', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      <Dropdown buttonName={'Customer Info'} buttonExtraClass={'button--filter text--normalized'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'customer_last_name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('customer_last_name', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'customer_last_name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('customer_last_name', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      <Dropdown buttonName={'Subject Name(s)'} buttonExtraClass={'button--filter text--normalized'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_name', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_name', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      {' '}
                      <Dropdown buttonName={'Type'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'type' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('type', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'type' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('type', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>Order Number</th>
                    <th>
                      <Dropdown buttonName={'Used Credits'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'used_credits' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('used_credits', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'used_credits' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('used_credits', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      <Dropdown buttonName={'Amount'} buttonSubtitle={'(after credits)'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'amount' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('amount', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'amount' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('amount', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {insightsRequesting ? (
                    <tr>
                      <td colSpan="7">
                        <TableLoader rows={2} rowHeight={100} />
                      </td>
                    </tr>
                  ) : list.length ? (
                    <>
                      {list.map((sale) => {
                        return (
                          <tr className="animate" key={sale.id}>
                            <td data-header="Purchase Date">{shortDate(sale.date)}</td>
                            <td data-header="Customer Info">
                              <span className="text--block text--nowrap">
                                Name: <i>{sale.name || 'N/A'}</i>
                              </span>
                              <span className="text--block text--nowrap">
                                Email: <i>{sale.email || 'N/A'}</i>
                              </span>
                              <span className="text--block text--nowrap">
                                Phone #: <i>{sale.phone || 'N/A'}</i>
                              </span>
                            </td>
                            <td data-header="Subject Name(s)">{sale.subject_details}</td>
                            <td data-header="Type">{sale.type}</td>
                            <td data-header="Order Number">
                              <Link
                                to={
                                  sale.type === 'Order'
                                    ? `/storefront/orders/${sale.id}?from_insights`
                                    : `/storefront/orders/advance-pay/credits/${sale.id}?from_insights`
                                }
                              >
                                {sale.has_reprint ? `${sale.num} (RP)` : `${sale.num}`}
                              </Link>
                            </td>
                            <td data-header="Used Credits">
                              {sale.type === 'Credit Purchase' && sale.used_credits === 0 ? 'N/A' : `$${formatCurrency(sale.used_credits)}`}
                            </td>
                            <td data-header="Amount After Credits">${formatCurrency(sale.amount_cents)}</td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr className="text--center">
                      <td colSpan="7">No items were found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </article>
            {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
          </div>
        </section>
      </main>
    </>
  );
};

Sales.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

Sales.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  history: {}
};

export default Sales;
