import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

// Styles
import './style.css';

const VideoPlayer = function VideoPlayer({ url, showPlayer, onPlayerToggle, className }) {
  return (
    <aside className={`modal ${showPlayer ? '' : 'transparent'} ${className ? className : ''}`}>
      <div className="modal__box">
        <header className="modal__header">
          <button className="button button--action modal__close" name="button" type="button" onClick={onPlayerToggle}>
            <i className="icon-close"></i>
          </button>
        </header>

        <main className="modal__content react-player__container">
          <ReactPlayer className="react-player" url={url} volume={1} controls={true} width="100%" height="100%" />
        </main>
      </div>
    </aside>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  showPlayer: PropTypes.bool.isRequired,
  className: PropTypes.string
};

VideoPlayer.defaultProps = {
  url: '',
  showPlayer: false,
  className: ''
};

export default VideoPlayer;
