import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from '../validators';
import loadExif from '@/utils/loadExif';

import './styles.css';

class UploadFile extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  };

  static defaultProps = {
    label: null,
    placeholder: null,
    placeholderRemove: null,
    disabled: null,
    image: ''
  };

  readImage = (reader, image) => {
    return new Promise((resolve) => {
      reader.addEventListener('load', resolve, false);
      reader.readAsDataURL(image);
    });
  };

  onChange = (input) => (event) => {
    const reader = new FileReader();
    const image = event.target.files[0];

    const promises = [this.readImage(reader, image), loadExif(image)];

    Promise.all(promises).then(() => {
      const { name, type, data } = image;

      let orientation = 1;

      if (data?.Orientation) {
        orientation = data.Orientation;
      }

      input.onChange({
        orientation,
        filename: name,
        content_type: type,
        content: reader.result,
        blob: URL.createObjectURL(image)
      });
      input.onBlur();
    });
  };

  removePhoto = (input) => {
    input.onChange(null);
    input.onBlur();
  };

  renderInput = ({ input, meta: { touched, error } }) => {
    const { placeholder, placeholderRemove, disabled } = this.props;
    return (
      <div>
        <label className="UploadFile button" onFocus={input.onFocus}>
          <input type="file" disabled={disabled} onChange={this.onChange(input)} accept="image/png,image/jpeg" />
          <span>{placeholder}</span>
        </label>
        <label className="UploadFile btn btn-danger" onFocus={input.onFocus}>
          <input type="text" disabled={disabled} onClick={() => this.removePhoto(input)} />
          <span>{placeholderRemove}</span>
        </label>
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  renderInputNoImage = ({ input, meta: { touched, error } }) => {
    const { placeholder, disabled } = this.props;
    return (
      <div>
        <label className="UploadFile button" onFocus={input.onFocus}>
          <input type="file" disabled={disabled} onChange={this.onChange(input)} accept="image/png,image/jpeg" />
          <span>{placeholder}</span>
        </label>
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  render() {
    const { name, label, type, image } = this.props;

    return (
      <div id="shared__forms__upload_file">
        <div>
          {label && <label>{label}</label>}
          {image && <Field name={name} component={this.renderInput} type={type} validate={matchValidatePropToValidators(this.props)} />}

          {!image && <Field name={name} component={this.renderInputNoImage} type={type} validate={matchValidatePropToValidators(this.props)} />}
        </div>
      </div>
    );
  }
}

export default UploadFile;
