import React from 'react';

const StepContainer = (props) => {
  const { step, image, title, isOptional, description } = props;
  return (
    <div className="step-container">
      <h4 className="text--bold step-number">{step}</h4>
      <img src={image} alt={step} />
      <div className="step-text">
        <h4 className="text--bold">
          {title}
          {isOptional ? <small>(optional)</small> : null}
        </h4>
        <div>{description}</div>
      </div>
    </div>
  );
};

export default StepContainer;
