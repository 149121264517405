import PropTypes from 'prop-types';
import { useState } from 'react';

// Components
import Navigation from '@/components/Shared/Navigation';

// Styles
import './style.css';

const Header = ({ match, user }) => {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const roles = (user && user.roles) || [];

  const items = [
    { name: 'Sales', link: '/reports/sales', icon: 'icon-sales', hidden: roles.includes('view_sales') ? false : true },
    { name: 'Orders', link: '/reports/orders', icon: 'icon-order', hidden: roles.includes('view_sales') ? false : true },
    { name: 'Products', link: '/reports/products', icon: 'icon-product', hidden: roles.includes('view_sales') ? false : true }
  ];

  const currentItem = items.find((item) => match.path.includes(item.link)) || items[0];

  const handleToggleNavigation = () => setNavigationIsOpen(!navigationIsOpen);

  return (
    <header className="reports-header">
      <div className="box flex middle">
        <div className="flex flex-3 flex-12-md middle between-md reports-header__mobile">
          <h1 className="text--nomargin">Reports</h1>
          <button className="button button--outline reports-header__trigger" onClick={handleToggleNavigation}>
            <i className={currentItem.icon} />
            {currentItem.name}
          </button>
        </div>

        <div className="flex-9 between">
          <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  })
};

export default Header;
