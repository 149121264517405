import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector } from 'react-redux';

// Images
import image_academy_basics from '@/assets/images/image-academy-basics.jpg';
import image_academy_tip_week from '@/assets/images/image-academy-tip-week.jpg';
import image_academy_weekly_fb from '@/assets/images/image-academy-weekly-fb.jpg';
import image_academy_summer_series from '@/assets/images/image-academy-summer-series.jpg';
import image_academy_trail_blazer from '@/assets/images/image-academy-trail-blazer-talks.jpg';

const Programs = function Programs() {
  const { academy } = useSelector((state) => state.home);

  const programs = [
    {
      label: 'Basics',
      imageUrl: image_academy_basics,
      buttonTitle: academy.basics.videos.length > 0 ? 'View Program' : 'Coming Soon',
      buttonUrl: 'programs/basics',
      description:
        'Getting started in volume photography or need a refresher? <strong>Basics</strong> gets you acquainted with equipment, technique and workflow so you can have a successful picture day!'
    },
    {
      label: 'PhotoDay Weekly',
      imageUrl: image_academy_weekly_fb,
      buttonTitle: academy.weekly.videos.length > 0 ? 'View Program' : 'Coming Soon',
      buttonUrl: 'programs/weekly',
      description:
        'Join the PhotoDay team as they recap the week. <strong>PhotoDay Weekly</strong> is a weekly Facebook Live stream that covers weekly updates, usergroup posts, and industry news.'
    },
    {
      label: 'Summer Series',
      imageUrl: image_academy_summer_series,
      buttonTitle: academy['summer-series'].videos.length > 0 ? 'View Program' : 'Coming Soon',
      buttonUrl: 'programs/summer-series',
      description:
        "Who's ready to try something new this year? Don't miss Week 3 of our Summer Series all about shooting outside of your genre! Whether you're looking to try composites, natural, schools, dance, or sports—we want to help you step out of your comfort zone and photograph entirely new jobs with PhotoDay"
    },
    {
      label: 'Trailblazer Tips',
      imageUrl: image_academy_tip_week,
      buttonTitle: academy.tips.videos.length > 0 ? 'View Program' : 'Coming Soon',
      buttonUrl: 'programs/tips',
      description:
        'Monthly tips and tricks that will help you have the best picture days ever. Trailblazers are photographers who have embraced PhotoDay— and are seeing results!'
    },
    {
      label: 'Trailblazer Talks',
      imageUrl: image_academy_trail_blazer,
      buttonTitle: academy.trailblazer.videos.length > 0 ? 'View Program' : 'Coming Soon',
      buttonUrl: 'programs/trailblazer',
      description: '<strong>Trailblazer</strong> Talks are a series of influential videos from studios that have found success using the PhotoDay workflow.'
    }
  ];

  return (
    <>
      <header className="flex middle nowrap animate academy-container__header">
        <h2 className="text--nomargin">Programs</h2>
      </header>

      <section className="animate academy__program-grid">
        {programs.map((program, index) => (
          <div className="academy__program" key={index}>
            <div className="flex start academy__program-header">
              <h4>{program.label}</h4>
            </div>
            <figure className="flex column middle" key={index}>
              <LazyLoadImage className="academy__program-image" src={program.imageUrl} alt={program.title} draggable={false} effect="opacity" />
              <figcaption className="flex column between">
                <div className="academy__program-description">
                  {program.description.split('\n').map((line, i) => (
                    <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
                  ))}
                </div>

                <Link to={program.buttonUrl} className="button button--outline" disabled={program.buttonTitle === 'Coming Soon'}>
                  {program.buttonTitle}
                </Link>
              </figcaption>
            </figure>
          </div>
        ))}
      </section>
    </>
  );
};

export default Programs;
