const imageUpscaler = 1;
const sizes = {
  xsmall: 100,
  small: 200,
  medium: 300,
  large: 600,
  xlarge: 1200
};

// https://docs.imagizer.com/api_reference/
const imagizerScaling = (url = null, width = null, height = null) => {
  if (width) {
    url = `${url}&w=${width * imageUpscaler}`;
  }

  if (height) {
    url = `${url}&h=${height * imageUpscaler}`;
  }

  return url;
};

const imageScaling = ({ url, width = null, height = null, size } = {}) => {
  if (size) {
    width = sizes[size];
  }

  if (('' + url).substr(0, 5).toLowerCase() === 'data:') {
    return url;
  }

  if (!url) {
    return null;
  }

  return imagizerScaling(url, width, height);
};

export default imageScaling;
