import { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Select } from '@/components/Shared/Forms';

class PercentOrFixedOffers extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { offerType, amount } = props;
    this.state = {
      offerType,
      amount
    };
  }

  handleAmountChange = (event) => {
    const { onChange } = this.props;
    this.setState({ amount: event.target.value });
    onChange &&
      onChange({
        amount: event.target.value,
        offer_type: this.state.offerType
      });
  };

  handleOfferTypeChange = (event) => {
    const { onChange } = this.props;
    this.setState({ offerType: event.target.value });
    onChange && onChange({ amount: this.state.amount, offer_type: event.target.value });
  };

  render() {
    const { offerType } = this.state;
    const { required } = this.props;
    return (
      <fieldset id="fixed-discount-container">
        <label>Discount</label>
        <div className="flex flex-row">
          <div className="flex-6">
            <Select
              required
              options={[
                { value: 'fixed_amount', label: 'Fixed Dollar Amount' },
                { value: 'fixed_percentage', label: 'Percent' }
              ]}
              name="offer_type"
              onChange={this.handleOfferTypeChange}
            />
          </div>
          <div className="flex-6">
            <InputGroup
              name="amount"
              placeholder={offerType === 'fixed_percentage' ? '0' : '0.00'}
              addonPosition={offerType === 'fixed_percentage' ? 'right' : 'left'}
              onChange={this.handleAmountChange}
              required={required}
              type="number"
            >
              <span>{offerType === 'fixed_percentage' ? '%' : '$'}</span>
            </InputGroup>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default PercentOrFixedOffers;
