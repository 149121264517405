import { FC, useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Styles
import './style.css';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createKnockoutItem, updateJobKnockout, updateKnockoutDropzone, updateKnockoutUploadFromCable } from '../../../../actions';

// Types
import { ImageFile } from '@/types';

interface ChannelParamProps {
  channel: string;
  knockout_id: string;
}

const PostProcessingWidget: FC = () => {
  const dispatch = useDispatch();
  const {
    upload: { queue: galleryQueue, successful: gallerySuccessful, failed: galleryFailed, rejected: galleryRejected, hold: galleryHold },
    knockoutUpload: {
      knockoutId,

      failed,
      rejected,
      duplicated,

      time,
      queue,

      total,
      current,
      successful,

      completed,

      hold,
      retry,
      replace,
      processing,
      dismiss
    }
  } = useSelector((state: any) => state.jobs);

  const { jwt } = useSelector((state: any) => state.login);

  const [closeTimer, setCloseTimer] = useState<any | null>(null);
  const [channelParams, setChannelParams] = useState<ChannelParamProps | null>(null);

  const isGalleryWidgetVisible = galleryQueue.length > 0 || gallerySuccessful > 0 || galleryFailed.length > 0 || galleryRejected.length > 0 || galleryHold;

  const remaining = Number((time.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) / time.length) * queue.length) || 0;

  const handleRetryAll = () => {
    if (processing) {
      dispatch(updateKnockoutDropzone({ retry: true }));
    } else {
      dispatch(updateKnockoutDropzone({ retry: true, failed: [], processing: true, queue: failed }));
      dispatch(createKnockoutItem());
    }
  };

  const handleDismiss = () => dispatch(updateKnockoutDropzone({ dismiss: true, failed: [], rejected: [] }));

  const handleReplaceAll = () => {
    window.clearTimeout(closeTimer);

    if (processing) {
      dispatch(updateKnockoutDropzone({ replace: true }));
    } else {
      dispatch(updateKnockoutDropzone({ replace: true, duplicated: [], processing: true, queue: duplicated }));
      dispatch(createKnockoutItem());
    }
  };

  const handleSkipAll = () => {
    dispatch(updateKnockoutDropzone({ duplicated: [] }));

    if (queue.length === 0) {
      dispatch(updateJobKnockout({ knockoutId, uploaded: true }));
    }
  };

  const handleKeywordsStrategy = (strategy: string) => {
    dispatch(updateKnockoutDropzone({ hold: false, keywords: strategy === 'use' ? true : false }));
    dispatch(createKnockoutItem());
  };

  const handleCancel = () => dispatch(updateKnockoutDropzone({ cancel: true }));

  const handleDone = () => {
    dispatch(
      updateKnockoutDropzone({
        knockoutId: '',

        failed: [],
        rejected: [],
        duplicated: [],

        time: [],
        queue: [],

        total: 0,
        current: 0,
        successful: 0,
        completed: 0,

        hold: false,
        cancel: false,
        skip: false,
        retry: false,
        replace: false,
        keywords: true,
        processing: false,
        requesting: false,
        dismiss: false
      })
    );
  };

  const handlePostProcessingItemMessageReceive = {
    received(message: any) {
      if (message.type === 'knockout-item') {
        const knockoutItemMessage = message?.knockout_item;

        if (knockoutItemMessage) {
          const { filename, status } = knockoutItemMessage;

          const file = queue.find((item: ImageFile) => item?.name === filename) ?? failed.find((item: ImageFile) => item?.name === filename);

          dispatch(updateKnockoutUploadFromCable({ data: knockoutItemMessage, file }));

          if (status === 'failed') {
            queue.splice(
              queue.findIndex((item: File) => item.name === filename),
              1
            );
          }
        }
      }
    }
  };

  if (jwt) {
    // This event channel is unique to the current post-processing job
    // eslint-disable-next-line
    useActionCable(channelParams, handlePostProcessingItemMessageReceive);
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      if (queue.length) {
        return 'Changes you make may not be saved.';
      } else {
        return;
      }
    };
  }, []);

  useEffect(() => {
    if (knockoutId) {
      setChannelParams({ channel: 'KnockoutEventsChannel', knockout_id: knockoutId });
    } else {
      setChannelParams(null);
    }
  }, [knockoutId]);

  useEffect(() => {
    if (!queue.length && successful) {
      if (completed < successful) {
        window.clearTimeout(closeTimer);
        setCloseTimer(window.setTimeout(() => handleDone(), 90000));
      } else {
        window.clearTimeout(closeTimer);
        setCloseTimer(window.setTimeout(() => handleDone(), 5000));
      }
    }
  }, [queue, completed]);

  return (
    <aside className={`job-postprocessing-widget ${isGalleryWidgetVisible ? 'job-postprocessing-widget--shifted' : ''}`}>
      {/* Reject/Fail panel */}
      {((rejected.length > 0 && retry === false) || (failed.length > 0 && dismiss === false)) && (
        <section className="job-postprocessing-widget__panel animate">
          <h5 className="job-postprocessing-widget__title job-postprocessing-widget__title--error">
            Houston, we have a problem. <br /> <small className="job-postprocessing-widget__subtitle">Some files could not be uploaded.</small>
          </h5>

          <article className="job-postprocessing-widget__list-container">
            {/* Rejected files */}
            <ul className="job-postprocessing-widget__list">
              {rejected.map((file: ImageFile, index: number) => (
                <li className="job-postprocessing-widget__item job-postprocessing-widget__item--file" key={`${index}-${file.name}`}>
                  {file.name} <span>{file.reason}</span>
                </li>
              ))}
            </ul>

            {/* Failed files */}
            <ul className="job-postprocessing-widget__list">
              {failed.map((file: File, index: number) => (
                <li className="job-postprocessing-widget__item job-postprocessing-widget__item--image" key={`${index}-${file?.name}`}>
                  <span className="job-postprocessing-widget__item-name">{file?.name}</span>
                </li>
              ))}
            </ul>
          </article>

          <aside className="job-postprocessing-widget__actions">
            <button
              className={`btn job-postprocessing-widget__actions-button ${failed.length ? '' : 'hidden'}`}
              type="button"
              onClick={handleRetryAll}
              disabled={failed.length > 0 && retry === true}
            >
              Retry all
            </button>
            <button className="button" type="button" onClick={handleDismiss}>
              Dismiss all
            </button>
          </aside>
        </section>
      )}

      {/* Duplicates panel */}
      {duplicated.length > 0 && replace === false && (
        <section className="job-postprocessing-widget__panel animate">
          <h5 className="job-postprocessing-widget__title job-postprocessing-widget__title--warning">
            Duplicates! <br /> <small className="job-postprocessing-widget__subtitle">These items have already been uploaded.</small>
          </h5>

          <article className="job-postprocessing-widget__list-container">
            {/* Duplicate files */}
            <ul className="job-postprocessing-widget__list">
              {duplicated.map((file: File) => (
                <li className="job-postprocessing-widget__item job-postprocessing-widget__item--image" key={file.name}>
                  <span className="job-postprocessing-widget__item-name">{file.name}</span>
                </li>
              ))}
            </ul>
          </article>

          <aside className="job-postprocessing-widget__actions">
            <button className="button job-postprocessing-widget__actions-button" type="button" onClick={handleReplaceAll}>
              Replace all
            </button>
            <button className="button" type="button" onClick={handleSkipAll}>
              Skip all
            </button>
          </aside>
        </section>
      )}

      {/* Keywords */}
      {hold && (
        <section className="job-postprocessing-widget__panel animate">
          <h5 className="job-postprocessing-widget__title job-postprocessing-widget__title--warning">
            Keywords! <br />
            <small className="job-postprocessing-widget__subtitle">
              PhotoDay has detected keywords in the photos you’re about to upload. We can use the keywords and convert them into tags or we can ignore them.
            </small>
            <br />
            <br />
            <small className="job-postprocessing-widget__subtitle">What would you like PhotoDay to do?</small>
          </h5>

          <aside className="job-postprocessing-widget__actions">
            <button className="button job-postprocessing-widget__actions-button" type="button" onClick={() => handleKeywordsStrategy('use')}>
              Use keywords
            </button>
            <button className="button" type="button" onClick={() => handleKeywordsStrategy('ignore')}>
              Ignore
            </button>
          </aside>
        </section>
      )}

      {/* Progress status panel */}
      {processing && (
        <section className="job-postprocessing-widget__panel animate">
          <div className="job-postprocessing-widget__status">
            <figure className="job-postprocessing-widget__figure">
              {hold === false && queue.length ? (
                <img
                  className={`job-postprocessing-widget__thumbnail ${
                    Object.prototype.hasOwnProperty.call(queue[0]?.data, 'Orientation')
                      ? `job-postprocessing-widget__thumbnail--orientation-${queue[0]?.data['Orientation']}`
                      : ''
                  }`}
                  src={
                    Object.prototype.hasOwnProperty.call(queue[0]?.data, 'Thumbnail') &&
                    Object.prototype.hasOwnProperty.call(queue[0]?.data['Thumbnail'], 'blob')
                      ? URL.createObjectURL(queue[0]?.data['Thumbnail']?.blob)
                      : URL.createObjectURL(queue[0])
                  }
                  alt="Thumbnail"
                />
              ) : null}
            </figure>

            <article className="job-postprocessing-widget__progress">
              <aside className="job-postprocessing-widget__info">
                {hold === false && queue.length ? (
                  <div>
                    <span className="job-postprocessing-widget__uploading">Uploading Photos</span>
                    <span className="job-postprocessing-widget__uploading-subtitle">For Post-Processing</span>
                  </div>
                ) : (
                  <div className="flex middle">
                    <div className="job-postprocessing-widget-spinner">
                      <div className="job-postprocessing-widget-spinner__circle"></div>
                    </div>
                    <span className="job-postprocessing-widget__uploading">Processing</span>
                  </div>
                )}
                {hold === false && (
                  <button className="button" type="button" onClick={handleCancel}>
                    Cancel
                  </button>
                )}
              </aside>

              {hold === false && queue.length ? (
                <footer>
                  <span className="job-postprocessing-widget__image-name">{queue[0].name}</span>
                  <div className="job-postprocessing-widget-progress-bar">
                    <span className="job-postprocessing-widget-progress-bar__bar">
                      <span className="job-postprocessing-widget-progress-bar__progress" style={{ width: `${Math.round((current / total) * 100)}%` }}></span>
                    </span>
                  </div>
                  <aside className="job-postprocessing-widget__progress-status">
                    <span>
                      {current} of {total}
                    </span>
                    {time.length >= 10 ? (
                      <span>{moment.duration(remaining).format('h [hr] m [min]', { minValue: 1 }).replace('<', 'less than')} remaining</span>
                    ) : (
                      <span>calculating time...</span>
                    )}
                  </aside>
                </footer>
              ) : null}
            </article>
          </div>
        </section>
      )}

      {/* Done panel */}
      {!queue.length && successful && !failed.length ? (
        <>
          {completed < successful && !failed.length ? (
            <section className="job-postprocessing-widget__panel animate">
              <div className="job-postprocessing-widget__status">
                <figure className="job-postprocessing-widget__figure job-postprocessing-widget__figure--gear"></figure>

                <article className="job-postprocessing-widget__progress">
                  <aside className="job-postprocessing-widget__info">
                    <div className="flex middle">
                      <div className="job-postprocessing-widget-spinner">
                        <div className="job-postprocessing-widget-spinner__circle job-postprocessing-widget-spinner__circle--purple"></div>
                      </div>
                      <span className="job-postprocessing-widget__uploading">Processing Photos</span>
                    </div>
                  </aside>

                  <footer>
                    <div className="job-postprocessing-widget-progress-bar">
                      <span className="job-postprocessing-widget-progress-bar__bar">
                        <span
                          className="job-postprocessing-widget-progress-bar__progress job-postprocessing-widget-progress-bar__progress--purple"
                          style={{ width: `${Math.round((completed / successful) * 100)}%` }}
                        ></span>
                      </span>
                    </div>
                  </footer>
                </article>
              </div>
            </section>
          ) : (
            <section className="job-postprocessing-widget__panel animate">
              <header className="flex between job-postprocessing-widget__header">
                <h5 className="job-postprocessing-widget__title job-postprocessing-widget__title--success">Upload Successful</h5>
                <button className="button" type="button" onClick={handleDone}>
                  Done
                </button>
              </header>
              <small className="job-postprocessing-widget__subtitle">
                Great news! Your {successful} photo(s) are uploaded and now in the magic-making process. If everything's good to go, they'll be shining in the
                gallery real soon. Thanks for your patience – we're almost there! 😊
              </small>
            </section>
          )}
        </>
      ) : null}
    </aside>
  );
};

export default PostProcessingWidget;
