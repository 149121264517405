import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const States = ({ selectedState: initialSelectedState, states, country, returnId, parentHandleChange, elementName, disabled }) => {
  const [selectedState, setSelectedState] = useState(initialSelectedState);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = states[country].map((s) => ({
      label: s.name,
      value: returnId ? s.id : s.abbreviation,
      selected: false
    }));
    setOptions(newOptions);
  }, [states, country, returnId]);

  useEffect(() => {
    setSelectedState(initialSelectedState);
  }, [initialSelectedState]);

  const handleOnChange = (val) => {
    setSelectedState('');

    if (val) {
      const newOptions = states[country].map((s) => ({
        label: s.name,
        value: returnId ? s.id : s.abbreviation,
        selected: val.label === s.name
      }));

      setOptions(newOptions);
      parentHandleChange({ ...val, selected: true });
    } else {
      const resetOptions = options.map((s) => ({ ...s, selected: false }));

      setOptions(resetOptions);
      parentHandleChange(val);
    }
  };

  const selectedOption = selectedState.length > 0 ? options.find((state) => state.value === selectedState) : '';

  return (
    <Select
      id={elementName}
      className="select"
      classNamePrefix="select"
      name={elementName}
      options={options}
      value={selectedOption}
      isDisabled={disabled}
      onChange={handleOnChange}
    />
  );
};

States.propTypes = {
  country: PropTypes.string.isRequired,
  parentHandleChange: PropTypes.func.isRequired,
  elementName: PropTypes.string,
  selectedState: PropTypes.string,
  states: PropTypes.object,
  returnId: PropTypes.bool
};

States.defaultProps = {
  elementName: 'statesHiddenInput',
  selectedState: '',
  states: {
    USA: []
  },
  returnId: false
};

export default States;
