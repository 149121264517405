import { connect } from 'react-redux';
import { Component } from 'react';

// Helpers
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import { getAllLabs, getStudioLabsInStudio, addStudioLabtoStudio } from '../../actions';

// Styles
import './../studio.css';

const mapStateToProps = (state) => {
  const {
    login: { entities: loginEntities, requesting: loginRequesting },
    users: { entities, requesting: usersRequesting }
  } = state;
  const requesting = loginRequesting || usersRequesting;
  const studio = Object.values(loginEntities.studio)[0] || {};
  const { id: studioId, lab_id: labId } = studio || {};

  return {
    studioId,
    labId,
    entities,
    requesting
  };
};

const mapDispatchToProps = {
  getAllLabs,
  getStudioLabsInStudio,
  addStudioLabtoStudio
};

class Labs extends Component {
  state = {
    isLabSelected: false,
    studioLabs: [],
    labLoading: ''
  };

  componentDidMount() {
    const { studioId } = this.props;

    this.props.getAllLabs();
    this.props.getStudioLabsInStudio(studioId, (res) => {
      const studioLabs = Object.values(res).map((lab) => lab.lab.id);
      this.setState({ studioLabs });
    });
  }

  handleToggleLab = (labId) => {
    const { studioId, addStudioLabtoStudio } = this.props;

    this.setState({ labLoading: labId }, () => {
      addStudioLabtoStudio({ studio_id: studioId, lab_id: labId }, (res) => {
        const labId = res.lab.id;
        this.setState({ studioLabs: [...this.state.studioLabs, labId], labLoading: '' });
      });
    });
  };

  render() {
    const { entities, requesting } = this.props;
    const allLabs = entities ? (entities.allLabs ? Object.values(entities.allLabs) : []) : [];
    const { studioLabs, labLoading } = this.state;

    return (
      <>
        <header className="flex middle between my-studio__header">
          <h2 className="text--nomargin">Add Labs</h2>
        </header>

        <section className="panel panel--section">
          <p>To use a lab's product catalog in PhotoDay, simply select the labs you wish to add to your account.</p>
          {allLabs.length > 0 && (
            <ul className="labs__container animate">
              {allLabs.map((lab) => {
                const added = studioLabs.indexOf(lab.id) >= 0;

                // TODO: Find a better way to disable labs from being added
                if (lab.key === 'bri' && !added) return null;

                return (
                  <li className="lab" key={lab.id}>
                    <figure className="lab__logo-container">
                      <img className="lab__logo" src={lab.logo_url} alt="studio logo" />
                    </figure>
                    <button
                      className={`button lab__button ${added ? 'lab__button--selected' : ''}`}
                      onClick={() => this.handleToggleLab(lab.id)}
                      disabled={labLoading === lab.id && requesting}
                      data-loading={labLoading === lab.id && requesting}
                    >
                      {added ? 'Added' : 'Add'}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}

          {requesting && !allLabs.length && (
            <>
              <br />
              <br />
              <GridLoader rows={2} columns={4} gap={20} />
            </>
          )}
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Labs);
