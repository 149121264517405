import { FC, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyAccountStudioRequest } from '../../../../Settings/actions';
import { createJobKnockoutReady, getJobKnockout, updateKnockoutDropzone } from '../../../actions';

// Plugins
import { Tooltip } from 'react-tippy';
import { SwiperRef, Swiper, SwiperSlide } from 'swiper/react';

// Components
import AddEdit from '../AddEdit';
import UploadDropzone from '../Upload/Dropzone';
import BuyWalletCredits from '../../../../Settings/Studio/Wallet/BuyWalletCredits';

// Helpers
import { getWalletCreditValue } from '@/utils/getWalletCreditValue';

// Images
import ReactImageCloud from '@/assets/images/icon-cloud-upload.svg?react';
import ReactImageGetStarted from '@/assets/images/icon-roadmap.svg?react';
import ReactImageSubmit from '@/assets/images/icon-circle-check.svg?react';
import ReactImageReview from '@/assets/images/icon-knockout-review.svg?react';

// Styles
import './style.css';
import 'swiper/css';

interface SettingsProps {
  postProcessingId: string;
  history: { push: (location: string) => void; goBack: () => void };
  match: {
    params: {
      jobId: string;
      id: string;
    };
  };
}

const CREDIT_VALUE_CENTS = 10;
const env = import.meta.env.VITE_ENVIRONMENT;

const Settings: FC<SettingsProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const swiperRef = useRef<SwiperRef>(null);

  const {
    params: { jobId, id: postProcessingId }
  } = match;

  const { studio: usersStudio } = useSelector((state: any) => state.users);
  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const {
    knockout,
    knockoutUpload: { queue, processing, requesting: knockoutRequesting }
  } = useSelector((state: any) => state.jobs);

  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [postProcessingCurrentStep, setPostProcessingCurrentStep] = useState<string | null>(null);

  const [showSubmitPostProcessingJobModal, setShowSubmitPostProcessingJobModal] = useState<boolean>(false);
  const [showBuyWalletCreditsModal, setShowBuyWalletCreditsModal] = useState<boolean>(false);
  const [studioMissingCredits, setStudioMissingCredits] = useState<number>();

  const [dryTestSubmit, setDryTestSubmit] = useState<boolean>(env !== 'production' ? true : false);

  const isUploadDisabled = queue.length > 0 || processing;
  const isReviewStepDisabled = !knockout?.knockout_items_count;
  const isSubmitStepDisabled = knockout.uploading || !knockout.reviewed;

  const handleAddEditModalToggle = () => setShowAddEditModal(!showAddEditModal);

  const handleStepModalClose = () => setPostProcessingCurrentStep(null);

  const handleSubmitPrevious = () => {
    if (swiperRef?.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSubmitNext = () => {
    if (swiperRef?.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleAgreeSubmit = () => {
    // Check if studio have enough credits
    const { knockout_items_count: postProcessingItemsCount, extraction: hasExtraction, color_correction: hasColorCorrection } = knockout;
    const { credit_cents: studioCreditsCents, knockout_cost_cents: knockoutItemCostCents, color_correction_cost_cents: colorCorrectionItemCostCents } = studio;

    const studioTotalCredits = getWalletCreditValue(studioCreditsCents);
    const knockoutCreditsNeeded = hasExtraction ? getWalletCreditValue(knockoutItemCostCents) * postProcessingItemsCount : 0;
    const colorCorrectionCreditsNeeded = hasColorCorrection ? getWalletCreditValue(colorCorrectionItemCostCents) * postProcessingItemsCount : 0;
    const totalCreditsNeeded = knockoutCreditsNeeded + colorCorrectionCreditsNeeded;

    if (studioTotalCredits < totalCreditsNeeded) {
      setShowBuyWalletCreditsModal(true);
      setStudioMissingCredits(totalCreditsNeeded);
    } else {
      dispatch(
        createJobKnockoutReady({ knockoutId: postProcessingId, test: dryTestSubmit }, () => {
          // Fetch studio to update sidebar available credits
          dispatch(getMyAccountStudioRequest({ studioId: studio.id }, history.push(`/jobs/${jobId}/services/postprocessing`)));
        })
      );
    }
  };

  useEffect(() => {
    if (postProcessingId && postProcessingId !== knockout.id) {
      dispatch(getJobKnockout({ knockoutId: postProcessingId }));
    }
  }, []);

  return (
    <>
      <section>
        <header className="flex middle between job-postprocessing__header">
          <aside className="flex middle">
            <Tooltip {...{ title: 'Back to list' }}>
              <button className="button button--clean button--noborder" onClick={() => history.push(`/jobs/${jobId}/services/postprocessing`)}>
                <i className="icon-back"></i>
              </button>
            </Tooltip>
            <h2 className="text--nomargin">{knockout?.name}</h2>
          </aside>
        </header>
        <ul className="job-postprocessing__steps-list">
          <li className="job-postprocessing__steps">
            <figure className="flex middle center job-postprocessing__steps-figure">
              <ReactImageGetStarted />
            </figure>
            <h5 className="job-postprocessing__steps-title job-postprocessing__steps-title--completed">Selected Service(s)</h5>
            <p className="job-postprocessing__steps-description">
              {knockout.extraction === true && (
                <>
                  Knockouts {knockout.remove_spill === true ? '(Green Spill Correction)' : ''}{' '}
                  <a href="https://support.photoday.io/en/articles/6967528-post-processing-services-in-photoday" target="_blank" rel="noopener noreferrer">
                    learn more
                  </a>
                </>
              )}
              <br />
              {knockout.color_correction === true && (
                <>
                  Color Correction{' '}
                  <a href="https://support.photoday.io/en/articles/6971582-color-correction-in-photoday" target="_blank" rel="noopener noreferrer">
                    learn more
                  </a>
                </>
              )}
            </p>
            <button className="button button--outline job-postprocessing__steps-button" onClick={handleAddEditModalToggle}>
              Edit
            </button>
          </li>

          <li className="job-postprocessing__steps">
            <figure className="flex middle center job-postprocessing__steps-figure">
              <ReactImageCloud />
            </figure>
            <h5 className={`job-postprocessing__steps-title ${knockout.knockout_items_count > 0 ? 'job-postprocessing__steps-title--completed' : ''}`}>
              Upload Photos
            </h5>
            <p className="job-postprocessing__steps-description">Upload photos you would like to have post-processed.</p>
            <button
              className="button button--lean job-postprocessing__steps-button"
              onClick={() => {
                setPostProcessingCurrentStep('upload');
                dispatch(updateKnockoutDropzone({ dismiss: false, failed: [] }));
              }}
              disabled={isUploadDisabled}
            >
              {queue.length || processing ? 'Uploading...' : knockout?.knockout_items_count ? 'Upload More' : 'Upload'}
            </button>
          </li>

          <li className={`job-postprocessing__steps ${isReviewStepDisabled ? 'job-postprocessing__steps--disabled' : ''}`}>
            <figure className="flex middle center job-postprocessing__steps-figure">
              <ReactImageReview />
            </figure>
            <h5 className={`job-postprocessing__steps-title ${knockout.reviewed ? 'job-postprocessing__steps-title--completed' : ''}`}>
              <b> Review Uploaded Photos </b>
            </h5>
            <p className="job-postprocessing__steps-description">Review photos uploaded for post-processing.</p>
            <Link
              className={`button button--outline job-postprocessing__steps-button ${
                knockout?.status !== 'review' && !knockout.knockout_items_count ? 'disabled' : ''
              }`}
              to={`/jobs/${jobId}/services/postprocessing/${postProcessingId}/review`}
            >
              Review
            </Link>
          </li>

          <li className={`job-postprocessing__steps ${isSubmitStepDisabled ? 'job-postprocessing__steps--disabled' : ''}`}>
            <figure className="flex middle center job-postprocessing__steps-figure">
              <ReactImageSubmit />
            </figure>
            <h5 className={`job-postprocessing__steps-title ${knockout.status === 'ready' ? 'job-postprocessing__steps-title--completed' : ''}`}>
              Submit Post-Processing Job
            </h5>
            <p className="job-postprocessing__steps-description">Let's get this party started!</p>
            <button
              className="button button--outline job-postprocessing__steps-button"
              data-loading={knockoutRequesting}
              disabled={knockout.loading || !knockout.reviewed || knockoutRequesting}
              onClick={() => setShowSubmitPostProcessingJobModal(true)}
            >
              Submit
            </button>
            {env !== 'production' && (
              <div className="flex middle center">
                <small className="text--bold text--xsmall">Dry Submit (test mode):&nbsp;</small>
                <input
                  id="dryTestSubmit"
                  className="hidden"
                  type="checkbox"
                  checked={dryTestSubmit === true}
                  onChange={() => setDryTestSubmit(!dryTestSubmit)}
                />
                <label className="label-switch label-switch--small" htmlFor="dryTestSubmit" />
              </div>
            )}
          </li>
        </ul>
      </section>

      {/* Selected Services */}
      {showAddEditModal && <AddEdit postProcessingId={postProcessingId} postProcessingItem={knockout} onClose={handleAddEditModalToggle} />}

      {/* Upload */}
      {postProcessingCurrentStep === 'upload' && <UploadDropzone match={match} onClose={handleStepModalClose} />}

      {/* Submit Post-Processing Job */}
      {showSubmitPostProcessingJobModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--secondary modal__box--xlarge">
            <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowSubmitPostProcessingJobModal(false)}>
              <i className="icon-close"></i>
            </button>
            <main className="flex modal__content">
              <aside className="flex-4 flex-12-md modal__content-section">
                <h3>Submit Post-Processing Job</h3>
                {/* Knockout and Color Correction */}
                {knockout.extraction && knockout.color_correction && (
                  <>
                    <p>
                      Ready to have PhotoDay remove your backgrounds and color correct your photos? Through the power of AI, Knockouts and Color Correction jobs
                      take only minutes to process.
                    </p>
                    <p>You will receive an email once the knockouts and color corrections are ready.</p>
                  </>
                )}

                {/* Only knockout */}
                {knockout.extraction && knockout.color_correction === false && (
                  <>
                    <p>Ready to have PhotoDay remove your backgrounds? Through the power of AI, your Knockouts job will take only minutes to process.</p>
                    <p>You will receive an email once the knockouts are ready.</p>
                  </>
                )}

                {/* Only color correction */}
                {knockout.color_correction && knockout.extraction === false && (
                  <>
                    <p>
                      Ready to have PhotoDay color correct your photos? Through the power of AI, your Color Correction job will take only minutes to process.
                    </p>
                    <p>You will receive an email once the color corrections are ready.</p>
                  </>
                )}

                {/* Post-Processing credits */}
                <p>Credits Needed:</p>
                <ul className="panel panel--lean panel--dark">
                  {knockout.extraction === true && (
                    <li>
                      <p>Knockouts = {studio.knockout_cost_cents / CREDIT_VALUE_CENTS} per photo</p>
                    </li>
                  )}
                  {knockout.color_correction === true && (
                    <li>
                      <p>Color Correction = {studio.color_correction_cost_cents / CREDIT_VALUE_CENTS} per photo </p>
                    </li>
                  )}
                  <li>
                    {/* Knockouts and Color Correction */}
                    {knockout.extraction && knockout.color_correction ? (
                      <strong>
                        {knockout.knockout_items_count} Images x{' '}
                        {((studio.knockout_cost_cents + studio.color_correction_cost_cents) * CREDIT_VALUE_CENTS) / 100} ={' '}
                        {(knockout.knockout_items_count * (studio.knockout_cost_cents + studio.color_correction_cost_cents)) / CREDIT_VALUE_CENTS} Total
                      </strong>
                    ) : (
                      <>
                        {/* Knockouts */}
                        {knockout.extraction && (
                          <strong>
                            {knockout.knockout_items_count} Images x {(studio.knockout_cost_cents * CREDIT_VALUE_CENTS) / 100} ={' '}
                            {(knockout.knockout_items_count * studio.knockout_cost_cents) / CREDIT_VALUE_CENTS} Total
                          </strong>
                        )}
                        {/* Color Correction */}
                        {knockout.color_correction && (
                          <strong>
                            {knockout.knockout_items_count} Images x {(studio.color_correction_cost_cents * CREDIT_VALUE_CENTS) / 100} ={' '}
                            {(knockout.knockout_items_count * studio.color_correction_cost_cents) / CREDIT_VALUE_CENTS} Total
                          </strong>
                        )}
                      </>
                    )}
                  </li>
                </ul>
              </aside>
              <section className="flex-8 flex-12-md column between modal__content-section">
                <Swiper ref={swiperRef}>
                  {knockout.extraction === true && (
                    <SwiperSlide>
                      {/* Knockout */}
                      <h3>Knockout Items</h3>
                      <p>Before submitting, please read and agree to the following terms.</p>
                      <ul className="list--bullet">
                        <li>
                          <p>
                            I have read and understand the{' '}
                            <a href="https://support.photoday.io/en/articles/5506893-best-practices-for-knockouts" target="_blank" rel="noopener noreferrer">
                              Best Practices for Knockouts
                            </a>{' '}
                            article.
                          </p>
                        </li>
                        <li>
                          <p>
                            I understand that additional editing services may be required if uploaded images are blurry, underexposed, overexposed, contain a
                            color spill from the background that affects skin tones, and/or have props that could possibly interfere with the subject.
                          </p>
                        </li>
                        <li>
                          <p>I am uploading files under 27 million pixels (length x width) with a longest side of 6400 pixels.</p>
                        </li>
                      </ul>
                      <footer className="flex end mt-40">
                        <button
                          className="button button--large"
                          name="agree"
                          type="button"
                          data-loading={knockoutRequesting}
                          disabled={knockoutRequesting}
                          onClick={knockout.color_correction === true ? handleSubmitNext : handleAgreeSubmit}
                        >
                          {knockout.color_correction === true ? 'Agree' : 'Agree and Submit'}
                        </button>
                      </footer>
                    </SwiperSlide>
                  )}
                  {knockout.color_correction === true && (
                    <SwiperSlide>
                      {/* Color Correction */}
                      <h3>Color Correction Items</h3>
                      <p>Before submitting, please read and agree to the following terms.</p>
                      <ul className="list--bullet">
                        <li>
                          <p>
                            I understand that additional editing services may be required if uploaded images are blurry, underexposed, overexposed, contain a
                            color spill from the background, or contain mixed lighting that affects skin tones.{' '}
                            <a href="https://support.photoday.io/en/articles/6971582-color-correction-in-photoday" target="_blank" rel="noopener noreferrer">
                              Learn more
                            </a>
                            .
                          </p>
                        </li>
                        <li>
                          <p>I am uploading files under 27 million pixels (length x width) with a longest side of 6400 pixels.</p>
                        </li>
                      </ul>
                      <footer className={`flex ${knockout.extraction === true ? 'between' : 'end'} mt-40`}>
                        {knockout.extraction === true && (
                          <button
                            className="button button--lean button--large"
                            name="previous"
                            type="button"
                            disabled={knockoutRequesting}
                            onClick={handleSubmitPrevious}
                          >
                            Previous
                          </button>
                        )}
                        <button
                          className="button button--large"
                          name="agree"
                          type="button"
                          data-loading={knockoutRequesting}
                          disabled={knockoutRequesting}
                          onClick={handleAgreeSubmit}
                        >
                          Agree and Submit
                        </button>
                      </footer>
                    </SwiperSlide>
                  )}
                </Swiper>
              </section>
            </main>
          </div>
        </aside>
      )}

      {/* Buy Wallet Credits Modal */}
      {showBuyWalletCreditsModal && (
        <BuyWalletCredits
          studio={studio}
          missingCredits={studioMissingCredits}
          onBuyWalletCreditsCreateModalClose={() => setShowBuyWalletCreditsModal(false)}
        />
      )}
    </>
  );
};

export default Settings;
