import { Component } from 'react';
import { connect } from 'react-redux';

// Components
import CreateOrEditOfferModal from '../Offers/CreateOrEditOfferModal';
import CreateOrEditGiftCardModal from './CreateOrEditGiftCardModal';

// Helpers
import { getCreditSheetId } from './selectors';
import { updateCreditSheetRequest } from './actions';
import { getMyAccountStudioRequest } from '../../Settings/actions';
import { getPriceSheetId } from '../PriceSheets/selectors';
import { formatCurrency } from '@/utils/displayFormats';
import { giftCardListRequest, updateGiftCardListRequest, deleteGiftCardListRequest, createGiftCardListRequest, getCreditSheetDetailRequest } from './actions';

// Images
import logo_photoday from '@/assets/images/logo_black@2x.png';

// Styles
import './advancepay-detail.css';

const mapStateToProps = (state, ownProps) => {
  const priceSheetId = getPriceSheetId(ownProps);
  const creditSheetId = getCreditSheetId(ownProps);

  const {
    pricesheets: {
      requesting: priceSheetRequesting,
      entities: { pricesheet }
    },
    login: {
      studio: { id: studioId, lab_id: labId, lab_name: labName }
    },
    users: {
      studio: { logo_url: studioLogo }
    }
  } = state;

  const {
    advancepay: {
      entities: { gift_cards: giftCards },
      creditSheetDetail,
      requesting: advancePayRequesting
    }
  } = state;

  const requesting = advancePayRequesting || priceSheetRequesting;

  return {
    studioLogo,
    studioId,
    pricesheet,
    priceSheetId,
    creditSheetId,
    requesting,
    giftCards,
    creditSheetDetail,
    lab: { id: labId, name: labName }
  };
};

const mapDispatchToProps = {
  giftCardListRequest,
  updateCreditSheetRequest,
  updateGiftCardListRequest,
  deleteGiftCardListRequest,
  createGiftCardListRequest,
  getCreditSheetDetailRequest,
  getMyAccountStudioRequest
};

class AdvancePayDetail extends Component {
  state = {
    activeOffer: null,
    activeGiftCard: null,
    showGiftCardCreateOrEditModal: false,
    showOfferCreateOrEditModal: false,
    creatingGiftCard: false
  };

  componentDidMount() {
    const { studioId, getMyAccountStudioRequest } = this.props;

    this.fetchCreditSheetDetail();
    this.fetchGiftCards();

    studioId && getMyAccountStudioRequest({ studioId: studioId });
  }

  fetchGiftCards = () => {
    const { giftCardListRequest, creditSheetId } = this.props;
    giftCardListRequest(creditSheetId);
  };

  fetchCreditSheetDetail = () => {
    const { getCreditSheetDetailRequest, creditSheetId } = this.props;
    getCreditSheetDetailRequest(creditSheetId);
  };

  handleOfferGridSettingsAction = (offer) => {
    this.setState({
      activeOffer: Object.assign({}, offer),
      showOfferCreateOrEditModal: true
    });
  };

  handleGiftCardModal = (giftCard) => {
    this.setState({
      activeGiftCard: giftCard ? Object.assign({}, giftCard) : null,
      showGiftCardCreateOrEditModal: true
    });
  };

  handleOfferCreateOrEditDialogSave = (creditSheet) => {
    const { studioId } = this.props;
    const { updateCreditSheetRequest } = this.props;

    updateCreditSheetRequest({ studioId, creditSheet }, () => {
      this.fetchCreditSheetDetail();
      this.handleOfferDialogsCancel();
    });
  };

  handleGiftCardCreateOrEditDialogSave = (giftCard, amount, description) => {
    const { updateGiftCardListRequest, createGiftCardListRequest, creditSheetId } = this.props;
    const { creatingGiftCard } = this.state;

    amount = parseInt(amount * 100);

    if (giftCard) {
      updateGiftCardListRequest({ giftCardId: giftCard.id, amount, description }, () => {
        this.handleGiftCardDialogsCancel();
      });
    } else {
      if (!creatingGiftCard) {
        this.setState({ creatingGiftCard: true }, () => {
          createGiftCardListRequest({ creditSheetId, amount, description }, () => {
            this.handleGiftCardDialogsCancel();
          });
        });
      }
    }
  };

  handleOfferDialogsCancel = () => {
    this.setState({
      activeOffer: null,
      showOfferCreateOrEditModal: false
    });
  };

  handleGiftCardDialogsCancel = () => {
    this.setState({
      activeGiftCard: null,
      showGiftCardCreateOrEditModal: false,
      creatingGiftCard: false
    });
  };

  handleDeleteGiftCard = (giftCardId) => {
    const { deleteGiftCardListRequest } = this.props;
    deleteGiftCardListRequest(giftCardId, () => {
      this.handleGiftCardDialogsCancel();
    });
  };

  render() {
    const { showOfferCreateOrEditModal, showGiftCardCreateOrEditModal, activeGiftCard } = this.state;
    const { giftCards, creditSheetDetail, studioLogo, requesting } = this.props;

    const valuesGiftCards = giftCards.map((giftcard) => giftcard.amount_cents);

    if (!creditSheetDetail) {
      return null;
    }

    return (
      <div id="advancepay-container" className="box">
        {showOfferCreateOrEditModal && (
          <CreateOrEditOfferModal
            offer={creditSheetDetail}
            offerType="credit_sheets"
            pricesheetItems={[]}
            pricesheets={[]}
            onSave={this.handleOfferCreateOrEditDialogSave}
            onCancel={this.handleOfferDialogsCancel}
          />
        )}
        {showGiftCardCreateOrEditModal && (
          <CreateOrEditGiftCardModal
            giftCard={activeGiftCard}
            valuesGiftCards={valuesGiftCards}
            onSave={this.handleGiftCardCreateOrEditDialogSave}
            onCancel={this.handleGiftCardDialogsCancel}
            onDelete={this.handleDeleteGiftCard}
            requesting={requesting}
          />
        )}
        <div className="body-second-header">
          <div className="flex middle gap-10">
            <h3 className="text--nomargin">{creditSheetDetail.name}</h3>
            <button className="button" aria-hidden="true" data-toggle="modal" onClick={this.handleOfferGridSettingsAction}>
              Edit
            </button>
          </div>
        </div>

        <div className="add-credits">
          <div className="step-container">
            <h3 className="text--bold"> Add Credits </h3>
          </div>
          <div className="credits-container">
            <div className="credit-item" onClick={() => this.handleGiftCardModal(giftCards[0])}>
              <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
              <span className="credit-item__label text--bold">PhotoDay AdvancePay Credit</span>
              <div className="credit-item__accent">
                <p className="credit-amount text--bold">{giftCards[0] ? formatCurrency(giftCards[0].amount_cents) : 'Add Credit'}</p>
              </div>
            </div>
            <div className="credit-item" onClick={() => this.handleGiftCardModal(giftCards[1])}>
              <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
              <span className="credit-item__label text--bold">PhotoDay AdvancePay Credit</span>
              <div className="credit-item__accent">
                <p className="credit-amount text--bold">{giftCards[1] ? formatCurrency(giftCards[1].amount_cents) : 'Add Credit'}</p>
              </div>
            </div>
            <div className="credit-item" onClick={() => this.handleGiftCardModal(giftCards[2])}>
              <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
              <span className="credit-item__label text--bold">PhotoDay AdvancePay Credit</span>
              <div className="credit-item__accent">
                <p className="credit-amount text--bold">{giftCards[2] ? formatCurrency(giftCards[2].amount_cents) : 'Add Credit'}</p>
              </div>
            </div>
            <div className="credit-item" onClick={() => this.handleGiftCardModal(giftCards[3])}>
              <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
              <span className="credit-item__label text--bold">PhotoDay AdvancePay Credit</span>
              <div className="credit-item__accent">
                <p className="credit-amount text--bold">{giftCards[3] ? formatCurrency(giftCards[3].amount_cents) : 'Add Credit'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancePayDetail);
