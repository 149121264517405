const FORM_STACK_API_KEY = import.meta.env.VITE_FORM_STACK_API_KEY;

export const postOrderResolutionForm = (payload) => {
  let formData = new FormData();

  for (let [key, value] of Object.entries(payload)) {
    formData.append(key, value);
  }

  async function send() {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    try {
      const response = await fetch(`https://www.formstack.com/api/v2/form/4841153/submission.json?oauth_token=${FORM_STACK_API_KEY}`, {
        method: 'POST',
        headers,
        mode: 'no-cors',
        body: formData
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return send();
};
