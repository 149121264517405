import { Component } from 'react';
import PropTypes from 'prop-types';
import { NestedField } from '@/components/Shared/Forms';
import ProductSelector from './ProductSelector';

class GiveawayOptions extends Component {
  static propTypes = {
    productCategories: PropTypes.array
  };

  render() {
    return (
      <div className="form-segment">
        <h4 className="text--bold"> Customer gets a free... </h4>
        <NestedField name="free_products_attributes" component={ProductSelector} productCategories={this.props.productCategories} required={true} />
      </div>
    );
  }
}

export default GiveawayOptions;
