import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';
import Pagination from '@/components/Shared/Pagination';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import moment from 'moment-timezone';
import { Tooltip } from 'react-tippy';
import Dropdown from '@/components/Shared/Dropdown';

// Redux
import {
  getJob,
  updateJob,
  getInsightsCustomers,
  getInsightsCustomersSummary,
  getInsightsAccessedSubjectsSummary,
  getInsightsCustomersCsv
} from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import validator from '@/utils/validator';
import { useJobTimeZone } from '@/hooks';
import { normalizePhone } from '@/utils/formatters';
import { pickerDate, shortDate } from '@/utils/displayFormats';

// Styles
import '../style.css';

const YEARBOOK_INSIGHTS_ENABLED = import.meta.env.VITE_YEARBOOK_INSIGHTS_ENABLED === '1';

const Customers = ({ match, history }) => {
  const estimatedRef = useRef();

  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;
  const { job, insights, requesting } = useSelector((state) => state.jobs);
  const {
    customersSummary,
    accessedSubjectsSummary,
    customers: { list, pagination },
    requesting: insightsRequesting
  } = insights;

  const [search, setSearch] = useState('');
  const [galleryType, setGalleryType] = useState('');
  const [estimatedSubjects, setEstimatedSubjects] = useState('');
  const [tableFilter, setTableFilter] = useState({
    id: jobId,
    order: 'last_seen',
    dir: 'DESC',
    per_page: Number(pagination.perPage) || 100,
    search: '',
    page: pagination.page
  });

  const jobTimezone = useJobTimeZone();

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSearch = (e) => {
    e.preventDefault();
    setTableFilter({ ...tableFilter, search });
    dispatch(getInsightsCustomers({ ...tableFilter, search }));
  };

  const handleSearchClear = (e) => {
    e.stopPropagation();
    setSearch('');
    setTableFilter({ ...tableFilter, search: '' });
    dispatch(getInsightsCustomers({ id: jobId }));
  };

  const handleOrderBy = (order, dir) => {
    setTableFilter({ ...tableFilter, order, dir, page: 1 });
  };

  const handlePagination = (page) => {
    dispatch(getInsightsCustomers({ ...tableFilter, page, search }));
  };

  const handleDownloadCSV = () => {
    dispatch(getInsightsCustomersCsv({ id: jobId, name: job.name, date: pickerDate(new Date()) }));
  };

  const handleInputChange = (e, validation) => {
    const input = e.target;
    const valid = validator({ ...validation, value: input.value });

    input.classList[`${valid ? 'remove' : 'add'}`]('input--invalid');
    setEstimatedSubjects(valid ? input.value : estimatedSubjects);
  };

  const formatJobTimeZone = (time) => {
    if (time && jobTimezone) {
      const newTime = `${moment(time).tz(jobTimezone).format('L [@]h:mm A')} ${moment.tz(jobTimezone).zoneAbbr()}`;

      return newTime;
    }

    return 'N/A';
  };

  useEffect(() => {
    const estimatedElement = estimatedRef.current;

    let timer;

    if (estimatedElement) {
      timer = window.setTimeout(() => estimatedElement && estimatedElement.focus(), 1000);
    }

    dispatch(getInsightsCustomersSummary({ id: jobId }));
    dispatch(getInsightsAccessedSubjectsSummary({ id: jobId }));
    dispatch(
      getJob({ id: jobId }, ({ data }) => {
        setGalleryType(data.access_mode === 'access_per_subject' ? 'private' : 'public');
        setEstimatedSubjects(data.estimated_subjects_count);
      })
    );
    dispatch(getInsightsCustomers({ id: jobId, page: pagination.page, per_page: Number(pagination.perPage) || 100, order: 'last_seen', dir: 'DESC' }));

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    let timer;

    if (Number(estimatedSubjects) !== job.estimated_subjects_count && Number(estimatedSubjects) > 0) {
      timer = window.setTimeout(() => {
        dispatch(updateJob({ id: jobId, estimated_subjects_count: estimatedSubjects }));
        timer = null;
      }, 2000);
    }

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [estimatedSubjects]);

  useEffect(() => {
    dispatch(getInsightsCustomers(tableFilter));
  }, [tableFilter]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Insights" />

      <main className="box flex">
        <Sidebar jobId={jobId} title="Customers" />

        <section className="flex-9 flex-12-md job-insights-report">
          <header className="flex middle between job-insights-report__header">
            <h2 className="text--nomargin">Customers</h2>
            {list.length > 0 && (
              <button className="button button--medium" onClick={handleDownloadCSV} disabled={requesting}>
                Download CSV
              </button>
            )}
          </header>

          {/* Summary */}
          <div className="panel panel--section">
            <h4 className="text--tall">Summary</h4>

            {/* Engagement */}
            <div className="insights-summary">
              <div className="insights-summary__numbers insights-summary__numbers--bordered">
                <small>
                  <strong># Being Photographed</strong>
                </small>
                {insightsRequesting ? (
                  <TextLoader />
                ) : (
                  <input
                    className="insights-summary__edit"
                    ref={estimatedRef}
                    type="number"
                    name="estimated_subjects_count"
                    value={estimatedSubjects || '0'}
                    onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 5 })}
                    min="1"
                    max="10000"
                  />
                )}
              </div>

              <div className="insights-summary-group insights-summary-group--bordered">
                <h5 className="insights-summary-group__title">Engagement</h5>

                <div className="insights-summary-group__items">
                  <div className="insights-summary__numbers">
                    <small>
                      Opt Ins
                      <Tooltip title="Number of subjects who texted the access code." position="top" trigger="mouseenter">
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </small>
                    {insightsRequesting ? <TextLoader /> : <h3>{customersSummary.opt_ins}</h3>}
                  </div>

                  <div className="insights-summary__numbers">
                    <small>
                      % Opted In
                      <Tooltip
                        title="The percentage value is calculated by the number opt-ins divided by the number being photographed."
                        position="top"
                        trigger="mouseenter"
                      >
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </small>

                    {insightsRequesting ? (
                      <TextLoader />
                    ) : (
                      <h3>
                        {customersSummary.opt_ins > 0 && estimatedSubjects > 0
                          ? `${parseFloat((Number(customersSummary.opt_ins / estimatedSubjects) * 100.0).toFixed(2))}%`
                          : '0.0%'}
                      </h3>
                    )}
                  </div>

                  <div className="insights-summary__numbers">
                    <small>
                      Opt Outs
                      <Tooltip title="Number of subjects who texted STOP" position="top" trigger="mouseenter">
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </small>
                    {insightsRequesting ? <TextLoader /> : <h3>{customersSummary.opt_outs}</h3>}
                  </div>

                  <div className="insights-summary__numbers">
                    <small>
                      Placed Order
                      <Tooltip title="Number of subjects who texted the access code and placed an order." position="top" trigger="mouseenter">
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </small>
                    {insightsRequesting ? <TextLoader /> : <h3>{customersSummary.opt_ins_ordered}</h3>}
                  </div>
                  <div className="insights-summary__numbers insights-summary__numbers--right">
                    <small>
                      Not Ordered
                      <Tooltip title="Number of subjects who texted the access code and did not place order." position="top" trigger="mouseenter">
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </small>
                    {insightsRequesting ? <TextLoader /> : <h3>{customersSummary.not_ordered}</h3>}
                  </div>
                </div>
              </div>
            </div>

            {galleryType === 'private' && (
              <>
                {/* Engagement */}
                <div className="insights-summary">
                  <div className="insights-summary__numbers insights-summary__numbers--bordered">
                    <small>
                      <strong># Matched Subjects</strong>
                    </small>
                    {insightsRequesting ? (
                      <TextLoader />
                    ) : (
                      <input
                        className="insights-summary__edit"
                        ref={estimatedRef}
                        type="number"
                        name="estimated_subjects_count"
                        value={accessedSubjectsSummary.total_matched_subjects || ''}
                        onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 5 })}
                        min="1"
                        max="10000"
                      />
                    )}
                  </div>

                  <div className="insights-summary-group insights-summary-group--bordered">
                    <h5 className="insights-summary-group__title">Engagement</h5>

                    <div className="insights-summary-group__items">
                      <div className="insights-summary__numbers">
                        <small>
                          Accessed
                          <Tooltip
                            title="Unique access codes that have been accessed.<br/><small><i>Multiple access of the same access code count as 1 (qty)</i></small>"
                            position="top"
                            trigger="mouseenter"
                          >
                            <i className="fa fa-question-circle-o" />
                          </Tooltip>
                        </small>
                        {insightsRequesting ? <TextLoader /> : <h3>{accessedSubjectsSummary.total_subjects_accessed}</h3>}
                      </div>

                      <div className="insights-summary__numbers">
                        <small>
                          % Accessed
                          <Tooltip title="Total Subjects Accessed / Matched Subjects" position="top" trigger="mouseenter">
                            <i className="fa fa-question-circle-o" />
                          </Tooltip>
                        </small>
                        {insightsRequesting ? (
                          <TextLoader />
                        ) : (
                          <h3>
                            {accessedSubjectsSummary.total_matched_subjects > 0
                              ? `${parseFloat(
                                  (Number(accessedSubjectsSummary.total_subjects_accessed / accessedSubjectsSummary.total_matched_subjects) * 100.0).toFixed(2)
                                )}%`
                              : '0.0%'}
                          </h3>
                        )}
                      </div>

                      <div className="insights-summary__numbers">
                        <small>
                          Subjects Ordered
                          <Tooltip title="Number of unique subjects included on an order." position="top" trigger="mouseenter">
                            <i className="fa fa-question-circle-o" />
                          </Tooltip>
                        </small>
                        {insightsRequesting ? <TextLoader /> : <h3>{accessedSubjectsSummary.total_subjects_ordered}</h3>}
                      </div>

                      <div className="insights-summary__numbers insights-summary__numbers--right">
                        <small>
                          % Ordered
                          <Tooltip title="Total Subjects Ordered / Matched Subjects" position="top" trigger="mouseenter">
                            <i className="fa fa-question-circle-o" />
                          </Tooltip>
                        </small>
                        {insightsRequesting ? (
                          <TextLoader />
                        ) : (
                          <h3>
                            {accessedSubjectsSummary.total_matched_subjects > 0
                              ? `${parseFloat(
                                  (Number(accessedSubjectsSummary.total_subjects_ordered / accessedSubjectsSummary.total_matched_subjects) * 100.0).toFixed(2)
                                )}%`
                              : '0.0%'}
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Yearbook Selection */}
                {YEARBOOK_INSIGHTS_ENABLED && (
                  <div className="insights-summary">
                    <div className="insights-summary__numbers insights-summary__numbers--bordered">
                      <small>
                        <strong>Deadline</strong>
                      </small>
                      {insightsRequesting ? <TextLoader /> : <h3>{shortDate(new Date())}</h3>}
                    </div>

                    <div className="insights-summary-group insights-summary-group--bordered">
                      <h5 className="insights-summary-group__title">Yearbook Selection</h5>

                      <div className="insights-summary-group__items">
                        <div className="insights-summary__numbers">
                          <small>
                            Selected
                            <Tooltip title="XXXXXXXXX" position="top" trigger="mouseenter">
                              <i className="fa fa-question-circle-o" />
                            </Tooltip>
                          </small>
                          {insightsRequesting ? <TextLoader /> : <h3>00</h3>}
                        </div>

                        <div className="insights-summary__numbers">
                          <small>
                            % Selected
                            <Tooltip title="XXXXXXXXXXXXXX" position="top" trigger="mouseenter">
                              <i className="fa fa-question-circle-o" />
                            </Tooltip>
                          </small>
                          {insightsRequesting ? <TextLoader /> : <h3>0.0%</h3>}
                        </div>

                        <div className="insights-summary__numbers">
                          <small>
                            Ordered & Not Selected
                            <Tooltip title="XXXXXXXXXXXx" position="top" trigger="mouseenter">
                              <i className="fa fa-question-circle-o" />
                            </Tooltip>
                          </small>
                          {insightsRequesting ? <TextLoader /> : <h3>00</h3>}
                        </div>

                        <div className="insights-summary__numbers insights-summary__numbers--right">
                          <small>
                            Selected & Not Ordered
                            <Tooltip title="XXXXXXXXXXXXXXXXXXxx" position="top" trigger="mouseenter">
                              <i className="fa fa-question-circle-o" />
                            </Tooltip>
                          </small>
                          {insightsRequesting ? <TextLoader /> : <h3>00</h3>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Unique Customers */}
            <div className="insights-summary insights-summary--orders">
              <div className="insights-summary__numbers insights-summary__numbers--bordered">
                <small>
                  <strong>Unique Customers</strong>
                  <Tooltip title="Number of unique customers who placed one or more orders." position="top" trigger="mouseenter">
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </small>
                {insightsRequesting ? <TextLoader /> : <h3>{customersSummary.total_ordered}</h3>}
              </div>
            </div>
          </div>

          {/* Details */}
          <div className="panel panel--section">
            <div className="flex between">
              <h4 className="text--bold">Details</h4>

              <form onSubmit={handleSearch}>
                <fieldset>
                  <input type="search" name="search" placeholder="Search Customers" maxLength="50" value={search} onChange={handleSearchChange} />
                  <button
                    className={`button button--clear button--small ${tableFilter.search || (list.length === 0 && search.length) ? '' : 'hidden'}`}
                    name="button"
                    type="button"
                    onClick={handleSearchClear}
                  >
                    Clear
                  </button>
                  <button className="button button--icon" name="button" type="submit">
                    <i className="icon-search"></i>
                  </button>
                </fieldset>
              </form>
            </div>

            <article className="table-box">
              <table className="table">
                <thead className="table__header">
                  <tr>
                    <th>
                      <Dropdown
                        buttonName={'Last Seen'}
                        buttonExtraClass={'button--filter text--nowrap'}
                        buttonTooltip={'The last time a logged-in customer has viewed this gallery.'}
                      >
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_seen' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_seen', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_seen' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_seen', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      <Dropdown buttonName={'Opt-in Date'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'opt_in_date' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('opt_in_date', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'opt_in_date' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('opt_in_date', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>Phone Number</th>
                    <th>
                      <Dropdown buttonName={'Name'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_name', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_name', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>Email</th>
                    <th>
                      <Dropdown
                        buttonName={'Last Reminder'}
                        buttonExtraClass={'button--filter text--nowrap'}
                        buttonTooltip={`The last time an SMS text reminder was sent.`}
                      >
                        <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_reminder' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_reminder', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'last_reminder' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('last_reminder', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                    <th>
                      <Dropdown buttonName={'Ordered'} buttonExtraClass={'button--filter'}>
                        <ul className="panel panel-dropdown panel-dropdown--small">
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'ordered' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('ordered', 'ASC');
                            }}
                          >
                            Ascending
                          </li>
                          <li
                            className={`panel-dropdown__item ${
                              tableFilter.order === 'ordered' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                            }`}
                            onClick={() => {
                              handleOrderBy('ordered', 'DESC');
                            }}
                          >
                            Descending
                          </li>
                        </ul>
                      </Dropdown>
                    </th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {insightsRequesting ? (
                    <tr>
                      <td colSpan="7">
                        <TableLoader rows={2} rowHeight={60} />
                      </td>
                    </tr>
                  ) : list.length ? (
                    <>
                      {list.map((customer) => {
                        return (
                          <tr key={customer.id}>
                            <td data-header="Last Seen">{customer.last_seen && shortDate(customer.last_seen)}</td>
                            <td data-header="Opt-In Date">{customer.opt_in_date && shortDate(customer.opt_in_date)}</td>
                            <td data-header="Phone Number">{normalizePhone(customer.phone)}</td>
                            <td data-header="Name">{customer.name}</td>
                            <td data-header="Email">{customer.email}</td>
                            <td data-header="Last Reminder">{formatJobTimeZone(customer.last_reminder_date)}</td>
                            <td data-header="Ordered">{customer.ordered ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="7">No items were found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </article>
            {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
          </div>
        </section>
      </main>
    </>
  );
};

Customers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

export default Customers;
