import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../../../actions';
import { getBackgroundsCollection, getBackgroundsCollectionList, getBackground, resetBackground } from '../../../../../Storefront/Backgrounds/actions';

// Components
import Sidebar from '../../../Sidebar';
import Header from '../../../../Header';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Images
import imageBackgrounds from '@/assets/images/icon-backgrounds.png';

// Styles
import '../../style.css';

const Backgrounds = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId },
    path
  } = match;

  const { job } = useSelector((state) => state.jobs);
  const { studio } = useSelector((state) => state.login);
  const { collections, requesting: backgroundsRequesting, background: currentBackground } = useSelector((state) => state.backgrounds);

  const backgroundCollections = collections.filter((collection) => collection.first_background && collection.background_count > 0);

  const [sendBack, setSendBack] = useState(false);

  const [backgroundCollectionsShow, setBackgroundCollectionsShow] = useState(false);
  const [defaultBackgroundSelected, setDefaultBackgroundSelected] = useState('');

  const [initialInformation, setInitialInformation] = useState('');
  const [information, setInformation] = useState({
    background_collections: [],
    featured_background: {},
    featured_background_id: ''
  });

  const [selectedBackgroundCollection, setSelectedBackgroundCollection] = useState('');
  const [selectedBackgroundCollectionBackgrounds, setSelectedBackgroundCollectionBackgrounds] = useState([]);

  const handleSave = (e) => {
    e.preventDefault();

    const values = {
      background_collection_ids: information.background_collections,
      featured_background_id: information.featured_background_id
    };

    setInitialInformation(JSON.stringify(information));

    dispatch(updateJob({ ...values, id: jobId }, () => sendBack && history.push(`/jobs/${jobId}/dashboard`)));
  };

  const handleBackgroundCollectionSelect = (collection) => {
    const collectionIsSelected = information.background_collections.indexOf(collection);

    let currentBackgroundCollections = information.background_collections;

    if (collectionIsSelected !== -1) {
      currentBackgroundCollections.splice(collectionIsSelected, 1);
    }

    const newBackgroundCollectionValues =
      collectionIsSelected === -1 ? information.background_collections.concat(collection).sort() : currentBackgroundCollections.sort();

    let featuredBackground = null;

    if (newBackgroundCollectionValues.length > 0) {
      if (information.featured_background && information.featured_background.background_collection_id) {
        const featuredMatchesCollection = newBackgroundCollectionValues.some(
          (collection) => collection === information.featured_background.background_collection_id
        );

        if (featuredMatchesCollection) {
          featuredBackground = information.featured_background;
        }
      }

      if (!featuredBackground || !information.featured_background.background_collection_id) {
        const foundBackground = backgroundCollections.find((bg) => bg.id === newBackgroundCollectionValues[0]);

        if (foundBackground) {
          featuredBackground = foundBackground.first_background;
        }
      }
    }

    const featuredBackgroundId = newBackgroundCollectionValues.length > 0 && featuredBackground ? featuredBackground.id : '';

    setInformation({
      featured_background: featuredBackground,
      featured_background_id: featuredBackgroundId,
      background_collections: newBackgroundCollectionValues
    });
  };

  const handleBackgroundFeaturedSelect = (backgroundId) => setDefaultBackgroundSelected(backgroundId);

  const handleBackgroundsRefresh = () => {
    dispatch(getBackgroundsCollectionList({ order: 'created_at', dir: 'DESC', per_page: 1000, page: 1 }));
  };

  const handleSelectAllBackgroundCollections = () => {
    const allBackgroundCollectionsAdded = backgroundCollections.every((collection) => information.background_collections.includes(collection.id));

    if (allBackgroundCollectionsAdded) {
      setInformation({ ...information, background_collections: [] });
    } else {
      const addIds = backgroundCollections.filter((collection) => !information.background_collections.includes(collection.id)).map((theme) => theme.id);

      setInformation({ ...information, background_collections: [...information.background_collections, ...addIds] });
    }
  };

  const handleToggleBackgroundCollectionsShow = (backgroundId) => {
    setBackgroundCollectionsShow(!backgroundCollectionsShow);

    if (backgroundId) {
      setInformation({ ...information, featured_background: {}, featured_background_id: backgroundId });
      dispatch(getBackground({ id: backgroundId }));
    }
  };

  useEffect(() => {
    const { sendBack: shouldSendBack } = location.state ? location.state : false;

    if (shouldSendBack) {
      setSendBack(true);
    }

    if (studio) {
      dispatch(getBackgroundsCollectionList({ order: 'created_at', dir: 'DESC', per_page: 1000, page: 1 }));
      dispatch(resetBackground());
    }
  }, []);

  useEffect(() => {
    if (job?.id) {
      const dataInfo = {
        background_collections: job.background_collections?.length > 0 ? job.background_collections.map((collection) => collection.id) : [],
        featured_background: Object.keys(job?.featured_background ?? {})?.length ? job.featured_background : {},
        featured_background_id: job.featured_background?.id ?? ''
      };

      setInformation(dataInfo);
      setInitialInformation(JSON.stringify(dataInfo));
    }
  }, [job?.id]);

  useEffect(() => {
    const { featured_background: featuredBackground, featured_background_id: featuredBackgroundId, background_collections } = information;

    if (featuredBackground) {
      const { background_collection_id: backgroundCollectionId } = featuredBackground;

      if (backgroundCollectionId) {
        const foundCollection = backgroundCollections.find((collection) => collection.id === featuredBackground.background_collection_id);

        if (foundCollection) {
          setSelectedBackgroundCollection({ value: foundCollection.id, label: foundCollection.name });
        }
      }
    }

    if (!featuredBackgroundId) {
      setSelectedBackgroundCollection('');
      setSelectedBackgroundCollectionBackgrounds([]);
    }

    if (background_collections.length > 0) {
      const initialSelectedBackground = backgroundCollections.find((bg) => bg.id === background_collections[0]);

      if (initialSelectedBackground) {
        setSelectedBackgroundCollection({ label: initialSelectedBackground.name, value: initialSelectedBackground.id });
      }
    }
  }, [information]);

  useEffect(() => {
    if (selectedBackgroundCollection) {
      dispatch(
        getBackgroundsCollection({ collectionId: selectedBackgroundCollection?.value }, ({ data }) => {
          setSelectedBackgroundCollectionBackgrounds(data.backgrounds);
          handleBackgroundFeaturedSelect(data.first_background.id);
        })
      );
    }
  }, [selectedBackgroundCollection]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Settings" />

      <main className="box flex job-settings-store">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} />
        <form className="flex-9 flex-12-md job-settings-store__container" onSubmit={handleSave}>
          <header className="flex between middle job-settings-store__header">
            <h2 className="text--nomargin">Store</h2>
            <button type="submit" className="button button--medium" disabled={JSON.stringify(information) === initialInformation}>
              Save
            </button>
          </header>

          <section className="panel panel--section panel--tall">
            <header className="job-settings-store__icon-header">
              <img src={imageBackgrounds} className="job-settings-store__icon" alt="Backgrounds" />
              <div className="flex column between">
                <h2 className="job-settings-store__panel-title">Backgrounds</h2>
                <p className="text--nomargin">
                  Backgrounds give life to your photos. If you’ve photographed on green screen and have already extracted the subject(s), upload your own
                  backgrounds by creating a background collection and select which collection you’d like to include in this job below.
                </p>
              </div>
            </header>

            {backgroundCollections && (
              <section>
                <header className="flex end job-settings-store__backgrounds-header">
                  <div className="flex end middle button-group">
                    {information.background_collections.length > 0 && (
                      <button className="button button--link button--inline" type="button" onClick={handleSelectAllBackgroundCollections}>
                        {backgroundCollections.every((collection) => information.background_collections.includes(collection.id)) ? 'Clear All' : 'Select All'}
                      </button>
                    )}

                    <button className="button button--outline button--small" type="button" onClick={handleBackgroundsRefresh}>
                      <i className="icon-refresh"></i>
                    </button>
                    <button className="button button--outline button--small hidden--sm" type="button">
                      <a href="/storefront/backgrounds" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <i className="icon-add"></i>
                      </a>
                    </button>
                  </div>
                </header>

                {backgroundCollections.length === 0 && backgroundsRequesting ? (
                  <GridLoader columns={6} rows={2} />
                ) : (
                  <>
                    {backgroundCollections.length > 0 ? (
                      <ul className="grid gap-20 animate job-settings-store__backgrounds-list">
                        {backgroundCollections.map((collection, index) => (
                          <li
                            className="job-settings-store__backgrounds-container"
                            key={collection.id + index}
                            onClick={() => handleBackgroundCollectionSelect(collection.id)}
                          >
                            <figure className="flex column center">
                              <div
                                className={`job-settings-store__backgrounds-item ${
                                  information.background_collections.find((value) => value === collection.id)
                                    ? 'job-settings-store__backgrounds-item--active'
                                    : ''
                                }`}
                              >
                                <img
                                  className="job-settings-store__backgrounds-image"
                                  src={imageScaling({ url: collection.first_background && collection.first_background.image_url, size: 'small' })}
                                  alt={collection.first_background.name}
                                />
                              </div>

                              <figcaption className="text--truncate">
                                <Tooltip
                                  className="text--center"
                                  title={collection.name}
                                  position="top"
                                  arrow="true"
                                  distance="10"
                                  disabled={collection.name.length < 12}
                                >
                                  {collection.name}
                                </Tooltip>
                              </figcaption>
                            </figure>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text--center">
                        Create your first background collection{' '}
                        <a href="/storefront/backgrounds" className="button--link text--bold" target="_blank" rel="noopener noreferrer">
                          here
                        </a>
                      </p>
                    )}
                  </>
                )}

                {information.background_collections.length > 0 && (
                  <>
                    <hr />

                    <fieldset>
                      <h3>Default Background</h3>
                      <div className="flex between middle">
                        <figure className="job-settings-store__backgrounds-default-background">
                          {(currentBackground.image_url || (information.featured_background && information.featured_background.image_url)) && (
                            <img
                              className="job-settings-store__backgrounds-default-background-image"
                              src={imageScaling({ url: currentBackground.image_url || information.featured_background.image_url, size: 'small' })}
                              alt="default background"
                            />
                          )}
                        </figure>

                        <span className="job-settings-store__sub-title--background">
                          Set a default background that will be used as the primary background when customers view their photos. For private jobs, the default
                          background will also be used when exporting service item and yearbook export files.{' '}
                        </span>
                        <button className="button button--medium button--outline" type="button" onClick={() => handleToggleBackgroundCollectionsShow('')}>
                          Update
                        </button>
                      </div>
                    </fieldset>
                  </>
                )}
              </section>
            )}
          </section>

          <aside className={`modal ${backgroundCollectionsShow ? '' : 'transparent'}`}>
            <div className="modal__box modal__box modal__box--secondary">
              <button className="button button--action modal__close" name="close" type="button" onClick={() => handleToggleBackgroundCollectionsShow()}>
                <i className="icon-close"></i>
              </button>
              <div className="flex modal__content">
                <section className="flex-5 flex-12-md modal__content-section modal__content-section--dark">
                  <header>
                    <h3 className="text-left">Set Default Collection</h3>
                  </header>
                  <Select
                    className="select"
                    classNamePrefix="select"
                    placeholder="Select a collection"
                    value={selectedBackgroundCollection}
                    options={
                      backgroundCollections
                        ? backgroundCollections
                            .filter((collection) => information.background_collections.includes(collection.id))
                            .map((collection) => ({ label: collection.name, value: collection.id }))
                        : []
                    }
                    onChange={(select) => setSelectedBackgroundCollection(select)}
                  />
                  <ul className="grid gap-10 row-2 panel panel--lean job-settings-store__modal-background-container">
                    {selectedBackgroundCollectionBackgrounds.map((background) => (
                      <li
                        key={background.id}
                        className={`job-settings-store__backgrounds-default ${
                          background.id === defaultBackgroundSelected ? 'job-settings-store__backgrounds-default--active' : ''
                        }`}
                        onClick={() => handleBackgroundFeaturedSelect(background.id)}
                      >
                        <img
                          className="job-settings-store__backgrounds-default-image"
                          src={imageScaling({ url: background.image_url, size: 'small' })}
                          alt={background.name}
                        />
                      </li>
                    ))}
                  </ul>
                </section>
                <section className="flex flex-7 flex-12-md column between middle modal__content-section">
                  <h3 className="self-start">Preview Background</h3>
                  {defaultBackgroundSelected ? (
                    <figure>
                      {selectedBackgroundCollectionBackgrounds
                        .filter((background) => background.id === defaultBackgroundSelected)
                        .map((background) => (
                          <img key={background.id} src={imageScaling({ url: background.image_url, size: 'medium' })} alt={background.name} />
                        ))}
                    </figure>
                  ) : (
                    <h6>Select a background to preview it.</h6>
                  )}
                  <button
                    className="button button--medium mt-20"
                    name="select"
                    type="button"
                    onClick={() => handleToggleBackgroundCollectionsShow(defaultBackgroundSelected)}
                  >
                    Select
                  </button>
                </section>
              </div>
            </div>
          </aside>
        </form>
      </main>
    </>
  );
};

Backgrounds.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      sendBack: PropTypes.bool.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

Backgrounds.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  location: {
    state: {
      sendBack: false
    }
  },
  history: {}
};

export default Backgrounds;
