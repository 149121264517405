import { FC, useState } from 'react';

// Plugins
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Components
import CardForm from './CardForm';

// Redux
import { useDispatch } from 'react-redux';
import { createStudioCreditPurchase, updateMyAccountStudioRequest } from '../../../actions';

// Images
import ReactImageCoin from '@/assets/images/icon-coin.svg?react';

// Styles
import './style.css';

// Types
interface BuyWalletCreditsProps {
  studio: { id: string; knockout_cost_cents: number; color_correction_cost_cents: number; studio_credits_agreed: boolean };
  missingCredits?: number;
  onBuyWalletCreditsCreateModalClose: () => void;
}

const STRIPE_API_KEY = import.meta.env.VITE_STRIPE_API_KEY;

const stripePromise = (async () => {
  try {
    const stripe = await loadStripe(STRIPE_API_KEY ?? '');
    return stripe ?? null;
  } catch (err) {
    console.error(err);
    window.location.reload();
    return null;
  }
})();

const BuyWalletCredits: FC<BuyWalletCreditsProps> = ({ studio, missingCredits, onBuyWalletCreditsCreateModalClose }) => {
  const dispatch = useDispatch();

  const [showAllSetConfirmation, setShowAllSetConfirmation] = useState<boolean>(false);

  const handleBuyWalletCredits = (amount: number, source: string) => {
    dispatch(
      createStudioCreditPurchase({ amount_cents: amount, source }, () => {
        if (!studio.studio_credits_agreed) {
          dispatch(
            updateMyAccountStudioRequest({ id: studio.id, studio_credits_agreed: true }, () => {
              setShowAllSetConfirmation(true);
            })
          );
        } else {
          setShowAllSetConfirmation(true);
        }
      })
    );
  };

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--xsmall">
        {showAllSetConfirmation ? (
          <div className="flex column middle center gap-20 animate">
            <ReactImageCoin />
            <h3>You’re all set!</h3>
            <p className="mb-20">We’ve added the credits to your account and emailed a receipt. Thanks!</p>
            <button className="button button--medium" name="close" type="button" onClick={onBuyWalletCreditsCreateModalClose}>
              Close
            </button>
          </div>
        ) : (
          <>
            <header className="modal__header text--left">
              <button className="button button--action modal__close" name="button" type="button" onClick={onBuyWalletCreditsCreateModalClose}>
                <i className="icon-close"></i>
              </button>
              {missingCredits ? (
                <>
                  <h3>
                    Load Your Wallet with <br /> More Credits
                  </h3>
                  <p className="text--small">Uh oh! You don’t have enough credits to submit this job.</p>
                </>
              ) : (
                <>
                  <h3>Credits</h3>
                  <p>Buy credits for PhotoDay’s services. Enter quantity below.</p>
                </>
              )}
            </header>
            <main className="modal__content">
              {window.Stripe ? (
                <Elements stripe={stripePromise}>
                  <CardForm
                    missingCredits={missingCredits}
                    knockoutCostCents={studio.knockout_cost_cents}
                    colorCorrectionCostCents={studio.color_correction_cost_cents}
                    studioCreditsPolicyAgreed={studio.studio_credits_agreed}
                    onBuyWalletCredits={handleBuyWalletCredits}
                  />
                </Elements>
              ) : (
                <aside className="panel panel--secondary">
                  <p className="text--nomargin">
                    Ops.. looks like your browser blocked the credit card widget. Please, allow third-party scripts to load before continue.
                  </p>
                </aside>
              )}
            </main>
            <footer className="modal__footer">
              <hr />
              <p className="text--nomargin">
                <small>
                  Additional resources:{' '}
                  <a href="https://support.photoday.io/en/articles/5506887-photoday-credits" target="_blank" rel="noopener noreferrer">
                    Credit usage and refund policy
                  </a>
                  .
                </small>
              </p>
            </footer>
          </>
        )}
      </div>
    </aside>
  );
};

export default BuyWalletCredits;
