import { FC } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Components
import Header from '../Header';

import SideBar from './Sidebar';
import Exports from './Exports';
import PostProcessing from './PostProcessing';

// Styles
import './style.css';

// Types
interface ServicesProps {
  match: {
    params: {
      jobId: string;
    };
  };
}

const Services: FC<ServicesProps> = ({ match }) => {
  const location = useLocation();
  const history = useHistory();

  const {
    params: { jobId }
  } = match;

  const { job } = useSelector((state: any) => state.jobs);
  const galleryType = job.access_mode === 'access_per_subject' ? 'private' : 'public';

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio } = useSelector((state: any) => state.users);
  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  return (
    <>
      <Header {...{ history, jobId, title: 'Services' }} />

      <main className="box flex">
        <SideBar studio={studio} jobId={jobId} path={location.pathname} galleryType={galleryType} />

        <section className="flex-9 flex-12-md job-services__container">
          <Switch>
            <Redirect exact from="/jobs/:jobId/services" to={`/jobs/:jobId/services/${galleryType === 'private' ? 'exports' : 'postprocessing'}`} />
            <Route path="/jobs/:jobId/services/exports" component={Exports} />
            <Redirect from="/jobs/:jobId/services/knockouts" to="/jobs/:jobId/services/postprocessing" />
            <Route path="/jobs/:jobId/services/postprocessing" component={PostProcessing} />
          </Switch>
        </section>
      </main>
    </>
  );
};

export default Services;
