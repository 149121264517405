import { Component } from 'react';

import icon_tip from './icon_tip_lightbulb.png';

import './Tip.css';

class Tip extends Component {
  renderContent = () => {
    const { type, list, text } = this.props;

    switch (type) {
      case 'list':
        return (
          <ul className="tip-text">
            {list.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        );
      case 'text':
        return <p className="tip-text">{text}</p>;
      default:
        return <p className="tip-text">{this.props.children}</p>;
    }
  };

  render() {
    return (
      <div className="tip-container tip-align-top">
        <span className="badge-tip">
          <img src={icon_tip} alt="Tip Icon" />
        </span>
        {this.renderContent()}
      </div>
    );
  }
}

export default Tip;
