import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

class CustomCheckboxList extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.element])).isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    label: null,
    className: ''
  };

  renderCheckboxes = ({ input }) => {
    const { className, options, name } = this.props;
    const itemClass = `flex-${12 / options.length}`;

    return (
      <div className={`row ${className}`}>
        {options.map((CheckBoxComponent, index) => (
          <div key={`cl-${name}-${index}`} className={itemClass}>
            <CheckBoxComponent {...input} />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { name, label, onChange } = this.props;

    return (
      <div>
        {label && <label>{label}</label>}
        <Field
          name={name}
          component={this.renderCheckboxes}
          onChange={(event, newValue) => {
            onChange && onChange(newValue);
          }}
        />
      </div>
    );
  }
}

export default CustomCheckboxList;
