import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { updatePricesheetItemRequest } from './actions';

// Plugins
import { Tooltip } from 'react-tippy';
import { NumericFormat } from 'react-number-format';

// Components
import DigitalProductSetup from './DigitalProductSetup';
import { QuantityInput } from '@/components/Shared/Forms';

// Helpers
import ProfitCalculator from './ProfitCalculator';
import { formatCurrency } from '@/utils/displayFormats';
import { selectInitialValuesFromPriceSheetItem } from './selectors';
import { convertToCents, convertToText } from '@/utils/currency';
import { getProductCostByShippingType } from '@/utils/productCostByShippingType';

import { DIGITAL_PRICE_MINIMUM, DIGITAL_BUNDLE_MIN_PHOTOS, DIGITAL_BUNDLE_MAX_PHOTOS } from './constants';

const DOWNLOAD_DIGITALS_ENABLED = import.meta.env.VITE_DOWNLOAD_DIGITALS_ENABLED === '1';
const BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';
const DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT = 10; // %

const EditProductModal = ({ isVisible, toggleDelete, initialValues, markup, pdFee, onClose, requesting, shippingType }) => {
  const dispatch = useDispatch();

  const [productValues, setProductValues] = useState({
    name: '',
    description: '',
    base_cost_cents: '',
    base_bulk_cost_cents: '',
    markup_cents: '',
    price_cents: '',
    products_attributes: [{}],
    digitalProductsCount: 0,

    digital_bundle: false,
    digital_bundle_pricing_type: null,
    digital_bundle_max_price_cents: null,
    digital_bundle_max_qty: null,
    digital_bundle_tiers: null
  });

  const [errors, setErrors] = useState({});
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [initialSnapshot, setInitialSnapShot] = useState({});
  const [formattedValue, setFormattedValue] = useState('$0');

  const isDigitalDownload = initialValues.has_digital_downloads;
  const currentQuantity = productValues.products_attributes[0].quantity;
  const baseCostTip = isDigitalDownload ? 'Cost of digital download' : 'Click icon to see the cost breakdown';
  const product = initialValues.price_sheet_item_purchasables[0].product;

  const handleInputChange = (e) => {
    const input = e.target;

    setProductValues({ ...productValues, [input.name]: input.value });
  };

  const handleQuantityChange = (nextQuantity) => {
    const currentProduct = productValues.products_attributes[0];
    const newBaseCostCents = (productValues.base_cost_cents / currentProduct.quantity) * nextQuantity;
    const newBaseCostBulkCents = (productValues.base_bulk_cost_cents / currentProduct.quantity) * nextQuantity;
    const newProducts = [{ id: currentProduct.id, quantity: nextQuantity, digitalProductsCount: currentProduct.has_digital_downloads ? nextQuantity : 0 }];

    const calc = new ProfitCalculator({
      digitalProductsCount: newProducts.digitalProductsCount,
      baseCostCents: getProductCostByShippingType(newBaseCostCents, newBaseCostBulkCents, shippingType),
      markup: markup,
      pdFee: pdFee
    });

    calc.calculate();

    setEnableSubmit(false);
    setFormattedValue(convertToText(calc.priceCents, '$'));
    setProductValues({
      ...productValues,
      base_cost_cents: newBaseCostCents,
      base_bulk_cost_cents: calc.baseCostCents,
      markup_cents: calc.markupCents,
      price_cents: calc.priceCents,
      products_attributes: newProducts,
      minimumPriceCents: calc.minimumPriceCents
    });
  };

  const handlePriceChange = (input) => {
    const price = convertToCents(input.value);

    const calc = new ProfitCalculator({
      digitalProductsCount: productValues.digitalProductsCount,
      baseCostCents: getProductCostByShippingType(productValues.base_cost_cents, productValues.base_bulk_cost_cents, shippingType),
      markup: markup,
      priceCents: price,
      pdFee: pdFee
    });

    calc.calculate();

    if (productValues?.digital_bundle_tiers?.length > 0) {
      const updatedDigitalBundleTiers = productValues?.digital_bundle_tiers?.map((tier) => {
        const price_cents = convertToText(price - (price * Number(tier.discount)) / 100);
        return { ...tier, price_cents };
      });
      productValues.digital_bundle_tiers = updatedDigitalBundleTiers;
    }

    setEnableSubmit(false);
    setFormattedValue(input.formattedValue);
    setProductValues({
      ...productValues,
      base_bulk_cost_cents: calc.baseCostCents,
      markup_cents: calc.markupCents,
      price_cents: calc.priceCents,
      minimumPriceCents: calc.minimumPriceCents
    });
  };

  const handleBundlePricingTypeChange = ({ bundleType }) => {
    if (bundleType === 'digitals_tiered_pricing') {
      const initialDigitalBundleTier = {
        min_qty: DIGITAL_BUNDLE_MIN_PHOTOS,
        max_qty: DIGITAL_BUNDLE_MIN_PHOTOS + 2,
        price_cents: convertToText(productValues?.price_cents - (productValues?.price_cents * DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT) / 100),
        discount: DIGITAL_BUNDLE_TIER_FIRST_DISCOUNT
      };

      const updateDigitalBundleTiers =
        productValues?.digital_bundle_tiers?.length > 0
          ? productValues?.digital_bundle_tiers?.map((tier, index) => {
              if (index === 0) {
                return { id: tier.id, ...initialDigitalBundleTier };
              }

              return { ...tier, _destroy: true };
            })
          : [initialDigitalBundleTier];

      setProductValues({
        ...productValues,
        digital_bundle: true,
        digital_bundle_pricing_type: 'digitals_tiered_pricing',
        digital_bundle_max_price_cents: null,
        digital_bundle_max_qty: null,
        digital_bundle_tiers: updateDigitalBundleTiers
      });
    } else if (bundleType === 'digitals_buy_all_pricing') {
      setProductValues({
        ...productValues,
        digital_bundle: true,
        digital_bundle_pricing_type: 'digitals_buy_all_pricing',
        digital_bundle_max_price_cents: convertToText(productValues?.price_cents + 100),
        digital_bundle_max_qty: DIGITAL_BUNDLE_MAX_PHOTOS
      });
    } else {
      setProductValues({
        ...productValues,
        digital_bundle: false,
        digital_bundle_pricing_type: null,
        digital_bundle_max_price_cents: null,
        digital_bundle_max_qty: null
      });
    }

    // Reset errors
    setErrors({});
    setEnableSubmit(true);
  };

  const handleBundlePricingChange = ({ bundleProp, bundleValue, bundleTierValue }) => {
    const updatedBundleProp = bundleProp === 'digital_bundle_tiers_attributes' ? 'digital_bundle_tiers' : bundleProp;

    if (bundleTierValue) {
      const retailPrice = Number(productValues?.price_cents / 100);
      const activeTiers = productValues?.digital_bundle_tiers?.filter((tier) => !tier._destroy) || [];
      const destroyedTiers = productValues?.digital_bundle_tiers?.filter((tier) => tier._destroy) || [];

      bundleValue = activeTiers
        .map((tier, index) => {
          if (index !== bundleTierValue.index) return tier;

          const value = bundleTierValue.value;
          if (bundleTierValue.prop === 'price_cents') {
            return {
              ...tier,
              price_cents: value,
              discount: Math.max(((retailPrice - value) / retailPrice) * 100, 0)
            };
          }

          if (bundleTierValue.prop === 'discount') {
            return {
              ...tier,
              price_cents: retailPrice - (retailPrice * value) / 100,
              discount: value
            };
          }

          return { ...tier, [bundleTierValue.prop]: value };
        })
        .concat(destroyedTiers);
    }

    setProductValues((prevProductValues) => ({ ...prevProductValues, [updatedBundleProp]: bundleValue }));
    setErrors({});
    setEnableSubmit(true);
  };

  const handleBundleTierAddRemove = ({ action, tierIndex }) => {
    const bundleTiers = productValues?.digital_bundle_tiers;

    if (action === 'add') {
      const filteredBundleTiers = bundleTiers.filter((tier) => !tier._destroy);
      const bundleTiersLength = filteredBundleTiers?.length ?? 0;
      const lastBundleTier = filteredBundleTiers[bundleTiersLength - 1];

      const retailPrice = convertToText(productValues?.price_cents);
      const newPriceCents = lastBundleTier?.price_cents - lastBundleTier?.price_cents * 0.1; // 10%
      const newDiscountApplied = Math.round(((retailPrice - newPriceCents) / retailPrice) * 100);

      const newBundleTier = {
        min_qty: lastBundleTier?.max_qty + 1,
        max_qty: Math.min(lastBundleTier?.max_qty + 5, DIGITAL_BUNDLE_MAX_PHOTOS),
        price_cents: newPriceCents,
        discount: newDiscountApplied
      };

      setProductValues({
        ...productValues,
        digital_bundle_tiers: [...bundleTiers, newBundleTier]
      });
    }

    if (action === 'remove') {
      const selectedBundleTier = bundleTiers[tierIndex];

      let filteredBundleTiers = bundleTiers.filter((_, index) => index !== tierIndex);

      if (selectedBundleTier?.id) {
        filteredBundleTiers = [...filteredBundleTiers, { ...selectedBundleTier, _destroy: true }];
      }

      setProductValues({
        ...productValues,
        digital_bundle_tiers: filteredBundleTiers
      });
    }

    // Reset errors
    setErrors({});
    setEnableSubmit(true);
  };

  const handleClose = () => {
    const changesMade = JSON.stringify(initialSnapshot) !== JSON.stringify(productValues);

    !changesMade && onClose();
    !confirmClose && setConfirmClose(true);
    confirmClose && onClose();
  };

  const handleSave = () => {
    // Replace the digital_bundle_tiers property to became digital_bundle_tiers_attributes
    const updatedProductValues = Object.entries(productValues).reduce((acc, [key, value]) => {
      if (key === 'digital_bundle_tiers') {
        acc.digital_bundle_tiers_attributes = value; // Replace 'digital_bundle_tiers' with 'digital_bundle_tiers_attributes '
      } else {
        acc[key] = value; // Keep other properties as they are
      }

      return acc;
    }, {});

    dispatch(
      updatePricesheetItemRequest(
        {
          ...updatedProductValues,
          ...(updatedProductValues?.digital_bundle_tiers_attributes
            ? {
                digital_bundle_tiers_attributes: updatedProductValues?.digital_bundle_tiers_attributes.map((tier) => ({
                  ...tier,
                  price_cents: convertToCents(tier.price_cents)
                }))
              }
            : {}),
          ...(updatedProductValues?.digital_bundle_max_price_cents
            ? { digital_bundle_max_price_cents: convertToCents(updatedProductValues.digital_bundle_max_price_cents) }
            : {})
        },
        () => onClose()
      )
    );
  };

  const handleValidate = (saveOnComplete) => {
    const newErrors = {};

    if (!productValues.description) {
      newErrors.description = 'Description Required';
    }

    if (productValues.price_cents < productValues.minimumPriceCents) {
      newErrors.profit = `Minimum retail price must be ${convertToText(productValues.minimumPriceCents, '$')} or higher.`;
    }

    if (product.is_digital_download) {
      if (product.markupCents < DIGITAL_PRICE_MINIMUM) {
        newErrors.digital = 'The retail price must be at least $5.00.';
        newErrors.contents = 'Please review the products marked red.';
      }

      // Digital bundle validations
      if (productValues.digital_bundle) {
        if (productValues.digital_bundle_pricing_type === 'digitals_tiered_pricing') {
          const digitalBundleTiers = productValues.digital_bundle_tiers ?? [];
          const filteredBundleTiers = digitalBundleTiers.filter((tier) => !tier._destroy);

          for (const i in filteredBundleTiers) {
            const currentTier = filteredBundleTiers[i];
            const previousTier = filteredBundleTiers[i - 1];

            const { min_qty: currMinQty, max_qty: currMaxQty, price_cents: currPPP } = currentTier;

            // #1 Quantity should be greater than 1
            if (Math.min(currMinQty, currMaxQty) < DIGITAL_BUNDLE_MIN_PHOTOS) {
              newErrors.digital = 'Bundle tiers minimum quantity must be greater than 1.';
            }

            // #2 Min quantity should be less than Max quantity
            if (currMinQty >= currMaxQty) {
              newErrors.digital = 'Minimum quantity should be less than maximum quantity in each tier.';
            }

            // #3 PPP should be less than Retail Price
            if (currPPP >= Number(productValues?.price_cents / 100)) {
              newErrors.digital = 'Tier prices should be less than the retail price.';
            }

            if (previousTier) {
              const { max_qty: prevMaxQty, price_cents: prevPriceCents } = previousTier;

              // #4 Tier quantities should always show progression
              if (prevMaxQty >= currMinQty) {
                newErrors.digital = 'Each tier must have greater quantities than its preceding tier.';
              }

              // #5 Tiers PPP should always show regression in pricing
              if (Number(currPPP) >= Number(prevPriceCents)) {
                newErrors.digital = 'Each tier must be cheaper than its preceding tier.';
              }
            }
          }
        }

        if (productValues.digital_bundle_pricing_type === 'digitals_buy_all_pricing') {
          // #1 Max retail price should be greater than retail price.
          if (productValues.digital_bundle_max_qty < DIGITAL_BUNDLE_MIN_PHOTOS || productValues.digital_bundle_max_qty > DIGITAL_BUNDLE_MAX_PHOTOS) {
            newErrors.digital = `Max allowed digitals should be greater or equal to ${DIGITAL_BUNDLE_MIN_PHOTOS} and less or equal to ${DIGITAL_BUNDLE_MAX_PHOTOS}.`;
          }

          // #2 Max retail price should be greater than retail price.
          if (Number(productValues.digital_bundle_max_price_cents * 100) < productValues?.price_cents) {
            newErrors.digital = 'Max retail price should be greater than retail price.';
          }
        }
      }
    }

    setErrors(newErrors);

    if (Object.values(newErrors).length === 0 && saveOnComplete) {
      enableSubmit ? handleSave() : setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  };

  const handleDelete = () => {
    const { id } = initialValues;

    toggleDelete(true, id);
  };

  useEffect(() => {
    const selectInitialValues = selectInitialValuesFromPriceSheetItem(initialValues);
    const formattedValue = convertToText(initialValues.price_cents, '$');
    const minimumPriceCents = selectInitialValues.digitalProductsCount
      ? selectInitialValues.digitalProductsCount * DIGITAL_PRICE_MINIMUM
      : Math.ceil(selectInitialValues.base_cost_cents / ((100 - pdFee) / 100)) + 1;

    const digitalProductBundleInitialValues = {
      digital_bundle_pricing_type: initialValues.digital_bundle_pricing_type,
      digital_bundle_max_price_cents: convertToText(initialValues.digital_bundle_max_price_cents),
      digital_bundle_max_qty: initialValues.digital_bundle_max_qty,
      digital_bundle_tiers: initialValues.digital_bundle_tiers.map((tier) => ({
        ...tier,
        price_cents: convertToText(tier.price_cents)
      }))
    };

    setProductValues({ ...selectInitialValues, ...digitalProductBundleInitialValues, minimumPriceCents });
    setFormattedValue(formattedValue);

    Object.values(initialValues).length
      ? setInitialSnapShot({ ...selectInitialValues, ...digitalProductBundleInitialValues, minimumPriceCents })
      : setInitialSnapShot(productValues);
  }, []);

  return (
    <>
      <aside className={`modal edit-product-modal ${isVisible ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--secondary">
          <button className="button button--action modal__close" name="close" type="button" onClick={handleClose}>
            <i className="icon-close"></i>
          </button>

          <main className="flex modal__content">
            <section className="flex-4 flex-12-sm modal__content-section">
              <h3>
                {productValues.name} <small>{currentQuantity > 1 ? `Pack of ${currentQuantity}` : ''}</small>
              </h3>

              <figure className="edit-product-modal__image-container">
                <img src={productValues.image_url} className="edit-product-modal__product-image" alt={[productValues.name]} />
                {productValues.has_digital_downloads && <figcaption className="text--italic">This product has a $2.00 minimum.</figcaption>}
              </figure>
            </section>

            <section className="flex-8 flex-12-sm modal__content-section">
              <h3>Edit Product</h3>
              <form>
                <div className="flex flex-row ">
                  <fieldset className="flex column flex-12 fieldset--clean">
                    <label htmlFor="description">Product Description</label>
                    <textarea
                      type="text"
                      name="description"
                      rows="4"
                      value={productValues.description}
                      required
                      placeholder="product description"
                      onChange={handleInputChange}
                    />
                    {errors?.description && (
                      <aside className="panel panel--error panel--neat animate">
                        <p className="text--nomargin">{errors.description}</p>
                      </aside>
                    )}
                  </fieldset>
                </div>

                {!isDigitalDownload && <QuantityInput value={productValues?.products_attributes[0]?.quantity} onChange={handleQuantityChange} />}

                {DOWNLOAD_DIGITALS_ENABLED && productValues?.products_attributes[0]?.digital_bundle_enabled ? (
                  <DigitalProductSetup
                    priceFieldValues={{
                      baseCostText: convertToText(shippingType === 'bulk_shipping' ? productValues.base_bulk_cost_cents : productValues.base_cost_cents, '$'),
                      photoDayFeeText: convertToText(Math.round(productValues.price_cents * (pdFee / 100)), '$'),
                      priceCents: productValues.price_cents,
                      formattedValue
                    }}
                    priceFieldError={errors.profit || errors.digital || errors.contents}
                    digitalProductBundle={{
                      digital_bundle_pricing_type: productValues.digital_bundle_pricing_type,
                      digital_bundle_max_price_cents: productValues.digital_bundle_max_price_cents,
                      digital_bundle_max_qty: productValues.digital_bundle_max_qty,
                      digital_bundle_tiers_attributes: productValues.digital_bundle_tiers
                    }}
                    onPriceChange={handlePriceChange}
                    onBundlePricingTypeChange={handleBundlePricingTypeChange}
                    onBundlePricingChange={handleBundlePricingChange}
                    onBundleTierChange={handleBundleTierAddRemove}
                  />
                ) : (
                  <div className="flex flex-row ">
                    <div className="flex-12">
                      <label>Product Price</label>
                      <p>Enter the amount you'd like to set as the price for this product. We'll automatically calculate your markup.</p>
                      <div className="flex flex-row">
                        <fieldset className="flex column flex-4">
                          <label>
                            <Tooltip title={baseCostTip} position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                              Unit Cost
                              <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-question-circle-o" />
                              </a>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="baseCostCents"
                            value={convertToText(shippingType === 'bulk_shipping' ? productValues.base_bulk_cost_cents : productValues.base_cost_cents, '$')}
                            required
                            placeholder="0"
                            disabled={true}
                          />
                        </fieldset>
                        <fieldset className="flex column flex-4">
                          <label>
                            <Tooltip title="Retail Price minus Base Cost" position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                              Markup
                              <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-question-circle-o" />
                              </a>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="markupCents"
                            value={convertToText(productValues.markup_cents, '$')}
                            style={productValues.markup_cents < 1 ? { color: '#a94442' } : {}}
                            required
                            placeholder="0"
                            disabled={true}
                          />
                        </fieldset>
                        <fieldset className="flex column flex-4">
                          <label>
                            <Tooltip title={`${pdFee}% of Retail Price`} position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                              PhotoDay Fee
                              <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-question-circle-o" />
                              </a>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="minPriceCents"
                            value={convertToText(Math.round(productValues.price_cents * (pdFee / 100)), '$')}
                            style={productValues.price_cents < productValues.minimumPriceCents ? { color: '#a94442' } : {}}
                            required
                            placeholder="0"
                            disabled={true}
                          />
                        </fieldset>
                        <div className="flex flex-row">
                          <fieldset className="flex column flex-4">
                            <label>
                              {' '}
                              <Tooltip title="Amount displayed on price sheet" position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                                Retail Price
                                <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                                  <i className="fa fa-question-circle-o" />
                                </a>
                              </Tooltip>
                            </label>
                            <NumericFormat
                              className="input--block"
                              name="priceCents"
                              prefix={'$'}
                              value={formattedValue}
                              allowNegative={false}
                              decimalScale={2}
                              onValueChange={handlePriceChange}
                              style={enableSubmit ? { borderWidth: '2px', borderColor: '#7dd322' } : {}}
                            />
                          </fieldset>
                        </div>
                        {errors.profit && <p className="flex-12 text--bold text--danger">{errors.profit}</p>}

                        {enableSubmit && <p className="flex-12 text--bold text--danger">Please check your price one last time before saving!</p>}

                        {BULK_SHIPPING_ENABLED && shippingType !== 'drop_shipping' && product.bulk_price_cents > 0 && (
                          <>
                            <p className="flex-12">
                              {(shippingType === 'bulk_shipping' || product.bulk_price_cents > product.price_cents) &&
                                `*Retail price calculated using Bulk price: $${formatCurrency(product.bulk_price_cents)}`}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </section>
          </main>

          <footer className="flex end modal__footer modal__footer--secondary">
            <button className="button button--clean button--inline button--grey" onClick={handleDelete}>
              Remove this product
            </button>

            <button
              className="button button--large"
              disabled={requesting || Object.values(errors).length}
              onClick={() => handleValidate(true)}
              data-loading={requesting}
            >
              Save
            </button>
          </footer>
        </div>
      </aside>

      {/* Confirm close modal */}
      <aside className={`modal modal__confirm-close ${confirmClose ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--secondary">
          <main className="modal__content flex column center middle">
            <p>Are you sure you want to close the window?</p>
            <small>The product will not be saved.</small>
            <button className="button button--medium button--outline" onClick={() => setConfirmClose(false)}>
              No, take me back
            </button>
            <button className="button button--medium button--clean" onClick={handleClose}>
              Yes, I'm sure
            </button>
          </main>
        </div>
      </aside>
    </>
  );
};

EditProductModal.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  markup: PropTypes.object.isRequired,
  pdFee: PropTypes.number.isRequired
};

EditProductModal.defaultProps = {
  initialValues: {},
  isVisible: true,
  markup: {},
  pdFee: 0
};

export default EditProductModal;
