import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Styles
import './style.css';

class Switch extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    note: PropTypes.string
  };

  static defaultProps = {
    name: null,
    label: null,
    note: null,
    size: 'default'
  };

  render() {
    const { name, label, note, size } = this.props;
    const id = `sw-${name}`;

    return (
      <fieldset className="flex between middle fieldset--clean">
        <div>
          {label && (typeof label === 'string' ? <h4>{label}</h4> : label)}
          {note && <p>{note}</p>}
        </div>
        <div className="switch-container">
          <div className="switch">
            <Field
              id={id}
              className={`cmn-toggle cmn-toggle-round ${size === 'small' ? 'cmn-toggle-round--small' : ''}`}
              name={name}
              type="checkbox"
              component="input"
            />
            <label className="label--clean" htmlFor={id} />
          </div>
        </div>
      </fieldset>
    );
  }
}

export default Switch;
