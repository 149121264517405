import { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getWhccStudioInformation } from '../../actions';
import whcc_logo from '@/assets/images/whcc_logo.png';
import { ToastContainer } from 'react-toastify';
import Container from '@/components/SignUp/Shared/Container';
import PropTypes from 'prop-types';
import * as qs from 'qs';

const getWhData = (props) => {
  return qs.parse(props.location.search)['?whData'];
};

const mapStateToProps = (state, ownProps) => {
  const whData = getWhData(ownProps);
  return {
    ...state.signUp,
    ...ownProps,
    whData
  };
};

const mapDispatchToProps = { getWhccStudioInformation };

class ConnectCallback extends Component {
  componentDidMount() {
    this.props.getWhccStudioInformation(this.props.whData);
  }

  render() {
    const { location, lab, successful } = this.props;

    if (successful) {
      return <Redirect to={`/whcc-register${location.search}`} />;
    }

    return (
      <div>
        <ToastContainer />
        <Container id="register" labImage={lab.image}>
          <div className="whcc-connect">
            <img src={whcc_logo} alt="WHCC Logo" />
            <br />
            {!!successful && <p>Loaded in WHCC account information...</p>}
            {!successful && <p>Loading in WHCC account information...</p>}
            <Link to={`/whcc-register${location.search}`}> Don't have a WHCC account? </Link>
          </div>
        </Container>
      </div>
    );
  }
}

ConnectCallback.propTypes = {
  lab: PropTypes.shape({
    key: PropTypes.string,
    image: PropTypes.string
  }),
  whcc: PropTypes.shape({
    session_id: PropTypes.string,
    login_url: PropTypes.string,
    redirect_uri: PropTypes.string
  })
};

ConnectCallback.defaultProps = {
  lab: {},
  whcc: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCallback);
