import React from 'react';
import { Link } from 'react-router-dom';

import StepContainer from './Step';

import workflow_step1 from '@/assets/images/workflow_step1.jpg';
import workflow_step2 from '@/assets/images/workflow_step2.jpg';
import workflow_step3 from '@/assets/images/workflow_step3.jpg';
import workflow_step4 from '@/assets/images/workflow_step4.jpg';
import workflow_step5 from '@/assets/images/workflow_step5.jpg';
import workflow_step6 from '@/assets/images/workflow_step6.jpg';
import workflow_step7 from '@/assets/images/workflow_step7.jpg';
import workflow_step8 from '@/assets/images/workflow_step8.jpg';
import workflow_step9 from '@/assets/images/workflow_step9.jpg';
import workflow_step10 from '@/assets/images/workflow_step10.jpg';

import './steps.css';

const StepByStep = () => {
  return (
    <div className="step-by-step">
      <header className="flex middle resources-container__header">
        <h2 className="text--nomargin">A Simpler Way to Set Up Picture Day</h2>
      </header>

      <div className="panel flex middle between">
        <p className="text--nomargin">
          Check out the <Link to="/collateral/marketing">Marketing Kit</Link> in the Studio Panel for all of these resources + more!
        </p>
        <a href="https://media.photoday.io/pdfs/step_by_step_simpler_way.pdf" className="button button--medium" download>
          Download PDF
        </a>
      </div>

      <div className="steps-list">
        <StepContainer
          step="Step 1"
          image={workflow_step1}
          title="When you schedule a job"
          description={<span>Set up the job, create a price sheet, and generate a special offer.</span>}
        />

        <StepContainer
          step="Step 2"
          image={workflow_step2}
          title="4 Weeks before Picture Day"
          description="Create marketing materials to get parents excited about picture day: PhotoDay flyer, posters, and social media graphics. (Templates are in the PhotoDay Marketing Kit.)"
        />

        <StepContainer
          step="Step 3"
          image={workflow_step3}
          title="2-4 Weeks before Picture Day"
          description="Share marketing materials with the organization. Hang posters at the location."
        />

        <StepContainer
          step="Step 4"
          image={workflow_step4}
          title="1 Week before"
          description="Get the team / group names and counts from the organization. This will help determine staffing needs for picture day."
        />

        <StepContainer
          step="Step 5"
          image={workflow_step5}
          title="1 week before"
          description="Add the picture day schedule and a special offer to the flyer. Send the flyer to the league to distribute via email to parents. (This way they print and keep it handy!)"
        />

        <StepContainer
          step="Step 6"
          image={workflow_step6}
          title="Day before or morning of"
          description="Set up tents and tables. Make sure your staff is trained to tell parents to text the event's unique access code to 90738 so they can see their child's photos."
        />

        <StepContainer step="Step 7" image={workflow_step7} title="Day of" description="Set up banners, posters, reminder cards, and equipment." />

        <StepContainer
          step="Step 8"
          image={workflow_step8}
          title="Picture Day!"
          description="Before each child's photo is taken, ask parents to text the access code. Use a time-sensitive offer to create order urgency. Hand out those reminder cards!"
        />

        <StepContainer
          step="Step 9"
          image={workflow_step9}
          title="During Picture Day"
          isOptional={true}
          description="Day-of uploads. Use iPads and upload photos every 30 minutes to encourage day-of sales. Give customers an incentive, like free shipping, if they purchase today."
        />

        <StepContainer
          step="Step 10"
          image={workflow_step10}
          title="After the Shoot"
          description={
            <span>
              Post process, edit, and upload photos. Switch the gallery from Draft to Published so that everyone who texted the access code will receive a text
              notification that photos are ready! Encourage the organization to let parents know (via email and/or social media) that they can now access their
              child's photos.
            </span>
          }
        />
      </div>
    </div>
  );
};

export default StepByStep;
