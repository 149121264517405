import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Images
import imageGettingStartedCampaigns from '@/assets/images/getting-started-campaigns.jpg';
import imageGettingStartedFlyers from '@/assets/images/getting-started-flyers.jpg';
import imageGettingStartedPromos from '@/assets/images/getting-started-promos.jpg';
import imageGettingStartedHelp from '@/assets/images/getting-started-help.jpg';

const GettingStarted: FC = () => {
  const params: { jobId: string } = useParams();
  const { jobId } = params;
  const {
    job: { access_mode: jobAccessMode }
  } = useSelector((state: any) => state.jobs);

  return (
    <>
      <header className="job-marketing__header">
        <h2 className="text--nomargin">Getting Started</h2>
      </header>
      <aside className="job-marketing__banner">
        <h1 className="job-marketing__title">Keep your customers in the know and engaged with our marketing tools.</h1>
        <h4 className="text--normal">
          Communication is key when it comes to online selling! Customers need to know how and when to access their gallery before they can order photos—and
          PhotoDay is here to help.
        </h4>
      </aside>
      <ul className="grid row-3 row-2-md row-1-sm gap-20">
        {jobAccessMode === 'access_per_subject' && (
          <li>
            <h4>Campaigns</h4>
            <figure className="mb-10">
              <img className="job-marketing__start-banner" src={imageGettingStartedCampaigns} alt="Campaigns" />
              <figcaption>
                Campaigns are a series of text messages and emails automatically sent to customers based on the status of the gallery or an event (special
                offers, upcoming expiration, etc.).{' '}
                <a href="https://support.photoday.io/en/articles/6066274-automatic-email-sms-campaigns" target="_blank" rel="noopener noreferrer">
                  Learn More
                </a>
              </figcaption>
            </figure>
            <Link className="button button--link" to={`/jobs/${jobId}/marketing/active-messages`}>
              View Active Messages <i className="icon-arrow-mini"></i>
            </Link>
          </li>
        )}
        <li>
          <h4>Flyers</h4>
          <figure className="mb-10">
            <img className="job-marketing__start-banner" src={imageGettingStartedFlyers} alt="Flyers" />
            <figcaption>
              Flyers are a great way to promote and share valuable information about picture day and the gallery through email, print, and/or an HTML link.
              You’re in control!{' '}
              <a href="https://support.photoday.io/en/articles/2225051-how-do-i-create-flyers-to-promote-my-gallery" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </figcaption>
          </figure>
          <Link className="button button--link" to={`/jobs/${jobId}/marketing/flyers`}>
            Create Flyers <i className="icon-arrow-mini"></i>
          </Link>
        </li>
        <li>
          <h4>Promos</h4>
          <figure className="mb-10">
            <img className="job-marketing__start-banner" src={imageGettingStartedPromos} alt="Promos" />
            <figcaption>
              Promos let you re-promote galleries via timely text message marketing. Seasonal and custom promos are available to give your customers an
              incentive and a second chance to purchase photos.{' '}
              <a href="https://support.photoday.io/en/articles/2533941-what-are-promos" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </figcaption>
          </figure>
          <Link
            className="button button--link"
            to={{
              pathname: '/storefront/promos',
              state: { fromJobId: jobId }
            }}
          >
            Add + Request Promos <i className="icon-arrow-mini"></i>
          </Link>
        </li>
        <li>
          <h4>Need more help?</h4>
          <figure className="mb-10">
            <img className="job-marketing__start-banner" src={imageGettingStartedHelp} alt="Promos" width="309" />
            <figcaption>
              PhotoDay offers world-class support every step of the way. Get your picture day set up, running, and primed for success in no time. Visit our
              support page or chat with our amazing Customer Success team by clicking the chat bubble below.{' '}
            </figcaption>
          </figure>
          <a className="button button--link" href="https://support.photoday.io/en/collections/752876-faq-for-studios" target="_blank" rel="noopener noreferrer">
            Search Help Center <i className="icon-arrow-mini"></i>
          </a>
        </li>
      </ul>
    </>
  );
};

export default GettingStarted;
