import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

import Gallery from './Gallery';
import './style.css';

class Skeleton extends Component {
  static propTypes = {
    tag: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
  };

  static defaultProps = {
    tag: 'div',
    height: '250px',
    width: null
  };

  render() {
    const { tag, height, width, className, ...options } = this.props;
    const style = { height, ...(width ? { width } : {}) };

    return createElement(tag, {
      ...options,
      className: ['shared__skeleton', className || ''].join(' '),
      style
    });
  }
}

Skeleton.Gallery = Gallery;

export default Skeleton;
