import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';
import matchNormalizePropToNormalizers from './normalize';

class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    error: PropTypes.string
  };

  static defaultProps = {
    type: 'text',
    label: null,
    placeholder: null,
    disabled: null,
    loading: null,
    error: null
  };

  renderInput = ({ input, type, meta: { touched, error } }) => {
    const { placeholder, loading, disabled, transformValueOnChange, autoComplete, readOnly } = this.props;
    const { onChange } = input;

    const handleOnChange = (event) => {
      onChange(transformValueOnChange ? transformValueOnChange(event.target.value) : event.target.value);
    };

    return (
      <div className={loading ? 'input--block-loading' : ''}>
        <input
          {...input}
          readOnly={readOnly}
          disabled={disabled || loading}
          placeholder={placeholder}
          type={type}
          className="input--block"
          autoComplete={autoComplete}
          onChange={handleOnChange}
        />
        {touched && (error || this.props.error) && <small className="text--block text--danger">{error || this.props.error}</small>}
      </div>
    );
  };

  componentDidUpdate(nextProps) {
    if (nextProps.required !== this.props.required) this.forceUpdate();
  }

  render() {
    const { name, label, type, onChange, onBlur, className, normalize } = this.props;

    return (
      <fieldset className={className || ''}>
        {label && <label>{label}</label>}
        <Field
          name={name}
          component={this.renderInput}
          type={type}
          validate={matchValidatePropToValidators(this.props)}
          onChange={onChange}
          onBlur={onBlur}
          normalize={normalize && ((value) => matchNormalizePropToNormalizers(normalize, value))}
        />
      </fieldset>
    );
  }
}

export default Input;
