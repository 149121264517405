import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, Field } from 'redux-form';

import { Input } from '@/components/Shared/Forms';
import matchValidatePropToValidators from '@/components/Shared/Forms/validators';

const mapStateToProps = (state, ownProps) => {
  const { name, productCategories } = ownProps;
  const productId = formValueSelector('OfferForm')(state, `${name}.id`);

  let products = [];
  let selectedCategory;

  if (productCategories) {
    const selected = productCategories.find(({ products }) => products.map(({ value }) => value).includes(productId)) || {};

    selectedCategory = selected.value;
    products = selected.products || [];
  }
  return { products, selectedCategory, productCategories };
};

class ProductSelector extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    pricesheetItems: PropTypes.array
  };

  static defaultProps = {
    pricesheetItems: []
  };

  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      selectedCategory: props.selectedCategory
    };
  }

  renderSelect = ({ input, meta: { touched, error } }) => {
    const { name, index, productCategories, onRemove } = this.props;
    const { products, selectedCategory } = this.state;

    const onChange = (evt) => {
      input.onChange(null);
      this.onChangeSelect(evt);
    };

    return (
      <div>
        <div className="flex-4">
          <div>
            <label>Select Category</label>
            <div className="select-caret">
              <select onChange={onChange} value={selectedCategory} className="input--block">
                <option value=""> Select... </option>
                {productCategories.map(({ value, label }) => (
                  <option key={`category-${name}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex-5">
          <div>
            <label>Select Product</label>
            <div className="select-caret">
              <select {...input} className="input--block">
                <option value=""> Select... </option>
                {products.map(({ value, label }) => (
                  <option key={`select-${name}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            {touched && error && <small className="text--block text--danger">{error}</small>}
          </div>
        </div>
        <div className="flex-2">
          <Input validate={matchValidatePropToValidators(this.props)} name={`${name}[quantity]`} label="Qty" required={true} type="number" />
        </div>
        {index > 0 && (
          <div className=" flex-1" style={{ marginTop: '27px' }}>
            <button className="button button--dark" onClick={onRemove}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    );
  };

  onChangeSelect = ({ target: { value } }) => {
    const { productCategories } = this.props;
    const selected = productCategories.find(({ value: id }) => id === value);
    this.setState({
      products: (selected || {}).products || [],
      selectedCategory: value
    });
  };

  render() {
    const { name } = this.props;
    return (
      <div>
        <Field validate={matchValidatePropToValidators(this.props)} name={`${name}[id]`} component={this.renderSelect} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ProductSelector);
