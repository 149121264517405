import { useState, useEffect } from 'react';
import { Switch, NavLink, Redirect, useLocation } from 'react-router-dom';

// Components
import Upcoming from './Upcoming';
import Webinars from './Webinars';
import Programs from './Programs';
import ProgramList from './Programs/ProgramList';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Style
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Academy = function Academy() {
  const [section, setSection] = useState('Live');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const location = useLocation();

  const handleToggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen);

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const pathSection = pathArr.pop();

    if (pathSection === 'upcoming' && section !== 'Upcoming') {
      setSection('Upcoming');
    } else if (pathSection === 'webinars' && section !== 'Webinars') {
      setSection('Webinars');
    } else if (pathSection === 'programs' || (pathSection === 'basics' && section !== 'Programs')) {
      setSection('Programs');
    }
  };

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, [location]);

  return (
    <div className="box flex">
      <aside className="flex-3 flex-12-md sidebar-container">
        <header className="flex middle between sidebar-header">
          <h2 className="text--nomargin">Academy</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          <NavLink className="sidebar-navigation__item" to={`/home/academy/upcoming`} activeClassName="sidebar-navigation__item--active">
            Upcoming
          </NavLink>
          <NavLink className="sidebar-navigation__item" to={`/home/academy/webinars`} activeClassName="sidebar-navigation__item--active">
            Webinars
          </NavLink>
          <NavLink className="sidebar-navigation__item" to={`/home/academy/programs`} activeClassName="sidebar-navigation__item--active">
            Programs
          </NavLink>
        </nav>
      </aside>

      <section className="flex-9 flex-12-md academy-container">
        <Switch>
          <Redirect exact from="/home/academy" to="/home/academy/upcoming" />
          <PrivateRoute exact path="/home/academy/upcoming" component={Upcoming} />
          <PrivateRoute exact path="/home/academy/webinars" component={Webinars} />
          <PrivateRoute exact path="/home/academy/programs" component={Programs} />
          <PrivateRoute path="/home/academy/programs/:program" component={ProgramList} />
        </Switch>
      </section>
    </div>
  );
};

export default Academy;
