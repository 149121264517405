import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

// Plugins
import moment from 'moment';

// Redux
import { getJobList } from '../../Jobs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { salesSummaryRequest } from '../../Reports/actions';
import { orderListRequest } from '../../Storefront/Orders/actions';
import { updateMyAccountRequest, getCurrentTermsRequest } from '../../Settings/actions';

// Components
import AddNew from '../../Jobs/List/AddNew';
import QuickGlanceTable from '../QuickGlanceTable';
import VideoPlayer from '@/components/Shared/VideoPlayer';
import ConsentModal from '@/components/Shared/ConsentModal';

// Helpers
import * as storage from '@/utils/storage';
import { formatCurrency } from '@/utils/displayFormats';

const LOGIN_ENTITIES = import.meta.env.VITE_LOGIN_ENTITIES;

const Summary = function Summary() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { entities } = useSelector((state) => state.login);
  const { currentTerms } = useSelector((state) => state.users);
  const {
    upcomingWebinars,
    academy: {
      webinars: { videos: webinars }
    }
  } = useSelector((state) => state.home);
  const {
    jobs: { list, requesting: jobRequesting }
  } = useSelector((state) => state.jobs);
  const {
    entities: { orders, requesting: ordersRequesting }
  } = useSelector((state) => state.orders);
  const { summary } = useSelector((state) => state.reports);

  const studio = entities.studio ? Object.values(entities.studio)[0] : {};
  const photoDaySession = JSON.parse(storage.get(LOGIN_ENTITIES)) || {};
  const user = photoDaySession && photoDaySession.user ? Object.values(photoDaySession.user)[0] : {};
  const roles = (user && user.roles) || [];

  const upcomingWebinar = upcomingWebinars?.length > 0 && upcomingWebinars[0];

  const [showAddNew, setShowAddNew] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showUrl, setShowUrl] = useState('');
  const [showConsentModal, setShowConsentModal] = useState(false);

  const getSummaryItems = () => {
    if (summary) {
      const {
        sales_today,
        sales_this_week,
        sales_this_month,
        sales_this_year,
        total_orders,
        avg_order_value,
        total_sales,
        total_advancedpay,
        order_sales,
        credit_sales
      } = summary;

      return [
        {
          id: 'sales_today',
          key: 'Sales Today',
          value: typeof sales_today === 'undefined' ? '' : '$' + formatCurrency(sales_today)
        },
        {
          id: 'sales_this_week',
          key: 'Sales This Week',
          value: typeof sales_this_week === 'undefined' ? '' : '$' + formatCurrency(sales_this_week)
        },
        {
          id: 'sales_this_month',
          key: 'Sales This Month',
          value: typeof sales_this_month == 'undefined' ? '' : '$' + formatCurrency(sales_this_month)
        },
        {
          id: 'sales_this_year',
          key: 'Sales This Year',
          value: typeof sales_this_year == 'undefined' ? '' : '$' + formatCurrency(sales_this_year)
        },
        {
          id: 'total_orders',
          key: 'Total Number of Orders',
          value: typeof total_orders === 'undefined' ? '' : total_orders
        },
        {
          id: 'total_advancedpay',
          key: 'Total AdvancePay Sales',
          value: typeof total_advancedpay === 'undefined' ? '' : total_advancedpay
        },
        {
          id: 'order_sales',
          key: 'Total Gross Order Sales',
          value: typeof order_sales === 'undefined' ? '' : '$' + formatCurrency(order_sales)
        },
        {
          id: 'credit_sales',
          key: 'Total Gross Credit Sales',
          value: typeof credit_sales === 'undefined' ? '' : '$' + formatCurrency(credit_sales)
        },
        {
          id: 'avg_order_value',
          key: 'Average Order Value',
          value: typeof avg_order_value === 'undefined' ? '' : '$' + formatCurrency(avg_order_value)
        },
        {
          id: 'total_sales',
          key: <h4 className="text--nomargin">Total Sales</h4>,
          value: typeof total_sales === 'undefined' ? '' : <h4 className="text--nomargin">${formatCurrency(total_sales)}</h4>
        }
      ];
    }
  };

  const handleAddNewToggle = () => setShowAddNew(!showAddNew);

  const handleConsentAccept = () => {
    dispatch(
      updateMyAccountRequest({ policies_agreed: true }, () => {
        window.location.reload();
      })
    );
  };

  const handlePlayerToggle = (url) => {
    if (!showPlayer) {
      setShowUrl(url);
      setShowPlayer(true);
    } else {
      setShowUrl('');
      setShowPlayer(false);
    }
  };

  useEffect(() => {
    const studioId = (studio && studio.id) || '';

    if (roles.includes('view_sales')) {
      dispatch(salesSummaryRequest('all'));
      dispatch(
        orderListRequest({
          studioId,
          page: 1,
          perPage: 15,
          order: 'submitted_at',
          dir: 'desc'
        })
      );
    }

    if (roles.includes('view_jobs')) {
      dispatch(getJobList({ page: 1, per_page: 15, order: 'date', dir: 'desc' }));
    }
  }, []);

  useEffect(() => {
    if (currentTerms?.last_requested_at) {
      const requestedTime = new Date(currentTerms.last_requested_at).getTime();
      const elapsedTime = Date.now() - requestedTime;
      const maxTime = 86436000; // 24 hours

      if (elapsedTime >= maxTime) {
        dispatch(getCurrentTermsRequest());
      }
    } else {
      dispatch(getCurrentTermsRequest());
    }
  }, [currentTerms]);

  useEffect(() => {
    if (!user.policies_agreed) {
      setShowConsentModal(true);
    } else if (currentTerms?.user_changed_at && user?.policies_agreed_at) {
      const policyChangedTime = new Date(currentTerms.user_changed_at).getTime();
      const userAcceptedTime = new Date(user.policies_agreed_at).getTime();
      const shouldAcceptPolicy = userAcceptedTime < policyChangedTime;

      setShowConsentModal(shouldAcceptPolicy);
    }
  }, [user?.policies_agreed, user?.policies_agreed_at, currentTerms?.user_changed_at]);

  return (
    <>
      <section className="summary__tables">
        {/* Sales */}
        {studio.id !== 'all-studios' && roles.includes('view_sales') && (
          <QuickGlanceTable items={getSummaryItems().slice(0, 10)} type="sales" title="Sales" route="/reports" requesting={false} />
        )}

        {/* Jobs */}
        {roles.includes('view_jobs') && (
          <QuickGlanceTable
            items={list.slice(0, 9)}
            type="job"
            title="Jobs"
            route="/jobs"
            roles={roles}
            requesting={jobRequesting}
            onAddNewToggle={handleAddNewToggle}
          />
        )}

        {/* Latest Orders */}
        {roles.includes('view_sales') && (
          <QuickGlanceTable
            history={history}
            items={Object.values(orders).slice(0, 9)}
            type="order"
            title="Latest Orders"
            route="/storefront/orders"
            requesting={ordersRequesting}
          />
        )}

        {/* Academy */}
        <article className="summary__academy">
          <header className="flex between middle summary__academy-header">
            <h3>Academy</h3>
            <Link to="academy" className="button button--clean">
              <i className="icon-rocket"></i>
            </Link>
          </header>

          {/* Upcoming Webinar */}
          {upcomingWebinar && (
            <>
              <figure className="summary__preview-main">
                <picture className="flex middle center summary__preview-image-container summary__preview-image-container--clickable">
                  <img src={upcomingWebinar.image_url} alt="Upcoming Webinar" />
                </picture>
                <figcaption>
                  <h6 className="text--green">Upcoming</h6>
                  <h6>{upcomingWebinar.title}</h6>
                  <footer className="flex center">
                    <a className="button button--outline button--block" href={upcomingWebinar.register_url} target="_blank" rel="noopener noreferrer">
                      Register
                    </a>
                  </footer>
                </figcaption>
              </figure>
              <hr />
            </>
          )}

          {/* Webinars */}
          {webinars
            .sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
            .slice(0, 3)
            .map((webinar) => (
              <figure key={webinar.id} className="flex nowrap summary__event" onClick={() => handlePlayerToggle(webinar.url)}>
                <picture className="summary__event-picture">
                  <img src={webinar.thumbnail_url} alt={webinar.title} width="116" />
                </picture>
                <figcaption>
                  <h6 className="text--nomargin">{webinar.title}</h6>
                  <p className="text--bold summary__event-subtitle">
                    <small>Released on {moment(webinar.published_at).format('MM.DD.YY')}</small>
                  </p>
                </figcaption>
              </figure>
            ))}
        </article>
      </section>

      {showAddNew && <AddNew history={history} showAddNew={showAddNew} onAddNewToggle={handleAddNewToggle} />}

      <VideoPlayer className="academy__video-player" url={showUrl} showPlayer={showPlayer} onPlayerToggle={handlePlayerToggle} />

      {showConsentModal && <ConsentModal onAccept={handleConsentAccept} />}
    </>
  );
};

export default Summary;
