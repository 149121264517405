import { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './styles.css';

class SafeLink extends Component {
  static propsTypes = {
    Component: PropTypes.element,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    Component: NavLink,
    disabled: false
  };

  render() {
    const { Component, disabled, children, to, ...options } = this.props;
    if (disabled)
      return (
        <button className={['shared__safe_link', options.className || ''].join(' ')} disabled>
          {children}
        </button>
      );
    return (
      <Component {...options} to={to || ''}>
        {children}
      </Component>
    );
  }
}

export default SafeLink;
