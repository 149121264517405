import { FC, useState, MouseEvent } from 'react';

// Images
import imageHalfCircle from '@/assets/images/half-circle-up.png';
import imageDataList from '@/assets/images/image-data-list.png';
import imagePeopleData from '@/assets/images/people-data-mock.png';
import imageCard1 from '@/assets/images/bg-photo-1.png';
import imageCard2 from '@/assets/images/bg-photo-2.png';
import imageCard3 from '@/assets/images/bg-photo-3.png';

// Styles
import './style.css';

interface GalleryOnboardingProps {
  onClose: (dontShowAgain: boolean) => void;
}

const GalleryOnboardingPrivate: FC<GalleryOnboardingProps> = ({ onClose }) => {
  const [step, setStep] = useState<number>(0);
  const [showDisable, setShowDisable] = useState<boolean>(false);
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  const lastStep = step === 2;
  const nextStep = step + 1 <= 2 ? step + 1 : step;
  const prevStep = step - 1 >= 0 ? step - 1 : step;

  const handleNextStep: (e: MouseEvent) => void = () => {
    setStep(nextStep);
    if (nextStep === 2) setShowDisable(true);
  };

  const handlePrevStep: (e: MouseEvent) => void = () => {
    setStep(prevStep);
  };

  return (
    <aside className="modal">
      <div className="modal__box flex column between onboarding-gallery">
        {step > 0 && (
          <button className="button button--clean button--noborder button--small onboarding-gallery__next" type="button" onClick={handlePrevStep}>
            <i className="icon-back--white"></i>
          </button>
        )}

        <div className="onboarding-gallery__steps">
          <figure className="onboarding-gallery__step">
            <div className="onboarding-gallery__header">
              <img src={imageDataList} className="onboarding-gallery__header-first" alt="" />
            </div>
            <figcaption className="flex column onboarding-gallery__content">
              <div className="flex column middle">
                <h2>Upload your subjects data to PhotoDay</h2>
                <p>
                  If you’re using this private gallery for schools, you’ll first want to upload a .csv file containing subject data. PhotoDay will use this data
                  to help streamline the rest of the picture day process for you.{' '}
                </p>
              </div>
            </figcaption>
          </figure>

          <figure className={`onboarding-gallery__step ${step > 0 ? 'onboarding-gallery__step--next' : ''}`}>
            <div className="onboarding-gallery__header">
              <img src={imagePeopleData} className="onboarding-gallery__header-next" alt="subject data" />
            </div>
            <figcaption className="flex column onboarding-gallery__content">
              <div className="flex column middle">
                <h2>Data matching made easy.</h2>
                <p>
                  No more barcodes. No more scanners No more paperwork. Simply upload your data file and let PhotoDay’s Capture app do all the work. Select a
                  subject and snap a reference photo and that’s it!
                </p>
              </div>
            </figcaption>
          </figure>

          <figure className={`onboarding-gallery__step onboarding-gallery__step--next ${lastStep ? ' onboarding-gallery__step--last' : ''}`}>
            <div className="onboarding-gallery__header">
              <div className="onboarding-gallery__cards">
                <img src={imageCard1} className="onboarding-gallery__card" alt="card" />
                <img src={imageCard2} className="onboarding-gallery__card" alt="card" />
                <img src={imageCard3} className="onboarding-gallery__card" alt="card" />
              </div>
              <img src={imageHalfCircle} className="onboarding-gallery__design-image" alt="" />
            </div>
            <figcaption className="flex column onboarding-gallery__content">
              <div className="flex column middle">
                <h2>Upload and manage your photos</h2>
                <p>
                  Once picture day is done and any post work has been completed, upload your images to PhotoDay. Watch your images instantly{' '}
                  <span className="text--bold">
                    match <i className="icon-match-full"></i>{' '}
                  </span>
                  to each subject.
                </p>
                <p>
                  You’ll also be able to{' '}
                  <span className="text--bold">
                    feature images <i className="icon-star icon-star--small"></i>{' '}
                  </span>
                  that are used for the main gallery, flyers, and exports such as yearbooks and ID cards and{' '}
                  <span className="text--bold">
                    disable digitals downloads <i className="icon-download"></i>{' '}
                  </span>
                  on any image uploaded.
                </p>
              </div>
            </figcaption>
          </figure>
        </div>
        <footer className="flex column middle end onboarding-gallery__footer">
          <button className="button" onClick={lastStep ? () => onClose(dontShowAgain) : handleNextStep}>
            {lastStep ? 'Get started' : 'Next'}
          </button>
          <fieldset className="onboarding-gallery__checkbox-container">
            {showDisable ? (
              <>
                <input
                  type="checkbox"
                  className="hidden"
                  name="dontShowAgain"
                  id="dontShowAgain"
                  checked={dontShowAgain}
                  onChange={() => setDontShowAgain(!dontShowAgain)}
                />
                <label htmlFor="dontShowAgain" className="label--checkbox animate">
                  Don't show this again
                </label>
              </>
            ) : (
              <div className="placeholder"></div>
            )}
          </fieldset>
          <div className="onboarding-gallery__pagination">
            <div className={`onboarding-gallery__page ${step === 0 ? 'onboarding-gallery__page--active' : ''}`}></div>
            <div className={`onboarding-gallery__page ${step === 1 ? 'onboarding-gallery__page--active' : ''}`}></div>
            <div className={`onboarding-gallery__page ${step === 2 ? 'onboarding-gallery__page--active' : ''}`}></div>
          </div>
        </footer>
      </div>
    </aside>
  );
};

export default GalleryOnboardingPrivate;
