import { Component } from 'react';
import { connect } from 'react-redux';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { PatternFormat } from 'react-number-format';

// Redux
import { getJobTimezoneList } from '../../../Jobs/actions';
import { addressValidate } from '@/components/Shared/AddressVerification/actions';
import { getMyAccountStudioRequest, updateMyAccountStudioRequest } from '../../actions';

// Components
import StatesDDL from '@/components/Shared/StatesDDL';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import { phoneNumberValidator, emailValidator } from '@/components/Shared/Forms/validators';
import { stringSpecialCharactersValidator, urlSlugValidator } from '@/utils/validateHelpers';

// Types
import { StudioVerificationStatus, StudioSellerType } from '@/types';

const MY_PHOTODAY_ORG_PAGE_URL = import.meta.env.VITE_MY_PHOTODAY_ORG_PAGE_URL;

const mapStateToProps = (state) => {
  const {
    login: { studio: loginStudio },
    users: { studio: usersStudio, requesting: usersRequesting },
    jobs: { timezones, requesting: jobRequesting }
  } = state;

  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  return {
    requesting: jobRequesting || usersRequesting,
    timezones,
    studioId: studio?.id,
    studio
  };
};

const mapDispatchToProps = {
  getJobTimezoneList,
  addressValidate,
  getMyAccountStudioRequest,
  updateMyAccountStudioRequest
};

class StudioInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      studio: props.studio,
      addressError: false,
      addressDeliverability: 'deliverable',
      isStudioNameValid: true,
      isStudioUrlSlugValid: true,
      showUrlSlugConfirmationModal: false
    };
  }

  componentDidMount() {
    const { studioId, timezones, getMyAccountStudioRequest, getJobTimezoneList } = this.props;

    if (studioId) {
      getMyAccountStudioRequest({ studioId }, ({ data }) => this.setState({ studio: data }));
    }

    if (!timezones.length) {
      getJobTimezoneList();
    }
  }

  handleInputOnChange = (evt) => {
    const input = evt.target.name.split('-');
    const value = evt.target.value;
    const parentProp = `${input[0]}`;
    const childProp = `${input[1]}`;
    const parentObj = this.state[parentProp];
    const newParentObj = Object.assign({}, parentObj, { [childProp]: value });
    const newState = Object.assign({}, this.state, {
      [parentProp]: newParentObj,
      phoneErrorMsg: null,
      emailErrorMsg: null
    });

    this.setState(newState);
  };

  handleAddressInputOnChange = (evt) => {
    evt.preventDefault();

    const input = evt.target.name.split('-');
    const value = evt.target.value;
    const parentProp = `${input[0]}`;
    const childProp = `${input[1]}`;
    const parentObj = this.state.studio[parentProp];
    const newParentObj = Object.assign({}, parentObj, { [childProp]: value });
    const newState = Object.assign({}, this.state.studio, {
      [parentProp]: newParentObj
    });

    this.setState({
      studio: newState,
      addressError: false
    });
  };

  handleAddressValidate = () => {
    const {
      studio: { address }
    } = this.state;
    const { addressValidate } = this.props;

    addressValidate({ line1: address.line1, zip: address.zip }, (response) => {
      if (response) {
        const { components } = response;

        this.setState((prevState) => {
          return {
            studio: {
              ...prevState.studio,
              address: {
                ...prevState.studio.address,
                city: components.city,
                state: components.state,
                zip: components.zip_code
              }
            },
            addressDeliverability: response.deliverability
          };
        });
      } else {
        this.setState({ addressDeliverability: '' });
      }
    });
  };

  handleStudioNameValidate = (evt) => {
    this.setState({ isStudioNameValid: !stringSpecialCharactersValidator(evt?.target?.value) });
  };

  handleStudioUrlSlugValidate = (evt) => {
    this.setState({ isStudioUrlSlugValid: urlSlugValidator(evt?.target?.value) });
  };

  handleStateDDLOnChange = (val) => {
    const { studio } = this.state;
    const { address } = studio;

    const newAddress = val ? Object.assign({}, address, { state: val.value }) : Object.assign({}, address, { state: '' });

    const newState = Object.assign({}, this.state.studio, {
      address: newAddress
    });

    this.setState({
      studio: newState,
      addressError: false
    });
  };

  handleResidentialAddressChange = (evt) => {
    const name = evt.target.name;
    const hasResidentialAddress = name === 'residentialYes' ? true : false;
    const newState = Object.assign({}, this.state.studio, { has_residential_address: hasResidentialAddress });

    this.setState({ studio: newState });
  };

  handleTimezoneOnChange = (val) => {
    const { studio } = this.state;
    this.setState({ studio: { ...studio, time_zone: val.value } });
  };

  handleUrlSlugConfirmationModalClose = () => this.setState({ showUrlSlugConfirmationModal: false });
  handleUrlSlugConfirmationModalConfirm = () => {
    this.saveStudio();
    this.setState({ showUrlSlugConfirmationModal: false });
  };

  validateStudioInfo = () => {
    const { studio: propStudio } = this.props;
    const { studio: stateStudio } = this.state;
    const { address, email, phone, studio_slug: studioSlug } = stateStudio;

    const { line1, city, state, zip } = address || {};
    const isAddressError = !line1 || !city || !state || !zip;
    const phoneBlank = !phone;
    const emailBlank = !email;
    const phoneInvalid = phoneNumberValidator(phone);
    const emailInvalid = emailValidator(email);

    if (phoneBlank) {
      this.setState({ phoneErrorMsg: 'Required' });
      return;
    }

    if (phoneInvalid) {
      this.setState({ phoneErrorMsg: 'Invalid phone format' });
      return;
    }

    if (isAddressError) {
      this.setState({ addressError: true });
      return;
    }

    if (emailBlank) {
      this.setState({ emailErrorMsg: 'Required' });
      return;
    }

    if (emailInvalid) {
      this.setState({ emailErrorMsg: 'Invalid email format' });
      return;
    }

    // Show a confirmation modal before save the studio identifier, as it can only be set once.
    if (studioSlug && stateStudio.studio_slug !== propStudio.studio_slug) {
      this.setState({ showUrlSlugConfirmationModal: true });
      return;
    }

    this.saveStudio();
  };

  saveStudio = () => {
    const { studio } = this.state;
    const { updateMyAccountStudioRequest } = this.props;

    let studioPayload = {
      id: studio.id,
      name: studio.name,
      web_url: studio.web_url,
      phone: studio.phone,
      email: studio.email,
      time_zone: studio.time_zone,
      address_attributes: studio.address,
      watermark_alpha: studio.watermark_alpha,
      watermark_position: studio.watermark_position,
      watermark_scale: studio.watermark_scale,
      watermark_url: studio.watermark_url,
      reporting_code: studio.reporting_code,
      studio_slug: studio.studio_slug,
      has_residential_address: studio.has_residential_address
    };

    updateMyAccountStudioRequest(studioPayload);
  };

  render() {
    const { timezones, loadStudios, requesting, studio: propStudio } = this.props;
    const { addressError, addressDeliverability, isStudioNameValid, isStudioUrlSlugValid, showUrlSlugConfirmationModal, phoneErrorMsg, emailErrorMsg } =
      this.state;
    const { studio } = loadStudios ? this.props : this.state;
    const {
      name,
      phone,
      email,
      web_url,
      address,
      reporting_code,
      studio_slug: studioUrlSlug,
      studio_verification: studioVerification,
      verification_status: verificationStatus,
      has_residential_address: hasResidentialAddress
    } = studio;
    const { line1, line2, city, state, zip } = address || {};
    const timezoneOptions = timezones.map(({ name }) => ({ value: name, label: name }));

    const isStudioUrlDisabled = !!propStudio.studio_slug;

    const selectedZone = timezoneOptions.find((zone) => zone.value === studio.time_zone);

    const stateDDLOptions = {
      elementName: 'address-state',
      country: 'USA',
      selectedState: state,
      parentHandleChange: this.handleStateDDLOnChange
    };

    return (
      <>
        <header className="flex middle between mb-20">
          <h2 className="text--nomargin">Studio Information</h2>
          <button
            className="button button--medium"
            type="button"
            onClick={this.validateStudioInfo}
            disabled={requesting || addressDeliverability !== 'deliverable' || !isStudioNameValid || !isStudioUrlSlugValid}
            data-loading={requesting}
          >
            Save
          </button>
        </header>
        <section className="mb-40">
          <h4 className="text--normal mb-20">The information below will be shared with your customers.</h4>
          <fieldset className="flex nowrap wrap-md gap-20">
            <div className="flex-6 flex-12-md nowrap wrap-sm">
              <label>Studio Name</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  name="studio-name"
                  type="text"
                  value={name || ''}
                  readOnly={studioVerification?.seller_type === StudioSellerType.Company && verificationStatus === StudioVerificationStatus.Verified}
                  onBlur={this.handleStudioNameValidate}
                  onChange={this.handleInputOnChange}
                />
              )}
              {!isStudioNameValid && (
                <small className="text--block text--danger">Invalid Studio Name! Please remove all special characters before proceeding.</small>
              )}
            </div>
            <div className="flex-6 flex-12-md">
              <label> Website URL </label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input value={web_url || ''} onChange={this.handleInputOnChange} name="studio-web_url" type="text" className="input--block" />
              )}
            </div>
          </fieldset>
          <fieldset className="flex nowrap wrap-md gap-20">
            <div className="flex-6 flex-12-md">
              <label>Customer Contact Phone</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <PatternFormat
                  className="input--block"
                  format="+1 (###) ###-####"
                  allowEmptyFormatting
                  mask="_"
                  value={(phone && normalizePhone(phone)) || ''}
                  name="studio-phone"
                  onValueChange={({ value }) => this.handleInputOnChange({ target: { name: 'studio-phone', value } })}
                />
              )}
              {phoneErrorMsg && <small className="text--danger">{phoneErrorMsg}.</small>}
            </div>
            <div className="flex-6 flex-12-md">
              <label>Customer Contact Email</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input value={email || ''} onChange={this.handleInputOnChange} name="studio-email" type="email" className="input--block" />
              )}
              {emailErrorMsg && <small className="text--danger">{emailErrorMsg}.</small>}
            </div>
          </fieldset>
          <fieldset className="flex nowrap wrap-md gap-20">
            <div className="flex-6 flex-12-md">
              <label>
                Studio Reporting ID{' '}
                <Tooltip
                  title="This field is for internal reporting purposes for multi-studio users and in most cases should be left blank"
                  theme="light"
                  arrow={true}
                  position="right"
                  distance="10"
                >
                  <i className="icon-question-mark"></i>
                </Tooltip>
              </label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  value={reporting_code || ''}
                  onChange={this.handleInputOnChange}
                  maxLength="100"
                  name="studio-reporting_code"
                  type="text"
                  className="input--block"
                />
              )}
            </div>
            <div className="flex-6 flex-12-md">
              <label>
                Studio Identifier {''}
                <Tooltip
                  title="Customize the identifier for your Studio. Please make sure to double check spelling as this will be used in various communications as well as organization pages. This can only be done once."
                  theme="light"
                  arrow={true}
                  position="right"
                  distance="10"
                >
                  <i className="icon-question-mark"></i>
                </Tooltip>
              </label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <div className="flex middle nowrap">
                  <input type="text" value={MY_PHOTODAY_ORG_PAGE_URL || ''} readOnly={true} />
                  <input
                    className="flex-10"
                    name="studio-studio_slug"
                    type="text"
                    maxLength={24}
                    value={studioUrlSlug || ''}
                    readOnly={isStudioUrlDisabled}
                    onBlur={this.handleStudioUrlSlugValidate}
                    onChange={this.handleInputOnChange}
                  />
                </div>
              )}
              {!isStudioUrlSlugValid && (
                <small className="text--block text--danger">Invalid URL slug! Please remove all special characters or spaces before proceeding.</small>
              )}
            </div>
          </fieldset>
        </section>
        <section className="mb-40">
          <h4 className="mb-20">Studio Address</h4>
          <fieldset className="flex gap-20 nowrap">
            <div className="flex-8">
              <label htmlFor="address-line1">Address</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  id="address-line1"
                  type="text"
                  name="address-line1"
                  value={line1 || ''}
                  placeholder="Street Address"
                  readOnly={verificationStatus === StudioVerificationStatus.Verified}
                  onBlur={this.handleAddressValidate}
                  onChange={this.handleAddressInputOnChange}
                />
              )}
              {addressError || !line1 ? (
                <small className="text--block text--danger">Required.</small>
              ) : (
                addressDeliverability !== 'deliverable' && <small className="text--block text--danger">Undeliverable address.</small>
              )}
            </div>
            <div className="flex-4">
              <label htmlFor="address-line2">Apt/Unit #</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  id="address-line2"
                  type="text"
                  name="address-line2"
                  value={line2 || ''}
                  placeholder="Apt/Unit #"
                  readOnly={verificationStatus === StudioVerificationStatus.Verified}
                  onChange={this.handleAddressInputOnChange}
                />
              )}
            </div>
          </fieldset>
          <fieldset className="flex gap-20 nowrap">
            <div className="flex-4">
              <label htmlFor="address-city">City</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  id="address-city"
                  type="text"
                  name="address-city"
                  value={city || ''}
                  placeholder="City"
                  readOnly={verificationStatus === StudioVerificationStatus.Verified}
                  onChange={this.handleAddressInputOnChange}
                />
              )}
              {addressError && !city && <small className="text--block text--danger">Required.</small>}
            </div>
            <div className="flex-4">
              <label htmlFor="address-state">State</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <StatesDDL {...stateDDLOptions} disabled={verificationStatus === StudioVerificationStatus.Verified} />
              )}
              {addressError && !state && <small className="text--block text--danger">Required.</small>}
            </div>
            <div className="flex-4">
              <label htmlFor="address-zip">Zip</label>
              {requesting && !studio ? (
                <TextLoader height={37} marginBottom={10} />
              ) : (
                <input
                  id="address-zip"
                  type="text"
                  name="address-zip"
                  value={zip || ''}
                  placeholder="Zip Code"
                  onBlur={this.handleAddressValidate}
                  readOnly={verificationStatus === StudioVerificationStatus.Verified}
                  onChange={this.handleAddressInputOnChange}
                />
              )}
              {addressError || !zip ? (
                <small className="text--block text--danger">Required.</small>
              ) : (
                addressDeliverability !== 'deliverable' && <small className="text--block text--danger">Undeliverable address.</small>
              )}
            </div>
          </fieldset>
          {verificationStatus === StudioVerificationStatus.Verified && (
            <fieldset>
              <label>Is the address you entered above residential?</label>
              <div className="flex gap-20">
                <div className="flex nogrow">
                  <input
                    id="residentialYes"
                    type="radio"
                    name="residentialYes"
                    checked={hasResidentialAddress === true}
                    onChange={this.handleResidentialAddressChange}
                  />
                  <label htmlFor="residentialYes" className="text--nomargin">
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    id="residentialNo"
                    type="radio"
                    name="residentialNo"
                    checked={hasResidentialAddress === false}
                    onChange={this.handleResidentialAddressChange}
                  />
                  <label htmlFor="residentialNo" className="text--nomargin">
                    No
                  </label>
                </div>
              </div>
            </fieldset>
          )}
        </section>
        <section>
          <h4>Studio Time Zone</h4>
          <h4 className="text--normal mb-20">
            Default time zone for your studio. You can set a custom time zone for a job when it's created or in the job's settings.
          </h4>
          <div className="flex-4">
            <label htmlFor="time_zone">Time Zone</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <Select
                id="time_zone"
                className="select"
                classNamePrefix="select"
                maxMenuHeight={200}
                name="time_zone"
                menuPlacement="top"
                value={selectedZone}
                options={timezoneOptions}
                onChange={this.handleTimezoneOnChange}
              />
            )}
          </div>
        </section>

        {/* Slug Identifier Confirmation Modal */}
        {showUrlSlugConfirmationModal && (
          <aside className="modal animate">
            <div className="modal__box modal__box--small modal__box--nomin">
              <main className="modal__content">
                <h2>Please note:</h2>
                <h3 className="text--normal">
                  The Studio Identifier slug can only be set once and cannot be changed later. Are you sure you want to proceed with saving it?
                </h3>
              </main>
              <footer className="modal__footer flex gap-10 center">
                <button className="button button--outline" type="button" name="cancel" onClick={this.handleUrlSlugConfirmationModalClose}>
                  Cancel
                </button>
                <button className="button" type="button" name="save" onClick={this.handleUrlSlugConfirmationModalConfirm}>
                  Save
                </button>
              </footer>
            </div>
          </aside>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioInfo);
