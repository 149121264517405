import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { ToastContainer } from 'react-toastify';
import ModalForgotPassword from './modalForgotPassword';

// Helpers
import { loginRequest } from './actions';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

// Styles
import './login.css';

const mapStateToProps = (state) => ({
  requesting: state.login.requesting,
  invalidLogin: state.login.invalidLogin,
  isUnverified: state.login.isUnverified,
  isLocalStorageAvailable: state.login.isLocalStorageAvailable,
  isAuthenticated: state.login.isAuthenticated
});

class Login extends Component {
  state = {
    email: '',
    password: '',
    showModal: false,
    rememberMe: false,
    showPassword: false
  };

  static propTypes = {
    isUnverified: PropTypes.bool,
    invalidLogin: PropTypes.bool,
    isLocalStorageAvailable: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object.isRequired,
    loginRequest: PropTypes.func.isRequired
  };

  static defaultProps = {
    history: {},
    isLocalStorageAvailable: false,
    isAuthenticated: true,
    invalidLogin: false,
    isUnverified: false
  };

  handleInputOnChange = (evt) => {
    const { target } = evt;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const { loginRequest } = this.props;
    const { email, password, rememberMe } = this.state;

    if (email && password) {
      loginRequest({ email, password, rememberMe });
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { isLocalStorageAvailable, isAuthenticated, invalidLogin, isUnverified, requesting } = this.props;
    const { email, password, showPassword, rememberMe, showModal: showForgotPasswordModal } = this.state;

    if (isUnverified) {
      return <Redirect to="/register/resend-verification" />;
    }

    if (isAuthenticated) {
      return <Redirect to="/home" />;
    }

    return (
      <div id="login__index">
        <ToastContainer />
        <section className="onboarding-bg box">
          <img src={logo} className="gray-logo" alt="Logo" />

          <main className={'container-fluid flex-page-centered' + (invalidLogin ? ' error' : '')}>
            <div className="white-container flex-9 login-screen">
              <figure className="blue-gradient left-onboarding-container">
                <img src={logo_oto_hair} alt="Logo Oto" />
                <h1 className="text--bold"> Welcome back!</h1>
                <p> Please enter your email and password to log back in. </p>
              </figure>

              <aside className="right-onboarding-container">
                <form className="sign-in-form">
                  <div className={`mb-10 ${invalidLogin ? 'has-error' : ''}`}>
                    <label className="text--bold">Email </label>
                    <input
                      id="exampleInputEmail1"
                      className="input--block"
                      value={email}
                      onChange={this.handleInputOnChange}
                      type="email"
                      name="email"
                      placeholder="johnsmith@email.com"
                      autoComplete="username"
                      required
                    />
                  </div>
                  <div className={` ${invalidLogin ? 'has-error' : ''}`}>
                    <label className="text--bold">Password</label>
                    <input
                      value={password}
                      onChange={this.handleInputOnChange}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      className="input--block"
                      id="exampleInputPassword1"
                      placeholder="••••••"
                      autoComplete="current-password"
                      required
                    />
                    <small
                      style={{
                        visibility: invalidLogin ? 'visible' : 'hidden'
                      }}
                      className="text--block text--danger"
                    >
                      Invalid Login
                    </small>
                  </div>
                  <div className="flex middle between">
                    <button className="button button--link" type="button" onClick={this.toggleModal}>
                      Forgot Password
                    </button>
                    <input
                      id="showPassword"
                      className="hidden"
                      type="checkbox"
                      name="showPassword"
                      checked={showPassword}
                      onChange={this.handleInputOnChange}
                    />
                    <label htmlFor="showPassword" className="label--checkbox label--clean">
                      Show Password
                    </label>
                  </div>
                  <button
                    className="button login-btn"
                    type="submit"
                    disabled={requesting || isLocalStorageAvailable === false || !email || !password}
                    data-loading={requesting}
                    onClick={this.handleSubmit}
                  >
                    Log In
                  </button>
                  <fieldset className="flex center">
                    <input id="rememberMe" className="hidden" type="checkbox" name="rememberMe" checked={rememberMe} onChange={this.handleInputOnChange} />
                    <label htmlFor="rememberMe" className="label--checkbox label--clean">
                      Remember Me
                    </label>
                  </fieldset>
                </form>

                {isLocalStorageAvailable === false && (
                  <aside className="panel panel--dark panel--round panel--nomargin animate">
                    <p className="text--nomargin">
                      Your browser’s settings may need to be configured to log in and to get the best experience on this website.{' '}
                      <a
                        href="https://support.photoday.io/en/articles/6577497-how-to-enable-cookies-and-javascript"
                        className="text--black text--bold"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </aside>
                )}

                <a href="https://photoday.io/signup" className="text--bold no-account-link">
                  Don't have an account?
                </a>
              </aside>
            </div>
          </main>

          <ModalForgotPassword isVisible={showForgotPasswordModal} onClose={this.toggleModal} />
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, { loginRequest })(Login);
