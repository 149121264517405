import { Component } from 'react';

// Components
import { Input, InputGroup } from '@/components/Shared/Forms';

// Redux
import { connect } from 'react-redux';
import { offerGenerateCodeRequest } from './../actions';

const mapStateToProps = (state) => {
  const {
    login: {
      studio: { id: studioId }
    }
  } = state;

  return { studioId };
};

const mapDispatchToProps = { offerGenerateCodeRequest };

class OfferMain extends Component {
  onGenerate = () => {
    const { studioId, offerGenerateCodeRequest } = this.props;
    offerGenerateCodeRequest(studioId);
  };

  render() {
    return (
      <div className="flex flex-row">
        <div className="flex-6">
          <Input required name="name" label="Offer Name" placeholder="e.g. Summer Special" type="text" maxLength50 />
        </div>
        <div className="flex-6">
          <InputGroup required name="code" label="Offer Code" placeholder="e.g. SUMMER2017" type="text" maxLength20>
            <span className="text--link-group-addon" onClick={this.onGenerate}>
              Generate
            </span>
          </InputGroup>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferMain);
