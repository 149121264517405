import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import Select from 'react-select';
import Dropzone from 'react-dropzone';

// Redux
import { getPeopleList, getSubject, getPeopleFieldOptions, createPeople, updatePeople, createPeopleLookupValidate } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Styles
import './style.css';

const AddEdit = ({ showAddEdit = false, jobId = '', selectPeople = [], isPngImageAllowed, map = [], onAddEditToggle, onCaptureToggle, studio }) => {
  const dispatch = useDispatch();
  const {
    people: { list, fields, pagination, lookupConfig },
    requesting
  } = useSelector((state) => state.jobs);

  const [information, setInformation] = useState({});
  const [data, setData] = useState([{ value: '', label: '' }]);

  const handleDropAccepted = (file) => {
    const image = file[0];
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setInformation({
        ...information,
        session_photo_attachment: {
          content: reader.result,
          filename: image.name,
          content_type: image.type
        },
        session_photo_url: URL.createObjectURL(image),
        session_photo_filename: image.name
      });
    };
  };

  const handleRemovePhoto = () => setInformation({ ...information, session_photo_attachment: {}, session_photo_url: '', session_photo_filename: '' });

  const handleInputChange = (e) =>
    setInformation({ ...information, [e.target.name]: e.target.value === 'true' || e.target.value === 'false' ? JSON.parse(e.target.value) : e.target.value });

  const handleBlur = (e) =>
    setInformation({
      ...information,
      [e.target.name]: e.target.value === 'true' || e.target.value === 'false' ? JSON.parse(e.target.value) : e.target.value.trim()
    });

  const handleDataChange = (select, index) => {
    setData((prevState) => {
      const prevField = prevState[index].value;
      const prevFieldHasData = information[prevField];
      const newInfo = { ...information };

      if (prevFieldHasData) delete newInfo[prevField];

      data.splice(index, 1, select);
      setInformation(newInfo);

      return data;
    });
  };

  const handleDataAdd = () => setData([...data, { value: '', label: '' }]);

  const handleSave = () => {
    if (information.id) {
      dispatch(
        updatePeople(information, () => {
          onAddEditToggle();
          dispatch(getPeopleFieldOptions({ id: jobId }));
        })
      );
    } else {
      dispatch(
        createPeople({ ...information, id: jobId }, () => {
          onAddEditToggle();
          dispatch(getPeopleFieldOptions({ id: jobId }));
          dispatch(getPeopleList({ id: jobId, page: pagination.page, per_page: Number(pagination.perPage) || 100, order: 'last_name', dir: 'asc' }));

          if (studio.display_capture_message && list.length === 0) {
            onCaptureToggle();
          }

          // Check lookup configuration on very first subject add
          if (lookupConfig.enabled && list.length === 0) {
            dispatch(createPeopleLookupValidate({ jobId, lookupConfig }));
          }
        })
      );
    }
  };

  const loadSubjectData = (subject) => {
    let information = { first_name: '', last_name: '', session_photo_url: '', session_photo_filename: '' };

    if (subject) {
      information = {
        id: subject.id,
        absent: subject.absent,
        first_name: subject.first_name,
        last_name: subject.last_name,
        session_photo_url: subject.session_photo_url,
        session_photo_filename: subject.session_photo_filename
      };

      for (let [key] of Object.entries(fields)) {
        information = { ...information, [key]: subject[key] };
      }
    }

    setInformation(information);
  };

  useEffect(() => {
    if (showAddEdit) {
      let person = list.find((person) => person.id === selectPeople[0]);

      if (person) {
        loadSubjectData(person);
      } else if (selectPeople.length > 0) {
        dispatch(
          getSubject({ id: selectPeople[0] }, ({ data }) => {
            loadSubjectData(data);
          })
        );
      } else {
        loadSubjectData();
      }
    } else {
      setData([{ value: '', label: '' }]);
    }
  }, [showAddEdit]);

  const supportedImageMimeTypes = { 'image/jpg': ['.jpeg', '.jpg'], ...(isPngImageAllowed ? { 'image/png': ['.png'] } : {}) };

  return (
    <aside className={`modal ${showAddEdit ? '' : 'transparent'}`}>
      <div className="modal__box modal__box--secondary job-people-addedit">
        <button className="button button--action modal__close" name="close" type="button" onClick={onAddEditToggle}>
          <i className="icon-close"></i>
        </button>
        <main className="flex modal__content">
          <section className="flex flex-5 flex-12-sm column nowrap between modal__content-section">
            <header>
              <h3>Add reference photo</h3>
              <p>This photo will be used to match uploaded picture day photos</p>
            </header>

            <article className="flex column center middle">
              <Dropzone
                multiple={false}
                maxSize={15000000}
                accept={supportedImageMimeTypes}
                noClick={false}
                noKeyboard={true}
                noDragEventsBubbling={true}
                preventDropOnDocument={true}
                onDropAccepted={handleDropAccepted}
              >
                {({ getRootProps, getInputProps }) => (
                  <figure className="job-people-addedit__figure" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {information.session_photo_url && (
                      <img
                        className="job-people-addedit__image"
                        src={imageScaling({
                          url: information.session_photo_url,
                          size: information.session_photo_attachment && information.session_photo_attachment.content ? null : 'small'
                        })}
                        alt={information.session_photo_filename}
                        width="150"
                      />
                    )}
                  </figure>
                )}
              </Dropzone>
              <button
                className="button button--link text--tall"
                name="remove"
                type="button"
                onClick={handleRemovePhoto}
                disabled={!information.session_photo_url}
              >
                <small>Remove</small>
              </button>
            </article>

            <footer className="panel panel--lean panel--dark">
              <h6>Reference Photo Tips</h6>
              <ul>
                <li>
                  <small>• At least 300 pixels wide</small>
                </li>
                <li>
                  <small>• Photo must be 15MB or less</small>
                </li>
                <li>
                  <small>• Taken in front of a plain white or off-white background</small>
                </li>
                <li>
                  <small>• Taken in full-face view directly facing the camera</small>
                </li>
                <li>
                  <small>• With a neutral facial expression and both eyes open</small>
                </li>
              </ul>
            </footer>
          </section>

          <section className="flex-7 flex-12-sm modal__content-section">
            <h3>Edit subject data</h3>
            <form className="job-people-addedit__form">
              <fieldset className="flex column">
                <label htmlFor="first-name">
                  Name <small>(required)</small>
                </label>
                <div className="flex flex-row">
                  <div className="flex-6 flex-12-sm">
                    <input
                      id="first-name"
                      type="text"
                      name="first_name"
                      value={information.first_name || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="First Name"
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="flex-6 flex-12-sm">
                    <input
                      type="text"
                      name="last_name"
                      value={information.last_name || ''}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      onBlur={handleBlur}
                      maxLength="50"
                      required
                    />
                  </div>
                </div>
              </fieldset>
              <div className="flex flex-row">
                {Object.keys(information)
                  .filter(
                    (key) =>
                      Object.keys(fields).includes(key) &&
                      !data.some((item) => item.value === key) &&
                      key !== 'first_name' &&
                      key !== 'last_name' &&
                      key !== 'absent' &&
                      key !== 'image_name'
                  )
                  .map((key) => (
                    <fieldset className={`flex ${key.toLowerCase().includes('email') ? 'flex-12' : 'flex-6'}`} key={key}>
                      <label className="text--capitalize" htmlFor={key}>
                        {key.replace('session_start', 'check-in_date').replace('_', ' ')}
                      </label>
                      <input
                        id={key}
                        type={`${key.toLowerCase().includes('period') ? 'number' : 'text'}`}
                        name={key}
                        value={information[key] || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        maxLength={key.toLowerCase().includes('email') ? '200' : '50'}
                        readOnly={key === 'access_id'}
                      />
                    </fieldset>
                  ))}
              </div>
              <fieldset className="flex column">
                <label htmlFor="label">Data</label>
                {data.map((item, index) => (
                  <div className="flex flex-row mb-10" key={item.value + index}>
                    <Select
                      className="flex-6 select"
                      classNamePrefix="select"
                      placeholder="Select data"
                      value={item.value ? item : ''}
                      options={map.filter(
                        (item) =>
                          !Object.keys(information).includes(item.value) &&
                          item.value !== 'image_name' &&
                          data.every((dataItem) => item.value !== dataItem.value)
                      )}
                      onChange={(select) => handleDataChange(select, index)}
                    />
                    <input
                      className="flex-6"
                      type={`${item.value.toLowerCase().includes('period') ? 'number' : 'text'}`}
                      name={item.value}
                      value={information[item.value] || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter data"
                      maxLength={item.value.toLowerCase().includes('email') ? '200' : '50'}
                    />
                  </div>
                ))}
              </fieldset>
              {data.length < map.length - 2 && (
                <button
                  className="button button--link button--block"
                  type="button"
                  name="another"
                  onClick={handleDataAdd}
                  disabled={!data.slice(-1)[0]['value']}
                >
                  + Add data
                </button>
              )}
              <hr />
              <fieldset className="flex column">
                <label htmlFor="absent">Mark subject absent?</label>
                <div className="flex flex-row">
                  <div className="flex nowrap middle flex-4 flex-6-sm">
                    <input id="absent-no" type="radio" name="absent" value={false} checked={information.absent === false} onChange={handleInputChange} />
                    <label htmlFor="absent-no" className="text--nomargin">
                      No
                    </label>
                  </div>
                  <div className="flex nowrap middle flex-4 flex-6-sm">
                    <input id="absent-yes" type="radio" name="absent" value={true} checked={information.absent === true} onChange={handleInputChange} />
                    <label htmlFor="absent-yes" className="text--nomargin">
                      Yes
                    </label>
                  </div>
                </div>
              </fieldset>
            </form>
            <footer className="job-people-addedit__footer">
              <button
                className="button button--medium"
                name="save"
                type="button"
                onClick={handleSave}
                data-loading={requesting}
                disabled={requesting || !(information.first_name && information.last_name)}
              >
                Save
              </button>
            </footer>
          </section>
        </main>
      </div>
    </aside>
  );
};

AddEdit.propTypes = {
  showAddEdit: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
  selectPeople: PropTypes.array.isRequired,
  map: PropTypes.array.isRequired
};

export default AddEdit;
