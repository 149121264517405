import { Component } from 'react';
import PropTypes from 'prop-types';

import logo_white from '@/assets/images/logo_white.png';
import '../studio.css';

class PreviewModal extends Component {
  close = () => {
    this.props.toggleModal();
  };

  render() {
    const { isVisible, logo, position, opacity, scale } = this.props;
    let scaledImageWidth = Math.round((252 * scale) / 100);
    let scaledImageWidthLandscape = Math.round((500 * scale) / 100);
    let positionValue;

    switch (position) {
      case 'top,left':
        positionValue = {
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        };
        break;
      case 'top,right':
        positionValue = {
          justifyContent: 'flex-end',
          alignItems: 'flex-start'
        };
        break;
      case 'center,middle':
      case 'center':
        positionValue = { justifyContent: 'center', alignItems: 'center' };
        break;
      case 'bottom,left':
        positionValue = {
          justifyContent: 'flex-start',
          alignItems: 'flex-end'
        };
        break;
      case 'bottom,right':
        positionValue = { justifyContent: 'flex-end', alignItems: 'flex-end' };
        break;
      default:
        positionValue = { justifyContent: 'flex-start' };
        break;
    }

    if (isVisible) {
      return (
        <aside id="large-preview-modal" className="modal animate">
          <div className="modal__box">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={this.close}>
                <i className="icon-close"></i>
              </button>
              <h4>Watermark Preview</h4>
            </header>
            <main className="modal__content">
              <div className="flex around">
                <div className="image-preview-container" style={positionValue}>
                  <div className="logo-sample">
                    <img
                      src={logo || logo_white}
                      alt="Logo Sample"
                      style={{
                        opacity: Number(opacity) === 100 ? 1 : `0.${opacity}`,
                        height: 'auto',
                        maxHeight: '349px',
                        width: 'auto',
                        maxWidth: '' + scaledImageWidth + 'px'
                      }}
                    />
                  </div>
                </div>
                <div className="image-preview-container image-preview-container--landscape" style={positionValue}>
                  <div className="logo-sample">
                    <img
                      src={logo || logo_white}
                      alt="Logo Sample"
                      style={{
                        opacity: Number(opacity) === 100 ? 1 : `0.${opacity}`,
                        height: 'auto',
                        maxHeight: '349px',
                        width: 'auto',
                        maxWidth: '' + scaledImageWidthLandscape + 'px'
                      }}
                    />
                  </div>
                </div>
              </div>
            </main>
            <footer className="center modal__footer">
              <button className="button button--large" onClick={this.close}>
                Done
              </button>
            </footer>
          </div>
        </aside>
      );
    }

    return null;
  }
}

PreviewModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  position: PropTypes.string,
  opacity: PropTypes.number
};

export default PreviewModal;
