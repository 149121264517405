import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { change } from 'redux-form';

import { ReactSelect } from '@/components/Shared/Forms';

const mapStateToProps = (state) => {
  const requesting = state.pricesheets.requesting;
  return { requesting };
};

const mapDispatchToProps = { change };

class GiveawayMain extends Component {
  static propTypes = {
    pricesheets: PropTypes.array
  };

  resetSelectedData = () => {
    this.props.change('OfferForm', 'required_products_attributes', [], true);
    this.props.change('OfferForm', 'free_products_attributes', [], true);
  };

  render() {
    const { requesting } = this.props;
    return (
      <div className="form-segment">
        <div className="flex">
          <div className="flex-6">
            <ReactSelect
              label="Select Type of Giveaway"
              name="freebie_type"
              placeholder="Select..."
              required={true}
              options={[
                { label: 'Free Product', value: 'free_product' },
                { label: 'BOGO', value: 'bogo' }
              ]}
            />
          </div>
          <div className="flex-6">
            <ReactSelect
              label="Select Price Sheet"
              name="price_sheet_id"
              placeholder="Select..."
              options={this.props.pricesheets}
              required={true}
              loading={requesting}
              onChange={this.resetSelectedData}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiveawayMain);
