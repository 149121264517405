import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Icons
import icon_settings_filled from '@/assets/images/icon-gear.png';
import icon_trash from '@/assets/images/icon-trash.png';

class OfferRow extends Component {
  static propTypes = {
    offer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
      description: PropTypes.string,
      redemptions: PropTypes.number,
      expiration: PropTypes.string,
      max_redemptions: PropTypes.number
    }).isRequired,
    offerType: PropTypes.string,
    onClickRemove: PropTypes.func,
    onClickSettings: PropTypes.func
  };

  handleSettingsClick = () => {
    const { offer, onClickSettings } = this.props;
    onClickSettings && onClickSettings(offer);
  };

  handleRemoveClick = () => {
    const { offer, onClickRemove } = this.props;
    onClickRemove && onClickRemove(offer);
  };

  render() {
    const { offer, canManageAdvancePayCredits } = this.props;
    const { name, description } = offer;

    return (
      <tr>
        <td className="text--bold">{canManageAdvancePayCredits ? <Link to={`advance-pay/${offer.id}`}>{name}</Link> : <span>{offer.name}</span>}</td>
        <td>{description}</td>
        {canManageAdvancePayCredits && (
          <td className="actions">
            <img src={icon_settings_filled} onClick={this.handleSettingsClick} className="offer-table-actions" alt="Settings" />
            <img src={icon_trash} onClick={this.handleRemoveClick} className="offer-table-actions" alt="Delete" />
          </td>
        )}
      </tr>
    );
  }
}

export default OfferRow;
