import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';

import { Switch } from '@/components/Shared/Forms';

const NPS_NOTIFICATIONS_ENABLED = import.meta.env.VITE_NPS_NOTIFICATIONS_ENABLED === '1';

class AccountNotificationForm extends Component {
  render() {
    const { loading, requesting, handleSubmit } = this.props;

    return (
      <section className="flex-12">
        <Form onSubmit={handleSubmit}>
          <header className="flex middle between user-container__header">
            <h2 className="text--nomargin">Notifications</h2>
            <button type="submit" className="button button--medium" disabled={requesting || loading}>
              Save
            </button>
          </header>
          <main className="panel panel--section">
            <Switch
              name="daily_order_notification"
              label={<h5 className="text--bold">Daily Order Summary</h5>}
              note="This email summary gets sent once daily if there are any orders placed that day."
            />
            {NPS_NOTIFICATIONS_ENABLED ? (
              <>
                <Switch
                  name="daily_nps_summary"
                  label={<h5 className="text--bold">Daily NPS Response Summary</h5>}
                  note="Receive an email daily if customers submit an NPS rating."
                />
                <Switch
                  name="weekly_nps_summary"
                  label={<h5 className="text--bold">Weekly NPS Response Summary</h5>}
                  note="Receive a weekly email summary if customers submit an NPS rating."
                />
              </>
            ) : null}
          </main>
        </Form>
      </section>
    );
  }
}

export default reduxForm({
  form: 'AccountNotificationForm',
  enableReinitialize: true,
  destroyOnUnmount: false
})(AccountNotificationForm);
