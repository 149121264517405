import PropTypes from 'prop-types';
import { Component } from 'react';

export default class DeleteOfferModal extends Component {
  static propTypes = {
    offer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { confirmText: '' };
  }

  cancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  };

  confirm = () => {
    const { onConfirm, offer } = this.props;

    let { confirmText } = this.state;

    if (confirmText.toLowerCase() === 'delete') {
      onConfirm && onConfirm(offer);
    }
  };

  handleInputOnChange = (event) => {
    this.setState({ confirmText: event.target.value });
  };

  handleInputOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.confirm();
    }
  };

  render() {
    const { offer } = this.props;
    const { name } = offer;

    return (
      <aside className="modal text--left">
        <div className="modal__box modal__box--small modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={this.cancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete an offer</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to delete this offer, <strong>{name}</strong>?
              <br />
              This action cannot be undone.
            </p>
            <p>
              Type <b>"delete"</b> to permanently delete this offer.
            </p>
            <input className="input--block" type="text" name="delete" value={this.state.confirmText} onChange={this.handleInputOnChange} maxLength="50" />
          </main>
          <footer className="center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={this.confirm}
              disabled={!(this.state.confirmText.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}
