import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';
import { Input } from '@/components/Shared/Forms/index';

class VerifyForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="sign-up-form">
        <div className="flex">
          <div className="flex-12">
            <Input label="Email Address" name="email" required email disabled={this.props.initialValues.email} />
          </div>
        </div>
        <button className="button button--block login-btn" type="submit">
          Resend Verification Email
        </button>
      </Form>
    );
  }
}

export default reduxForm({ form: 'VerifyForm', destroyOnUnmount: false })(VerifyForm);
