import { Component } from 'react';

// Redux
import { Field } from 'redux-form';

// Plugins
import DatePicker from 'react-datepicker';

// Helpers
import { InputGroup, Input } from '@/components/Shared/Forms';

class OfferOptions extends Component {
  state = {
    expirationDate: this.props.offer.expiration ? new Date(this.props.offer.expiration) : ''
  };

  renderHiddenInput = ({ input }) => {
    return <input {...input} type="hidden" className="input--block" />;
  };

  handleMinOrderPriceChange = (e) => {
    const { change } = this.props;

    let num = parseFloat(e.target.value);
    change('min_order_price_cents', !isNaN(num) ? Math.round(num * 100) : null);
  };

  handleExpirationDateChange = (date) => {
    const { change } = this.props;

    change('expiration', date);
    this.setState({ expirationDate: date });
  };

  render() {
    return (
      <div>
        <div className="flex flex-row">
          <div className="flex-4">
            <InputGroup
              name="min_order_price"
              label="Minimum Order Dollar Amount"
              placeholder="Optional"
              addonPosition="left"
              onChange={this.handleMinOrderPriceChange}
              number
              maxLength5
            >
              <span>$</span>
            </InputGroup>
          </div>
          <fieldset className="flex-4">
            <label>Offer Expiration Date</label>
            <DatePicker
              className="input--date input--block"
              name="expiration"
              minDate={new Date()}
              dateFormat="MM/dd/yy"
              placeholderText="MM/DD/YY"
              selected={this.state.expirationDate}
              onChange={this.handleExpirationDateChange}
              strictParsing
            />
            <strong className="valid-until">Valid until 11:59pm PST</strong>
          </fieldset>
          <div className="flex-4">
            <Input name="max_redemptions" label="Max Redemptions" placeholder="Optional" number maxLength10 />
          </div>
        </div>
        <p className="text--center">
          <small>
            * If planning to use this offer for AdvancePay, either don't assign an expiration date, or make sure expiration date goes beyond the gallery publish
            date to give customers time to purchase.
          </small>
        </p>
        <Field name="min_order_price_cents" component={this.renderHiddenInput} type="hidden" />
      </div>
    );
  }
}

export default OfferOptions;
