import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Types
interface SidebarProps {
  path: string;
  jobId: string;
  jobAccessMode: string;
}

const MARKETING_TIMELINE_ENABLED = import.meta.env.VITE_MARKETING_TIMELINE_ENABLED === '1';

const Sidebar: FC<SidebarProps> = ({ path, jobId, jobAccessMode }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const handleToggleSidebar = (): void => setSidebarIsOpen(!sidebarIsOpen);

  const setSectionTitle = (path: string) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    switch (section) {
      case 'getting-started':
        return 'Getting Started';
      case 'active-messages':
        return 'Active Messages';
      case 'flyers':
        return 'Manual Flyers';
      case 'promos':
        return 'Promos';
      case 'campaign-settings':
        return 'Auto Campaign Settings';
      default:
        return 'Getting Started';
    }
  };

  return (
    <aside className="flex-3 flex-12-md sidebar-container">
      <header className="flex middle between sidebar-header">
        <h2 className="text--nomargin">Marketing</h2>
        <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
          {path && setSectionTitle(path)}
        </button>
      </header>

      <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
        {MARKETING_TIMELINE_ENABLED === true && (
          <>
            <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/marketing/getting-started`} activeClassName="sidebar-navigation__item--active">
              Getting Started
            </NavLink>
            <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/marketing/active-messages`} activeClassName="sidebar-navigation__item--active">
              Active Messages
            </NavLink>
          </>
        )}
        <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/jobs/${jobId}/marketing/flyers`}>
          Manual Flyers
        </NavLink>
        <NavLink
          className="sidebar-navigation__item"
          to={{
            pathname: '/storefront/promos',
            state: { fromJobId: jobId }
          }}
        >
          Promos
        </NavLink>
        {jobAccessMode === 'access_per_subject' && (
          <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/jobs/${jobId}/marketing/campaign-settings`}>
            Auto Campaign Settings
          </NavLink>
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
