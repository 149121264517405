import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ jobId, title }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  return (
    <aside className="flex-3 flex-12-md sidebar-container">
      <header className="flex middle between sidebar-header">
        <h2 className="text--nomargin">Admin</h2>
        <button className="button button--outline sidebar__mobile-action" onClick={handleToggleSidebar}>
          {title}
        </button>
      </header>

      <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
        <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/jobs/${jobId}/admin/sms`}>
          SMS Sending
        </NavLink>
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  jobId: PropTypes.string.isRequired
};

export default Sidebar;
