import { FC, useState, useRef, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getJob, getPeopleList, getSidebarPeopleList, getPhotoTypeOptions, createPeopleBulkFeature } from '../../../actions';

// Plugins
import Select, { SingleValue } from 'react-select';

// Images
import imageBG from '@/assets/images/image-bulk-feature-bg.jpg';

// Styles
import './style.css';

// Types
import { SelectOptionType } from '@/types';

interface BulkFeatureProps {
  jobId: string;
  requesting: boolean;
  onShowBulkFeatureToggle: () => void;
}

const BulkFeature: FC<BulkFeatureProps> = ({ jobId, requesting, onShowBulkFeatureToggle }) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const { photoTypeOptions } = useSelector((state: any) => state.jobs);

  // Remove featured option from photo type options
  const filteredPhotoTypeOptions = photoTypeOptions.filter(({ value: optionValue }: SelectOptionType) => !optionValue?.includes('featured'));

  const [shouldOverwrite, setShouldOverwrite] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const [bulkType, setBulkType] = useState<SingleValue<SelectOptionType>>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFilterValue(value);
  };

  const handleSelectChange = (value: SingleValue<SelectOptionType>) => {
    if (value?.value === 'filename_filter') {
      inputRef?.current?.focus();
    }
    setFilterValue('');
    setBulkType(value);
  };

  const handleFeature = () => {
    if (bulkType?.value) {
      dispatch(
        createPeopleBulkFeature({ id: jobId, preference: bulkType.value, filename_filter: filterValue || null, overwrite_existing: shouldOverwrite }, () => {
          dispatch(getJob({ id: jobId }));
          dispatch(getPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));
          dispatch(getSidebarPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));

          onShowBulkFeatureToggle();
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getPhotoTypeOptions({ jobId }));
  }, []);

  return (
    <aside className="modal animate bulk-feature">
      <div className="modal__box">
        <div className="modal__content">
          <header className="bulk-feature__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={onShowBulkFeatureToggle}>
              <i className="icon-close"></i>
            </button>
            <img src={imageBG} alt="background" />
          </header>
          <main className="bulk-feature__main flex column middle">
            <h2>Set your featured photos</h2>
            <p>
              PhotoDay uses featured photos in flyers, subject galleries, and on job exports.
              <br /> Select an option below and PhotoDay will automatically do the featuring.
            </p>

            <div className="bulk-feature__input-group">
              <Select
                className="select bulk-feature__modal-select"
                classNamePrefix="select"
                placeholder="Select one..."
                value={bulkType}
                maxMenuHeight={400}
                options={filteredPhotoTypeOptions}
                onChange={handleSelectChange}
              />
              <input
                className={`bulk-feature__input ${bulkType?.value !== 'filename_filter' ? 'bulk-feature__input--hidden' : ''}`}
                ref={inputRef}
                type="text"
                value={filterValue}
                onChange={handleInputChange}
              />
            </div>
            <fieldset className="bulk-feature__fieldset">
              <input
                id="overwrite"
                className="hidden"
                type="checkbox"
                name="overwrite"
                checked={shouldOverwrite}
                onChange={() => setShouldOverwrite(!shouldOverwrite)}
              />
              <label htmlFor="overwrite" className={`label--checkbox label--lighter label--clean ${shouldOverwrite ? '' : 'text--grey'}`}>
                Override existing featured photos
              </label>
            </fieldset>

            <button
              className="button button--medium"
              disabled={!bulkType?.value || (bulkType?.value === 'filename_filter' && !filterValue)}
              onClick={handleFeature}
              data-loading={requesting}
            >
              Feature
            </button>
            <button className="button button--outline" type="button" onClick={onShowBulkFeatureToggle}>
              I want to manually feature photos
            </button>
          </main>
        </div>
      </div>
    </aside>
  );
};

export default BulkFeature;
