import { Component } from 'react';
import { Switch, NavLink, Redirect } from 'react-router-dom';

// Components
import Sales from './Sales';
import Marketing from './Marketing';
import PrivateRoute from '@/components/Shared/PrivateRoute';

class Collateral extends Component {
  state = {
    section: 'Sales Kit',
    sidebarIsOpen: false
  };

  componentDidMount = () => {
    this.setSectionTitle(this.props.location.pathname);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSectionTitle(this.props.location.pathname);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr.pop();

    if (section === 'sales' && this.state.section !== 'Sales Kit') {
      this.setState({ section: 'Sales Kit' });
    } else if (section === 'marketing' && this.state.section !== 'Marketing Kit') {
      this.setState({ section: 'Marketing Kit' });
    }
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  render() {
    return (
      <>
        <aside className="flex-3 flex-12-md sidebar-container">
          <header className="flex middle between sidebar-header">
            <h2 className="text--nomargin">Collateral</h2>
            <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
              {this.state.section}
            </button>
          </header>

          <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
            <NavLink className="sidebar-navigation__item" to={`/collateral/sales`} activeClassName="sidebar-navigation__item--active">
              Sales Kit
            </NavLink>
            <NavLink className="sidebar-navigation__item" to={`/collateral/marketing`} activeClassName="sidebar-navigation__item--active">
              Marketing Kit
            </NavLink>
          </nav>
        </aside>

        <section className="flex-9 flex-12-md resources-container">
          <Switch>
            <Redirect exact from="/collateral" to="/collateral/sales" />
            <PrivateRoute exact path="/collateral/sales" component={Sales} />
            <PrivateRoute exact path="/collateral/marketing" component={Marketing} />
          </Switch>
        </section>
      </>
    );
  }
}

export default Collateral;
