import { connect } from 'react-redux';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import TopNav from '@/components/Shared/TopNav';
import Navigation from '../../../../Storefront/Navigation';

// Helpers
import { getStripeCode, getStripeClientId } from './selectors';
import { updateStripeAccountRequest } from '../../../../Settings/actions';

const mapStateToProps = (state, ownProps) => {
  const code = getStripeCode(ownProps);
  const stripeClientId = getStripeClientId(state);

  const {
    login: {
      studio: { id: studioId }
    }
  } = state;

  return {
    code,
    stripeClientId,
    studioId,
    successful: state.users.successful,
    isError: state.users.errors.length > 0
  };
};

const mapDispatchToProps = { updateStripeAccountRequest };

class StripeContainer extends Component {
  componentDidMount() {
    const { studioId, code, updateStripeAccountRequest } = this.props;
    if (code) updateStripeAccountRequest({ studioId, code });
  }

  render() {
    const { code, stripeClientId, isError, successful } = this.props;
    return (
      <div>
        <TopNav />
        <Navigation />

        <div className="container">{(isError || !code || successful || stripeClientId) && <Redirect to="/user/my-studio/payment" />}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeContainer);
