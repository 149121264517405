import { Component } from 'react';
import { Input } from '@/components/Shared/Forms';

class CreditSheet extends Component {
  render() {
    return (
      <div className="form-segment">
        <div className="flex">
          <div className=" flex-12">
            <Input required name="name" label="Credit Sheet Name" placeholder="Give your credit sheet a name" />
          </div>
        </div>
      </div>
    );
  }
}

export default CreditSheet;
