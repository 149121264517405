import { Component } from 'react';
import { Form, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Input } from '@/components/Shared/Forms/index';

class CreateAccountForm extends Component {
  state = {
    showPassword: false
  };

  render() {
    const { handleSubmit, disableEmail, initialValues, requesting, policies_agreed } = this.props;
    const { showPassword } = this.state;

    let errors = initialValues.errors || [];
    let errorMessage = '';

    return (
      <Form onSubmit={handleSubmit} className="sign-up-form">
        <div className="flex">
          <div className="flex-12 mb-20">
            <div className={`email-input-wrapper ${errors.length && 'has-error'}`}>
              <Input label="Email" name="user.email" required email disabled={disableEmail} />

              {errors.length
                ? errors.map((err, key) => {
                    errorMessage = err.body.error === 'Validation failed: Email has already been taken' ? 'This email already exists' : null;

                    return errorMessage ? (
                      <small key={key} className="text--block text--danger input-error">
                        {errorMessage}
                      </small>
                    ) : null;
                  })
                : null}
            </div>
          </div>
          <div className="flex-12">
            <Input label="Create PhotoDay Password" name="user.password" required type={showPassword ? 'text' : 'password'} autoComplete="new-password" />
          </div>
        </div>
        <div className="flex end">
          <fieldset>
            <input
              id="showPassword"
              className="hidden"
              type="checkbox"
              name="showPassword"
              checked={showPassword}
              onChange={() => this.setState({ showPassword: !showPassword })}
            />
            <label htmlFor="showPassword" className="label--checkbox label--clean">
              Show Password
            </label>
          </fieldset>
        </div>

        <div className="create-account-disclaimer">
          <Field name="user.policies_agreed" id="user.policies_agreed" className="hidden" component="input" type="checkbox" />
          <label htmlFor="user.policies_agreed" className=" label--checkbox label--clean label--paragraph">
            By creating an account with PhotoDay, you agree to our{' '}
            <a href="https://photoday.io/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.photoday.io/brand_guidelines/" target="_blank" rel="noopener noreferrer">
              Brand Guidelines
            </a>
            .
          </label>
        </div>
        <button className="button button--block login-btn" type="submit" disabled={requesting || !policies_agreed} data-loading={requesting}>
          Create Account
        </button>
      </Form>
    );
  }
}

CreateAccountForm.propTypes = {
  disableEmail: PropTypes.bool
};

CreateAccountForm.defaultProps = {
  disableEmail: false
};

export default reduxForm({ form: 'CreateAccountForm', destroyOnUnmount: false })(CreateAccountForm);
