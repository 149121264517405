import { Component } from 'react';
import { connect } from 'react-redux';

import PhotoDayGrid from '@/components/Shared/PhotodayGrid';
import OfferRow from './OfferRow';
import CreateOrEditOfferModal from './CreateOrEditOfferModal';
import DeleteOfferModal from './DeleteOfferModal';

import { getPricesheetListRequest } from '../PriceSheets/actions';
import { offerListRequest, destroyOfferRequest, createOfferRequest, updateOfferRequest } from './actions';
import { pricesheetsToOptions, getOfferType } from './selectors';

import SafeLink from '@/components/Shared/Link';

import './offers.css';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      user,
      studio: { id: studioId }
    },
    users: { studio }
  } = state;

  const roles = (user && user.roles) || [];
  const offerType = getOfferType(ownProps);
  const pricesheets = pricesheetsToOptions(state);
  const lab = { id: studio.lab_id, name: studio.lab_name };

  return {
    studioId,
    lab,
    roles,
    pricesheets,
    offerType,
    ...state.offers
  };
};

const mapDispatchToProps = {
  offerListRequest,
  getPricesheetListRequest,
  destroyOfferRequest,
  createOfferRequest,
  updateOfferRequest
};

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOffer: null,
      showOfferCreateOrEditModal: false,
      showOfferRemoveModal: false
    };
  }

  componentDidMount() {
    const { offerListRequest, getPricesheetListRequest, offerType } = this.props;

    getPricesheetListRequest({
      page: 1,
      perPage: 1000,
      order: 'name',
      dir: 'ASC'
    });
    offerListRequest({
      page: 1,
      perPage: 15,
      order: 'created_at',
      dir: 'DESC',
      search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
      searchField: 'offer_type'
    });
  }

  fetchOffersPage = (pager) => {
    const { offerListRequest, offerType } = this.props;

    offerListRequest(
      {
        ...pager,
        search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
        searchField: 'offer_type'
      },
      (payload) => {
        const { pager, result } = payload;
        const { page, totalPages } = pager;

        if (!result.length && page > 1) {
          offerListRequest({
            ...pager,
            page: Math.min(page - 1, totalPages),
            search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
            searchField: 'offer_type'
          });
        }
      }
    );
  };

  handleOfferCreateOrEditDialogSave = (offer) => {
    const { studioId, lab, pager } = this.props;
    const { createOfferRequest, updateOfferRequest } = this.props;

    if (offer.id) {
      updateOfferRequest({ offer }, () => {
        this.handleOfferDialogsCancel();
        this.fetchOffersPage(pager);
      });
    } else {
      createOfferRequest({ studioId, offer: { ...offer, lab_id: lab.id } }, () => {
        this.handleOfferDialogsCancel();
        this.fetchOffersPage(pager);
      });
    }
  };

  handleOfferDialogsCancel = () => {
    this.setState({
      activeOffer: null,
      showOfferCreateOrEditModal: false,
      showOfferRemoveModal: false
    });
  };

  handleDeleteOfferDialogConfirm = (offer) => {
    const { destroyOfferRequest, pager } = this.props;

    destroyOfferRequest({ offerId: offer.id }, () => {
      this.handleOfferDialogsCancel();
      this.fetchOffersPage(pager);
    });
  };

  handleAddOfferClick = () => {
    this.setState({
      activeOffer: {},
      showOfferCreateOrEditModal: true
    });
  };

  handleOfferGridRemoveAction = (offer) => {
    this.setState({
      activeOffer: Object.assign({}, offer),
      showOfferRemoveModal: true
    });
  };

  handleOfferGridSettingsAction = (offer) => {
    this.setState({
      activeOffer: Object.assign({}, offer),
      showOfferCreateOrEditModal: true
    });
  };

  render() {
    const { studioId, result, roles, entities, pager, pricesheets, requesting, offerType } = this.props;
    const { showOfferCreateOrEditModal, showOfferRemoveModal, activeOffer } = this.state;

    // Permissions
    const canManageOffers = roles.includes('manage_offers');

    const gridHeader = [
      {
        fieldName: 'name',
        displayName: 'Name',
        sortable: true
      },
      {
        fieldName: 'code',
        displayName: 'Code',
        sortable: true
      },
      {
        fieldName: 'description',
        displayName: 'Offer',
        sortable: false
      },
      {
        fieldName: 'expiration',
        displayName: 'Expires',
        sortable: true
      },
      {
        fieldName: 'redemptions',
        displayName: 'Redemptions',
        sortable: false
      },
      {
        fieldName: null,
        displayName: 'Actions',
        sortable: false
      }
    ].filter((header) => {
      if (header.displayName === 'Actions' && !canManageOffers) {
        return null;
      }
      return header;
    });

    let offersAndCreditsTypes = {
      discounts: {
        type: 'discounts',
        title: 'Discounts',
        subtitle: '',
        button: 'Create a Discount',
        colorClass: 'bright-green-border'
      },
      freebie: {
        type: 'freebie',
        title: 'Giveaways',
        subtitle: '',
        button: 'Create a Giveaway',
        colorClass: 'pink-border'
      },
      shipping: {
        type: 'shipping',
        title: 'Shipping',
        subtitle: 'Create offers to add to your price sheets.',
        button: 'Create a Shipping Offer',
        colorClass: 'blue-border'
      }
    };

    const offerDetail = offersAndCreditsTypes[offerType];

    return (
      <div id="storefront__offers">
        {showOfferCreateOrEditModal && (
          <CreateOrEditOfferModal
            offer={activeOffer}
            offerType={offerType}
            pricesheets={pricesheets}
            onSave={this.handleOfferCreateOrEditDialogSave}
            onCancel={this.handleOfferDialogsCancel}
          />
        )}
        {showOfferRemoveModal && activeOffer && (
          <DeleteOfferModal offer={activeOffer} onConfirm={this.handleDeleteOfferDialogConfirm} onCancel={this.handleOfferDialogsCancel} />
        )}

        <div className="flex middle between">
          {offerDetail && (
            <div>
              <h3 className="text--bold">{offerDetail.title}</h3>
              <p>{offerDetail.subtitle}</p>
            </div>
          )}
          {canManageOffers && studioId !== 'all-studios' && (
            <div>
              <SafeLink className="button" Component="button" disabled={requesting} onClick={() => this.handleAddOfferClick(offerDetail.type)}>
                {offerDetail.button}
              </SafeLink>
            </div>
          )}
        </div>

        <PhotoDayGrid
          headers={gridHeader}
          table_id="offer-table"
          extraClasses="offer-table"
          pager={pager}
          fetchRecordsPage={this.fetchOffersPage}
          borderClass={`${offerDetail.colorClass} color-border`}
          requesting={requesting}
          defaultContent="You have not added any offers yet."
        >
          {result.map((id) => (
            <OfferRow
              key={id}
              offerType={offerType}
              offer={entities.offers[id]}
              canManageOffers={canManageOffers}
              onClickRemove={this.handleOfferGridRemoveAction}
              onClickSettings={this.handleOfferGridSettingsAction}
            />
          ))}
        </PhotoDayGrid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
