import { FC } from 'react';

interface LogoErrorModalProps {
  logoErrorMessage: string;
  onModalClose: () => void;
}

const LogoErrorModal: FC<LogoErrorModalProps> = ({ logoErrorMessage, onModalClose }) => {
  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--small">
        <header className="text--left mb-30">
          <h2>Organization Logo Upload Error</h2>
        </header>
        <div className="modal__content flex column">
          {logoErrorMessage && <p className="text--left text--xlarge">{logoErrorMessage}</p>}
          <p className="text--left text--xlarge">You'll need to make sure your logo is of optimal quality. We suggest a RGB, PNG file that's 600px x 600px.</p>
          <p className="text--left text--xlarge"> Please check your logo and try again.</p>
        </div>
        <footer className="modal__footer mt-30 flex end">
          <button className="button button--outline" type="button" name="close" onClick={onModalClose}>
            Close
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default LogoErrorModal;
