import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Component } from 'react';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Images
import icon_settings_filled from '@/assets/images/icon-gear.png';
import icon_duplicate from '@/assets/images/icon-duplicate.png';
import icon_trash from '@/assets/images/icon-trash.png';

const VITE_BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';

class PriceSheetsRow extends Component {
  constructor(props) {
    super(props);
    const { priceSheet } = props;

    const priceSheetNewAttr = {
      action: 'create',
      id: ''
    };

    const newPricesheet = Object.assign({}, priceSheet, priceSheetNewAttr);

    this.state = {
      priceSheet: newPricesheet,
      duplicating: false
    };
  }

  handleClone = () => {
    const { priceSheet, cloneFunc } = this.props;
    this.setState({ duplicating: true });
    cloneFunc(priceSheet.id);
  };

  render() {
    const { priceSheet, canManagePriceSheet, deleteFunc } = this.props;
    const priceSheetCreated = shortDate(priceSheet.created_at);

    return (
      <tr>
        <td>{priceSheetCreated}</td>
        <td>
          {canManagePriceSheet ? (
            <Link to={`price-sheets/${priceSheet.id}`}>
              <b>{priceSheet.name}</b>
            </Link>
          ) : (
            <span>{priceSheet.name}</span>
          )}
        </td>
        <td>{priceSheet.lab ? priceSheet.lab.name : ''}</td>
        {VITE_BULK_SHIPPING_ENABLED && (
          <td>{{ drop_shipping: 'Drop', bulk_shipping: 'Bulk', drop_or_bulk_shipping: 'Drop & Bulk' }[priceSheet.shipping_type]}</td>
        )}

        {canManagePriceSheet && (
          <td className="actions">
            <Link to={`price-sheets/${priceSheet.id}`}>
              <img src={icon_settings_filled} className="pricesheet-table-actions" alt="edit" />
            </Link>
            {this.state.duplicating && <span className="loader" />}
            {!this.state.duplicating && <img src={icon_duplicate} onClick={this.handleClone} className="pricesheet-table-actions" alt="duplicate" />}
            <img src={icon_trash} onClick={() => deleteFunc(priceSheet)} className="pricesheet-table-actions" alt="delete" />
          </td>
        )}
      </tr>
    );
  }
}

PriceSheetsRow.propTypes = {
  priceSheet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price_sheet_type: PropTypes.string,
    lab: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  deleteFunc: PropTypes.func.isRequired,
  cloneFunc: PropTypes.func.isRequired
};

export default PriceSheetsRow;
