import { FC, useState } from 'react';

// Redux
import { createJobExport } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Types
interface ExportCreateProps {
  jobId: string;
  history: { push: (location: string | { search: string }) => void };
  onExportCreateModalClose: () => void;
}

const ExportCreate: FC<ExportCreateProps> = ({ history, jobId, onExportCreateModalClose }) => {
  const dispatch = useDispatch();

  const { requesting } = useSelector((state: any) => state.jobs);

  const [exportName, setExportName] = useState<string>('');

  const handleExportCreate = () => {
    dispatch(
      createJobExport({ jobId, name: exportName }, (response: { data: { id: string } }) => {
        const { data } = response;

        history.push(`/jobs/${jobId}/services/exports/${data.id}/settings`);
      })
    );
  };

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--dark modal__box--xsmall">
        <header className="modal__header text--left">
          <button className="button button--action modal__close" name="button" type="button" onClick={onExportCreateModalClose}>
            <i className="icon-close"></i>
          </button>
          <h3 className="text--nomargin">Export Name</h3>
          <span className="text--xsmall">Give your export a unique name.</span>
        </header>
        <main className="modal__content">
          <input
            className="input--block"
            type="text"
            name="name"
            value={exportName}
            onChange={({ target }) => setExportName(target.value)}
            placeholder="Give this export a name"
            maxLength={50}
          />
        </main>
        <footer className="modal__footer modal__footer--fixed">
          <button
            className="button button--center button--large"
            name="create"
            type="button"
            data-loading={requesting}
            onClick={handleExportCreate}
            disabled={exportName === '' || requesting === true}
          >
            Create
          </button>
          <hr />
          <p className="text--nomargin">
            <small>
              Need help? Check out our{' '}
              <a
                href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                target="_blank"
                rel="noopener noreferrer"
              >
                support article on export files
              </a>
              .
            </small>
          </p>
        </footer>
      </div>
    </aside>
  );
};

export default ExportCreate;
