import { Component } from 'react';

import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

import './container.css';
import PropTypes from 'prop-types';

class Container extends Component {
  render() {
    const { children, labImage, title, subtitle } = this.props;

    return (
      <div id="register" className="onboarding-bg">
        <img src={logo} className="gray-logo" alt="Logo" />

        <div className="container-fluid flex-page-centered">
          <div className="white-container flex-9 login-screen">
            <div className="left-onboarding-container">
              <div>
                <div className="register-logos">
                  <img src={logo_oto_hair} alt="PhotoDay Logo" />
                  {!!labImage && <p>+</p>}
                  {!!labImage && <img src={labImage} alt="Lab Logo" />}
                </div>
                <h1 className="text--bold"> {title} </h1>
                <p> {subtitle} </p>
              </div>
            </div>
            <div className="right-onboarding-container">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

Container.propTypes = {
  labImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

Container.defaultProps = {
  title: 'Hi There!',
  subtitle: "You're just seconds away from using PhotoDay."
};

export default Container;
