import { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'lodash';

// Styles
import './style.css';

class SearchBox extends Component {
  static WAIT_INTERVAL = 750;
  static ENTER_KEY = 13;

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    requesting: PropTypes.bool
  };

  static defaultProps = {
    placeholder: null,
    requesting: false
  };

  constructor(props) {
    super(props);

    this.triggerChange = debounce(this.triggerChange, SearchBox.WAIT_INTERVAL);

    this.state = { value: props.value };
  }

  handleChange = (event) => {
    event.persist();
    this.setState({ value: event.target.value }, this.triggerChange);
  };

  // handle submit form on "Enter"
  handleKeyDown = (evt) => evt.keyCode === SearchBox.ENTER_KEY && this.props.onChange(this.state.value);

  triggerChange = () => this.props.onChange(this.state.value);

  render() {
    const { children, requesting, placeholder } = this.props;
    return (
      <div className="search-and-btn">
        <div className="nav-search-field">
          <input
            type="text"
            placeholder={placeholder}
            className="input--block"
            value={this.state.value || ''}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          <div className="button--icon">
            {!requesting && <i className="fa fa-search" aria-hidden="true" />}
            {requesting && <i className="fa fa-spinner fa-spin" aria-hidden="true" />}
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default SearchBox;
