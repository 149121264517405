import { FC } from 'react';

// Plugins
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { Tooltip } from 'react-tippy';

// Types
import { PriceSheetFieldValues, PriceSheetProductDigitalBundle } from '@/types';

// Constants
import { DIGITAL_BUNDLE_MAX_PRICE, DIGITAL_BUNDLE_MAX_TIERS, DIGITAL_BUNDLE_MAX_PHOTOS } from '../constants';

interface BundlePricing {
  bundleProp: string;
  bundleValue?: number | string | boolean;
  bundleTierValue?: { index: number; prop: string; value?: number };
}

interface DigitalProductSetupProps {
  priceFieldValues: PriceSheetFieldValues;
  priceFieldError?: string;
  digitalProductBundle: PriceSheetProductDigitalBundle;
  onPriceChange: (values: NumberFormatValues) => void;
  onBundlePricingTypeChange: ({ bundleType }: { bundleType?: PriceSheetProductDigitalBundle['digital_bundle_pricing_type'] }) => void;
  onBundlePricingChange: ({ bundleProp, bundleValue, bundleTierValue }: BundlePricing) => void;
  onBundleTierChange: ({ action, tierIndex }: { action: 'remove' | 'add'; tierIndex?: number }) => void;
}

const DigitalProductSetup: FC<DigitalProductSetupProps> = ({
  priceFieldValues,
  priceFieldError,
  digitalProductBundle,
  onPriceChange,
  onBundlePricingTypeChange,
  onBundlePricingChange,
  onBundleTierChange
}: DigitalProductSetupProps) => {
  const isRetailPriceValid = Number(priceFieldValues?.priceCents) > 0;

  return (
    <div className="animate">
      <h5>1. Single Digital Download Price</h5>
      <p>
        <small>Confirm the retail price for a single high-res digital download.</small>
      </p>

      {/* Retail Pricing */}
      <section className="flex between">
        <fieldset className="flex-2 fieldset--clean">
          <label>Photos</label>
          <input className="input--clean" type="number" name="photos" value={1} disabled />
        </fieldset>
        <fieldset className="flex flex-6 flex-8-sm nowrap gap-10 fieldset--clean">
          <div>
            <label>Unit Price</label>
            <NumericFormat className="input--clean" value={priceFieldValues.baseCostText} prefix={'$'} decimalScale={2} disabled={true} />
          </div>
          <div>
            <label>PD Fee</label>
            <NumericFormat className="input--clean" value={priceFieldValues.photoDayFeeText} prefix={'$'} decimalScale={2} disabled={true} />
          </div>
          <div>
            <label>Retail Price</label>
            <NumericFormat
              className="input--clean"
              value={priceFieldValues.formattedValue}
              prefix={'$'}
              decimalScale={2}
              allowNegative={false}
              isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PRICE || floatValue === undefined}
              onValueChange={onPriceChange}
            />
          </div>
        </fieldset>
      </section>

      <hr />

      <h5>2. Digital Bundle (optional)</h5>

      {/* Tiered Pricing */}
      <section className="mt-20">
        <fieldset className="flex middle gap-10 mb-10">
          <h5 className="text--nomargin">Tiered Pricing</h5>
          <Tooltip
            {...{
              title: 'Enter a single digital retail price to enable',
              distance: 5,
              position: 'top',
              trigger: 'mouseenter',
              arrow: true,
              disabled: isRetailPriceValid
            }}
          >
            <input
              id="variablePricing"
              className="hidden"
              type="checkbox"
              checked={digitalProductBundle?.digital_bundle_pricing_type === 'digitals_tiered_pricing'}
              onChange={({ target }) => onBundlePricingTypeChange({ bundleType: target?.checked ? 'digitals_tiered_pricing' : undefined })}
              disabled={!isRetailPriceValid}
            />
            <label htmlFor="variablePricing" className="label-switch label-switch--small" />
          </Tooltip>
        </fieldset>
        <p>
          <small>
            Offer multiple high-res digital downloads by setting the number of photos selected and the price per photo (PPP) or percent discount. Add up to{' '}
            {DIGITAL_BUNDLE_MAX_TIERS} variable tiers.
          </small>
        </p>
        {digitalProductBundle?.digital_bundle_pricing_type === 'digitals_tiered_pricing' && (
          <aside className="mt-20 animate">
            <header className="flex hidden--sm">
              <label className="flex-6">Photos</label>
              <label className="flex-2">PPP</label>
              <label className="flex-2">Percent</label>
            </header>

            {/* Bundles */}
            {digitalProductBundle?.digital_bundle_tiers_attributes
              ?.filter((tier) => !tier._destroy)
              .map((tier, tierIndex, tierArray) => (
                <fieldset key={tierIndex} className="flex between mb-10">
                  <div className="flex flex-4 nowrap text--nowrap middle gap-10">
                    <NumericFormat
                      className="input--clean input--auto"
                      decimalScale={0}
                      value={tier?.min_qty}
                      allowNegative={false}
                      disabled={tierIndex > 0}
                      isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                      onValueChange={({ floatValue }) =>
                        onBundlePricingChange({
                          bundleProp: 'digital_bundle_tiers_attributes',
                          bundleTierValue: { index: tierIndex, prop: 'min_qty', value: floatValue }
                        })
                      }
                    />
                    <span>to</span>
                    <NumericFormat
                      className="input--clean input--auto"
                      decimalScale={0}
                      value={tier?.max_qty}
                      allowNegative={false}
                      isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                      onValueChange={({ floatValue }) =>
                        onBundlePricingChange({
                          bundleProp: 'digital_bundle_tiers_attributes',
                          bundleTierValue: { index: tierIndex, prop: 'max_qty', value: floatValue }
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-6 flex-8-sm nowrap gap-10">
                    <NumericFormat
                      className="input--clean"
                      prefix={'$'}
                      decimalScale={2}
                      value={tier?.price_cents}
                      onChange={({ target }: { target: { value: string } }) =>
                        onBundlePricingChange({
                          bundleProp: 'digital_bundle_tiers_attributes',
                          bundleTierValue: { index: tierIndex, prop: 'price_cents', value: Number(target?.value?.replace('$', '')) }
                        })
                      }
                    />
                    <NumericFormat
                      className="input--clean"
                      suffix={'%'}
                      decimalScale={2}
                      value={tier?.discount}
                      onChange={({ target }: { target: { value: string } }) =>
                        onBundlePricingChange({
                          bundleProp: 'digital_bundle_tiers_attributes',
                          bundleTierValue: { index: tierIndex, prop: 'discount', value: Number(target?.value?.replace('%', '')) }
                        })
                      }
                    />
                    <button
                      className={`button button--outline button--small ${tierIndex > 0 && tierIndex === tierArray.length - 1 ? '' : 'transparent'}`}
                      type="button"
                      name="remove"
                      onClick={() => onBundleTierChange({ action: 'remove', tierIndex })}
                    >
                      <i className="icon-trash"></i>
                    </button>
                    <button
                      className={`button button--outline button--small ${
                        tierIndex === tierArray.length - 1 && tierIndex < 4 && tier?.max_qty < DIGITAL_BUNDLE_MAX_PHOTOS && tier?.price_cents
                          ? ''
                          : 'transparent'
                      }`}
                      type="button"
                      name="add"
                      onClick={() => onBundleTierChange({ action: 'add' })}
                    >
                      <i className="icon-add"></i>
                    </button>
                  </div>
                </fieldset>
              ))}
          </aside>
        )}
      </section>

      <hr />

      {/* Buy All Pricing */}
      <section className="mb-40">
        <fieldset className="flex middle gap-10 mb-10">
          <h5 className="text--nomargin">Max Price</h5>
          <Tooltip
            {...{
              title: 'Enter a single digital retail price to enable',
              distance: 5,
              position: 'top',
              trigger: 'mouseenter',
              arrow: true,
              disabled: isRetailPriceValid
            }}
          >
            <input
              id="fixedPricing"
              className="hidden"
              type="checkbox"
              checked={digitalProductBundle?.digital_bundle_pricing_type === 'digitals_buy_all_pricing'}
              onChange={({ target }) => onBundlePricingTypeChange({ bundleType: target?.checked ? 'digitals_buy_all_pricing' : undefined })}
              disabled={!isRetailPriceValid}
            />
            <label htmlFor="fixedPricing" className="label-switch label-switch--small" />
          </Tooltip>
        </fieldset>
        <p>
          <small>
            Offer a bundle of high-res digital downloads at a fixed discounted price. Set a max number of allowed digitals and a max retail price.{' '}
            <a href="https://support.photoday.io/en/articles/8260763-how-do-i-set-up-digital-bundles" target="_blank" rel="noopener noreferrer">
              Learn More
            </a>
          </small>
        </p>
        {digitalProductBundle?.digital_bundle_pricing_type === 'digitals_buy_all_pricing' && (
          <aside className="flex between mt-20 animate">
            <p className="text--xsmall text--italic">
              <strong>Example</strong>
              <br />
              Max Allowed Digitals: 10 &nbsp;&nbsp;&nbsp;&nbsp; Max Retail Price: $100
            </p>
            <p className="text--xsmall text--italic">
              Since the Max Retail Price is $100 and price per download is $20, customers purchasing 5-10 downloads will pay the bundled Max Retail Price.
              Customers purchasing 1-4 downloads will pay the retail price of $20 per download.
            </p>
            <fieldset className="flex-12">
              <label className="label--block">Max Allowed Digitals</label>
              <NumericFormat
                className="input--auto"
                decimalScale={0}
                value={digitalProductBundle?.digital_bundle_max_qty}
                isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                allowNegative={false}
                onValueChange={({ floatValue }) =>
                  onBundlePricingChange({
                    bundleProp: 'digital_bundle_max_qty',
                    bundleValue: floatValue
                  })
                }
              />
            </fieldset>
            <fieldset className="flex-12 fieldset--clean">
              <label className="label--block">Max Retail Price</label>
              <NumericFormat
                className="input--auto"
                prefix={'$'}
                decimalScale={2}
                value={digitalProductBundle?.digital_bundle_max_price_cents}
                isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PRICE || floatValue === undefined}
                onValueChange={({ floatValue }) =>
                  onBundlePricingChange({
                    bundleProp: 'digital_bundle_max_price_cents',
                    bundleValue: floatValue
                  })
                }
              />
            </fieldset>
          </aside>
        )}
      </section>

      {priceFieldError && (
        <aside className="panel panel--error panel--neat animate">
          <p className="text--nomargin">{priceFieldError}</p>
        </aside>
      )}
    </div>
  );
};

export default DigitalProductSetup;
