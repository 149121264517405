import { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// Components
import Header from './Header';
import Studio from './Studio';
import Account from './Account';
import TopNav from '@/components/Shared/TopNav';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Helpers
import { getMyAccountRequest } from './actions';

// Styles
import './styles.css';

const mapStateToProps = (state) => ({
  user: state.login.user,
  studio: state.login.studio,
  account: state.users.account
});
const mapDispatchToProps = { getMyAccountRequest };

class SettingsRoute extends Component {
  componentDidMount() {
    const { account, getMyAccountRequest } = this.props;

    if (Object.keys(account).length === 0) {
      getMyAccountRequest();
    }
  }

  render() {
    return (
      <>
        <section className="user">
          <TopNav />
          <Header path={this.props.location.pathname} user={this.props.user} studio={this.props.studio} />

          <div className="box flex user-container">
            <Switch>
              <PrivateRoute path="/user/account" component={Account} />
              <PrivateRoute path="/user/my-studio" component={Studio} />
            </Switch>
          </div>
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsRoute);
