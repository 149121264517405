import { Component } from 'react';
import { connect } from 'react-redux';

import { updateMyAccountRequest } from '../../actions';

import SecurityForm from './SecurityForm';

const mapStateToProps = (state) => {
  const { requesting, account } = state.users;
  return { account, requesting };
};
const mapDispatchToProps = { updateMyAccountRequest };

class Account extends Component {
  saveAccount = (values) => this.props.updateMyAccountRequest(values);

  render() {
    const { requesting, loading } = this.props;

    return <SecurityForm onSubmit={this.saveAccount} requesting={requesting} loading={loading} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
