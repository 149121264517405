import PropTypes from 'prop-types';
import { Component } from 'react';
import Select from 'react-select';

export default class ReprintRequestModal extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { reprintReason: [], extra_info: '' };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSubmit = (requestInfo) => {
    const { extra_info, reprintReason } = this.state;

    requestInfo = {
      reason: reprintReason[0]?.value || '',
      extra_info: extra_info
    };

    this.props.onSubmit(requestInfo);
  };

  handleInputOnChange = (event) => {
    this.setState({ extra_info: event.target.value });
  };

  handleInputOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleSelectChange = (reprintReason) => {
    this.setState({ reprintReason: [{ label: reprintReason.label, value: reprintReason.value }] });
  };

  render() {
    const { reprintReason } = this.state;
    const selectedReprintReason = reprintReason[0] || { label: '', value: '' };

    return (
      <aside className="modal text--left">
        <div className="modal__box modal__box--small modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={this.handleCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Request Reprint</h3>
          </header>
          <main className="modal__content">
            <p>Please select the reason for your request:</p>
            <Select
              className="select"
              classNamePrefix="select"
              name="reprintReason"
              required={true}
              value={reprintReason}
              options={[
                { label: 'Damaged', value: 'damaged' },
                { label: 'Incorrect Items', value: 'incorrect_items' },
                { label: 'Never Arrived', value: 'never_arrived' },
                { label: 'Incorrect Image, Crop, or Customization', value: 'incorrect_image' }
              ]}
              onChange={(reprintReason) => this.handleSelectChange(reprintReason)}
            />
            {selectedReprintReason.value === 'incorrect_image' ? (
              <aside className="panel">
                <p className="text--nomargin">
                  Please note we cannot accommodate an automatic reprint that requires corrections to an image, crop, or customization. For these requests,
                  please reach out to our support team via the Bubble or at <a href="mailto:support@photoday.io">support@photoday.io</a>.
                </p>
              </aside>
            ) : (
              <>
                <p>Enter any additional information:</p>
                <textarea
                  className="input--block"
                  type="text"
                  name="additional-info"
                  rows={6}
                  value={this.state.extra_info}
                  onChange={this.handleInputOnChange}
                />
              </>
            )}
          </main>
          <footer className="center modal__footer">
            <button
              className="button button--large"
              name="submit"
              type="button"
              onClick={this.handleSubmit}
              disabled={!selectedReprintReason || selectedReprintReason.value === 'incorrect_image'}
            >
              Submit
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}
