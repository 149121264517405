import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

// Components
import Tip from '@/components/Shared/Tip';
import ProductsSelector from './ProductsSelector';

// Helpers
import ProfitCalculator from './ProfitCalculator';
import { selectInitialValuesFromPriceSheetItem } from './selectors';
import { convertToCents, convertToText } from '@/utils/currency';
import { updatePricesheetItemRequest, createPricesheetItemRequest } from './actions';
import { getProductCostByShippingType } from '@/utils/productCostByShippingType';

const AddEditPackage = ({ isVisible, requesting, onClose, initialValues, priceSheetId, shippingType, markup, pdFee, labId, currentSection }) => {
  const dispatch = useDispatch();
  const { entities } = useSelector((state) => state.productCategoriesDDL);

  const currentLabProducts = (entities[labId] && entities[labId].products) || {};

  const [initialSnapshot, setInitialSnapShot] = useState({});
  const [packageValues, setPackageValues] = useState({
    name: '',
    description: '',
    base_cost_cents: '',
    markup_cents: '',
    price_cents: '',
    products_attributes: []
  });

  const [stepOne, setStepOne] = useState(true);
  const [errors, setErrors] = useState({});
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [formattedValue, setFormattedValue] = useState('$0');
  const [confirmClose, setConfirmClose] = useState(false);
  const isPackagePresent = packageValues.id ? true : false;
  const productsAdded = packageValues.products_attributes;
  const [bulkProductsAdded, setBulkProductsAdded] = useState([]);

  const handleInputChange = (e) => {
    const input = e.target;

    setPackageValues({ ...packageValues, [input.name]: input.value });
  };

  const handleProductsAdd = (products) => {
    const newProductsAttributes = [...packageValues.products_attributes, ...products];

    calculateRetailFromCosts(newProductsAttributes);
  };

  const handleProductRemove = (index) => {
    const newProductsAttributes = packageValues.products_attributes;
    packageValues.products_attributes.splice(index, 1);

    calculateRetailFromCosts(newProductsAttributes);
  };

  const handleQuantityChange = ({ index, quantity }) => {
    const newProductsAttributes = packageValues.products_attributes;
    newProductsAttributes[index].quantity = quantity;

    calculateRetailFromCosts(newProductsAttributes);
  };

  const calculateRetailFromCosts = (products) => {
    const labPrice = products.reduce((result, { price_cents, quantity }) => result + price_cents * quantity, 0);
    const labPriceByShipping = products.reduce(
      (result, { price_cents, bulk_price_cents, quantity }) => result + getProductCostByShippingType(price_cents, bulk_price_cents, shippingType) * quantity,
      0
    );
    const photodayPrice = products.reduce((result, { photoday_price_cents, quantity }) => result + photoday_price_cents * quantity, 0);
    const baseCostCents = labPrice + photodayPrice;
    const baseCostCentsByShipping = labPriceByShipping + photodayPrice;

    const newDigitalProductsCount = products.filter((product) => product.is_digital_download).length;

    const calc = new ProfitCalculator({
      digitalProductsCount: newDigitalProductsCount,
      baseCostCents: baseCostCentsByShipping,
      markup: markup,
      pdFee: pdFee
    });

    const calcExisting = new ProfitCalculator({
      digitalProductsCount: newDigitalProductsCount,
      baseCostCents: baseCostCentsByShipping,
      priceCents: packageValues.price_cents,
      pdFee: pdFee
    });

    calc.calculate();
    if (isPackagePresent) calcExisting.calculate();

    // Existing packages: Check if there is enough markup based on the current price
    // If enough markup leave retail price as is
    // If not enough markup set price and markup to calculated price

    if (isPackagePresent && calcExisting.minimumPriceCents < packageValues.price_cents) {
      setPackageValues({
        ...packageValues,
        digitalProductsCount: newDigitalProductsCount,
        base_cost_cents: baseCostCents,
        markup_cents: packageValues.price_cents - calcExisting.baseCostCents,
        price_cents: packageValues.price_cents,
        products_attributes: products,
        minimumPriceCents: calc.minimumPriceCents,
        baseCostCentsByShipping: calc.baseCostCents
      });
    } else {
      setFormattedValue(convertToText(calc.priceCents, '$'));
      setPackageValues({
        ...packageValues,
        digitalProductsCount: newDigitalProductsCount,
        base_cost_cents: baseCostCents,
        markup_cents: calc.markupCents,
        price_cents: calc.priceCents,
        products_attributes: products,
        minimumPriceCents: calc.minimumPriceCents,
        baseCostCentsByShipping: calc.baseCostCents
      });
    }

    if (calc.priceCents < calc.baseCostCents) {
      setErrors({ ...errors, profit: "Profit can't be negative" });
    } else {
      setErrors({});
    }
  };

  const handlePriceChange = (input) => {
    const newErrors = {};
    const price = convertToCents(input.value);
    const calc = new ProfitCalculator({
      digitalProductsCount: packageValues.digitalProductsCount,
      baseCostCents: packageValues.baseCostCentsByShipping,
      priceCents: price,
      pdFee: pdFee
    });

    calc.calculate();

    setEnableSubmit(false);
    setFormattedValue(input.formattedValue);
    setPackageValues({
      ...packageValues,
      markup_cents: calc.markupCents,
      price_cents: calc.priceCents,
      minimumPriceCents: calc.minimumPriceCents,
      baseCostCentsByShipping: calc.baseCostCents
    });

    if (calc.priceCents < calc.minimumPriceCents) newErrors.profit = `Minimum retail price must be ${convertToText(calc.minimumPriceCents, '$')} or higher.`;

    setErrors(newErrors);
  };

  const handleValidate = () => {
    const newErrors = {};

    if (stepOne) {
      if (!packageValues.name) newErrors.name = 'Name Required';
      if (!packageValues.description) newErrors.description = 'Description Required';
    } else {
      if (packageValues.price_cents < packageValues.baseCostCentsByShipping) newErrors.profit = "Profit can't be negative.";
      if (packageValues.products_attributes.length < 2) newErrors.contents = 'Packages must contain at least 2 items.';
    }

    setErrors(newErrors);

    return Object.values(newErrors).length === 0 ? (stepOne ? setStepOne(false) : setEnableSubmit(true)) : null;
  };

  const checkForBulkProducts = () => {
    if (typeof currentLabProducts === 'object') {
      const bulkProducts = [];

      productsAdded.forEach((added) => {
        const bulkProducts = Object.values(currentLabProducts) || [];

        if (bulkProducts.length) {
          const bulkProduct = Object.values(currentLabProducts).find(
            (product) => product.id === added.id && product.bulk_price_cents > 0 && product.bulk_price_cents !== product.price_cents
          );

          bulkProduct && bulkProducts.every((product) => product.id !== bulkProduct.id) && bulkProducts.push(bulkProduct);
        }
      });

      setBulkProductsAdded(bulkProducts);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newList = [...productsAdded];
    const [removed] = newList.splice(result.source.index, 1);

    newList.splice(result.destination.index, 0, removed);

    setPackageValues({ ...packageValues, products_attributes: newList });
  };

  const handleClose = () => {
    const changesMade = JSON.stringify(initialSnapshot) !== JSON.stringify(packageValues);

    !changesMade && onClose();
    !confirmClose && setConfirmClose(true);
    confirmClose && onClose();
  };

  const handleSave = () => {
    const newPackageValues = { ...packageValues };
    delete newPackageValues.product_ids;

    isPackagePresent
      ? dispatch(updatePricesheetItemRequest(newPackageValues, () => onClose()))
      : dispatch(
          createPricesheetItemRequest(
            {
              priceSheetId,
              pricesheetItemInfo: {
                ...newPackageValues,
                price_sheet_item_type: 'package',
                price_sheet_id: priceSheetId
              }
            },
            () => onClose()
          )
        );
  };

  useEffect(() => {
    setEnableSubmit(false);

    checkForBulkProducts();
  }, [packageValues]);

  useEffect(() => {
    const selectInitialValues = selectInitialValuesFromPriceSheetItem(initialValues);
    const formattedValue = convertToText(initialValues.price_cents, '$');

    const calc = new ProfitCalculator({
      digitalProductsCount: selectInitialValues.digitalProductsCount,
      baseCostCents: getProductCostByShippingType(selectInitialValues.base_cost_cents, selectInitialValues.base_bulk_cost_cents, shippingType),
      priceCents: selectInitialValues.price_cents,
      pdFee: pdFee
    });

    calc.calculate();

    const newInitialValues = { ...selectInitialValues, base_cost_cents: calc.baseCostCents, minimumPriceCents: calc.minimumPriceCents };

    Object.values(initialValues).length && setPackageValues(newInitialValues);
    Object.values(initialValues).length ? setInitialSnapShot(newInitialValues) : setInitialSnapShot(packageValues);
    setFormattedValue(formattedValue);
  }, []);

  return (
    <>
      <aside className={`modal ${isVisible ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--secondary modal__box--xlarge">
          <button className="button button--action modal__close" name="close" type="button" onClick={handleClose}>
            <i className="icon-close"></i>
          </button>

          {stepOne ? (
            <main className="flex modal__content">
              <section className="flex-6 flex-12-sm modal__content-section">
                <h3>{isPackagePresent ? 'Edit Package' : 'Create A Package'}</h3>
                <div className="flex flex-row">
                  <fieldset className="flex column flex-12 fieldset--clean">
                    <label htmlFor="name">Package Name</label>
                    <input type="text" name="name" value={packageValues.name} required placeholder="i.e. Large Family Pack" onChange={handleInputChange} />
                    {errors?.name && (
                      <aside className="panel panel--error panel--neat animate">
                        <p className="text--nomargin">{errors.name}</p>
                      </aside>
                    )}
                  </fieldset>
                </div>

                <Tip
                  type="list"
                  list={[
                    'Give your package a catchy name.',
                    'Less products is more. Keep it simple!',
                    'Save specialty items for add-ons.',
                    <span>
                      Need inspiration?{' '}
                      <a href="https://blog.photoday.io/2020/11/23/rethinking-packages-in-an-online-world/" target="_blank" rel="noopener noreferrer">
                        Read our blog
                      </a>
                    </span>
                  ]}
                />
              </section>

              <section className="flex-6 flex-12-sm modal__content-section">
                <fieldset className="flex column flex-12">
                  <label htmlFor="description">Package Description</label>
                  <textarea
                    type="text"
                    name="description"
                    rows="6"
                    value={packageValues.description}
                    required
                    placeholder="i.e. Save 10% by ordering this package! Mix & match your photos. Get a FREE Social Sharing Digital!"
                    onChange={handleInputChange}
                  />
                  {errors?.description && (
                    <aside className="panel panel--error panel--neat animate">
                      <p className="text--nomargin">{errors.description}</p>
                    </aside>
                  )}
                </fieldset>
              </section>
            </main>
          ) : (
            <ProductsSelector
              pdFee={pdFee}
              labId={labId}
              errors={errors}
              isPackage={true}
              isPackagePresent={isPackagePresent}
              excludeProducts={[]}
              onDragEnd={onDragEnd}
              requesting={requesting}
              excludedCategories={[]}
              shippingType={shippingType}
              enableSubmit={enableSubmit}
              productsAdded={productsAdded}
              packageValues={packageValues}
              formattedValue={formattedValue}
              currentSection={currentSection}
              onProductsAdd={handleProductsAdd}
              onPriceChange={handlePriceChange}
              bulkProductsAdded={bulkProductsAdded}
              onProductRemove={handleProductRemove}
              onQuantityChange={handleQuantityChange}
            />
          )}

          <footer className="flex end modal__footer modal__footer--secondary">
            {stepOne && (
              <button className="button button--medium" onClick={handleValidate}>
                Next
              </button>
            )}

            {!stepOne && (
              <>
                <button className="button button--lean button--medium button--inline " onClick={() => setStepOne(true)}>
                  Back
                </button>

                {!enableSubmit ? (
                  <button
                    className="button button--medium"
                    disabled={requesting || Object.values(errors).length}
                    onClick={handleValidate}
                    data-loading={requesting}
                  >
                    Save
                  </button>
                ) : (
                  <button className="button button--medium" disabled={requesting} onClick={handleSave} data-loading={requesting}>
                    Save
                  </button>
                )}
              </>
            )}
          </footer>
        </div>
      </aside>

      {/* Confirm close modal */}
      <aside className={`modal modal__confirm-close ${confirmClose ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--secondary">
          <main className="modal__content flex column center middle">
            <p>Are you sure you want to close the window?</p>
            <small>This package will not be saved.</small>
            <button className="button button--medium button--outline" onClick={() => setConfirmClose(false)}>
              No, take me back
            </button>
            <button className="button button--medium button--clean" onClick={handleClose}>
              Yes, I'm sure
            </button>
          </main>
        </div>
      </aside>
    </>
  );
};

AddEditPackage.propTypes = {
  isVisible: PropTypes.bool,
  requesting: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  priceSheetId: PropTypes.string.isRequired,
  markup: PropTypes.object.isRequired,
  pdFee: PropTypes.number.isRequired,
  labId: PropTypes.string.isRequired,
  currentSection: PropTypes.string.isRequired
};

AddEditPackage.defaultProps = {
  isVisible: true,
  requesting: true,
  onClose: () => {},
  initialValues: {},
  priceSheetId: '',
  markup: {},
  pdFee: 10,
  labId: '',
  currentSection: ''
};

export default AddEditPackage;
