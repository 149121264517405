import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateMyAccountRequest } from '../../Settings/actions';
import { getAllCustomThemeLabs, deleteThemeCollection, getStudioThemeCollections, getPhotoDayThemeCollections } from './actions';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';

// Components
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import CollectionManager from './CollectionManager';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Images
import storefront_custom_themes from '@/assets/images/icon-custom-themes.png';
import image_custom_theme_splash from '@/assets/images/image-custom-theme-splash.jpg';

// Styles
import './style.css';

const CustomThemes = function CustomThemes({ location, history }) {
  const dispatch = useDispatch();

  const { collections, pdCollections, customThemeLabs, requesting, studioPagination, pdPagination } = useSelector((state) => state.customThemes);
  const {
    entities: { user: userList }
  } = useSelector((state) => state.login);

  const { page: queryPage } = queryString.parse(location.search);

  const user = Object.values(userList).length ? Object.values(userList)[0] : {};
  const roles = (user && user.roles) || [];
  const canManagePriceSheet = roles.includes('manage_price_sheets');
  const themePolicyAgreed = user?.themes_policy_agreed ? user['themes_policy_agreed'] : false;

  const [showCustomSplash, setShowCustomSplash] = useState(false);
  const [showCollectionManager, setShowCollectionManager] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [section, setSection] = useState('Studio Collections');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState('');
  const [selectCollection, setSelectCollection] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    order: 'created_at',
    dir: 'DESC',
    per_page: 15,
    page: queryPage || studioPagination.page
  });

  const handleshowCustomSplashToggle = () => setShowCustomSplash(!showCustomSplash);

  const handleOrderBy = (order, dir) => {
    setTableFilter({ ...tableFilter, order, dir });
  };

  const handlePagination = (page) => {
    history.push({ search: `?page=${page}` });
    setTableFilter({ ...tableFilter, page });
  };

  const handleManageTheme = (collectionId) => {
    !collectionId && setSection('Studio Collections');

    setShowCollectionManager(true);
    setSelectCollection(collectionId);
  };

  const handleManageThemeCancel = () => {
    setShowCollectionManager(false);
    setSelectCollection('');
  };

  const handleAcceptThemePolicy = () => {
    dispatch(updateMyAccountRequest({ themes_policy_agreed: true }, () => handleshowCustomSplashToggle()));
  };

  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);
  const handleDeleteCancel = () => {
    setSelectCollection('');
    setConfirmDelete('');
    setShowDelete(false);
  };
  const handleDeleteShow = (collectionId) => {
    setSelectCollection(collectionId);
    setShowDelete(true);
  };
  const handleDeleteCollection = () => {
    dispatch(
      deleteThemeCollection({ collectionId: selectCollection }, () => {
        dispatch(getStudioThemeCollections(tableFilter));
        handleDeleteCancel();
      })
    );
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const collapseMenu = (event) => {
    const newSection = event.target.innerHTML;

    setSection(newSection);
    setSidebarIsOpen(false);
  };

  useEffect(() => {
    if (!themePolicyAgreed) {
      handleshowCustomSplashToggle();
    }

    if (customThemeLabs.length === 0) {
      dispatch(getAllCustomThemeLabs({ custom_themes_enabled: true }));
    }

    dispatch(getPhotoDayThemeCollections());
  }, []);

  useEffect(() => {
    if (location.hash) {
      setSection(location.hash === '#photoday-collections' ? 'PhotoDay Collections' : 'Studio Collections');
    }
  }, [location]);

  useEffect(() => {
    dispatch(getStudioThemeCollections(tableFilter));
  }, [tableFilter]);

  return (
    <section className="flex">
      <aside className="flex-3 flex-12-md sidebar-container">
        <header className="flex middle between sidebar-header">
          <h2 className="text--nomargin">Custom Themes</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          <NavLink
            className={`sidebar-navigation__item ${section === 'Studio Collections' ? 'sidebar-navigation__item--active' : ''}`}
            to={`#studio-collections`}
            onClick={collapseMenu}
          >
            Studio Collections
          </NavLink>
          <NavLink
            className={`sidebar-navigation__item ${section === 'PhotoDay Collections' ? 'sidebar-navigation__item--active' : ''}`}
            to={'#photoday-collections'}
            onClick={collapseMenu}
          >
            PhotoDay Collections
          </NavLink>
        </nav>
      </aside>

      <div className="flex-9 flex-12-md">
        <aside className="custom-themes__header">
          <div className="flex column">
            <h2 className="text--nomargin">{section}</h2>
            <p className="text--nomargin">
              {section === 'Studio Collections'
                ? 'Create a collection and add customize themed products by uploading your own design.'
                : 'Preview themes created by the PhotoDay design team.'}
            </p>
          </div>
          {canManagePriceSheet && (
            <div className="flex between nowrap gap-10 custom-themes__header-action">
              <Dropdown buttonIcon="icon-important" buttonExtraClass={'button--lean button--small button-dropdown'}>
                <ul className="panel panel-dropdown panel-dropdown--large">
                  <li className="panel-dropdown__item">
                    <a href="https://support.photoday.io/en/articles/4156983-custom-themes" target="_blank" rel=" noopener noreferrer">
                      Download Template Guide
                    </a>
                  </li>
                  <li className="panel-dropdown__item">
                    <a href="https://support.photoday.io/en/articles/4157921-icc-profiles" target="_blank" rel=" noopener noreferrer">
                      Color Profile Tips
                    </a>
                  </li>
                  <li className="panel-dropdown__item">
                    <a href="https://support.photoday.io/en/articles/4157848-monitor-calibration" target="_blank" rel=" noopener noreferrer">
                      Monitor Calibration Tips
                    </a>
                  </li>
                </ul>
              </Dropdown>
              <Tooltip title="Add Collection" position="bottom" arrow="true" distance="30">
                <button className="button button--small button--lean" name="add" type="button" onClick={() => handleManageTheme('')}>
                  <i className="icon-add"></i>
                </button>
              </Tooltip>
            </div>
          )}
        </aside>
        {section === 'Studio Collections' ? (
          <>
            {!collections.length && !requesting ? (
              <div className="panel flex middle animate center custom-themes__empty-table">
                {canManagePriceSheet ? (
                  <p className="text--bold">
                    Add your first collection{' '}
                    <button className="button--link" type="button" onClick={() => handleManageTheme('')}>
                      here
                    </button>
                    .
                  </p>
                ) : (
                  <p className="text--bold">No collections found.</p>
                )}
              </div>
            ) : (
              <>
                <article className="table-box animate">
                  <table className="table">
                    <thead className="table__header">
                      <tr>
                        <th>
                          <Dropdown buttonName={'Collection Name'} buttonExtraClass={'button--filter'}>
                            <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('name', 'ASC');
                                }}
                              >
                                Ascending
                              </li>
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('name', 'DESC');
                                }}
                              >
                                Descending
                              </li>
                            </ul>
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown buttonName={'Lab'} buttonExtraClass={'button--filter'}>
                            <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'lab_id' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('lab_id', 'ASC');
                                }}
                              >
                                Ascending
                              </li>
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'lab_id' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('lab_id', 'DESC');
                                }}
                              >
                                Descending
                              </li>
                            </ul>
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown buttonName={'Created On'} buttonExtraClass={'button--filter'}>
                            <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'created_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('created_at', 'ASC');
                                }}
                              >
                                Ascending
                              </li>
                              <li
                                className={`panel-dropdown__item ${
                                  tableFilter.order === 'created_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                                }`}
                                onClick={() => {
                                  handleOrderBy('created_at', 'DESC');
                                }}
                              >
                                Descending
                              </li>
                            </ul>
                          </Dropdown>
                        </th>
                        <th># Of Themes</th>
                        {canManagePriceSheet && <th>Action</th>}
                      </tr>
                    </thead>

                    <tbody className="table__body">
                      {requesting && !collections.length ? (
                        <tr>
                          <td colSpan="5">
                            <TableLoader rows={4} />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {collections.map((collection) => (
                            <tr key={collection.id}>
                              <td data-header="Collection" className="text--capitalize text--nowrap">
                                {collection.name}
                              </td>
                              <td data-header="Lab" className="text--capitalize text--nowrap">
                                {collection.lab.name}
                              </td>
                              <td data-header="Created On" className="text--capitalize">
                                {moment(collection.created_at).format('MM/DD/YY')}
                              </td>
                              <td data-header="# of Themes" className="text--capitalize">
                                {collection.themes_count}
                              </td>

                              {canManagePriceSheet && (
                                <td data-header="Action" className="flex">
                                  <Tooltip title="Edit Collection" position="bottom" arrow="true" delay={700} distance="30">
                                    <button
                                      className="button button--clean button--small"
                                      name="button"
                                      type="button"
                                      onClick={() => handleManageTheme(collection.id)}
                                    >
                                      <i className="icon-edit" />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Delete Collection" position="bottom" arrow="true" delay={700} distance="30">
                                    <button
                                      className="button button--clean button--small"
                                      name="button"
                                      type="button"
                                      onClick={() => handleDeleteShow(collection.id)}
                                    >
                                      <i className="icon-trash" />
                                    </button>
                                  </Tooltip>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </article>
                {studioPagination.total > studioPagination.perPage && (
                  <Pagination pagination={studioPagination} onPagination={handlePagination} showPagesCount={4} />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <article className="table-box animate">
              <table className="table">
                <thead className="table__header">
                  <tr>
                    <th>Collection Name</th>
                    <th>Lab</th>
                    <th>Created On</th>
                    <th># Of Themes</th>
                    {canManagePriceSheet && <th>Action</th>}
                  </tr>
                </thead>

                <tbody className="table__body">
                  {requesting ? (
                    <tr>
                      <td colSpan="5">
                        <TableLoader rows={4} />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {pdCollections.length ? (
                        <>
                          {pdCollections.map((collection) => (
                            <tr key={collection.id}>
                              <td data-header="Collection" className="text--capitalize text--nowrap">
                                {collection.name}
                              </td>
                              <td data-header="Lab" className="text--capitalize text--nowrap">
                                {collection.lab.name}
                              </td>
                              <td data-header="Created On" className="text--capitalize">
                                {moment(collection.created_at).format('MM/DD/YY')}
                              </td>
                              <td data-header="# of Themes" className="text--capitalize">
                                {collection.themes_count}
                              </td>
                              {canManagePriceSheet && (
                                <td data-header="Action" className="flex">
                                  <Tooltip title="View Collection" position="bottom" arrow="true" delay={700} distance="30">
                                    <button
                                      className="button button--clean button--small"
                                      name="button"
                                      type="button"
                                      onClick={() => handleManageTheme(collection.id)}
                                    >
                                      <i className="icon-preview" />
                                    </button>
                                  </Tooltip>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr className="text--center">
                          <td colSpan="5">No PhotoDay themes were found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </article>
            {pdPagination.total > pdPagination.perPage && <Pagination pagination={pdPagination} onPagination={handlePagination} showPagesCount={4} />}
          </>
        )}

        {/* Theme Manager */}
        <CollectionManager
          showCollectionManager={showCollectionManager}
          selectCollection={selectCollection}
          onManageThemeCancel={handleManageThemeCancel}
          tableFilter={tableFilter}
        />

        {/* Delete modal */}
        <aside className={`modal ${showDelete ? '' : 'transparent'} text--left`}>
          <div className="modal__box modal__box--xsmall modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={handleDeleteCancel}>
                <i className="icon-close"></i>
              </button>
              <h3>Delete Collection</h3>
            </header>
            <main className="modal__content">
              <p>
                Are you sure you want to delete{' '}
                <strong>
                  {(selectCollection &&
                    collections.find((collection) => collection.id === selectCollection) &&
                    collections.find((collection) => collection.id === selectCollection)['name']) ||
                    'this'}
                </strong>{' '}
                collection?
              </p>
              <p>
                Type <b>"delete"</b> to permanently delete this job.
              </p>
              <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="50" />
            </main>
            <footer className="center modal__footer">
              <button
                className="button button--danger button--large"
                name="button"
                type="button"
                onClick={handleDeleteCollection}
                disabled={requesting || !(confirmDelete.toLowerCase() === 'delete')}
              >
                Delete
              </button>
            </footer>
          </div>
        </aside>

        {/* Custom template splash */}
        <aside className={`modal ${showCustomSplash ? '' : 'transparent'} custom-themes__splash`}>
          <div className="modal__box">
            <main className="flex nowrap modal__content custom-themes__splash-main">
              <figure className="custom-themes__splash-image">
                <img src={image_custom_theme_splash} className="hidden--sm" alt="color swatches" />
                <img src={storefront_custom_themes} className="hidden--lg" alt="color swatches" />
              </figure>
              <div className="custom-themes__splash-content">
                <h4 className="text--left text--tall">Custom Themes</h4>
                <p className="text--left">
                  Have some design skills? You can now customize the design of select themed products in PhotoDay! Before you get started, here are some
                  important tips that will help you design your own themes.
                </p>
                <br></br>
                <ul className="text--left">
                  <li>
                    Download{' '}
                    <a href="https://support.photoday.io/en/articles/4156983-custom-themes" target="_blank" rel=" noopener noreferrer">
                      the theme template guide
                    </a>{' '}
                    to get started.
                  </li>
                  <li>
                    Learn more about{' '}
                    <a href="https://support.photoday.io/en/articles/4157921-icc-profiles" target="_blank" rel=" noopener noreferrer">
                      color profiles
                    </a>{' '}
                    and{' '}
                    <a href="https://support.photoday.io/en/articles/4157848-monitor-calibration" target="_blank" rel=" noopener noreferrer">
                      calibrating your monitor
                    </a>
                    .
                  </li>
                  <li>
                    Read our{' '}
                    <a href="https://www.photoday.com/custom-themes-terms-of-use" target="_blank" rel=" noopener noreferrer">
                      custom themes policy
                    </a>
                    .
                  </li>
                </ul>

                <footer className="flex column middle custom-themes__splash-footer">
                  <fieldset>
                    <input
                      id="acceptPolicy"
                      className="hidden"
                      type="checkbox"
                      name="acceptPolicy"
                      checked={acceptPolicy}
                      onChange={() => setAcceptPolicy(!acceptPolicy)}
                    />
                    <label htmlFor="acceptPolicy" className="label--checkbox label--lighter label--clean">
                      I’ve read and agree to PhotoDay’s custom themes policy.
                    </label>
                  </fieldset>
                  <button className="button button--medium" name="button" type="button" disabled={!acceptPolicy} onClick={handleAcceptThemePolicy}>
                    Continue
                  </button>
                </footer>
              </div>
            </main>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default CustomThemes;
