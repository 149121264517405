import { Component } from 'react';
import PropTypes from 'prop-types';
import { NestedField } from '@/components/Shared/Forms';
import ProductSelector from './ProductSelector';

class BogoRequiredOptions extends Component {
  static propTypes = {
    productCategories: PropTypes.array
  };

  render() {
    return (
      <div id="giveaway-offer-bogo-step1" className="form-segment">
        <h4 className="text--bold">Buy...</h4>
        <p> If the customer purchases any of the products below… </p>
        <NestedField name="required_products_attributes" component={ProductSelector} productCategories={this.props.productCategories} required={true} />
      </div>
    );
  }
}

export default BogoRequiredOptions;
