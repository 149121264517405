import { FC } from 'react';

// Images
import imageJobDeleted from '@/assets/images/job-illustration-deleted.svg';

const NotFoundDeleted: FC = () => {
  return (
    <main className="box flex column middle center nowrap h-80">
      <img className="mb-30" src={imageJobDeleted} width="335" alt="Job Deleted" />
      <h1 className="text--xxxlarge">Whoops...it looks like this job has been deleted</h1>
    </main>
  );
};

export default NotFoundDeleted;
