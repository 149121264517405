import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//Helpers
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector } from 'react-redux';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import VideoPlayer from '@/components/Shared/VideoPlayer';

// Images
import image_academy_default from '@/assets/images/image-academy-default.jpg';

interface Video {
  id: string;
  title: string;
  description: null | string;
  published_at: string;
  thumbnail_url: null | string;
  url: string;
  scheduled_at: null | string;
  upcoming: boolean;
}

interface ProgramListProps {
  match: {
    params: {
      [key: string]: string;
    };
  };
}

const ProgramList: FC<ProgramListProps> = ({ match }) => {
  const { academy, requesting } = useSelector((state: any) => state.home);

  const [videos, setVideos] = useState<Video[]>([]);
  const [showUrl, setShowUrl] = useState<string>('');
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const [programTitle, setProgramTitle] = useState<string>('');

  const setProgram = (program: string) => {
    const academyProgram = academy[program];

    if (academyProgram) {
      setVideos(academyProgram.videos);
      setProgramTitle(academyProgram.title);
    }
  };

  const handlePlayerToggle = (url: string) => {
    if (!showPlayer) {
      setShowUrl(url);
      setShowPlayer(true);
    } else {
      setShowUrl('');
      setShowPlayer(false);
    }
  };

  useEffect(() => {
    if (match.params) {
      const { program } = match.params;

      if (program) {
        setProgram(program);
      }
    }
  }, [academy, match.params]);

  return (
    <>
      <header className="flex middle nowrap animate academy-container__header">
        <Link to="/home/academy/programs" className="button button--outline button--noborder">
          <i className="icon-back"></i>
        </Link>
        <h2 className="text--nomargin text--capitalize">{programTitle}</h2>
      </header>

      {videos.length > 0 ? (
        <section className="animate academy__events-grid">
          {videos
            .sort((a, b) => Number(new Date(b.published_at)) - Number(new Date(a.published_at)))
            .map((video) => (
              <div className="academy__event" key={video.id}>
                <figure className="flex column middle">
                  <LazyLoadImage src={video.thumbnail_url || image_academy_default} alt={video.title} draggable={false} height={184} effect="opacity" />

                  <figcaption>
                    <small className="text--bold academy__event-title">{`Released on ${moment(video.published_at).format('M.D.YY')}`}</small>
                    <p className="text--bold summary__event-subtitle">{video.title}</p>
                    <div className="academy__event-description">
                      {video.description && video.description.split('\n').map((line, i) => <p key={i}>{line}</p>)}
                    </div>
                    <button
                      className="button button--outline button--block mt-20"
                      type="button"
                      disabled={video.upcoming}
                      onClick={() => handlePlayerToggle(video.url)}
                    >
                      {video.upcoming ? 'Coming Soon' : 'Watch Now'}
                    </button>
                  </figcaption>
                </figure>
              </div>
            ))}
        </section>
      ) : (
        <>
          {requesting ? (
            <GridLoader rows={4} columns={3} gap={20} minHeight={400} />
          ) : (
            <aside className="flex middle center panel panel--secondary panel--tall animate">
              <h3 className="text--nomargin">{`${programTitle} doesn't have any videos yet`}</h3>
            </aside>
          )}
        </>
      )}

      <VideoPlayer className="academy__video-player" url={showUrl} showPlayer={showPlayer} onPlayerToggle={handlePlayerToggle} />
    </>
  );
};

export default ProgramList;
