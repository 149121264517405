import { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import './navigation.css';

class Navigation extends Component {
  state = {
    active: false,
    section: 'Quick Tips'
  };

  componentDidMount = () => {
    this.setSectionTitle(this.props.path);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.path !== prevProps.path) {
      this.setSectionTitle(this.props.path);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path && path.split('/');
    const section = pathArr && pathArr[pathArr.length - 1];

    if (section === 'quicktips' && this.state.section !== 'Quick Tips') {
      this.setState({ section: 'Quick Tips' });
    } else if (section === 'orders' && this.state.section !== 'Orders') {
      this.setState({ section: 'Orders' });
    } else if (section === 'price-sheets' && this.state.section !== 'Price Sheets') {
      this.setState({ section: 'Price Sheets' });
    } else if (section === 'offers-and-credits' && this.state.section !== 'Offers') {
      this.setState({ section: 'Offers' });
    } else if (section === 'advance-pay' && this.state.section !== 'AdvancePay') {
      this.setState({ section: 'AdvancePay' });
    } else if (section === 'promos' && this.state.section !== 'Promos') {
      this.setState({ section: 'Promos' });
    } else if (section === 'settings' && this.state.section !== 'Settings') {
      this.setState({ section: 'Settings' });
    }
  };

  toggleMenu = () => {
    this.setState({ active: !this.state.active });
  };

  collapseMenu = (event) => {
    const newSection = event.target.innerHTML;

    this.setState({ active: false, section: newSection });
  };

  render() {
    return (
      <aside className="subnavigation">
        <header
          className={`subnavigation__header subnavigation__hide-desktop ${this.state.active ? 'subnavigation__header--active' : ''}`}
          onClick={this.toggleMenu}
        >
          <h5 className="subnavigation__item"> Store </h5>
          <h5 className="subnavigation__link--narrow">
            <b style={{ textTransform: 'capitalize' }}>{this.state.section}</b>
            <i className={`fa fa-angle-down sidebar__icon ${this.state.active ? 'sidebar__icon--active' : ''}`} />
          </h5>
        </header>

        <nav className={`subnavigation__container ${this.state.active ? 'subnavigation__container--active' : ''}`}>
          <header className="subnavigation__header subnavigation__hide-mobile">
            <h5 className="subnavigation__item"> Store </h5>
          </header>

          <ul className="subnavigation__list" onClick={this.collapseMenu}>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/quicktips">
                Quick Tips
              </NavLink>
            </li>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/orders">
                Orders
              </NavLink>
            </li>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/price-sheets">
                Price Sheets
              </NavLink>
            </li>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/offers-and-credits">
                Offers
              </NavLink>
            </li>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/advance-pay">
                AdvancePay
              </NavLink>
            </li>
            <li className="subnavigation__item">
              <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/promos">
                Promos
              </NavLink>
            </li>
          </ul>
        </nav>
      </aside>
    );
  }
}

export default Navigation;
