import { FC, ChangeEvent, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { createOrganization, updateOrganization } from './actions';

// Types
import { Organization } from '@/types';

interface EditModalProps {
  studioId?: string;
  organizationId?: string;
  organizationName?: string;
  organizationReportingCode?: string;
  organizationNotes?: string;
  onModalClose: () => void;
}

const EditModal: FC<EditModalProps> = ({ studioId, organizationId, organizationName, organizationReportingCode, organizationNotes, onModalClose }) => {
  const dispatch = useDispatch();
  const { requesting } = useSelector((state: any) => state.organizations);

  const [organizationInfo, setOrganizationInfo] = useState<Partial<Organization>>({
    name: organizationName,
    reporting_code: organizationReportingCode,
    notes: organizationNotes
  });

  const handleInfoChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setOrganizationInfo((prevState) => ({ ...prevState, [target.name]: target.value }));
  };

  const handleEditSave = (): void => {
    dispatch(updateOrganization({ organizationId, updatedOrganizationInfo: organizationInfo }, () => onModalClose()));
  };

  const handleAdd = (): void => {
    dispatch(createOrganization({ studioId, newOrganization: organizationInfo }, () => onModalClose()));
  };

  const handleEditCancel = (): void => onModalClose();

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--small">
        <header>
          <h2>{organizationId ? 'Edit' : 'Add'} Organization</h2>
        </header>
        <form className="modal__content">
          <fieldset>
            <label htmlFor="name">Organization Name</label>
            <input id="name" type="text" name="name" value={organizationInfo?.name ?? ''} maxLength={50} onChange={handleInfoChange} />
          </fieldset>
          <fieldset>
            <label htmlFor="reportingCode">Organization Reporting ID</label>
            <input
              id="reportingCode"
              type="text"
              name="reporting_code"
              value={organizationInfo?.reporting_code ?? ''}
              maxLength={50}
              onChange={handleInfoChange}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="notes">Notes</label>
            <textarea id="notes" name="notes" value={organizationInfo?.notes ?? ''} rows={5} maxLength={244} onChange={handleInfoChange} />
          </fieldset>
        </form>
        <footer className="modal__footer flex end gap-10">
          <button className="button button--outline" type="button" name="cancel" onClick={handleEditCancel}>
            Cancel
          </button>
          {organizationId ? (
            <button className="button" type="button" name="save" data-loading={requesting} onClick={handleEditSave}>
              Save
            </button>
          ) : (
            <button className="button" type="button" name="addOrganization" data-loading={requesting} disabled={!organizationInfo?.name} onClick={handleAdd}>
              Add
            </button>
          )}
        </footer>
      </div>
    </aside>
  );
};

export default EditModal;
