import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// Plugins
import { FullStory, isInitialized as getIsFullstoryInitialized } from '@fullstory/browser';

const mapStateToProps = (state) => ({
  user: state.login.user,
  isAuthenticated: state.login.isAuthenticated,
  userPermissions: (state.login.user && state.login.user.roles) || []
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, userPermissions, requiredPermission, user, location } = rest;
  const hasPermission = requiredPermission === undefined || userPermissions.includes(requiredPermission) ? true : false;
  const isFullstoryInitialized = getIsFullstoryInitialized();

  useEffect(() => {
    if (user?.id && isFullstoryInitialized) {
      // Call FullStory's setIdentity on each page to associate the user with the session.
      // Once set it can't be overwritten, but there is no getter to know if it's set.
      FullStory('setIdentity', { uid: user.id });
    }
  }, [location?.pathname, user?.id]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (hasPermission) {
            return <Component {...props} />;
          } else {
            return props.history.goBack();
          }
        } else {
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default connect(mapStateToProps)(PrivateRoute);
