import { Component } from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';

class FlipContainer extends Component {
  static propTypes = {
    animate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  };

  static defaultProps = {
    component: 'div',
    animate: true
  };

  render() {
    const { component, animate, children, ...options } = this.props;
    return (
      <FlipMove typeName={component} enterAnimation="fade" leaveAnimation="fade" easing="ease-out" disableAllAnimations={animate} {...options}>
        {children}
      </FlipMove>
    );
  }
}

export default FlipContainer;
