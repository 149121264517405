import { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from 'react-tippy';
import InputGroup from './InputGroup';
import RenderSelect from '@/components/Shared/Forms/ReduxSelect';

class PercentOrFixed extends Component {
  static propTypes = {
    children: PropTypes.element,
    onChange: PropTypes.func
  };

  static defaultProps = {
    amountName: 'amount'
  };

  constructor(props) {
    super(props);

    const { markupType, amount, shippingType } = props;

    this.state = {
      markupType,
      amount,
      shippingType,
      initialType: markupType,
      initialAmount: amount
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.shippingType !== this.props.shippingType) {
      this.setState({ shippingType: this.props.shippingType });
    }
  };

  markupOptions = [
    { value: 'fixed_amount', label: 'Fixed Amount' },
    { value: 'fixed_percentage', label: 'Percent' }
  ];

  handleMarkupChange = (select) => {
    const { onChange } = this.props;
    const value = select.value;
    const amount = value === 'fixed_amount' ? '5' : '20';

    this.setState((state) => ({ ...state, markupType: value, amount }));

    if (onChange) {
      onChange({ amount, markup_type: value });
    }
  };

  handleShippingChange = (select) => {
    const { onChange } = this.props;

    this.setState((state) => ({ ...state, shippingType: select }));

    if (onChange) {
      onChange({ shipping_type: select });
    }
  };

  amountOnChange = (event) => {
    const { onChange } = this.props;
    const { markupType } = this.state;
    const amount = event.target.value;

    this.setState((state) => ({ ...state, amount }));

    if (onChange) {
      onChange({ amount, markup_type: markupType });
    }
  };

  render() {
    const { name, amountName, priceSheetId, labSelected, jobsCount } = this.props;
    const { markupType, shippingType } = this.state;

    const shippingDisabled = labSelected ? !labSelected.allowBulkShipping : false;
    const shippingUpdateForbidden = priceSheetId && jobsCount > 0;
    const showShippingTooltip = shippingUpdateForbidden && !shippingDisabled;
    const markupTypeValue = markupType?.value ? markupType.value : markupType;

    return (
      <div className="mb-60">
        <fieldset className="flex column">
          <div className="flex between nowrap">
            <label>Markup</label>
            <Tooltip {...{ title: 'Retail Price minus Base Cost', position: 'bottom-end', trigger: 'mouseenter', arrow: true, zIndex: 10000 }}>
              <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-question-circle-o" />
              </a>
            </Tooltip>
          </div>
          <div>
            <RenderSelect
              value={markupTypeValue}
              name={name}
              required={true}
              placeholder="Markup"
              options={this.markupOptions}
              onChange={this.handleMarkupChange}
            />
          </div>

          <InputGroup
            name={amountName}
            addonPosition={markupTypeValue === 'fixed_percentage' ? 'right' : 'left'}
            required
            disabled={markupTypeValue === ''}
            onChange={this.amountOnChange}
          >
            <span>{markupTypeValue === 'fixed_percentage' ? '%' : '$'}</span>
          </InputGroup>

          {priceSheetId && (
            <aside className="panel panel--neat panel--warning">
              <p className="text--nomargin text--small">
                Updating the markup will overwrite <strong>all prices</strong>, including prices that were manually set.
              </p>
            </aside>
          )}
        </fieldset>

        <hr />

        <fieldset className="flex column">
          <div className="flex between nowrap">
            <label>Shipping</label>
            <Tooltip {...{ title: 'Select how you want products to be shipped.', position: 'bottom-end', trigger: 'mouseenter', arrow: true, zIndex: 10000 }}>
              <a href="https://support.photoday.io/en/articles/5099838-what-shipping-options-are-available" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-question-circle-o" />
              </a>
            </Tooltip>
          </div>

          <div>
            <Tooltip
              title="This price sheet is assigned to a job and the shipping type can’t be modified"
              distance={5}
              position="top"
              trigger="mouseenter"
              arrow={true}
              disabled={!showShippingTooltip}
            >
              <RenderSelect
                disabled={shippingUpdateForbidden || shippingDisabled}
                classNamePrefix="select"
                name="shipping_type"
                required={true}
                placeholder="Shipping"
                value={shippingType}
                options={[
                  { value: 'drop_shipping', label: 'Drop' },
                  { value: 'bulk_shipping', label: 'Bulk' },
                  { value: 'drop_or_bulk_shipping', label: 'Bulk or Drop' }
                ]}
                onChange={this.handleShippingChange}
              />
            </Tooltip>
          </div>
        </fieldset>

        {!priceSheetId && shippingType.value !== 'drop_shipping' && (
          <div className="panel panel--lean">
            <p className="text--left">
              <small>
                Some products may not be available to bulk ship.{' '}
                <a href="https://support.photoday.io/en/articles/4037868-product-availability-for-bulk-shipping" target="_blank" rel="noopener noreferrer">
                  Learn more
                </a>{' '}
              </small>
            </p>
          </div>
        )}

        {priceSheetId && shippingType.value !== 'drop_shipping' && (
          <div className="panel panel--lean panel--warning">
            <p className="text--left text--nomargin text--small">
              Changing shipping to “Bulk” or “Bulk or Drop” will remove certain products.{' '}
              <a
                href="https://support.photoday.io/en/articles/4037868-product-availability-for-bulk-shipping-miller-s-lab"
                target="_blank"
                rel="noopener noreferrer"
                className="text--black"
              >
                <strong>Learn more</strong>
              </a>{' '}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default PercentOrFixed;
