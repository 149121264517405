import { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment';

// Redux
import { createGiftCardResendReceipt, getGiftCardTransactionsList, giftCardDetailRequest } from './actions';
import { getMyAccountStudioRequest } from '../../../Settings/actions';

// Components
import SafeLink from '@/components/Shared/Link';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Helpers
import { giftCardIdSelector } from './selectors';
import { convertToText } from '@/utils/currency';

// Images
import ReactImageArrowBack from '@/assets/images/icon-arrow-back.svg?react';

// Styles
import './style.css';

const mapStateToProps = (state, ownProps) => {
  const giftCardId = giftCardIdSelector(ownProps);
  const {
    users: { studio },
    login: {
      studio: { id: studioId }
    }
  } = state;

  const giftCard = state.credits.entities.gift_card;
  const giftCardTransactions = state.credits.entities.transactions || [];
  const fromInsights = ownProps.location.search.includes('from_insights');
  const fromReports = ownProps.location.state ? ownProps.location.state.fromReports : '';

  return {
    studio,
    giftCard,
    giftCardTransactions,
    studioId,
    giftCardId,
    fromReports,
    fromInsights,
    requesting: state.credits.requesting
  };
};

const mapDispatchToProps = {
  createGiftCardResendReceipt,
  getGiftCardTransactionsList,
  giftCardDetailRequest,
  getMyAccountStudioRequest
};

class CreditDetail extends Component {
  static propTypes = {
    giftCard: object.isRequired
  };

  static defaultProps = {
    giftCard: {}
  };

  componentDidMount() {
    const { giftCardDetailRequest, getMyAccountStudioRequest, getGiftCardTransactionsList, studioId, giftCardId } = this.props;

    giftCardDetailRequest(giftCardId);
    getGiftCardTransactionsList({ giftCardId });

    if (studioId !== 'all-studios') {
      getMyAccountStudioRequest({ studioId });
    }
  }

  handleResendReceipt = () => {
    const { giftCardId, createGiftCardResendReceipt } = this.props;

    createGiftCardResendReceipt({ giftCardId });
  };

  render() {
    const { giftCard, giftCardTransactions, requesting, fromReports, fromInsights, location } = this.props;
    const insightsUrl = `/jobs/${giftCard.job_id}/insights/sales`;

    return (
      <>
        <header className="credit-details__header mb-20">
          {fromInsights && (
            <SafeLink Component={Link} to={insightsUrl} disabled={requesting}>
              Back to Insights
            </SafeLink>
          )}
          {fromReports && (
            <SafeLink Component={Link} to={{ pathname: fromReports, state: { ...location.state } }} disabled={requesting}>
              Back to Reports
            </SafeLink>
          )}
          {!fromInsights && !fromReports && (
            <SafeLink Component={Link} to="/storefront/orders" disabled={requesting}>
              <ReactImageArrowBack width="12" /> View all orders
            </SafeLink>
          )}
        </header>

        <main className="credit-details__main flex nowrap wrap-md gap-10">
          <aside className="credit-details__order panel panel--nomargin flex-3 flex-12-md">
            <h4>Order Number - {giftCard.num}</h4>
            <dl className="dl--inline mb-10">
              <dt>Order Date:</dt>
              <dd>{requesting ? <TextLoader height={20} /> : moment(giftCard.created_at).format('L')}</dd>
              <br />
              <dt>Order Status:</dt>
              <dd>{requesting ? <TextLoader height={20} /> : 'Paid'}</dd>
              {giftCard.expiration && (
                <>
                  <br />
                  <dt>Expiration Date:</dt>
                  <dd>{requesting ? <TextLoader height={20} /> : moment(giftCard.expiration).format('L')}</dd>
                </>
              )}
            </dl>
            <aside className="mb-20">
              <div className="flex nowrap gap-10 mb-10">
                <Link
                  className="button button--outline button--small flex-6"
                  to={{
                    pathname: '/support/refund-request',
                    search: `?customer_name=${`${giftCard?.customer?.first_name}${giftCard?.customer?.last_name}`}&customer_email=${
                      giftCard?.customer?.email
                    }&order_number=${giftCard.num}&purchase_type=advance_pay&refund_amount=${giftCard?.amount_cents}`
                  }}
                  disabled={requesting}
                >
                  Request Refund
                </Link>
                <button
                  className="button button--outline button--small flex-6"
                  name="resend"
                  type="button"
                  onClick={this.handleResendReceipt}
                  disabled={requesting}
                >
                  Resend Receipt
                </button>
              </div>
              <button className="button button--small button--block" name="print" type="button" onClick={window.print} disabled={requesting}>
                Print Invoice
              </button>
            </aside>

            <h4>Customer Information</h4>
            <dl className="mb-10">
              <dt>Bill to:</dt>
              {requesting || !giftCard.address ? (
                <TextLoader height={40} />
              ) : (
                <dd>
                  {giftCard.address.recipient}
                  <br />
                  {giftCard.address.line1}
                  {giftCard.address.line2}
                  <br />
                  {giftCard.address.city}, {giftCard.address.state} {giftCard.address.zip}
                </dd>
              )}
            </dl>
            <dl className="mb-20">
              <dt>Additional Info:</dt>
              {requesting || !giftCard.customer ? (
                <TextLoader height={40} />
              ) : (
                <dd className="text--normalized">
                  {giftCard.customer.first_name} {giftCard.customer.last_name}
                  <br />
                  {giftCard.email}
                  <br />
                  {giftCard.phone}
                </dd>
              )}
            </dl>

            <h4>Additional Information</h4>
            <dl className="dl--inline mb-20">
              <dt>Job Name:</dt>
              <dd>{requesting ? <TextLoader height={20} /> : <Link to={`/jobs/${giftCard.job_id}/dashboard`}>{giftCard.job_name}</Link>}</dd>
              <br />
              <dt>Job Access Mode:</dt>
              <dd>
                {requesting ? (
                  <TextLoader height={20} />
                ) : (
                  { access_per_subject: 'Private', access_public: 'Public', access_per_job: 'Group' }[giftCard.job_access_mode]
                )}
              </dd>
              {giftCard.job_access_mode !== 'access_per_subject' && (
                <>
                  <br />
                  <dt>Job Access Code:</dt>
                  <dd>{requesting ? <TextLoader height={20} /> : giftCard.job_access_code}</dd>
                </>
              )}
              <br />
              <dt>Studio Name:</dt>
              <dd>{requesting ? <TextLoader height={20} /> : giftCard.studio_name}</dd>
              {giftCard.subject_details && (
                <>
                  <br />
                  <dt>Subject Info:</dt>
                  <dd>{requesting ? <TextLoader height={20} /> : giftCard.subject_details}</dd>
                </>
              )}
            </dl>

            <footer className="panel panel--lean panel--dark flex between">
              <h4 className="text--nomargin">Total</h4>
              <h4 className="text--nomargin">{requesting ? <TextLoader height={20} /> : convertToText(giftCard.amount_cents, '$')}</h4>
            </footer>
          </aside>
          <article className="credit-details__transactions flex-9 flex-12-md">
            {moment(giftCard.expiration).isBefore(moment().subtract(1, 'days')) === true && (
              <aside className="panel panel--danger panel--lean panel--round text--center">
                <h4 className="text--nomargin text--white">
                  This AdvancePay credit has expired with {convertToText(giftCard.remaining_amount_cents, '$')} remaining
                </h4>
              </aside>
            )}

            <table className="table">
              <caption>
                <hgroup className="flex between">
                  <h3>Transaction History</h3>
                  {giftCard.expiration && <h3>Expiration Date: {moment(giftCard.expiration).format('L')}</h3>}
                </hgroup>
              </caption>
              <thead className="table__header">
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody className="table__body">
                {requesting ? (
                  <tr>
                    <td colSpan="5">
                      <TableLoader rows={5} />
                    </td>
                  </tr>
                ) : (
                  <>
                    {giftCardTransactions.map((transaction) => (
                      <tr key={transaction.transaction_id}>
                        <td data-header="Date">{moment(transaction.transaction_date).format('L')}</td>
                        <td data-header="Type">{transaction.transaction_type}</td>
                        <td data-header="Id">
                          {transaction.transaction_type === 'AP' ? (
                            <b>{transaction.transaction_id}</b>
                          ) : (
                            <Link to={`/storefront/orders/${transaction.order_id}`}>
                              <b>{transaction.transaction_id}</b>
                            </Link>
                          )}
                        </td>
                        <td data-header="Amount">
                          {Number(transaction.transaction_amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                        </td>
                        <td data-header="Balance">
                          {Number(transaction.remaining_balance / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
              <tfoot className="table__footer">
                <tr>
                  <td colSpan="4">
                    <h3 className="text--nomargin">Remaining Balance</h3>
                  </td>
                  <td>
                    <h3 className="text--nomargin">
                      {Number(giftCard.remaining_amount_cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                    </h3>
                  </td>
                </tr>
              </tfoot>
            </table>
          </article>
        </main>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditDetail);
