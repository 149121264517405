import { FC, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';

// Plugins
import pLimit from 'p-limit';
import Dropzone, { FileWithPath, FileRejection, DropzoneRef } from 'react-dropzone';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createPhotoCheckDuplicate, createPhoto, updatePhotoDropzone } from '../../../actions';

// Hooks & Helpers
import loadExif from '@/utils/loadExif';

// Images
import iconUploadCloud from '@/assets/images/icon-cloud-upload.svg';

// Styles
import './style.css';

// Types
import { ImageFile } from '@/types';

interface UploadDropzoneProps {
  jobId: string;
  isPngImageAllowed: boolean;
  galleryType: string;
  galleryHasPhotos: boolean;

  showDropzone: boolean;
  showDropzoneSplash: boolean;

  onDropzoneSplashToggle: (show: boolean) => void;
}

// Constants
const MAX_PARALLEL_IMAGES = 10;
const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';

const UploadDropzone: FC<UploadDropzoneProps> = forwardRef(
  ({ jobId, isPngImageAllowed, galleryType, galleryHasPhotos, showDropzone, showDropzoneSplash, onDropzoneSplashToggle }, ref) => {
    const dropzoneRef = useRef<DropzoneRef>(null);
    const imageFilesToProcessRef = useRef<File[]>([]);

    const dispatch = useDispatch();

    const { upload } = useSelector((state: any) => state.jobs);
    const [showChecklistModal, setShowChecklistModal] = useState<boolean>(false);
    const [showDuplicatesModal, setShowDuplicatesModal] = useState<boolean>(false);

    const [fileDialogType, setFileDialogType] = useState<string>('');

    const processImageFiles = () => {
      const imageFiles = imageFilesToProcessRef.current;

      // Add EXIF properties inside each accepted file
      const exifPromises: any = [];
      const imageParallelLimit = pLimit(MAX_PARALLEL_IMAGES);

      imageFiles.forEach((file: File) => exifPromises.push(imageParallelLimit(() => loadExif(file))));

      Promise.all(exifPromises).then(() => {
        // Check files width and height acceptance first
        const rejectedFiles: FileRejection[] = [];

        imageFiles.forEach((file: ImageFile) => {
          const { data } = file;

          if (data) {
            const imageWidth: number = data['Image Width']?.value || 0;
            const imageHeight: number = data['Image Height']?.value || 0;

            if (imageWidth > 8000 || imageHeight > 8000) {
              rejectedFiles.push({ file, errors: [{ code: 'dimensions-too-large', message: 'File dimensions exceed 8000px' }] });
            }
          }
        });

        if (rejectedFiles.length) {
          handleDropRejected(rejectedFiles);
        }

        // Filter out rejectedFiles
        const imageFilesFiltered = imageFiles.filter(
          (file: File) => rejectedFiles.some((rejectedFile: FileRejection) => rejectedFile.file.name === file.name) === false
        );

        const shouldHold = galleryType !== 'private' && imageFilesFiltered.some((file: ImageFile) => file.data?.keywords?.length);

        dispatch(updatePhotoDropzone({ jobId: jobId, queue: imageFilesFiltered, hold: shouldHold }));
        dispatch(createPhoto());
      });
    };

    const handleDragLeave = (): void => onDropzoneSplashToggle(false);

    const handleDrop = (droppedFiles: File[]): void => {
      onDropzoneSplashToggle(false);
      dispatch(updatePhotoDropzone({ processing: droppedFiles.length ? true : false }));
    };

    const handleDropAccepted = (acceptedFiles: File[]): void => {
      // Remove OS image junk files
      const acceptedFilesWithoutJunk = acceptedFiles.filter((file: FileWithPath) => !file.name.startsWith('.') && !file?.path?.includes('__macosx'));

      // Strip special characters from File name
      acceptedFilesWithoutJunk.forEach((file: FileWithPath) => {
        Object.defineProperty(file, 'name', { writable: true, value: file.name.replace(/[&$+,/:;=?@<>[\]{}|\\^~%#'"*]/g, '') });
      });

      if (acceptedFilesWithoutJunk.length) {
        imageFilesToProcessRef.current = acceptedFilesWithoutJunk;

        // Only check for duplicates if gallery have photos
        if (galleryHasPhotos) {
          // Check for duplicate files
          const acceptedFileNames: string[] = acceptedFilesWithoutJunk.map((file: File) => file.name) || [];

          dispatch(
            createPhotoCheckDuplicate({ jobId, fileNames: acceptedFileNames }, ({ data: responseDuplicates }: { data: string[] }) => {
              if (responseDuplicates.length) {
                setShowDuplicatesModal(true);
                dispatch(updatePhotoDropzone({ duplicated: responseDuplicates }));
              } else {
                processImageFiles();
              }
            })
          );
        } else {
          processImageFiles();
        }
      }
    };

    const handleDropDuplicatesReplace = ({ replace, retainAttributes }: { replace: boolean; retainAttributes?: boolean }): void => {
      setShowDuplicatesModal(false);

      dispatch(updatePhotoDropzone({ replace, retainAttributes }));
      processImageFiles();
    };

    const handleDuplicateCancel = (): void => {
      dispatch(
        updatePhotoDropzone({
          failed: [],
          rejected: [],
          duplicated: [],

          time: [],
          queue: [],

          total: 0,
          current: 0,
          successful: 0,

          hold: false,
          cancel: false,
          retry: false,
          replace: false,
          retainAttributes: false,
          keywords: true,
          processing: false,
          requesting: false
        })
      );
      setShowDuplicatesModal(false);
    };

    const handleDropRejected = (rejectedFiles: FileRejection[]): void => {
      const filteredRejectedFiles = rejectedFiles
        .map(({ file, errors }) => {
          function getReason(): string {
            if (!isPngImageAllowed && file.type === 'image/png') {
              return 'PNG File Type NOT allowed';
            }

            if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
              return 'Invalid File Type';
            }

            if (file.size < 30720) {
              return 'File should be at least 30KB';
            }

            if (file.size > 15728640) {
              return 'File should not exceed 15MB';
            }

            return errors[0]?.message || 'File error';
          }

          return { name: file.name, reason: getReason() };
        })
        .filter((file) => !file.name.startsWith('.') && !file.name.toLowerCase().startsWith('thumbs'))
        .filter((file) => !upload.rejected.some((rejected: File) => rejected.name === file.name));

      dispatch(updatePhotoDropzone({ rejected: filteredRejectedFiles }));
    };

    const handleDialogTypeChange = (type: 'folder' | 'file'): void => setFileDialogType(type);

    const handleDialogOpen = (): void => {
      if (dropzoneRef.current && typeof dropzoneRef.current.open === 'function') {
        dropzoneRef.current.open();
      }
    };

    useImperativeHandle(ref, () => ({
      openFileDialog() {
        handleDialogOpen();
      },
      changeFileDialogType(type: 'folder' | 'file') {
        handleDialogTypeChange(type);
      }
    }));

    const supportedImageMimeTypes = { 'image/jpg': ['.jpeg', '.jpg'], ...(isPngImageAllowed ? { 'image/png': ['.png'] } : {}) };

    return (
      <>
        <Dropzone
          ref={dropzoneRef}
          disabled={upload.queue.length > 0}
          multiple={true}
          maxSize={15728640} // 15MB
          minSize={30720} // 30KB
          accept={supportedImageMimeTypes}
          noClick={true}
          noKeyboard={true}
          noDragEventsBubbling={true}
          preventDropOnDocument={true}
          useFsAccessApi={false}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <section
                className={`flex column middle center panel panel--secondary panel--tall job-dropzone__main ${showDropzone ? '' : 'hidden'}`}
                {...getRootProps()}
              >
                {upload.queue.length > 0 || upload.processing ? (
                  <>
                    <img className="job-dropzone__splash-icon job-dropzone__splash-icon--disabled" src={iconUploadCloud} alt="Icon Upload Cloud" />
                    <h2>Upload in progress.</h2>
                    <h5>Please wait until current upload is complete before uploading more photos.</h5>
                  </>
                ) : (
                  <>
                    <input
                      {...getInputProps({ disabled: false })}
                      {...(fileDialogType === 'folder' ? { webkitdirectory: '', mozdirectory: '', directory: '' } : {})}
                    />
                    <img className="job-dropzone__splash-icon" src={iconUploadCloud} alt="Icon Upload Cloud" />
                    <h2 className="job-dropzone__main-title">
                      Drag ready-to-sell photos here or upload{' '}
                      <button className="button button--link" onMouseEnter={() => handleDialogTypeChange('folder')} onClick={handleDialogOpen}>
                        folders
                      </button>{' '}
                      or{' '}
                      <button className="button button--link" onMouseEnter={() => handleDialogTypeChange('file')} onClick={handleDialogOpen}>
                        photos
                      </button>
                      .
                    </h2>
                    {SERVICES_ENABLED && (
                      <h2 className="job-dropzone__main-title job-dropzone__main-title--tall">
                        Create a post-processing job <Link to={`/jobs/${jobId}/services/postprocessing`}>here</Link>.
                      </h2>
                    )}
                    <p className="text--black">{`We accept photos in ${
                      isPngImageAllowed ? 'PNG and JPG formats' : 'JPG format'
                    } that are between 30K to 15MB, with a maximum side length of 8000px. Embedded color profile must be sRGB.`}</p>
                    <p className="text--black">
                      Need help? Check out our{' '}
                      <button className="button button--link" type="button" name="preflight" onClick={() => setShowChecklistModal(true)}>
                        pre-flight checklist
                      </button>
                      .
                    </p>
                    {/* Pre-flight modal */}
                    {showChecklistModal && (
                      <aside className="modal animate">
                        <div className="modal__box modal__box--small text--left">
                          <header className="modal__header">
                            <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowChecklistModal(false)}>
                              <i className="icon-close"></i>
                            </button>
                            <h3>Pre-flight Checklist</h3>
                          </header>
                          <ul className="list--bullet">
                            <li>
                              <p>
                                Ensure the aspect ratio is ideal for the products you’re offering, and that you’ve left at least a 1/2" margin to allow for
                                bleed and product assembly, especially if images contain rendered graphics or text.
                              </p>
                            </li>
                            <li>
                              <p>
                                Photos should be in a JPG/JPEG file format (or PNGs if that feature is available at your partner lab), be no greater than 15MB
                                per photo, and have a max side length of 8000px.
                              </p>
                            </li>
                            <li>
                              <p>
                                If there is a color profile embedded in the files, it must be sRGB to ensure consistent color results from our partner labs.
                              </p>
                            </li>
                            <li>
                              <p>It’s recommended that files names are no more than 31 characters long.</p>
                            </li>
                            <li>
                              <p>
                                If you attempt to upload a file with special characters like {`“< > : ” \\ / ? | * ~” [ ]`} we will strip them out and still
                                accept the file, but it might result in an image error.
                              </p>
                            </li>
                            <li>
                              <p>If a photo is in a folder or its folder is nested in a folder, the photo will only be tagged with the folder name it’s in.</p>
                            </li>
                            <li>
                              <p>
                                If a photo is uploaded and has Lightroom keywords, the keywords will become that photo’s tags (Group/Public galleries only).
                              </p>
                            </li>
                            <li>
                              <p>
                                If a folder containing photos that has Lightroom keywords is uploaded, you’ll have the option to choose either the keywords or
                                the folder name as the photo’s tag.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    )}
                  </>
                )}
              </section>

              {/* Splash Drop Zone */}
              {upload.queue.length > 0 ? (
                <aside className={`job-dropzone__splash ${showDropzoneSplash ? 'job-dropzone__splash--active' : ''}`}>
                  <img className="job-dropzone__splash-icon job-dropzone__splash-icon--disabled" src={iconUploadCloud} alt="Icon Upload Cloud" />
                  <h2>Upload in progress.</h2>
                  <h5>Please wait until current upload is complete before uploading more photos.</h5>
                </aside>
              ) : (
                <aside className={`job-dropzone__splash ${showDropzoneSplash ? 'job-dropzone__splash--active' : ''}`} {...getRootProps()}>
                  <img className="job-dropzone__splash-icon" src={iconUploadCloud} alt="Icon Upload Cloud" />
                  <h2>Drop folders and photos here to upload!</h2>
                  <h5>{`Remember, we accept photos in ${isPngImageAllowed ? 'PNG and JPG formats' : 'JPG format'} that are between 30K to 15MB.`}</h5>
                </aside>
              )}
            </>
          )}
        </Dropzone>

        {/* Duplicates modal */}
        {showDuplicatesModal && (
          <aside className="modal animate">
            <div className="modal__box modal__box--large">
              <main className="modal__content text--left">
                <h4>Duplicates Found</h4>
                <div className="flex nowrap gap-20">
                  <aside className="panel panel--dark panel--nomargin flex-5">
                    <h6>Images</h6>
                    <ul className="job-dropzone__duplicates">
                      {upload.duplicated.map((duplicatedFile: string) => (
                        <li key={duplicatedFile}>
                          <span className="text--block text--truncate">{duplicatedFile}</span>
                        </li>
                      ))}
                    </ul>
                  </aside>
                  <article className="flex-9">
                    <p className="mb-20">
                      The image filename(s) to the left are duplicates that can be replaced or skipped. These images may have manually assigned existing
                      attributes (tags, disabled digital downloads, manual subject matches, and/or featured photos).
                    </p>
                    <h5>How would you like to proceed?</h5>
                    <button
                      className="button button--block button--outline mb-10"
                      name="replace"
                      type="button"
                      onClick={() => handleDropDuplicatesReplace({ replace: true, retainAttributes: true })}
                    >
                      Replace Files, Retain Attributes (recommended)
                    </button>
                    <button
                      className="button button--block button--outline mb-10"
                      name="attributes"
                      type="button"
                      onClick={() => handleDropDuplicatesReplace({ replace: true, retainAttributes: false })}
                    >
                      Replace Files, Remove Attributes
                    </button>
                    <button
                      className="button button--block button--outline mb-10"
                      name="finish"
                      type="button"
                      onClick={() => handleDropDuplicatesReplace({ replace: false, retainAttributes: true })}
                    >
                      Skip These Files, Finish Upload
                    </button>
                    <button className="button button--block button--clean" name="cancel" type="button" onClick={handleDuplicateCancel}>
                      Cancel
                    </button>
                  </article>
                </div>
              </main>
            </div>
          </aside>
        )}
      </>
    );
  }
);

export default UploadDropzone;
