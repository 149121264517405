import PropTypes from 'prop-types';
import { Component } from 'react';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    const { currentId } = props;
    this.state = {
      currentId,
      deleteText: ''
    };
  }

  componentDidUpdate(nextProps) {
    if (this.props.currentId !== nextProps.currentId) {
      this.setState({ currentId: nextProps.currentId });
    }
  }

  close = () => {
    this.props.toggleModal(false);
  };

  handleDelete = () => {
    const { currentId } = this.state;
    const { onConfirm } = this.props;

    onConfirm(currentId);
  };

  handleInputOnChange = (evt) => {
    evt.preventDefault();

    const value = evt.target.value;

    this.setState({
      deleteText: value
    });
  };

  render() {
    const { isVisible, title, children, entity, requesting } = this.props;

    return (
      <aside className={`modal ${isVisible ? '' : 'transparent'} text--left`}>
        <div className="modal__box modal__box--small modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={this.close}>
              <i className="icon-close"></i>
            </button>
            <h3>{title}</h3>
          </header>
          <main className="modal__content">
            {children}
            <p>
              Type <b>"delete"</b> to permanently delete this {entity}.
            </p>
            <input className="input--block" type="text" name="delete" value={this.state.deleteText} onChange={this.handleInputOnChange} maxLength="50" />
          </main>
          <footer className="center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={this.handleDelete}
              data-loading={requesting}
              disabled={requesting || !(this.state.deleteText.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}

DeleteModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  currentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteModal;
