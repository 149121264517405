import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

class InputGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    addonPosition: PropTypes.oneOf(['left', 'right']),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    noMargin: PropTypes.bool
  };

  static defaultProps = {
    type: 'text',
    label: null,
    placeholder: null,
    addonPosition: 'right',
    disabled: false,
    noMargin: false
  };

  renderInput = ({ input, type, meta: { touched, error }, addonPosition, disabled }) => {
    const { placeholder, children } = this.props;
    return (
      <fieldset>
        {addonPosition === 'left' ? <div className="button--icon">{children}</div> : null}
        <input className="input--block input--noappearance" {...input} placeholder={placeholder} type={type} disabled={disabled} />
        {addonPosition === 'right' ? <div className="button--icon">{children}</div> : null}
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </fieldset>
    );
  };

  render() {
    const { name, label, type, addonPosition, onChange, disabled } = this.props;
    return (
      <div className="text--left">
        {label && <label>{label}</label>}
        <Field
          name={name}
          type={type}
          component={this.renderInput}
          addonPosition={addonPosition}
          validate={matchValidatePropToValidators(this.props)}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default InputGroup;
