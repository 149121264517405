import { Component } from 'react';
//import PropTypes from 'prop-types';
import { Switch } from '@/components/Shared/Forms';

class FreeShippingForm extends Component {
  static propTypes = {};

  render() {
    const { offer } = this.props;

    return (
      <div className="form-segment">
        <Switch
          name="sw2"
          label="Add Free Shipping"
          note="You will be responsible for shipping fees if you toggle this on."
          checked={offer.shipping_type === 'free'}
        />
      </div>
    );
  }
}

export default FreeShippingForm;
