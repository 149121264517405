import { Component } from 'react';
import { connect } from 'react-redux';

import { updateMyAccountRequest } from '../../actions';

import NotificationForm from './NotificationForm';

const mapStateToProps = (state) => {
  const { requesting, account } = state.users;
  const loading = !(account && account.id);

  return { account, requesting, loading };
};
const mapDispatchToProps = { updateMyAccountRequest };

class Notification extends Component {
  saveAccount = (values) => this.props.updateMyAccountRequest(values);

  render() {
    const { account, requesting, loading } = this.props;
    const { daily_order_notification, daily_nps_summary, weekly_nps_summary } = account || {};

    return (
      <NotificationForm
        onSubmit={this.saveAccount}
        initialValues={{ daily_order_notification, daily_nps_summary, weekly_nps_summary }}
        requesting={requesting}
        loading={loading}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
