import { Component } from 'react';

// Plugins
import { toast, Slide } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

// Styles
import 'react-toastify/dist/ReactToastify.min.css';

const MIN_CREDIT_VALUE = 5;
const MAX_CREDIT_VALUE = 500;

class CreateOrEditGiftCardModal extends Component {
  constructor(props) {
    super(props);

    const selectedValue = props.giftCard ? props.giftCard.amount_cents.toString().slice(0, -2) : MIN_CREDIT_VALUE;
    const description = props.giftCard ? props.giftCard.description : '';

    this.state = {
      selectedValue,
      description: description || ''
    };
  }

  save = () => {
    const { selectedValue, description } = this.state;

    if (this.checkAmount(selectedValue)) {
      const { onSave, giftCard } = this.props;

      onSave && onSave(giftCard, selectedValue, description);
    }
  };

  cancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  remove = () => {
    const {
      onDelete,
      giftCard: { id }
    } = this.props;
    onDelete && onDelete(id);
  };

  get modalTitle() {
    const { giftCard } = this.props;

    if (giftCard) {
      return 'Edit Credit';
    }

    return 'Add Credit';
  }

  get modalSubTitle() {
    const { giftCard } = this.props;

    if (giftCard) {
      return `To edit a credit value, please select a dollar amount below. You can also enter your own dollar amount $${MIN_CREDIT_VALUE} increments. Limit ${MAX_CREDIT_VALUE} per credit created.`;
    }

    return `To add a credit value to the credit sheet, please select a dollar amount below. You can also enter your own dollar amount in $${MIN_CREDIT_VALUE} increments. Limit ${MAX_CREDIT_VALUE} per credit created.`;
  }

  selectValue = (value = 0) => {
    const valString = String(value) ?? '';
    const newValue = valString?.charAt() === '0' ? valString?.slice(1) : valString;

    if (newValue?.length > 3) {
      return this.setState({ selectedValue: newValue.slice(0, 3) || 0 });
    }

    this.setState({ selectedValue: newValue || 0 });
  };

  checkAmount = (value) => {
    const options = {
      autoClose: 3000,
      transition: Slide,
      closeOnClick: false,
      pauseOnHover: false,
      position: 'top-right',
      type: 'error'
    };

    if (value % MIN_CREDIT_VALUE !== 0) {
      toast(`The dollar amount must be in $${MIN_CREDIT_VALUE} increments.`, options);

      return false;
    }

    if (value > MAX_CREDIT_VALUE) {
      toast(`The limit is $${MAX_CREDIT_VALUE} per credit.`, options);

      return false;
    }

    return true;
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  render() {
    const { valuesGiftCards, giftCard, requesting } = this.props;
    const { selectedValue, description } = this.state;

    return (
      <aside id="add-credit-modal" className="modal animate">
        <div className="modal__box">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={this.cancel}>
              <i className="icon-close"></i>
            </button>
            <h3>{this.modalTitle}</h3>
          </header>
          <main className="modal__content">
            <p>{this.modalSubTitle}</p>
            <div className="credit-amount-container">
              {valuesGiftCards.includes(2500) ? (
                <div className="credit-amount-number used-credit">$25</div>
              ) : (
                <div className={selectedValue === 25 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => this.selectValue(25)}>
                  {' '}
                  $25
                </div>
              )}
              {valuesGiftCards.includes(5000) ? (
                <div className="credit-amount-number used-credit">$50</div>
              ) : (
                <div className={selectedValue === 50 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => this.selectValue(50)}>
                  {' '}
                  $50
                </div>
              )}
              {valuesGiftCards.includes(7500) ? (
                <div className="credit-amount-number used-credit">$75</div>
              ) : (
                <div className={selectedValue === 75 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => this.selectValue(75)}>
                  {' '}
                  $75
                </div>
              )}
              {valuesGiftCards.includes(10000) ? (
                <div className="credit-amount-number used-credit">$100</div>
              ) : (
                <div className={selectedValue === 100 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => this.selectValue(100)}>
                  {' '}
                  $100
                </div>
              )}
              <div className="credit-amount-number credit-amount-number--custom">
                <NumericFormat
                  className="input--block custom-credit-amount"
                  prefix={'$'}
                  value={selectedValue}
                  allowNegative={false}
                  isAllowed={({ floatValue }) => Number(floatValue) <= MAX_CREDIT_VALUE || floatValue === undefined}
                  onValueChange={(values) => this.selectValue(values.floatValue ?? MIN_CREDIT_VALUE)}
                />
              </div>
            </div>

            <div>
              <p>Add an optional description that will let customers know what they can use this credit for.</p>
              <textarea className="textarea--block" rows="3" value={description} maxLength="310" onChange={this.handleDescriptionChange} />
            </div>
          </main>
          <footer className="modal__footer flex column middle gap-10">
            {giftCard && (
              <button className="button button--clean button--large" name="remove" type="button" onClick={this.remove}>
                Remove Credit
              </button>
            )}
            <button className="button button--large" type="button" disabled={requesting || selectedValue < 1} data-loading={requesting} onClick={this.save}>
              Save
            </button>
            <button className="button button--outline button--large" onClick={this.cancel}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}

export default CreateOrEditGiftCardModal;
