import { Component } from 'react';
import PropTypes from 'prop-types';

export default class SalesItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      key: PropTypes.any,
      value: PropTypes.any
    })
  };

  render() {
    const {
      item: { key, value }
    } = this.props;

    return (
      <tr>
        <td>{key}</td>
        <td className="text--right quickglance__item-value">{value}</td>
      </tr>
    );
  }
}
