import { Component } from 'react';
import PropTypes from 'prop-types';

import { CustomCheckboxList } from '@/components/Shared/Forms';

// Images
import icon_offer_discount from '@/assets/images/icon_offer_discount.png';
import icon_offer_shipping from '@/assets/images/icon_offer_shipping.png';

class PercentOrFixedCheckbox extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  handleSelectOnClick = () => {
    const { onChange, value } = this.props;
    let currentValue = ['fixed_amount', 'fixed_percentage'].includes(value) ? value : 'fixed_amount';
    onChange(currentValue, 1);
  };

  render() {
    //const className = ['fixed_amount', 'fixed_percentage'].includes(value) ? 'discount-btn active-offer' : 'discount-btn';
    return (
      <div className="offer-item">
        <img src={icon_offer_discount} alt="" className="offer-image" onClick={this.handleSelectOnClick} />
        <h4 className="text--bold">Discount</h4>
        <p> Take a percentage or a fixed amount off of the order total. </p>
        <button onClick={this.handleSelectOnClick} type="button" className="button center-block">
          Select{' '}
        </button>
      </div>
    );
  }
}

class FreeShippingCheckbox extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  handleSelectOnClick = () => {
    const { onChange } = this.props;
    onChange('shipping', 1);
  };

  render() {
    //const className = value === 'shipping' ? 'shipping-btn active-offer' : 'shipping-btn';
    return (
      <div className="offer-item">
        <img src={icon_offer_shipping} alt="" className="offer-image" onClick={this.handleSelectOnClick} />
        <h4 className="text--bold">Free Shipping</h4>
        <p> Get free shipping on all orders over a specified purchase amount. </p>

        <button onClick={this.handleSelectOnClick} type="button" className="button center-block">
          Select{' '}
        </button>
      </div>
    );
  }
}

class OfferType extends Component {
  static propTypes = {
    onSelectOfferType: PropTypes.func,
    offerTypeSelected: PropTypes.string
  };

  componentDidMount() {
    // Auto select the offertype modal
    const { onSelectOfferType, offerTypeSelected } = this.props;

    onSelectOfferType(offerTypeSelected);
  }

  handleCheckboxListChange = (newValue) => {
    const { onSelectOfferType } = this.props;
    onSelectOfferType && onSelectOfferType(newValue);
  };

  render() {
    const options = [PercentOrFixedCheckbox, FreeShippingCheckbox];

    return (
      <div>
        <CustomCheckboxList name="offer_type" className="offer-list" options={options} onChange={this.handleCheckboxListChange} />
      </div>
    );
  }
}

export default OfferType;
