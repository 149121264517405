import { Component } from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@/components/Shared/Skeleton';

class TableLoadingState extends Component {
  static propTypes = {
    rows: PropTypes.number,
    rowOptions: PropTypes.object,
    cells: PropTypes.number,
    cellOptions: PropTypes.object
  };

  static defaultProps = {
    rows: 5,
    rowOptions: {},
    cells: 1,
    cellOptions: {}
  };

  render() {
    const { rows, rowOptions, cells, cellOptions } = this.props;
    return Array(rows)
      .fill(null)
      .map((_, row) => (
        <tr key={row} {...rowOptions}>
          <td {...cellOptions} colSpan={cells}>
            <Skeleton height="20px" width="100%" />
          </td>
        </tr>
      ));
  }
}

export default TableLoadingState;
