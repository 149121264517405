import PropTypes from 'prop-types';
import { Component } from 'react';

// Helpers
import * as api from '@/utils/http';
import { successToast } from '@/utils/toast';

class ModalForgotPassword extends Component {
  state = {
    emailAddress: '',
    requesting: false,
    userNotFound: false,
    emailAddressBlank: true
  };

  static propTypes = {
    onClose: PropTypes.func,
    isVisible: PropTypes.bool.isRequired
  };

  static defaultProps = {
    isVisible: false
  };

  handleEmailAddress = (evt) => {
    const emailAddress = evt.target.value;
    const emailAddressBlank = emailAddress === '';

    this.setState({
      emailAddress: emailAddress,
      emailAddressBlank
    });
  };

  close = () => {
    this.setState({
      emailAddress: '',
      userNotFound: false,
      emailAddressBlank: true,
      requesting: false
    });
    this.props.onClose();
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const endpoint = 'studios/forgot-password';
    const { emailAddress } = this.state;
    this.setState({ userNotFound: false, requesting: true });

    api.post(endpoint, { email: emailAddress }, false).then(
      () => {
        this.close();
        successToast('E-mail with password reset link has been sent.');
      },
      () => {
        this.setState({ userNotFound: true, requesting: false });
      }
    );
  };

  render() {
    const { isVisible } = this.props;
    const { userNotFound, emailAddressBlank, requesting } = this.state;
    const submitDisabled = requesting || emailAddressBlank;

    if (isVisible) {
      return (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={this.close}>
                <i className="icon-close"></i>
              </button>
              <h3>Forgot your password?</h3>
              <p>No worries! Just enter the email address used in your PhotoDay account and we'll send you an email on how you can reset your password.</p>
            </header>
            <main className="modal__content">
              <form onSubmit={this.onSubmit}>
                <div className={` ${userNotFound && 'has-error'}`}>
                  <input
                    onChange={this.handleEmailAddress}
                    type="email"
                    className="input--block"
                    name="forgot-email"
                    placeholder="Enter your email address"
                    value={this.state.emailAddress}
                  />
                  <small
                    style={{
                      visibility: userNotFound ? 'visible' : 'hidden'
                    }}
                    className="text--block text--danger"
                  >
                    User with such E-mail not found
                  </small>
                </div>
              </form>
            </main>
            <footer className="center modal__footer flex gap-10">
              <button className="button" disabled={submitDisabled || requesting} onClick={this.onSubmit}>
                Submit
              </button>
              <button className="button button--outline" onClick={this.close}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      );
    }

    return null;
  }
}

export default ModalForgotPassword;
