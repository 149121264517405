import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

class Custom extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    label: null,
    placeholder: null,
    disabled: null,
    onChange: () => ({})
  };

  renderInput = ({ input, type, meta: { touched, error } }) => {
    const { placeholder, disabled, component: Component } = this.props;
    return (
      <div>
        <Component {...input} disabled={disabled} placeholder={placeholder} type={type} className="input--block" />
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  render() {
    const { name, label, onChange } = this.props;

    return (
      <div>
        {label && <label>{label}</label>}
        <Field
          name={name}
          component={this.renderInput}
          validate={matchValidatePropToValidators(this.props)}
          onChange={(event, newValue) => {
            onChange && onChange(newValue);
          }}
        />
      </div>
    );
  }
}

export default Custom;
