import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { ReactSelect } from '@/components/Shared/Forms';

import Select from 'react-select';

const mapStateToProps = (state, ownProps) => {
  const { name, pricesheetItems } = ownProps;
  const productId = formValueSelector('OfferForm')(state, `${name}.id`);

  let selected = { products: [] };

  if (pricesheetItems) {
    selected = pricesheetItems.find(({ products }) => products.map(({ value }) => value).includes(productId));
  }

  return { selected: selected, pricesheetItems };
};

class ProductsForm extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    pricesheetItems: PropTypes.array
  };

  static defaultProps = {
    pricesheetItems: []
  };

  constructor(props) {
    super(props);
    this.state = {
      products: (props.selected || {}).products,
      selected: props.selected
    };
  }

  onChangeSelect = (selected) => {
    this.setState({ products: (selected || {}).products, selected });
  };

  render() {
    const { name, index, onRemove, pricesheetItems } = this.props;
    const { products, selected } = this.state;
    return (
      <div>
        <div className="flex-6">
          <Select onChange={this.onChangeSelect} options={pricesheetItems} value={selected} />
        </div>
        <div className=" flex-5">
          <ReactSelect name={`${name}.id`} options={products} />
        </div>
        {index > 0 && (
          <div className=" flex-1">
            <button className="button button--dark" onClick={onRemove}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ProductsForm);
