import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ jobId, title }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  return (
    <aside className="flex-3 flex-12-md sidebar-container">
      <header className="flex middle between sidebar-header">
        <h2 className="text--nomargin">Insights</h2>
        <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
          {title}
        </button>
      </header>

      <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
        <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/insights/sales`} activeClassName="sidebar-navigation__item--active">
          Sales
        </NavLink>
        <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/insights/customers`} activeClassName="sidebar-navigation__item--active">
          Customers
        </NavLink>
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  jobId: PropTypes.string.isRequired
};

export default Sidebar;
