import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

export function useJobTimeZone() {
  const {
    timezones,
    job: { time_zone }
  } = useSelector((state: any) => state.jobs);

  const [timeZone, setTimeZone] = useState<string>('');

  const jobTimezone = timezones && timezones.length && timezones.find((zone: { name: string; zone: string }) => zone.name === time_zone);

  useEffect(() => {
    if (jobTimezone) {
      setTimeZone(jobTimezone.zone);
    }
  });

  return timeZone;
}
