import { Component } from 'react';
import { connect } from 'react-redux';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';
import States from './states';
import { getStatesListRequest } from './actions';

import './styles.css';

const mapStateToProps = (state) => ({
  isStateLoaded: state.states.isLoaded,
  states: state.states.result
});

const mapDispatchToProps = { getStatesListRequest };

class StatesDDL extends Component {
  componentDidMount() {
    const { isStateLoaded, getStatesListRequest } = this.props;

    if (!isStateLoaded) {
      getStatesListRequest();
    }
  }

  render() {
    if (!this.props.isStateLoaded) {
      return <TextLoader height={37} />;
    }

    return <States {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatesDDL);
