import { FC, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Components
import BuyWalletCredits from '../../../Settings/Studio/Wallet/BuyWalletCredits';

// Redux
import { useDispatch } from 'react-redux';
import { getMyAccountStudioRequest } from '../../../Settings/actions';

// Helpers
import { getWalletCreditValue } from '@/utils/getWalletCreditValue';

interface ServicesSidebarProps {
  path: string;
  jobId: string;
  galleryType: string;
  studio: { id: string; credit_cents: number; knockout_cost_cents: number; color_correction_cost_cents: number; studio_credits_agreed: boolean };
}

const Sidebar: FC<ServicesSidebarProps> = ({ jobId, path, galleryType, studio }) => {
  const dispatch = useDispatch();

  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [showBuyWalletCreditsModal, setShowBuyWalletCreditsModal] = useState<boolean>(false);

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const setSectionTitle = (path: string) => {
    const pathArr: string[] = path.split('/');

    switch (true) {
      case pathArr.includes('exports'):
        return 'Exports';
      case pathArr.includes('postprocessing'):
        return 'Post-Processing';
      default:
        return 'Services';
    }
  };

  useEffect(() => {
    if (!studio?.id) {
      dispatch(getMyAccountStudioRequest({ studioId: studio.id }));
    }
  }, []);

  return (
    <>
      <aside className="flex-3 flex-12-md sidebar-container">
        <header className="flex middle between sidebar-header">
          <h2 className="text--nomargin">Services</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {path && setSectionTitle(path)}
          </button>
        </header>

        <div className={`sidebar-navigation-container ${sidebarIsOpen ? 'sidebar-navigation-container--open' : ''}`} onClick={handleToggleSidebar}>
          <nav className="sidebar-navigation-submenu">
            {galleryType === 'private' && (
              <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/services/exports`} activeClassName="sidebar-navigation__item--active">
                Exports
              </NavLink>
            )}
            <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/services/postprocessing`} activeClassName="sidebar-navigation__item--active">
              Post-Processing
            </NavLink>
          </nav>

          {/* Buy Wallet Credits */}
          <section className="sidebar-section">
            <h2>Wallet</h2>
            <div className="panel text--center">
              <p>
                You have <b>{getWalletCreditValue(studio.credit_cents).toLocaleString()}</b> credits available.
              </p>
              <hr />
              <button className="button button--center button--medium" name="buy-credits" type="button" onClick={() => setShowBuyWalletCreditsModal(true)}>
                Buy Credits
              </button>
            </div>
          </section>
        </div>
      </aside>

      {showBuyWalletCreditsModal && <BuyWalletCredits studio={studio} onBuyWalletCreditsCreateModalClose={() => setShowBuyWalletCreditsModal(false)} />}
    </>
  );
};

export default Sidebar;
