import React from 'react';
import TopNav from '@/components/Shared/TopNav';
import { Link } from 'react-router-dom';
import not_found_image from '@/assets/images/404_image.png';
import { useLocation } from 'react-router-dom-v5-compat';

import './404.css';

const NotFound404 = () => {
  const { pathname } = useLocation();
  const errorContainer = (
    <div className="container">
      <div className="error-container">
        <h2> We couldn't find the page you were looking for.</h2>
        <img src={not_found_image} alt="page_not_found" />
        <p>
          Error 404 URL: <code>{pathname}</code> <br />
          Sorry, the page you're looking for cannot be found. <br />
          Either check the URL,&nbsp;
          <Link to="/home">go to the home</Link>, or feel free to&nbsp;
          <a href="mailto:support@photoday.io">report this issue</a>.
        </p>
      </div>
    </div>
  );
  return (
    <div id="not_found_404_index">
      <TopNav />
      {errorContainer}
    </div>
  );
};

export default NotFound404;
