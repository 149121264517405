import { FC, useState, useEffect } from 'react';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import { Tooltip } from 'react-tippy';
import { differenceBy } from 'lodash';
import Select, { SingleValue, MultiValue, OnChangeValue } from 'react-select';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPeopleList, getPeopleFieldOptions, filterPeopleFieldOptionsBySubjects } from '../../../../actions';

// Helpers
import imageScaling from '@/utils/imageScaling';
import subjectsFilterOptions from '@/utils/subjectsFilterOptions';

// Types
import { SelectOptionType, Export, Subject } from '@/types';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';

// Types
interface ExportFilterGroupProps {
  jobExport: Export;
  onExportStepModalClose: () => void;
  onExportStepComplete: (newValues: Export, step: string) => void;
}

const ExportFilterGroup: FC<ExportFilterGroupProps> = ({ jobExport, onExportStepModalClose, onExportStepComplete }) => {
  const dispatch = useDispatch();

  const {
    job,
    people: { list, fields: peopleFields, requesting: peopleRequesting }
  } = useSelector((state: any) => state.jobs);

  const [selectExportSubjectsFilter, setSelectExportSubjectsFilter] = useState<SingleValue<SelectOptionType>>(null);
  const [selectExportSubjectsGroup, setSelectExportSubjectsGroup] = useState<SingleValue<SelectOptionType>>(null);
  const [selectExportSubjectsData, setSelectExportSubjectsData] = useState<MultiValue<SelectOptionType>>([]);
  const [selectExportSubjectsGroupBy, setSelectExportSubjectsGroupBy] = useState<SingleValue<SelectOptionType>>(null);
  const [exportSubjectsOptions, setExportSubjectsOptions] = useState<SelectOptionType[]>([]);

  const [peopleToBeExported, setPeopleToBeExported] = useState<string[]>([]);
  const [peopleList, setPeopleList] = useState<Subject[]>([]);
  const [recipients, setRecipients] = useState<Subject[]>([]);

  const [subjectPurchasedFilter, setSubjectPurchasedFilter] = useState('');
  const [subjectAccessedFilter, setSubjectAccessedFilter] = useState('');
  const [subjectFilterRemoved, setSubjectFilterRemoved] = useState<Subject[]>([]);

  const [subjectsPurchasedIds, setSubjectsPurchasedIds] = useState<string[]>([]);
  const [subjectsNotPurchasedIds, setSubjectsNotPurchasedIds] = useState<string[]>([]);
  const [subjectsAccessedIds, setSubjectsAccessedIds] = useState<string[]>([]);
  const [subjectsNotAccessedIds, setSubjectsNotAccessedIds] = useState<string[]>([]);

  const resetRecipientList = () => {
    const reset = [...recipients, ...subjectFilterRemoved].sort((a: any, b: any) => {
      const nameA = a.last_name.toLowerCase();
      const nameB = b.last_name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return reset;
  };

  const handleFilterByPurchasedOptions = (event: any) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectPurchasedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let recipientsWithoutPurchases = currentRecipients.filter((subject: any) => subjectsNotPurchasedIds.includes(subject.id));
      let recipientsWithPurchases = currentRecipients.filter((subject: any) => subjectsPurchasedIds.includes(subject.id));

      if (subjectAccessedFilter) {
        if (subjectAccessedFilter === 'subjectsNotAccessed') {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject: any) => subjectsNotAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject: any) => subjectsNotAccessedIds.includes(subject.id));
        } else {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject: any) => subjectsAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject: any) => subjectsAccessedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectAccessedFilter) {
        const withoutAccessed = currentRecipients.filter((subject: any) => subjectsNotAccessedIds.includes(subject.id));
        const withAccessed = currentRecipients.filter((subject: any) => subjectsAccessedIds.includes(subject.id));

        const newRecipients = subjectAccessedFilter === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }
  };

  const handleFilterByAccessedOptions = (event: any) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectAccessedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let withoutAccessed = currentRecipients.filter((subject: any) => subjectsNotAccessedIds.includes(subject.id));
      let withAccessed = currentRecipients.filter((subject: any) => subjectsAccessedIds.includes(subject.id));

      if (subjectPurchasedFilter) {
        if (subjectPurchasedFilter === 'subjectsNoPurchase') {
          withoutAccessed = withoutAccessed.filter((subject: any) => subjectsNotPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject: any) => subjectsNotPurchasedIds.includes(subject.id));
        } else {
          withoutAccessed = withoutAccessed.filter((subject: any) => subjectsPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject: any) => subjectsPurchasedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectPurchasedFilter) {
        const recipientsWithoutPurchases = currentRecipients.filter((subject: any) => subjectsNotPurchasedIds.includes(subject.id));
        const recipientsWithPurchases = currentRecipients.filter((subject: any) => subjectsPurchasedIds.includes(subject.id));

        const newRecipients = subjectPurchasedFilter === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }
  };

  const fetchPeopleListWithFilter = (filterType: string, filterSelect: any) => {
    // Fetch people list based on selected filter, group and data
    const filterSubjects = filterType === 'subject' ? filterSelect?.value : selectExportSubjectsFilter?.value || 'all_subjects';
    const filterGroup = { search_field: filterType === 'group' ? filterSelect?.value : selectExportSubjectsGroup?.value };
    const filterData = filterType === 'data' ? filterSelect : filterType === 'group' ? null : selectExportSubjectsData;
    const filterDataMapped = {
      search: filterData
        ?.map((item: SingleValue<SelectOptionType>) => (filterGroup.search_field === 'absent' ? (item?.value === 'Yes' ? true : false) : item?.value))
        .join('|')
    };

    dispatch(
      getPeopleList(
        {
          id: job.id,
          dir: 'asc',
          per_page: 10000,
          order: 'last_name',
          search_accuracy: 'exact',
          ...(filterSelect.value !== 'everyone' && filterSelect.length > 0 ? filterGroup : {}),
          ...filterDataMapped,
          ...{ subject_filter: filterSubjects }
        },
        ({ data }: { data: Subject[] }) => {
          const { job_status: jobStatus } = job;

          setPeopleToBeExported(data.map((person: any) => person.id));

          const peopleListAccessedIds = data.filter((person) => person?.accessed === true).map((person) => person.id);
          setSubjectsAccessedIds(peopleListAccessedIds);

          const peopleListNotAccessedIds = data.filter((person) => person?.accessed === false).map((person) => person.id);
          setSubjectsNotAccessedIds(peopleListNotAccessedIds);

          const peopleListPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === true)
            .map((person) => person.id);
          setSubjectsPurchasedIds(peopleListPurchasedIds);

          const peopleListNotPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === false)
            .map((person) => person.id);
          setSubjectsNotPurchasedIds(peopleListNotPurchasedIds);

          // Get people field options based on current available subjects
          // Only call to narrow field options if filter type is not field options
          if (filterType !== 'data') {
            dispatch(filterPeopleFieldOptionsBySubjects());
          }
        }
      )
    );
  };

  const searchValueOptionsForField = (field: string | undefined, data: MultiValue<SelectOptionType>) => {
    const hasEmptyValue = data?.some((item) => item.value === '');

    if (field == null || peopleFields == null || Object.keys(peopleFields).length === 0 || hasEmptyValue) {
      return [];
    }

    const fieldLabel = selectExportSubjectsGroup?.label?.toString().replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());

    return [
      { value: '', label: `${fieldLabel} Empty` },
      ...(field !== 'everyone' && peopleFields[field ?? '']
        ? peopleFields[field ?? ''].sort().map((field: OnChangeValue<SelectOptionType, true>) => ({ value: field, label: field }))
        : [])
    ];
  };

  const handleSubjectsFilterChange = (select: OnChangeValue<SelectOptionType, false>) => {
    setSelectExportSubjectsFilter(select);
    fetchPeopleListWithFilter('subject', select);
  };

  const handleSubjectsGroupChange = (select: OnChangeValue<SelectOptionType, false>) => {
    setSelectExportSubjectsData([]);
    setSelectExportSubjectsGroup(select);

    if (select?.value === 'everyone') {
      fetchPeopleListWithFilter('group', select);
    }
  };

  const handleSubjectsDataChange = (select: OnChangeValue<SelectOptionType, true>) => {
    const hasEmptyValue = select.some((item) => item.value === '');

    if (hasEmptyValue) {
      const filteredSelect = select.filter((item) => item.value === '');
      setSelectExportSubjectsData(filteredSelect);
      fetchPeopleListWithFilter('data', filteredSelect);
    } else {
      setSelectExportSubjectsData(select);
      fetchPeopleListWithFilter('data', select);
    }
  };

  const handlePersonCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const personChecked = event.target.checked;
    const personId = event.target.value;

    if (personChecked) {
      setPeopleToBeExported([...peopleToBeExported, personId]);
    } else {
      setPeopleToBeExported([...peopleToBeExported.filter((id) => id !== personId)]);
    }
  };

  const handlePersonSelectAll = () => {
    if (peopleToBeExported?.length === recipients?.length) {
      setPeopleToBeExported([]);
    } else {
      setPeopleToBeExported(recipients.map((person: any) => person?.id));
    }
  };

  useEffect(() => {
    setPeopleList(list);
  }, [list]);

  useEffect(() => {
    setRecipients(peopleList);
  }, [peopleList]);

  useEffect(() => {
    setPeopleToBeExported(recipients.map((person: any) => person.id));
  }, [recipients]);

  useEffect(() => {
    if (subjectPurchasedFilter) {
      handleFilterByPurchasedOptions({
        target: {
          name: subjectPurchasedFilter,
          checked: true
        }
      });
    }

    if (subjectAccessedFilter) {
      handleFilterByAccessedOptions({
        target: {
          name: subjectAccessedFilter,
          checked: true
        }
      });
    }
  }, [subjectPurchasedFilter, subjectAccessedFilter]);

  useEffect(() => {
    const { group_by: exportGroupBy, subject_ids: exportSubjectIds, field_options: exportFieldOptions, subject_filter: exportSubjectFilter } = jobExport;

    const exportSubjectsGroup = { search_field: Object.keys(exportFieldOptions || {})[0] };
    const exportSubjectsData = { search: Object.values(exportFieldOptions || {})[0]?.join('|') };

    const exportSubjectsGroupValue = exportSubjectsGroup.search_field;
    const exportSubjectsDataValue = exportSubjectsData.search;

    // Set subjects filter select
    if (exportSubjectFilter) {
      setSelectExportSubjectsFilter(subjectsFilterOptions.find((filter) => filter?.value === exportSubjectFilter) || null);
    } else {
      setSelectExportSubjectsFilter(subjectsFilterOptions[0]);
    }

    // Set export subjects group select
    if (exportSubjectsGroupValue) {
      setSelectExportSubjectsGroup({
        value: exportSubjectsGroupValue,
        label: exportSubjectsGroupValue.replace('session_start', 'check-in_date').replace('_', ' ')
      });
    } else {
      setSelectExportSubjectsGroup({ value: 'everyone', label: 'Everyone' });
    }

    // Set export subjects data select
    if (exportSubjectsDataValue) {
      setSelectExportSubjectsData(exportSubjectsDataValue.split('|').map((item) => ({ label: item, value: item })));
    }

    // Set export subjects group by select
    if (exportGroupBy) {
      setSelectExportSubjectsGroupBy({ value: exportGroupBy, label: exportGroupBy.replace('session_start', 'check-in_date').replace('_', ' ') });
    }

    // Fetch people data
    dispatch(
      getPeopleList(
        {
          id: job.id,
          dir: 'asc',
          per_page: 10000,
          order: 'last_name',
          search_accuracy: 'exact',
          ...(exportSubjectsGroupValue !== 'everyone' ? exportSubjectsData : {}),
          ...(exportSubjectsGroupValue !== 'everyone' ? exportSubjectsGroup : {}),
          ...{ subject_filter: exportSubjectFilter }
        },
        ({ data }: { data: Subject[] }) => {
          const { job_status: jobStatus } = job;

          // Set people to be exported
          if (exportSubjectIds) {
            setPeopleToBeExported(exportSubjectIds);
          } else {
            setPeopleToBeExported(data.map((person: any) => person.id));
          }

          const peopleListAccessedIds = data.filter((person) => person?.accessed === true).map((person) => person.id);
          setSubjectsAccessedIds(peopleListAccessedIds);

          const peopleListNotAccessedIds = data.filter((person) => person?.accessed === false).map((person) => person.id);
          setSubjectsNotAccessedIds(peopleListNotAccessedIds);

          const peopleListPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === true)
            .map((person) => person.id);
          setSubjectsPurchasedIds(peopleListPurchasedIds);

          const peopleListNotPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === false)
            .map((person) => person.id);
          setSubjectsNotPurchasedIds(peopleListNotPurchasedIds);
        }
      )
    );

    // Fetch subject field data
    dispatch(getPeopleFieldOptions({ id: job.id }));
  }, []);

  useEffect(() => {
    setExportSubjectsOptions(searchValueOptionsForField(selectExportSubjectsGroup?.value, selectExportSubjectsData));
  }, [selectExportSubjectsGroup, selectExportSubjectsData]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--secondary">
        <button className="button button--action modal__close" name="button" type="button" onClick={onExportStepModalClose}>
          <i className="icon-close"></i>
        </button>
        <main className="flex modal__content">
          <section className="flex column flex-5 flex-12-md modal__content-section modal__content-section--dark">
            <div>
              <h3 className="text--left">Select and Group Subjects</h3>
              {/* Filter */}
              <label htmlFor="filter">1. Filter Subjects</label>
              <Select
                className="flex-12 select select--capitalize mb-10"
                classNamePrefix="select"
                isLoading={peopleRequesting}
                value={selectExportSubjectsFilter}
                options={subjectsFilterOptions}
                onChange={handleSubjectsFilterChange}
              />
              <Select
                id="filter"
                className="select select--capitalize mb-10"
                classNamePrefix="select"
                placeholder="Only the following group and data..."
                isLoading={peopleRequesting}
                value={selectExportSubjectsGroup}
                options={[
                  { label: 'Everyone', value: 'everyone' },
                  ...Object.keys(peopleFields)
                    .filter((key) => key !== 'image_name')
                    .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }))
                ]}
                onChange={handleSubjectsGroupChange}
              />
              <Select
                className="select select--height-limited mb-10"
                classNamePrefix="select"
                isMulti={true}
                isClearable={true}
                isDisabled={selectExportSubjectsGroup === null || selectExportSubjectsGroup.value === 'everyone'}
                placeholder="Select data..."
                value={selectExportSubjectsData}
                options={exportSubjectsOptions}
                onChange={handleSubjectsDataChange}
              />
              {/* Group by */}
              <label htmlFor="group">2. Group</label>
              <Select
                id="group"
                className="select select--capitalize"
                classNamePrefix="select"
                placeholder="Group by"
                isLoading={peopleRequesting}
                value={selectExportSubjectsGroupBy}
                options={Object.keys(peopleFields)
                  .filter((key) => key !== 'image_name')
                  .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }))}
                onChange={(select: SingleValue<SelectOptionType>) => setSelectExportSubjectsGroupBy(select)}
              />
              <div className="mt-20 animate">
                <p>Optional Filters:</p>
                <h6 className="mt-10 text--uppercase">Filter by purchase</h6>
                <fieldset>
                  <input
                    type="checkbox"
                    className="hidden"
                    name="subjectsPurchase"
                    id="subjectsPurchase"
                    checked={subjectPurchasedFilter === 'subjectsPurchase'}
                    onChange={handleFilterByPurchasedOptions}
                  />
                  <label htmlFor="subjectsPurchase" className="label--checkbox">
                    Only Subjects with Purchases
                  </label>
                  <input
                    type="checkbox"
                    className="hidden"
                    name="subjectsNoPurchase"
                    id="subjectsNoPurchase"
                    checked={subjectPurchasedFilter === 'subjectsNoPurchase'}
                    onChange={handleFilterByPurchasedOptions}
                  />
                  <label htmlFor="subjectsNoPurchase" className="label--checkbox">
                    Only Subjects without Purchases
                  </label>
                </fieldset>
                <h6 className="mt-10 text--uppercase">Filter by accessed gallery</h6>
                <fieldset>
                  <input
                    type="checkbox"
                    className="hidden"
                    name="subjectsAccessed"
                    id="subjectsAccessed"
                    checked={subjectAccessedFilter === 'subjectsAccessed'}
                    onChange={handleFilterByAccessedOptions}
                  />
                  <label htmlFor="subjectsAccessed" className="label--checkbox">
                    Only Subjects That Accessed Gallery
                  </label>
                  <input
                    type="checkbox"
                    className="hidden"
                    name="subjectsNotAccessed"
                    id="subjectsNotAccessed"
                    checked={subjectAccessedFilter === 'subjectsNotAccessed'}
                    onChange={handleFilterByAccessedOptions}
                  />
                  <label htmlFor="subjectsNotAccessed" className="label--checkbox">
                    Only Subjects That Have NOT Accessed Gallery
                  </label>
                </fieldset>
              </div>
            </div>
            <footer className="mt-auto center">
              <hr className="job-exports-filter__divider" />
              <p className="text--nomargin">
                <small>
                  Need help? Check out our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support article on export files
                  </a>
                  .
                </small>
              </p>
            </footer>
          </section>
          <section className="flex column flex-7 flex-12-md column between modal__content-section">
            <header>
              <h3>Data Preview</h3>
              <aside className="flex between">
                <p className="text--nomargin">{!peopleRequesting && <small>The following {peopleToBeExported?.length} people will be exported:</small>}</p>
                <button className="button button--link animate" name="select-all" onClick={handlePersonSelectAll}>
                  <small>{peopleToBeExported?.length === recipients?.length ? 'Deselect All' : 'Select All'}</small>
                </button>
              </aside>
            </header>
            <ul className="panel animate job-exports-filter__list">
              {peopleRequesting ? (
                <TableLoader rows={10} rowHeight={20} />
              ) : (
                <>
                  {recipients?.map((person) => (
                    <li key={person.id} className="flex between middle job-exports-filter__item">
                      <figure className="flex middle">
                        <img
                          className="job-exports-filter__image"
                          src={person.session_photo_url ? imageScaling({ url: person.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
                          alt={`${person.first_name}`}
                          width="40"
                        />
                        <figcaption>
                          <b>
                            {person.last_name}, {person.first_name}
                          </b>
                        </figcaption>
                      </figure>
                      <Tooltip {...{ title: `Toggle ${person.first_name}`, theme: 'light', distance: 4 }}>
                        <input type="checkbox" name="toggle" value={person.id} checked={peopleToBeExported.includes(person.id)} onChange={handlePersonCheck} />
                      </Tooltip>
                    </li>
                  ))}
                </>
              )}
            </ul>
            <footer className="flex end nogrow mt-auto gap-10">
              <button className="button button--outline" type="button" name="cancel" onClick={onExportStepModalClose}>
                Cancel
              </button>
              <button
                className="button job-exports-filter__button"
                name="confirm"
                type="button"
                onClick={() =>
                  onExportStepComplete(
                    {
                      subject_ids: peopleToBeExported,
                      group_by: selectExportSubjectsGroupBy?.value,
                      subject_filter: selectExportSubjectsFilter?.value,
                      field_options: {
                        [`${selectExportSubjectsGroup?.value}`]: selectExportSubjectsData?.map((item: SingleValue<SelectOptionType>) => item?.value ?? '')
                      }
                    },
                    'filter'
                  )
                }
                disabled={
                  (selectExportSubjectsData === null && selectExportSubjectsGroup?.value !== 'everyone') ||
                  selectExportSubjectsGroupBy === null ||
                  peopleToBeExported?.length === 0 ||
                  peopleRequesting
                }
              >
                Confirm
              </button>
            </footer>
          </section>
        </main>
      </div>
    </aside>
  );
};

export default ExportFilterGroup;
