import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect, memo } from 'react';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getSidebarPeopleList, getPeopleList, getPeopleFieldOptions, createPeopleAssociationPhotos, deletePeoplePhoto } from '../../../actions';

// Helpers
import imageScaling from '@/utils/imageScaling';
import subjectsFilterOptions from '@/utils/subjectsFilterOptions';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';

const Sidebar = memo(function Sidebar({
  jobId = '',
  biometricsEnabled,
  nextSubject,
  filterPeople = {},
  searchPhotos,
  filterPhotos,
  photoSelected = [],
  unselectedPhotos = [],
  allPhotosSelected = false,
  shouldClearSubjectsSearchBy,
  onMouseOverSidebar,
  onPhotosPeopleFilter
}) {
  const dispatch = useDispatch();
  const {
    job,
    query,
    people: { list: peopleList, sidebarList, fields, requesting: peopleRequesting }
  } = useSelector((state) => state.jobs);

  const [filterBy, setFilterBy] = useState(subjectsFilterOptions[0]);

  const [searchValueOptions, setSearchValueOptions] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchGroup, setSearchGroup] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const filterOptions = subjectsFilterOptions;

  const setPeopleFilter = (data) => {
    if (data.length) {
      dispatch(getPeopleFieldOptions({ id: jobId }));
    }
  };

  const searchValueOptionsForField = (field, data) => {
    const hasEmptyValue = data?.some((item) => item.value === '');

    if (field == null || fields == null || hasEmptyValue) {
      return [];
    }

    const fieldLabel = searchGroup.label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    return [{ value: '', label: `${fieldLabel} Empty` }].concat(
      fields[field].sort((a, b) => a.toString().localeCompare(b)).map((value) => ({ value: value, label: value }))
    );
  };

  const handlePeopleSelect = (person = {}) => {
    onPhotosPeopleFilter(person);
  };

  const handleSearchToggle = () => {
    if (showSearch) handleClearSearchBy();
    setShowSearch(!showSearch);
  };

  const handleFilterByChange = (filter) => {
    setFilterBy(filter);
  };

  const handleSearchGroupChange = (select) => {
    setSearchData([]);

    if (select !== null) {
      setSearchGroup(select);
    } else {
      setSearchGroup('');
    }
  };

  const handleSearchDataChange = (select) => {
    const hasEmptyValue = select.some((item) => item.value === '');

    if (hasEmptyValue) {
      setSearchData(select.filter((item) => item.value === ''));
    } else {
      setSearchData(select);
    }
  };

  const handleSearchBy = (e) => {
    e.preventDefault();

    const filters = {
      all: null,
      absent: { absent: true },
      with_ref: { with_ref: true },
      without_ref: { without_ref: true },
      has_photos: { has_photos: true },
      has_no_photos: { has_photos: false },
      with_ref_no_photos: { with_ref: true, has_photos: false }
    };

    const args = {
      id: jobId,
      per_page: 10000,
      order: 'last_name',
      dir: 'asc',
      ...filters[filterBy.value]
    };

    if (searchGroup.value && searchData.length > 0) {
      args['search_accuracy'] = 'exact';
      args['search_field'] = searchGroup.value;
      args['search'] = searchData.map((item) => item.value).join('|');
    }

    onPhotosPeopleFilter({});
    dispatch(getSidebarPeopleList(args));
  };

  const handleClearSearchBy = () => {
    setSearchData([]);
    setSearchGroup('');
    onPhotosPeopleFilter({});
    setFilterBy(subjectsFilterOptions[0]);
    dispatch(getSidebarPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const handleDragEnter = (e) => {
    e.stopPropagation();

    const element = e.target;

    if (element.classList.contains('job-people-sidebar__item')) {
      element.classList.add('job-people-sidebar__item--drag');
    }
  };

  const handleDragLeave = (e) => e.target.classList.remove('job-people-sidebar__item--drag');

  const handleDrop = (e, id) => {
    e.stopPropagation();

    const personSelected = filterPeople.id;
    const types = e.dataTransfer.types;
    const hasImageFiles = types.length && types.some((type) => type === 'Files');

    e.target.classList.remove('job-people-sidebar__item--drag');

    if (!hasImageFiles) {
      dispatch(
        createPeopleAssociationPhotos(
          {
            id: jobId,
            subject_id: personSelected, // origin
            subject_ids: [id], // destination
            perform_all: allPhotosSelected,
            ...(filterPhotos ? { filter_photos: filterPhotos } : {}),
            ...(searchPhotos ? { search: searchPhotos } : {}),
            ...(allPhotosSelected ? {} : { photo_ids: photoSelected }),
            ...(allPhotosSelected ? {} : { unselected_photo_ids: unselectedPhotos }),
            last_uploaded: query.people === 'last_uploaded'
          },
          () => {
            if (personSelected) {
              dispatch(
                deletePeoplePhoto({
                  id: personSelected,
                  perform_all: allPhotosSelected,
                  photo_ids: photoSelected,
                  unselected_photo_ids: unselectedPhotos
                })
              );
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    const {
      photo_stats: { subjects }
    } = job;

    if (!sidebarList.length || !peopleList.length || (subjects && (subjects !== peopleList.length || subjects !== sidebarList.length))) {
      const listArgs = { id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' };

      // Get list for all gallery components
      dispatch(getPeopleList(listArgs));

      // Get list for the sidebar
      dispatch(
        getSidebarPeopleList(listArgs, ({ data }) => {
          setPeopleFilter(data);
        })
      );
    } else {
      setPeopleFilter(sidebarList);
    }
  }, []);

  useEffect(() => {
    if (shouldClearSubjectsSearchBy === true) {
      handleClearSearchBy();
    }
  }, [shouldClearSubjectsSearchBy]);

  useEffect(() => {
    setSearchValueOptions(searchValueOptionsForField(searchGroup.value, searchData));
  }, [searchGroup, searchData]);

  return (
    <>
      <aside className="flex between middle nowrap job-people__header--mobile">
        {filterPeople.id && (
          <button className="button button--outline button--xlarge" onClick={handleToggleSidebar}>
            <figure className="flex middle start nowrap text--truncate">
              <div className="job-people-sidebar__image-box">
                <LazyLoadImage
                  className="job-people-sidebar__image"
                  src={filterPeople.session_photo_url ? imageScaling({ url: filterPeople.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
                  alt={filterPeople.first_name}
                  height={40}
                  draggable="false"
                />
              </div>
              <figcaption className="flex column text--truncate">
                <small className="text--truncate text--capitalize">{filterPeople.first_name.toLowerCase()}</small>
                <h3 className="text--nomargin text--truncate text--capitalize">{filterPeople.last_name.toLowerCase()}</h3>
              </figcaption>
            </figure>
            <div>
              <i className="icon-people job-people_icon" />
            </div>
          </button>
        )}
      </aside>
      <div
        className={`job-people__container ${sidebarIsOpen ? 'job-people__container--open' : ''}`}
        onClick={handleToggleSidebar}
        onMouseEnter={onMouseOverSidebar}
      >
        <header className="flex middle between job-people-sidebar__header hidden--md">
          <h2 className="text--nomargin">Subjects</h2>
          <aside className="flex end button-group">
            {job.setup_steps.uploaded_subjects && (
              <Tooltip className="animate" title="Search" position="top" arrow={false} delay={300}>
                <button className="button button--outline button--small" name="search" type="button" onClick={handleSearchToggle}>
                  <i className="icon-search" />
                </button>
              </Tooltip>
            )}
            <Tooltip title="View/Edit" position="top" arrow={false} delay={300}>
              <Link className="button button--outline button--small" to={`/jobs/${jobId}/people`} draggable={false}>
                <i className="icon-list" />
              </Link>
            </Tooltip>
          </aside>
        </header>

        <form className={`job-people-sidebar__search ${showSearch ? 'job-people-sidebar__search--active' : ''}`} onSubmit={handleSearchBy}>
          <Select
            className="select mb-10"
            classNamePrefix="select"
            placeholder="Filter by"
            value={filterBy}
            options={filterOptions}
            onChange={handleFilterByChange}
          />
          <Select
            className="select mb-10"
            classNamePrefix="select"
            placeholder="Search by"
            isClearable={true}
            value={searchGroup}
            options={Object.keys(fields)
              .filter((key) => key !== 'image_name')
              .sort((a, b) => a.localeCompare(b))
              .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }))}
            onChange={handleSearchGroupChange}
          />
          <Select
            className="select select--height-short mb-10"
            classNamePrefix="select"
            isMulti={true}
            isClearable={false}
            placeholder="Select data..."
            isDisabled={!searchGroup.value}
            value={searchData}
            options={searchValueOptions}
            onChange={handleSearchDataChange}
          />
          <div className="grid column-2 gap-10">
            <button
              className="button button--block"
              name="search"
              type="submit"
              data-loading={peopleRequesting}
              disabled={peopleRequesting || (searchGroup && !(searchData && searchData.length > 0))}
            >
              Search
            </button>
            <button
              className="button button--outline button--block"
              name="clear"
              type="button"
              onClick={handleClearSearchBy}
              disabled={!searchGroup && filterBy.value === 'all'}
            >
              Clear Search
            </button>
          </div>
        </form>

        {job.setup_steps.uploaded_subjects ? (
          <>
            {peopleRequesting && !sidebarList.length && <TableLoader rows={5} rowHeight={64} />}
            {!peopleRequesting && !sidebarList.length && (
              <aside className="panel panel--secondary text--center">
                <p className="text--nomargin">No results found. Please try new search.</p>
              </aside>
            )}
            {(!peopleRequesting || sidebarList.length > 0) && (
              <ul className="job-people-sidebar__list">
                {sidebarList.map((person) => (
                  <li
                    className={`flex middle job-people-sidebar__item ${
                      (query.people === person.id && !nextSubject.id) || nextSubject.id === person.id ? 'job-people-sidebar__item--active' : ''
                    }`}
                    key={person.id}
                    onClick={() => handlePeopleSelect(person)}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onDrop={(e) => handleDrop(e, person.id)}
                  >
                    <figure className="flex middle nowrap">
                      {biometricsEnabled && (
                        <div className="job-people-sidebar__image-box">
                          <LazyLoadImage
                            className="job-people-sidebar__image"
                            src={person.session_photo_url ? imageScaling({ url: person.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
                            alt={person.first_name}
                            height={40}
                            draggable="false"
                          />
                        </div>
                      )}
                      <figcaption className=" text--capitalize job-people-sidebar__figcaption">
                        {`${person.last_name.toLowerCase()}, ${person.first_name.toLowerCase()}`} {person.photos_count > 0 && `(${person.photos_count})`}
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            )}
          </>
        ) : (
          <aside className="panel panel--secondary text--center">
            <p className="text--nomargin">
              To add subjects data,{' '}
              <Link className="button button--link text--bold" to={`/jobs/${jobId}/people`} draggable={false}>
                click here
              </Link>
              .
            </p>
          </aside>
        )}
      </div>
    </>
  );
});

Sidebar.propTypes = {
  jobId: PropTypes.string.isRequired,
  filterPeople: PropTypes.object,
  photoSelected: PropTypes.array,
  unselectedPhotos: PropTypes.array,
  allPhotosSelected: PropTypes.bool
};

export default Sidebar;
