import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

class Textarea extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.string,
    className: PropTypes.string,
    subMessage: PropTypes.string,
    onInput: PropTypes.func
  };

  static defaultProps = {
    label: null,
    placeholder: null,
    rows: '6',
    className: '',
    subMessage: '',
    onInput: null
  };

  renderTextarea = ({ input, meta: { touched, error } }) => {
    const { placeholder, rows, className, transformValueOnChange, onInput } = this.props;
    const { onChange } = input;
    const handleOnChange = (event) => {
      onChange(transformValueOnChange ? transformValueOnChange(event.target.value) : event.target.value);
    };
    return (
      <div>
        <div>
          <textarea {...input} rows={rows} placeholder={placeholder} className={className || 'input--block'} onChange={handleOnChange} onInput={onInput} />
          {this.props.children}
        </div>
        {touched && error && <small className="text--block text--danger">{error}</small>}
      </div>
    );
  };

  componentDidUpdate(nextProps) {
    if (nextProps.required !== this.props.required) this.forceUpdate();
  }

  render() {
    const { name, label, type, subMessage } = this.props;

    return (
      <div>
        {label && (
          <label>
            {label}
            {subMessage && <span className="sub-message">{subMessage}</span>}
          </label>
        )}
        <Field name={name} component={this.renderTextarea} type={type} validate={matchValidatePropToValidators(this.props)} />
      </div>
    );
  }
}

export default Textarea;
