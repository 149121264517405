import { FC, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getJobMarketingMessages } from '../../actions';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Types
import { MarketingSection, MarketingMessage, MarketingMessageType, MarketingMessageCode } from '@/types';

const ActiveMessages: FC = () => {
  const dispatch = useDispatch();

  const params: { jobId: string } = useParams();
  const { jobId } = params;

  const { marketingMessages } = useSelector((state: any) => state.jobs);

  const [sectionTargetExpanded, setSectionTargetExpanded] = useState<{ [sectionTarget: string]: boolean } | null>(null);

  const [showSmsPreviewModal, setShowSmsPreviewModal] = useState<boolean>(false);
  const [smsPreviewSampleContent, setSmsPreviewSampleContent] = useState<{ title: string; message: string[] } | null>(null);

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState<boolean>(false);
  const [emailPreviewSampleContent, setEmailPreviewSampleContent] = useState<{ title: string; message: string } | null>(null);

  const handleSessionExpandToggle = (event: React.UIEvent<HTMLElement>, { selectedSection }: { selectedSection: string }): void => {
    event.stopPropagation();
    setSectionTargetExpanded({ ...sectionTargetExpanded, [selectedSection]: !sectionTargetExpanded?.[selectedSection] });
  };

  const handlePreviewOpen = ({
    messageType,
    messageTrigger,
    messageSample
  }: {
    messageType: string;
    messageTrigger: string | null;
    messageSample: string | string[];
  }): void => {
    if (messageType === MarketingMessageType.Sms) {
      const messageCoercedToArray = Array.isArray(messageSample) ? messageSample : [messageSample];

      setShowSmsPreviewModal(true);
      setSmsPreviewSampleContent({ title: messageTrigger ?? '', message: messageCoercedToArray });
    } else if (messageType === MarketingMessageType.Email) {
      setShowEmailPreviewModal(true);
      setEmailPreviewSampleContent({ title: messageTrigger ?? '', message: messageSample.toString() });
    }
  };
  const handleSmsPreviewClose = (): void => setShowSmsPreviewModal(false);
  const handleEmailPreviewClose = (): void => setShowEmailPreviewModal(false);

  useEffect(() => {
    dispatch(getJobMarketingMessages({ jobId }));
  }, []);

  return (
    <>
      <header className="job-marketing__header">
        <hgroup>
          <h2 className="text--nomargin">Active Messages</h2>
          <h5 className="text--normal">
            Below are the currently active messages for this job based on its job status and settings. Active Messages are dynamically adjusted based on changes
            you make to your job.{' '}
            <a href="https://support.photoday.io/en/articles/2337786-what-text-messages-do-you-send-to-customers" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
            .
          </h5>
        </hgroup>
      </header>

      {marketingMessages.map((messageSection: MarketingSection, index: number) => {
        const shouldShowTriggerColumn = messageSection.code !== MarketingMessageCode.CustomPromos;
        const shouldShowPromoColumn = messageSection.code === MarketingMessageCode.CustomPromos;
        const shouldShowScheduleTimeColumn =
          messageSection.code === MarketingMessageCode.Scheduled || messageSection.code === MarketingMessageCode.CustomPromos;
        const shouldShowSentTimeColumn = messageSection.code === MarketingMessageCode.Sent;

        const innerMessagesTable = (
          <table className="table">
            <thead className="table__header">
              <tr>
                <th>Type</th>
                {shouldShowTriggerColumn && <th>Trigger</th>}
                {shouldShowPromoColumn && <th>Promo</th>}
                {shouldShowScheduleTimeColumn && <th>Scheduled Date</th>}
                {shouldShowSentTimeColumn && <th>Sent Date</th>}
                <th>Message</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {messageSection?.messages.map((sectionMessage: MarketingMessage, index: number) => (
                <tr key={index}>
                  <td>{sectionMessage.campaign_title}</td>
                  {shouldShowTriggerColumn && <td>{sectionMessage.trigger}</td>}
                  {shouldShowPromoColumn && <td>{sectionMessage.custom_promo_title}</td>}
                  {shouldShowScheduleTimeColumn && <td>{shortDate(sectionMessage.scheduled_at) ?? '-'}</td>}
                  {shouldShowSentTimeColumn && <td>{shortDate(sectionMessage.sent_at) ?? '-'}</td>}
                  <td>
                    <button
                      className="button button--link"
                      name="view"
                      type="button"
                      onClick={() =>
                        handlePreviewOpen({
                          messageType: sectionMessage.message_type,
                          messageTrigger: sectionMessage.trigger,
                          messageSample: sectionMessage.sample
                        })
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );

        return (
          <Fragment key={index}>
            {messageSection.code === MarketingMessageCode.Sent ? (
              <>
                <hgroup className="mt-40">
                  <h2 className="text--nomargin">{messageSection.title}</h2>
                  <h5 className="text--normal">{messageSection.description}</h5>
                </hgroup>
                {innerMessagesTable}
              </>
            ) : (
              <section
                className={`panel panel-accordion panel-accordion--large ${sectionTargetExpanded?.[messageSection.code] ? '' : 'panel-accordion--contracted'} panel-accordion--group`}
              >
                <header className="panel-accordion__header" onClick={(event) => handleSessionExpandToggle(event, { selectedSection: messageSection.code })}>
                  <hgroup>
                    <h4 className="text--nomargin">{messageSection.title}</h4>
                    <h6 className="text--normal" dangerouslySetInnerHTML={{ __html: messageSection.description }}></h6>
                  </hgroup>
                </header>
                <main className="panel-accordion__content">{innerMessagesTable}</main>
              </section>
            )}
          </Fragment>
        );
      })}

      {/* Modal SMS preview */}
      {showSmsPreviewModal && (
        <aside className="modal animate">
          <div className="modal__box">
            <div className="modal__content">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleSmsPreviewClose}>
                <i className="icon-close"></i>
              </button>
              <h4 className="text--left text--truncate">{smsPreviewSampleContent?.title}</h4>
              <figure className="job-marketing__iphone">
                {smsPreviewSampleContent?.message?.map((contentMessage: string, index: number) => (
                  <figcaption key={index} className="job-marketing__iphone-message">
                    <div className="job-marketing__iphone-bubble">{contentMessage}</div>
                    <small className="job-marketing__iphone-delivered">Delivered</small>
                  </figcaption>
                ))}
              </figure>
            </div>
          </div>
        </aside>
      )}

      {/* Modal Email preview */}
      {showEmailPreviewModal && (
        <aside className="modal animate">
          <div className="modal__box">
            <div className="modal__content">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleEmailPreviewClose}>
                <i className="icon-close"></i>
              </button>
              <h4 className="text--left text--truncate">{emailPreviewSampleContent?.title}</h4>
              <div className="job-marketing__email" dangerouslySetInnerHTML={{ __html: emailPreviewSampleContent?.message || '' }} />
            </div>
          </div>
        </aside>
      )}
    </>
  );
};

export default ActiveMessages;
