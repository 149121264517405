import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PhotoDayGrid from '@/components/Shared/PhotodayGrid';
import OfferRow from './OfferRow';
import CreateOrEditOfferModal from '../Offers/CreateOrEditOfferModal';
import DeleteOfferModal from '../Offers/DeleteOfferModal';

import { getPricesheetListRequest } from '../PriceSheets/actions';
import { creditSheetListRequest, destroyCreditSheetRequest, updateCreditSheetRequest, createCreditSheetRequest } from './actions';
import { pricesheetsToOptions, getOfferType } from '../Offers/selectors';

import SafeLink from '@/components/Shared/Link';

import '../Offers/offers.css';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      user,
      studio: { id: studioId }
    },
    users: { studio }
  } = state;

  const roles = (user && user.roles) || [];
  const offerType = getOfferType(ownProps);
  const pricesheets = pricesheetsToOptions(state);
  const lab = { id: studio.lab_id, name: studio.lab_name };

  return {
    studioId,
    roles,
    lab,
    pricesheets,
    offerType,
    ...state.advancepay
  };
};

const mapDispatchToProps = {
  creditSheetListRequest,
  getPricesheetListRequest,
  destroyCreditSheetRequest,
  updateCreditSheetRequest,
  createCreditSheetRequest
};

class AdvancePay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOffer: null,
      showOfferCreateOrEditModal: false,
      showOfferRemoveModal: false,
      redirectTo: 0
    };
  }

  componentDidMount() {
    const { creditSheetListRequest, getPricesheetListRequest } = this.props;

    getPricesheetListRequest({ page: 1, perPage: 1000, order: 'name', dir: 'ASC' });
    creditSheetListRequest({ page: 1, perPage: 15, order: 'created_at', dir: 'DESC' });
  }

  fetchCreditSheets = (pager) => {
    const { creditSheetListRequest } = this.props;

    creditSheetListRequest(pager, (payload) => {
      const { pager, result } = payload;
      const { page, totalPages } = pager;

      if (!result.length && page > 1) {
        creditSheetListRequest({ ...pager, page: Math.min(page - 1, totalPages) });
      }
    });
  };

  handleOfferCreateOrEditDialogSave = (creditSheet) => {
    const { studioId, lab, pager } = this.props;
    const { createCreditSheetRequest, updateCreditSheetRequest } = this.props;

    if (creditSheet.id) {
      updateCreditSheetRequest({ studioId, creditSheet }, () => {
        this.handleOfferDialogsCancel();
        this.fetchCreditSheets(pager);
      });
    } else {
      createCreditSheetRequest({ studioId, creditSheet: { ...creditSheet, lab_id: lab.id } }, (newCreditSheet) => {
        this.handleOfferDialogsCancel();
        this.fetchCreditSheets(pager);
        this.setState({
          redirectTo: newCreditSheet.id
        });
      });
    }
  };

  handleOfferDialogsCancel = () => {
    this.setState({
      activeOffer: null,
      showOfferCreateOrEditModal: false,
      showOfferRemoveModal: false
    });
  };

  handleDeleteOfferDialogConfirm = (creditSheet) => {
    const { studioId, destroyCreditSheetRequest, pager } = this.props;

    destroyCreditSheetRequest({ studioId, creditSheetId: creditSheet.id }, () => {
      this.handleOfferDialogsCancel();
      this.fetchCreditSheets(pager);
    });
  };

  handleAddOfferClick = () => {
    this.setState({
      activeOffer: {},
      showOfferCreateOrEditModal: true
    });
  };

  handleOfferGridRemoveAction = (creditSheet) => {
    this.setState({
      activeOffer: Object.assign({}, creditSheet),
      showOfferRemoveModal: true
    });
  };

  handleOfferGridSettingsAction = (creditSheet) => {
    this.setState({
      activeOffer: Object.assign({}, creditSheet),
      showOfferCreateOrEditModal: true
    });
  };

  render() {
    const { result, roles, entities, pager, pricesheets, requesting } = this.props;
    const { showOfferCreateOrEditModal, showOfferRemoveModal, activeOffer, redirectTo } = this.state;

    // Permissions
    const canManageAdvancePayCredits = roles.includes('manage_gift_cards');

    const gridHeader = [
      {
        fieldName: 'name',
        displayName: 'Name',
        sortable: true
      },
      {
        fieldName: 'description',
        displayName: 'Description',
        sortable: false
      },
      {
        fieldName: null,
        displayName: 'Actions',
        sortable: false
      }
    ].filter((header) => {
      if (header.displayName === 'Actions' && !canManageAdvancePayCredits) {
        return null;
      }
      return header;
    });

    const offersAndCreditsTypes = {
      credit_sheets: {
        type: 'credit_sheets',
        title: 'AdvancePay',
        subtitle: 'Create a credit sheet to sell credits to your customers when your gallery is set to AdvancePay',
        button: 'Create Credits',
        colorClass: 'credit-border'
      }
    };

    const offerDetail = offersAndCreditsTypes['credit_sheets'];

    if (redirectTo) {
      return <Redirect to={`/storefront/advance-pay/${redirectTo}`} />;
    }

    return (
      <div id="storefront__offers">
        {showOfferCreateOrEditModal && (
          <CreateOrEditOfferModal
            offer={activeOffer}
            offerType="credit_sheets"
            pricesheets={pricesheets}
            onSave={this.handleOfferCreateOrEditDialogSave}
            onCancel={this.handleOfferDialogsCancel}
          />
        )}
        {showOfferRemoveModal && activeOffer && (
          <DeleteOfferModal offer={activeOffer} onConfirm={this.handleDeleteOfferDialogConfirm} onCancel={this.handleOfferDialogsCancel} />
        )}

        <div className="flex between middle">
          <div>
            <h3 className="text--bold"> {offerDetail.title} </h3>
            <p className="text--black">{offerDetail.subtitle}</p>
          </div>

          {canManageAdvancePayCredits && (
            <div>
              <SafeLink className="button button--medium" Component="button" disabled={requesting} onClick={() => this.handleAddOfferClick(offerDetail.type)}>
                {offerDetail.button}
              </SafeLink>
            </div>
          )}
        </div>

        <PhotoDayGrid
          headers={gridHeader}
          table_id="offer-table"
          extraClasses="offer-table"
          pager={pager}
          fetchRecordsPage={this.fetchCreditSheets}
          borderClass={`${offerDetail.colorClass} color-border`}
          requesting={requesting}
          defaultContent="You have not added any credit sheets yet."
        >
          {result.map((id) => (
            <OfferRow
              key={id}
              offerType="credit_sheets"
              offer={entities.gift_card_sheets[id]}
              canManageAdvancePayCredits={canManageAdvancePayCredits}
              onClickRemove={this.handleOfferGridRemoveAction}
              onClickSettings={this.handleOfferGridSettingsAction}
            />
          ))}
        </PhotoDayGrid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancePay);
