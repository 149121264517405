import { Component } from 'react';
import { Link } from 'react-router-dom';

// Helpers
import * as api from '@/utils/http';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

// Styles
import './forgot-password.css';

class ForgotPassword extends Component {
  state = {
    showNewPasswordField: false,
    displayError: {
      message: ''
    },
    showSuccessPasswordReset: false,
    password: '',
    showPassword: false,
    rememberMe: false
  };

  componentDidMount() {
    const {
      match: {
        params: { token }
      }
    } = this.props;
    const endpoint = `studios/check-forgot-password?token=${token}`;

    api
      .get(endpoint, {}, false)
      .then(() => {
        this.setState({
          showNewPasswordField: true
        });
      })
      .catch(() => {
        this.setState({
          displayError: {
            message: 'Invalid Token!'
          }
        });
      });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    const {
      match: {
        params: { token }
      }
    } = this.props;
    const { password } = this.state;
    const endpoint = 'studios/reset-password';

    api
      .post(endpoint, { token, password }, false)
      .then(() => {
        this.setState({
          showNewPasswordField: false,
          showSuccessPasswordReset: true
        });
      })
      .catch(() => {
        this.setState({
          displayError: {
            message: 'Error trying to reset password.'
          }
        });
      });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  handleInputOnChange = (evt) => {
    const { target } = evt;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  render() {
    const { showNewPasswordField, showSuccessPasswordReset, displayError, password, showPassword, rememberMe } = this.state;

    return (
      <section id="forgot_password" className="onboarding-bg">
        <img src={logo} className="gray-logo" alt="Logo" />

        <main className="container-fluid flex-page-centered">
          <div className="white-container flex-9 login-screen">
            <figure className="blue-gradient left-onboarding-container">
              <img src={logo_oto_hair} alt="Logo Oto" />
              <h1 className="text--bold"> Password Reset</h1>
              <p> Please enter your email and password to log back in. </p>
            </figure>

            {showNewPasswordField && (
              <article className="right-onboarding-container">
                <form onSubmit={this.handleSubmit} className="reset-password-form">
                  <div>
                    <label className="text--bold">New Password</label>
                    <input
                      value={password}
                      onChange={this.handleInputOnChange}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      className="input--block"
                      id="exampleInputPassword1"
                      placeholder="••••••"
                      autoComplete="current-password"
                    />
                  </div>
                  <fieldset>
                    <input
                      id="showPassword"
                      className="hidden"
                      type="checkbox"
                      name="showPassword"
                      checked={showPassword}
                      onChange={this.handleInputOnChange}
                    />
                    <label htmlFor="showPassword" className="label--checkbox label--clean">
                      Show Password
                    </label>
                  </fieldset>

                  <button ref="submitForm" type="submit" style={{ display: 'none' }} />
                </form>

                <button
                  className="button button--block login-btn"
                  onClick={() => {
                    this.refs.submitForm.click();
                  }}
                >
                  Log In
                </button>

                <fieldset>
                  <input id="rememberMe" className="hidden" type="checkbox" name="rememberMe" checked={rememberMe} onChange={this.handleInputOnChange} />
                  <label htmlFor="rememberMe" className="label--checkbox label--clean">
                    Remember Me
                  </label>
                </fieldset>

                <a href="https://www.photoday.io/signup/" className="text--bold no-account-link">
                  {' '}
                  Don't have an account?
                </a>
              </article>
            )}
            {showSuccessPasswordReset && (
              <aside className="right-onboarding-container centered">
                <h3>Password updated successfully!</h3>
                <Link to="/">
                  <h4>Login</h4>
                </Link>
              </aside>
            )}
            {displayError.message && (
              <aside className="right-onboarding-container centered">
                <h2>{displayError.message}</h2>
              </aside>
            )}
          </div>
        </main>
      </section>
    );
  }
}

export default ForgotPassword;
