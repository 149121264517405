import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';

import { Input } from '@/components/Shared/Forms';

class AccountForm extends Component {
  render() {
    const { loading, requesting, handleSubmit } = this.props;

    return (
      <section className="flex-12">
        <Form onSubmit={handleSubmit}>
          <header className="flex middle between user-container__header">
            <h2 className="text--nomargin">Account Information</h2>
            <button type="submit" className="button button--medium" disabled={requesting || loading}>
              Save
            </button>
          </header>

          <main className="panel panel--section account-settings-item">
            <div className="flex gap-10">
              <div className="flex-3">
                <Input label="First Name" name="first_name" required loading={loading} type="text" />
              </div>
              <div className="flex-3">
                <Input label="Last Name" name="last_name" required loading={loading} type="text" />
              </div>
            </div>
            <div className="flex-6">
              <Input label="Phone Number" name="phone" loading={loading} type="tel" />
            </div>
            <div className="flex-6">
              <Input label="Email Address" name="email" required email loading={loading} type="email" />
            </div>
          </main>
        </Form>
      </section>
    );
  }
}

export default reduxForm({ form: 'AccountForm', enableReinitialize: true, destroyOnUnmount: false })(AccountForm);
