import { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';

class NestedField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    label: PropTypes.string,
    moreLabel: PropTypes.string,
    maxCount: PropTypes.number
  };

  static defaultProps = {
    type: 'input',
    label: null,
    moreLabel: '+ Add more',
    maxCount: Infinity
  };

  renderNested = ({ fields, meta: { error, submitFailed } }) => {
    const { name, component, maxCount, moreLabel } = this.props;
    const ComponentItem = component;

    if (fields.length === 0) fields.push({});

    return (
      <div>
        <div className="flex">
          {fields.map((fieldName, index) => (
            <ComponentItem {...this.props} key={`${name}-${fieldName}`} name={fieldName} index={index} onRemove={() => fields.remove(index)} />
          ))}
        </div>
        {fields.length < maxCount && (
          <button type="button" onClick={() => fields.push({})} className="notifications-icon button--link text--left">
            {moreLabel}
          </button>
        )}
        {submitFailed && error && <span>{error}</span>}
      </div>
    );
  };

  render() {
    const { name, label } = this.props;

    return (
      <div className="nested-container">
        {label && <label>{label}</label>}
        <FieldArray name={name} component={this.renderNested} />
      </div>
    );
  }
}

export default NestedField;
