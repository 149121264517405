import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';
import Products from './Products';
import AdvancePay from './AdvancePay';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOffersDDLRequest } from '@/components/Shared/OffersDDL/actions';
import { updateJob, getJobPriceSheets } from '../../actions';
import { creditSheetListRequest } from '../../../Storefront/AdvancePay/actions';
import { getPricesheetRequest, getPriceSheetThemeProperties } from '../../../Storefront/PriceSheets/actions';

// Images
import imageCard from '@/assets/images/icon-advancepay-card.png';
import imageBusiness from '@/assets/images/icon-small-business.png';

// Styles
import './style.css';

const Store = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId },
    path
  } = match;

  const { job } = useSelector((state) => state.jobs);
  const { invalid_price_sheet_items: jobUnavailableItems, requesting: jobRequesting } = job;

  const { themeProperties, requesting: priceSheetsRequesting } = useSelector((state) => state.pricesheets);
  const { primary_theme_properties } = useSelector((state) => state.pricesheets.entities.pricesheet);
  const { options: optionsOffers, requesting: offersDDLRequesting } = useSelector((state) => state.offersDDL);
  const {
    entities: { gift_card_sheets: giftCardSheets },
    requesting: advancePayRequesting
  } = useSelector((state) => state.advancepay);

  const [sendBack, setSendBack] = useState(false);

  const [primaryThemeProperties, setPrimaryThemeProperties] = useState([]);
  const [categoryThemesSelected, setCategoryThemesSelected] = useState({});

  const [priceSheetOptions, setPriceSheetOptions] = useState([]);

  const [moreOptionsEnabled, setMoreOptionsEnabled] = useState(false);
  const [moreOptionsShow, setMoreOptionsShow] = useState(false);
  const [unavailableShow, setUnavailableShow] = useState(false);
  const [unavailableItems, setUnavailableItems] = useState(false);

  const [section, setSection] = useState('advancepay');

  const [initialInformation, setInitialInformation] = useState('');
  const [information, setInformation] = useState({
    priceSheetId: '',
    themeValues: [],
    collections: [],
    previewStore: false,
    giftCardSheetId: '',
    advancepayRequireSubject: false,
    advancePayOfferId: ''
  });

  const storeInformationSetup = (data) => {
    const themes = Object.values(data.job_theme_options)
      .map((theme) => theme.theme_property_value.id)
      .sort();

    let collections = [];

    if (data.studio_theme_collections) {
      collections = Object.values(data.studio_theme_collections)
        .map((collection) => collection.id)
        .sort();
    }

    const newInformation = {
      themeValues: themes,
      collections,
      priceSheetId: data.price_sheet_id,
      previewStore: data.preview_store,
      giftCardSheetId: data.gift_card_sheet_id,
      advancepayRequireSubject: data.advancepay_require_subject_details,
      advancePayOfferId: data?.advancepay_offer?.id
    };

    setInformation({ ...newInformation });

    if (!initialInformation) {
      setInitialInformation(JSON.stringify(newInformation));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    const values = {
      price_sheet_id: information.priceSheetId || null,
      theme_property_value_ids: information.themeValues,
      studio_theme_collection_ids: information.collections,
      preview_store: information.previewStore,
      gift_card_sheet_id: information.giftCardSheetId,
      advancepay_offer_id: information.advancePayOfferId,
      advancepay_require_subject_details: information.advancepayRequireSubject
    };

    setInitialInformation(JSON.stringify(information));
    dispatch(updateJob({ ...values, id: jobId }, () => sendBack && history.push(`/jobs/${jobId}/dashboard`)));
  };

  const handleSectionSelect = (newSection) => {
    if (newSection === 'advancepay' && section !== 'advancepay') {
      history.push(`/jobs/${jobId}/settings/store/advancepay`);
    } else {
      history.push(`/jobs/${jobId}/settings/store/products`);
    }
  };

  const handleSelectChange = (value, name) => {
    const currentPriceSheetId = information.priceSheetId;
    const newValue = value ? value.value : null;

    if (name === 'priceSheetId' && newValue && newValue !== currentPriceSheetId) {
      setInformation({ ...information, priceSheetId: newValue, themeValues: [] });
    }

    if (!value && name === 'priceSheetId') {
      return setInformation({ ...information, priceSheetId: '', themeValues: [] });
    }

    return setInformation({ ...information, [name]: newValue });
  };

  const handleOptionSelect = (e, option) => {
    e.preventDefault();

    const optionIsSelected = information.themeValues.indexOf(option);

    let currentThemesValues = information.themeValues;

    if (optionIsSelected !== -1) {
      currentThemesValues.splice(optionIsSelected, 1);
    }

    const newThemesValues = optionIsSelected === -1 ? information.themeValues.concat(option).sort() : currentThemesValues.sort();

    setInformation({ ...information, themeValues: newThemesValues });
  };

  const handleSwitchChange = (e) => setInformation({ ...information, [e.target.name]: e.target.checked });

  const handlePriceSheetsRefresh = () => {
    dispatch(getJobPriceSheets({ id: jobId, order: 'name', dir: 'asc' }, ({ data }) => priceSheetOptionsSet(data)));
  };

  const handleSelectAll = (category) => {
    const allCategoryThemes = primaryThemeProperties.find((property) => property.code === category).theme_property_values;
    const allCategoryThemesAdded = allCategoryThemes.every((theme) => information.themeValues.includes(theme.id));

    if (allCategoryThemesAdded) {
      const removeIds = information.themeValues.filter((existingTheme) => !allCategoryThemes.some((theme) => theme.id === existingTheme));

      setInformation({ ...information, themeValues: removeIds });
    } else {
      const addIds = allCategoryThemes.filter((categoryTheme) => !information.themeValues.includes(categoryTheme.id)).map((theme) => theme.id);

      setInformation({ ...information, themeValues: [...information.themeValues, ...addIds] });
    }
  };

  const handleMoreOptionsShow = () => {
    setMoreOptionsShow(!moreOptionsShow);
  };

  const handleCreditSheetsRefresh = () => {
    dispatch(creditSheetListRequest({ page: 1, perPage: 1000, order: 'name', dir: 'ASC' }));
  };

  const handleOffersRefresh = () => dispatch(getOffersDDLRequest());

  const handleToggleUnavailable = () => {
    setUnavailableShow(!unavailableShow);
  };

  const priceSheetOptionsSet = (data) => {
    const options = data.map((i) => {
      const { name: label, id: value, lab } = i;

      return Object.assign({}, { label, value, selected: false, lab: (lab && lab.name) || '' });
    });

    setPriceSheetOptions(options);
  };

  useEffect(() => {
    const { sendBack: shouldSendBack } = location.state ? location.state : false;

    if (shouldSendBack) {
      setSendBack(true);
    }

    if (!priceSheetOptions.length) {
      dispatch(getJobPriceSheets({ id: jobId, order: 'name', dir: 'ASC' }, ({ data }) => priceSheetOptionsSet(data)));
    }

    if (!optionsOffers.length) {
      dispatch(getOffersDDLRequest());
    }

    if (!Object.values(giftCardSheets).length) {
      dispatch(creditSheetListRequest({ page: 1, perPage: 1000, order: 'name', dir: 'ASC' }));
    }
  }, []);

  useEffect(() => {
    if (job?.id) {
      storeInformationSetup(job);
      setUnavailableItems(jobUnavailableItems?.length > 0);
    }
  }, [job?.id]);

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    const newSection = pathArr.pop();

    if (newSection === 'advancepay' && section !== 'advancepay') {
      setSection(newSection);
      history.push(`/jobs/${jobId}/settings/store/advancepay`);
    } else if (newSection === 'products' && section !== 'products') {
      setSection(newSection);
      history.push(`/jobs/${jobId}/settings/store/products`);
    } else {
      if (newSection !== 'advancepay' && job.price_sheet_id && !job.gift_card_sheet_id) {
        setSection('products');
        history.push(`/jobs/${jobId}/settings/store/products`);
      } else {
        setSection('advancepay');
        history.push(`/jobs/${jobId}/settings/store/advancepay`);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (information.priceSheetId) {
      setPrimaryThemeProperties([]);

      dispatch(
        getPricesheetRequest(information.priceSheetId, () => {
          if (job.price_sheet_id === information.priceSheetId) {
            storeInformationSetup(job);
          } else {
            setInformation({ ...information, themeValues: [], collections: [] });
            setMoreOptionsShow(false);
          }
        })
      );
    }
  }, [information.priceSheetId]);

  useEffect(() => {
    if (primary_theme_properties) {
      setPrimaryThemeProperties(primary_theme_properties);
    }
  }, [primary_theme_properties]);

  useEffect(() => {
    const firstPositionCategory = primaryThemeProperties.length ? primaryThemeProperties[0].code : '';
    const firstPositionThemes = (primaryThemeProperties.length && primaryThemeProperties.find((category) => category.code === firstPositionCategory)) || {};
    const firstPositionThemesSelected = firstPositionThemes.theme_property_values
      ? firstPositionThemes.theme_property_values.some((theme) => information.themeValues.includes(theme.id))
      : false;

    const secondaryCategories = primaryThemeProperties.length ? primaryThemeProperties.filter((category) => category.code !== firstPositionCategory) : [];
    const secondaryCategoriesSelected = secondaryCategories.length
      ? secondaryCategories.some((category) => category.theme_property_values.some((theme) => information.themeValues.includes(theme.id)))
      : false;
    const themesByCategory = {};

    primaryThemeProperties &&
      primaryThemeProperties.map(
        (category) =>
          (themesByCategory[category.code] = {
            themeValues: category.theme_property_values.filter((theme) => information.themeValues.includes(theme.id) && theme.id)
          })
      );

    setCategoryThemesSelected(themesByCategory);

    if (!firstPositionThemesSelected && information.priceSheetId) {
      // clear secondary theme selections if no first position themes selected
      setInformation({ ...information, themeValues: [] });
      setMoreOptionsShow(false);
      setMoreOptionsEnabled(false);
    } else {
      setMoreOptionsEnabled(true);
    }

    if (secondaryCategoriesSelected) {
      // Open More Options if job already has themes selected from secondary options
      setMoreOptionsShow(true);
    }
  }, [primaryThemeProperties, information.themeValues.length]);

  useEffect(() => {
    if (moreOptionsShow) {
      const { priceSheetId } = information;
      const firstThemeCode = primary_theme_properties.length ? primary_theme_properties[0].code : null;
      const firstThemeValues = firstThemeCode ? categoryThemesSelected[firstThemeCode].themeValues : [];

      if (firstThemeCode) {
        dispatch(getPriceSheetThemeProperties({ id: priceSheetId, [firstThemeCode]: firstThemeValues.map((theme) => theme.id) }));
      }
    }
  }, [categoryThemesSelected, moreOptionsShow]);

  useEffect(() => {
    if (information.priceSheetId) {
      const positionOneThemes = primaryThemeProperties.length ? primaryThemeProperties[0].theme_property_values.map((theme) => theme.id) : [];
      const possibleThemeProps = Object.values(themeProperties).flat().concat(positionOneThemes);
      const currentThemeValues = [...information.themeValues].filter((theme) => possibleThemeProps.includes(theme));

      setInformation({ ...information, themeValues: currentThemeValues });
    }
  }, [themeProperties]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Settings" />

      <main className="box flex job-settings-store">
        <Sidebar jobId={jobId} jobType={job.access_mode} path={path} />

        <form className="flex-9 flex-12-md job-settings-store__container" onSubmit={handleSave}>
          <header className="flex between middle job-settings-store__header">
            <h2 className="text--nomargin">Store</h2>
            <button type="submit" className="button button--medium" disabled={jobRequesting || JSON.stringify(information) === initialInformation}>
              Save
            </button>
          </header>

          {section === 'products' && unavailableItems && !jobRequesting && (
            <aside className="flex between middle panel panel--warning panel--neat">
              <p className="text--nomargin">There are unavailable products based on necessary themes not being selected.</p>
              <button
                type="button"
                className="button button--warning"
                disabled={JSON.stringify(information) !== initialInformation}
                onClick={handleToggleUnavailable}
              >
                View Unavailable Products
              </button>
            </aside>
          )}

          <div className="job-settings-store__selections">
            <figure
              className={`flex nowrap middle job-settings-store__section-nav-item ${
                section === 'advancepay' ? 'job-settings-store__section-nav-item--active' : ''
              }`}
              onClick={() => handleSectionSelect('advancepay')}
            >
              <img src={imageCard} className="hidden--md job-settings-store__section-nav-icon" alt="card" />
              <figcaption className="flex column nowrap start">
                <h2 className="job-settings-store__section-nav-title ">
                  AdvancePay <span className="hidden--md"> Credits </span>
                </h2>
                <p className="text--nomargin hidden--sm">Sell credits before you publish the job.</p>
              </figcaption>
            </figure>

            <figure
              className={`flex nowrap middle job-settings-store__section-nav-item ${
                section === 'products' ? 'job-settings-store__section-nav-item--active' : ''
              }`}
              onClick={() => handleSectionSelect('products')}
            >
              <img src={imageBusiness} className="hidden--md job-settings-store__section-nav-icon" alt="card" />
              <figcaption className="flex column nowrap start">
                <h2 className="job-settings-store__section-nav-title">Products</h2>
                <p className="text--nomargin hidden--sm">Add a price sheet to sell products in your store.</p>
              </figcaption>
            </figure>
          </div>

          {section === 'products' && (
            <Products
              information={information}
              jobRequesting={jobRequesting}
              onSelectAll={handleSelectAll}
              unavailableShow={unavailableShow}
              moreOptionsShow={moreOptionsShow}
              themeProperties={themeProperties}
              onOptionSelect={handleOptionSelect}
              onSelectChange={handleSelectChange}
              optionsPriceSheet={priceSheetOptions}
              moreOptionsEnabled={moreOptionsEnabled}
              jobUnavailableItems={jobUnavailableItems}
              onMoreOptionsShow={handleMoreOptionsShow}
              priceSheetsRequesting={priceSheetsRequesting}
              onToggleUnavailable={handleToggleUnavailable}
              categoryThemesSelected={categoryThemesSelected}
              onPriceSheetsRefresh={handlePriceSheetsRefresh}
              primaryThemeProperties={primaryThemeProperties}
            />
          )}

          {section === 'advancepay' && (
            <AdvancePay
              information={information}
              jobAdvancePayOffer={job?.advancepay_offer}
              optionsOffers={optionsOffers}
              giftCardSheets={giftCardSheets}
              offersDDLRequesting={offersDDLRequesting}
              advancePayRequesting={advancePayRequesting}
              onSwitchChange={handleSwitchChange}
              onSelectChange={handleSelectChange}
              onSectionSelect={handleSectionSelect}
              onOffersRefresh={handleOffersRefresh}
              onCreditSheetsRefresh={handleCreditSheetsRefresh}
            />
          )}
        </form>
      </main>
    </>
  );
};

Store.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      sendBack: PropTypes.bool.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

Store.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  location: {
    state: {
      sendBack: false
    }
  },
  history: {}
};

export default Store;
