import { FC, ChangeEvent, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrganization } from './actions';

// Constants
const DELETE_CONFIRMATION_WORD: string = 'delete';

// Types
interface DeleteModalProps {
  organizationId: string;
  organizationName: string;
  onOrganizationDelete?: () => void;
  onModalClose: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ organizationId, organizationName, onOrganizationDelete, onModalClose }) => {
  const dispatch = useDispatch();
  const { requesting } = useSelector((state: any) => state.organizations);

  const [deleteConfirmationWord, setDeleteConfirmationWord] = useState<string>('');

  const handleConfirmationWordChange = (event: ChangeEvent<HTMLInputElement>): void => setDeleteConfirmationWord(event.target.value);

  const handleDelete = (): void => {
    dispatch(
      deleteOrganization({ organizationId: organizationId }, () => {
        if (onOrganizationDelete) onOrganizationDelete();
        onModalClose();
      })
    );
  };

  const handleDeleteCancel = (): void => onModalClose();

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--small">
        <header className="text--left">
          <h2>Delete Organization</h2>
        </header>
        <main className="modal__content text--left">
          <p>
            Are you sure you want to delete the organization, <b>{organizationName}</b>?
          </p>
          <p>This action cannot be undone.</p>
          <p>
            Type <b>"delete"</b> to permanently delete this organization.
          </p>
          <input className="input--block" type="text" name="delete" value={deleteConfirmationWord} maxLength={10} onChange={handleConfirmationWordChange} />
        </main>
        <footer className="modal__footer flex end gap-10">
          <button className="button button--outline" type="button" name="cancel" onClick={handleDeleteCancel}>
            Cancel
          </button>
          <button
            className="button button--danger"
            name="delete"
            type="button"
            data-loading={requesting}
            onClick={handleDelete}
            disabled={!(deleteConfirmationWord.toLowerCase() === DELETE_CONFIRMATION_WORD.toLowerCase())}
          >
            Delete
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default DeleteModal;
