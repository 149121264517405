import PropTypes from 'prop-types';
import { useEffect } from 'react';

// Components
import TableLoader from '../ContentLoader/TableLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { shippingListRequest } from '../../Storefront/Shipping/actions';

const ShippingTable = function ShippingTable({ labId, labName, shippingType }) {
  const dispatch = useDispatch();

  const {
    entities: { shippings },
    requesting
  } = useSelector((state) => state.shippings);

  const isReedy = labName === 'Reedy Photo';
  const isMillers = labName === "Miller's";
  const isRichmond = labName === 'Richmond Pro Lab';

  const groupedShippings = Object.values(
    Object.values(shippings)
      .filter((shipping) => {
        if (shippingType.includes('drop') && shippingType.includes('bulk')) {
          return true;
        } else {
          return shippingType.includes('bulk') ? shipping.bulk === true : shipping.bulk === false;
        }
      })
      .reduce((a, b) => {
        // Group shippings by the min order amount
        (a[b['min_amount_without_symbol']] = a[b['min_amount_without_symbol']] || []).push(b);

        return a;
      }, {})
  );

  useEffect(() => {
    dispatch(shippingListRequest({ labId }));
  }, []);

  return (
    <section className="panel panel--section animate">
      <p>The estimated delivery time is based on lab processing and transit times.</p>
      <h5>United States</h5>
      <table className="table">
        <thead className="table__header">
          <tr>
            <th>Actual Lab Costs</th>
            <th>Shipping Rate</th>
            <th>Description</th>
            <th>Tracking</th>
            <th>PO Box Deliverable</th>
            <th>Estimated Delivery Time</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {requesting ? (
            <tr>
              <td colSpan="5">
                <TableLoader rows={6} />
              </td>
            </tr>
          ) : (
            <>
              {groupedShippings.map((group, index) => (
                <tr key={index}>
                  <td data-header="Actual Lab Costs">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        ${shipping.min_amount_without_symbol} {shipping.max_amount_without_symbol ? ` - $${shipping.max_amount_without_symbol}` : ' +'}
                      </span>
                    ))}
                  </td>

                  <td data-header="Shipping">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        ${shipping.rate_without_symbol}
                        {isReedy && <>{shipping.description === 'Ground' && shipping.rate_without_symbol === '7.95' && '*'}</>}
                      </span>
                    ))}
                  </td>

                  <td data-header="Description">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        {shipping.description}
                        {shipping.description === 'Free bulk shipping' && '**'}

                        {isMillers && (
                          <>
                            {shipping.description === 'Economy' && '*'}
                            {shipping.description === 'UPS overnight' && '**'}
                          </>
                        )}
                      </span>
                    ))}
                  </td>
                  <td data-header="Tracking">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        {shipping.tracking ? 'Yes' : 'No'}
                      </span>
                    ))}
                  </td>
                  <td data-header="PO Box Deliverable">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        <b>{shipping.pobox_available ? 'Yes' : 'No'}</b>
                      </span>
                    ))}
                  </td>
                  <td data-header="Transit">
                    {group.map((shipping) => (
                      <span className="text--block" key={shipping.id}>
                        {shipping.minimum_transit_days ? `${shipping.minimum_transit_days}` : 'Up to '}
                        {shipping.minimum_transit_days && shipping.maximum_transit_days ? '-' : ''}
                        {shipping.maximum_transit_days ? shipping.maximum_transit_days : '+'} {shipping.business_days ? 'Business Days' : 'Days'}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      {/* Miller's notes */}
      {isMillers && shippingType.includes('drop') && (
        <p>
          <small>
            <i>
              <br />
              *Economy shipping is available for the following scenarios:
              <br />
              A maximum combined quantity of less than or equal to 50 units of these products: 3.5x5, 4x4, 4x5, 4x6, 5x5, 5x7.
              <br />
              A maximum combined quantity of 15 or less units of these products: 3.5x5, 4x4, 4x5, 4x6, 5x5, 5x7, 6x9, 8x8, 8x10, Calendar (8x10), Memory Mate
              Horizontal or Vertical Team Picture (5x7), Team Picture (8x10), Tickets - Set of 5, Wallets, 4x5 Magnets.
              <br />
              Orders containing products that are not listed above will not be eligible for economy shipping.
            </i>
          </small>
        </p>
      )}

      {/* Reedy's notes */}
      {isReedy && (
        <p>
          <small>
            <i>
              <br />
              *Orders containing the following items will be billed $8.95 for economy shipping: All variations of plaques, metal prints, metal float mounts,
              coffee mugs, canvas prints, banners, and sports ball products.
            </i>
          </small>
        </p>
      )}

      {/* Richmond's notes */}
      {isRichmond && (
        <p>
          <small>
            <i>
              <br />
              *Orders containing the following items will be billed $9.95 for economy shipping or $35 for expedited shipping: <br />
              10x20, 11x17, 12x18, 12x36, 16x20, & 16x24 Luster Prints, 16x20 4-Pose Vertical, 11x14 Metal Prints, 20oz. Water Bottles, 3x5 & 5x7 Memory Mate
              Hinged Panel, 5x7 Acrylics, 8x10 Acrylics, 5x7 Black Framed Print, 8x10 Black Framed Print, 8x10 Hardboard Easel Panel, 8x10 Metal Print, 8x10
              Metal Print with Float Mount, 5x7 Tassel Frame, 8x10 Tassel Frame, 9x12 Wood Plaques, 6x8 Graphic Wooden Plaques, Tote bags, Keepsake Box, Small
              Towel, 14oz Travel Mugs, 15oz Mugs.
            </i>
          </small>
        </p>
      )}

      {shippingType.includes('bulk') && (
        <p>
          <small>
            <i>
              <br />
              <b>**</b>There is a minimum total lab cost of $15.00. You will receive an invoice for the remaining amount if the minimum is not met.{' '}
              <a href="https://support.photoday.io/en/articles/4534169-is-there-a-fee-for-bulk-shipping" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </i>
          </small>
        </p>
      )}
    </section>
  );
};

ShippingTable.propTypes = {
  labId: PropTypes.string.isRequired,
  labName: PropTypes.string.isRequired,
  shippingType: PropTypes.string.isRequired
};

ShippingTable.defaultProps = {
  labId: '',
  labName: '',
  shippingType: ''
};

export default ShippingTable;
