import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Container from '@/components/SignUp/Shared/Container';
import VerifyForm from '../Forms/VerifyForm';
import { ToastContainer } from 'react-toastify';
import { resendVerificationEmail, verify } from '../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.signUp,
    ...ownProps,
    isAuthenticated: state.login.isAuthenticated
  };
};
const mapDispatchToProps = { resendVerificationEmail, verify };

class Verify extends Component {
  env = import.meta.env.VITE_ENVIRONMENT;

  getToken() {
    const { match } = this.props;

    if (match && match.params && match.params.token) {
      return match.params.token;
    }

    return null;
  }

  componentDidMount() {
    const token = this.getToken();
    const { verify } = this.props;

    if (token) verify(token);
  }

  handleSubmit = (values) => {
    const { email } = values;
    const { resendVerificationEmail } = this.props;

    resendVerificationEmail(email);
  };

  render() {
    const { lab, email, requesting, verificationFailed, isAuthenticated, title, subtitle } = this.props;

    if (isAuthenticated) return <Redirect to="/home" />;

    return (
      <>
        <ToastContainer />
        <Container id="register" labImage={lab.image} title={title} subtitle={subtitle}>
          {requesting && (
            <div className="sign-up-form">
              <div className="flex">
                <div className="flex-offset-2 flex-8 text--center">
                  <p>Verifying email...</p>
                </div>
              </div>
            </div>
          )}
          {!requesting && (
            <div>
              {verificationFailed === true && (
                <div className="text--center">
                  <p>Failed to verify account. Your verification email may be expired.</p>
                  <p>Please enter your email below and try again.</p>
                </div>
              )}
              {!verificationFailed && (
                <div className="text--center">
                  <p>
                    We have sent you an email with instructions to verify your PhotoDay account. <br />
                    If you did not receive an email, select <b>Resend Verification Email</b> and we will send another verification email.
                  </p>
                </div>
              )}
              <VerifyForm
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
                className="sign-up-form text--center"
                initialValues={{ email: email }}
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}

Verify.propTypes = {
  email: PropTypes.string,
  lab: PropTypes.shape({
    key: PropTypes.string,
    image: PropTypes.string
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
