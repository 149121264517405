import { FC, useRef, useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyAccountStudioRequest, updateMyAccountStudioRequest } from '../../actions';

// Plugins
import { Tooltip } from 'react-tippy';

// Components
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';
import BusinessVerificationModal from '@/components/Shared/BusinessVerification/VerificationModal';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Types
import { StudioVerificationStatus, StudioSellerType } from '@/types';

const StudioVerification: FC = () => {
  const dispatch = useDispatch();
  const isUpdateVerificationRef = useRef(false);

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio, requesting: usersRequesting } = useSelector((state: any) => state.users);

  const { id: studioId, studio_verification: studioVerificationAttributes } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const [showUpdateVerificationModal, setShowUpdateVerificationModal] = useState<boolean>(false);
  const [showBusinessVerificationModal, setShowBusinessVerificationModal] = useState<boolean>(false);

  // UI Handlers
  const handleGetVerified = (): void => setShowBusinessVerificationModal(true);
  const handleBusinessVerificationModalClose = (): void => setShowBusinessVerificationModal(false);

  const handleNoSameInfo = (): unknown => dispatch(updateMyAccountStudioRequest({ id: studioId, reverified: true }));
  const handleUpdateVerificationModalOpen = (): void => setShowUpdateVerificationModal(true);
  const handleUpdateVerificationModalClose = (): void => setShowUpdateVerificationModal(false);
  const handleBeginVerifying = (): void => {
    isUpdateVerificationRef.current = true;
    setShowBusinessVerificationModal(true);
    setShowUpdateVerificationModal(false);
  };

  useEffect(() => {
    dispatch(getMyAccountStudioRequest({ studioId }));
  }, []);

  return (
    <>
      <header className="flex middle between my-studio__header">
        <h2 className="text--nomargin">Verification</h2>
        {(usersStudio?.verification_status === StudioVerificationStatus.Pending ||
          usersStudio?.verification_status === StudioVerificationStatus.NotVerified) && (
          <Tooltip
            {...{
              title: 'Verification is in progress. Our customer success team will be in touch if there are any issues. Message us if you have questions!',
              position: 'bottom',
              size: 'big',
              disabled: usersStudio?.verification_status !== StudioVerificationStatus.Pending
            }}
          >
            <button className="button button--medium" type="button" name="getVerified" onClick={handleGetVerified}>
              {usersStudio?.verification_status === StudioVerificationStatus.Pending ? 'Verificaton In Progress' : 'Get Verified'}
            </button>
          </Tooltip>
        )}
        {usersStudio?.verification_status === StudioVerificationStatus.Verified && !studioVerificationAttributes?.reverify && (
          <button className="button button--medium" type="button" name="getVerified" onClick={handleUpdateVerificationModalOpen}>
            Update Verification
          </button>
        )}
      </header>
      {usersStudio?.verification_status === StudioVerificationStatus.Verified && studioVerificationAttributes?.reverify && (
        <aside className="panel panel--light-yellow flex middle between">
          <p className="text--large text--nomargin">Review your business information below, has anything changed within the last year?</p>
          <div className="flex end gap-10">
            <button className="button button--blue-outline" type="button" name="sameInfo" onClick={handleNoSameInfo}>
              No, same info
            </button>
            <button className="button button--medium" type="button" name="updateVerification" onClick={handleUpdateVerificationModalOpen}>
              Yes, update verification
            </button>
          </div>
        </aside>
      )}
      <h4 className="text--normal mb-40">
        In compliance with the consumer INFORM Consumers Act, all high-volume sellers must verify their business in order to continue selling through PhotoDay.
        This act provides a national standard that ensures a safe online buying experience for consumers while protecting the privacy of individual and small
        business sellers.
      </h4>

      {usersRequesting || !usersStudio?.id ? (
        <TableLoader rows={6} rowHeight={36} />
      ) : (
        <form>
          <h4 className="mb-20">Verification</h4>
          <fieldset className="flex gap-20">
            <div className="flex-4">
              <label>Status</label>
              <span className="text--small text--block">
                {usersStudio?.verification_status === StudioVerificationStatus.Verified ? 'Verified' : 'Not Yet Verified'}
              </span>
            </div>
            <div className="flex-4">
              <label>Verified On</label>
              <span className="text--small text--block">
                {usersStudio?.verification_status === StudioVerificationStatus.Verified && shortDate(studioVerificationAttributes.verified_at)}
              </span>
            </div>
          </fieldset>
          <h4 className="mb-20">Customer Facing Information</h4>
          <div className={usersStudio?.verification_status === StudioVerificationStatus.Verified ? '' : 'disabled'}>
            <fieldset className="flex gap-20">
              <div className="flex-4">
                <label htmlFor="verifiedAs">Verified As</label>
                <input
                  id="verifiedAs"
                  name="verifiedAs"
                  type="text"
                  value={
                    usersStudio?.verification_status === StudioVerificationStatus.Verified
                      ? studioVerificationAttributes?.seller_type === StudioSellerType.Company
                        ? 'Registered Company'
                        : 'Individual'
                      : ''
                  }
                  readOnly
                />
              </div>
            </fieldset>

            {/* Verify As Company */}
            {(usersStudio?.verification_status === StudioVerificationStatus.NotVerified ||
              studioVerificationAttributes?.seller_type === StudioSellerType.Company) && (
              <fieldset className="flex gap-20 nowrap">
                <div className="flex-4">
                  <label htmlFor="businessLegalName">Business Legal Name</label>
                  <input id="businessLegalName" name="businessLegalName" type="text" value={studioVerificationAttributes?.business_name} readOnly />
                </div>
                <div className="flex-4">
                  <label htmlFor="doingBusinessAs">Doing Business As (Optional)</label>
                  <input id="doingBusinessAs" name="doingBusinessAs" type="text" value={studioVerificationAttributes?.dba} readOnly />
                </div>
              </fieldset>
            )}

            {/* Verify As Individual */}
            {studioVerificationAttributes?.seller_type === StudioSellerType.Individual && (
              <fieldset className="flex gap-20 nowrap">
                <div className="flex-4">
                  <label htmlFor="firstName">First Name</label>
                  <input id="firstName" name="firstName" type="text" value={studioVerificationAttributes?.first_name} readOnly />
                </div>
                <div className="flex-4">
                  <label htmlFor="lastName">Last Name</label>
                  <input id="lastName" name="lastName" type="text" value={studioVerificationAttributes?.last_name} readOnly />
                </div>
              </fieldset>
            )}

            <fieldset className="flex gap-20">
              <div className="flex-4">
                <label htmlFor="address">Address</label>
                <input id="address" name="address" type="text" value={usersStudio?.address?.line1} readOnly />
              </div>
              <div className="flex-4">
                <label htmlFor="aptUnit">Apt/Unit #</label>
                <input id="aptUnit" name="aptUnit" type="text" value={usersStudio?.address?.line2 ?? ''} readOnly />
              </div>
            </fieldset>
            <fieldset className="flex gap-20 nowrap">
              <div className="flex-4">
                <label htmlFor="city">City</label>
                <input id="city" name="city" type="text" value={usersStudio?.address?.city} readOnly />
              </div>
              <div className="flex-4">
                <label htmlFor="state">State</label>
                <input id="state" name="state" type="text" value={usersStudio?.address?.state} readOnly />
              </div>
              <div className="flex-4">
                <label htmlFor="zip">Zip</label>
                <input id="zip" name="zip" type="text" value={usersStudio?.address?.zip} readOnly />
              </div>
            </fieldset>
          </div>
        </form>
      )}

      {/* Update Verification Modal */}
      {showUpdateVerificationModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleUpdateVerificationModalClose}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content text--left">
              <h1>Update Verification</h1>
              <h4 className="text--normal">Updating your information will require you to go through the verification process again. Are you sure?</h4>
            </main>
            <footer className="modal__footer flex gap-10">
              <button className="button button--block" type="button" name="beginVerifying" onClick={handleBeginVerifying}>
                Begin Verifying
              </button>
              <button className="button button--outline button--block" type="button" name="cancel" onClick={handleUpdateVerificationModalClose}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      )}

      {showBusinessVerificationModal && (
        <BusinessVerificationModal
          studioId={studioId}
          studioVerificationUid={isUpdateVerificationRef.current === false ? studioVerificationAttributes?.verification_uid : undefined}
          onCloseVerificationModal={handleBusinessVerificationModalClose}
        />
      )}
    </>
  );
};

export default StudioVerification;
