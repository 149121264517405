import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortDate } from '@/utils/displayFormats';

export default class JobItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired
    })
  };

  render() {
    const {
      roles,
      item: { id, name, date }
    } = this.props;
    const formattedDate = shortDate(date);

    return (
      <tr>
        <td>
          {roles.includes('manage_jobs') ? (
            <Link className="text--normalized" to={`/jobs/${id}/dashboard`}>
              <b>{name}</b>
            </Link>
          ) : (
            <span>{name}</span>
          )}
        </td>
        <td className="text--right">{formattedDate}</td>
      </tr>
    );
  }
}
