import { Component } from 'react';
import { Switch, NavLink, Redirect } from 'react-router-dom';

// Components
import PrivateRoute from '@/components/Shared/PrivateRoute';
import SetUpPictureDay from './set-up-picture-day';
import SellBeforePictureDay from './sell-before-picture-day';
import BoostBuyRate from './boost-buy-rate';
import RefundRequest from './refund-request';

class Support extends Component {
  state = {
    section: 'Set Up Picture Day',
    sidebarIsOpen: false
  };

  componentDidMount() {
    this.setSectionTitle(this.props.location.pathname);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSectionTitle(this.props.location.pathname);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'set-up-picture-day' && this.state.section !== 'Set Up Picture Day') {
      this.setState({ section: 'Set Up Picture Day' });
    }
    if (section === 'sell-before-picture-day' && this.state.section !== 'Sell Before Picture Day') {
      this.setState({ section: 'Sell Before Picture Day' });
    }
    if (section === 'boost-your-buy-rates' && this.state.section !== 'Boost Your Buy Rates') {
      this.setState({ section: 'Boost Your Buy Rates' });
    }
    if (section === 'refund-request' && this.state.section !== 'Refund Request Form') {
      this.setState({ section: 'Refund Request Form' });
    }
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  render() {
    return (
      <>
        <aside className="flex-3 flex-12-md sidebar-container">
          <header className="flex middle between sidebar-header">
            <h2 className="text--nomargin">Support</h2>
            <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
              {this.state.section}
            </button>
          </header>

          <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
            <NavLink className="sidebar-navigation__item" to={`/support/set-up-picture-day`} activeClassName="sidebar-navigation__item--active">
              Set Up Picture Day
            </NavLink>
            <NavLink className="sidebar-navigation__item" to={`/support/sell-before-picture-day`} activeClassName="sidebar-navigation__item--active">
              Sell Before Picture Day
            </NavLink>
            <NavLink className="sidebar-navigation__item" to={`/support/boost-your-buy-rates`} activeClassName="sidebar-navigation__item--active">
              Boost Your Buy Rates
            </NavLink>
            <a href="https://guide.photoday.io" className="sidebar-navigation__item" target="_blank" rel="noopener noreferrer">
              User Guide
            </a>
            <a href="https://www.photoday.io/troubleshoot" className="sidebar-navigation__item" target="_blank" rel="noopener noreferrer">
              Customer Troubleshooting Guide
            </a>
            <a href="https://support.photoday.io/" className="sidebar-navigation__item" target="_blank" rel="noopener noreferrer">
              Support FAQs
            </a>
          </nav>
        </aside>

        <section className="flex-9 flex-12-md resources-container">
          <Switch>
            <Redirect exact from="/support" to="/support/set-up-picture-day" />
            <PrivateRoute exact path="/support/set-up-picture-day" component={SetUpPictureDay} />
            <PrivateRoute exact path="/support/sell-before-picture-day" component={SellBeforePictureDay} />
            <PrivateRoute exact path="/support/boost-your-buy-rates" component={BoostBuyRate} />
            <PrivateRoute exact path="/support/refund-request" component={RefundRequest} />
          </Switch>
        </section>
      </>
    );
  }
}

export default Support;
