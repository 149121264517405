import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import matchValidatePropToValidators from './validators';

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedOption: null
    };
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    checkedOption: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  };

  static defaultProps = {
    label: null,
    checkedOption: null,
    options: []
  };

  componentDidUpdate(newProps) {
    if (newProps.checkedOption !== this.props.checkedOption) {
      if (!this.state.checkedOption) {
        this.setState({ checkedOption: newProps.checkedOption });
      }
    }
  }

  setOption = (option) => {
    this.setState({
      checkedOption: option
    });
  };

  render() {
    const { name, label, options } = this.props;
    const { checkedOption } = this.state;
    const selectedOption = checkedOption || options[0];

    return (
      <div>
        {label && <label>{label}</label>}
        {options.map((option) => (
          <div className="radio" key={option.value}>
            <label className="unbold-label">
              <Field
                name={name}
                component="input"
                type="radio"
                value={option.value}
                validate={matchValidatePropToValidators(this.props)}
                checked={option.value === selectedOption.value}
                onClick={() => this.setOption(option)}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default RadioGroup;
