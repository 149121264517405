import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateMyAccountRequest } from '../../Settings/actions';
import { getBackgroundsCollectionList, deleteBackgroundsCollection } from './actions';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';

// Components
import CollectionManager from './CollectionManager';

// Helpers
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Images
import storefront_backgrounds from '@/assets/images/icon-backgrounds.png';
import image_backgrounds_splash from '@/assets/images/image-backgrounds-splash.jpg';

// Styles
import './style.css';

const Backgrounds = function Backgrounds({ location, history }) {
  const dispatch = useDispatch();

  const {
    studio,
    entities: { user: userList }
  } = useSelector((state) => state.login);
  const { account: userAccount } = useSelector((state) => state.users);
  const { collections, pagination, requesting } = useSelector((state) => state.backgrounds);

  const { page: queryPage } = queryString.parse(location.search);

  const loginUser = Object.values(userList).length ? Object.values(userList)[0] : {};
  const roles = (loginUser && loginUser.roles) || [];
  const canManagePriceSheet = roles.includes('manage_price_sheets');

  const backgroundPolicyAgreed = userAccount?.backgrounds_policy_agreed
    ? userAccount['backgrounds_policy_agreed']
    : loginUser?.backgrounds_policy_agreed
      ? loginUser['backgrounds_policy_agreed']
      : false;

  const [tableFilter, setTableFilter] = useState({
    order: 'created_at',
    dir: 'DESC',
    per_page: 15,
    page: queryPage || pagination.page
  });

  const [selectCollection, setSelectCollection] = useState({});
  const [showCollectionManager, setShowCollectionManager] = useState(false);

  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [showBackgroundsSplash, setShowBackgroundSplash] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const handleOrderBy = (order, dir) => setTableFilter({ ...tableFilter, order, dir });

  const handlePagination = (page) => {
    history.push({ search: `?page=${page}` });
    setTableFilter({ ...tableFilter, page });
  };

  const handleManageBackgrounds = (collection) => {
    setShowCollectionManager(true);
    setSelectCollection(collection);
  };

  const handleManageBackgroundsToggle = () => setShowCollectionManager(!showCollectionManager);
  const handleshowBackgroundsSplashToggle = () => setShowBackgroundSplash(!showBackgroundsSplash);

  const handleAcceptBackgroundPolicy = () => {
    dispatch(
      updateMyAccountRequest({ backgrounds_policy_agreed: true }, () => {
        handleshowBackgroundsSplashToggle();
      })
    );
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
    setConfirmDelete('');
    setSelectCollection({});
  };

  const handleDeleteShow = (collection) => {
    setShowDelete(true);
    setSelectCollection(collection);
  };

  const handleCollectionDelete = () => {
    dispatch(deleteBackgroundsCollection({ collectionId: selectCollection.id }, () => handleDeleteCancel()));
  };

  useEffect(() => {
    if (!backgroundPolicyAgreed) {
      handleshowBackgroundsSplashToggle();
    }
  }, []);

  useEffect(() => {
    if (studio) {
      dispatch(getBackgroundsCollectionList(tableFilter));
    }
  }, [tableFilter]);

  return (
    <section className="storefront-backgrounds">
      <header className="flex between storefront-backgrounds__header">
        <hgroup>
          <h2>Backgrounds</h2>
          <p>
            Create a collection by uploading your own backgrounds.{' '}
            <a
              href="https://support.photoday.io/en/articles/4873011-getting-started-with-photoday-s-backgrounds-feature"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
            .
          </p>
        </hgroup>
        {canManagePriceSheet && (
          <aside>
            <Tooltip title="Add Collection" position="bottom" arrow="true" distance="30">
              <button className="button button--small button--lean" name="add" type="button" onClick={() => handleManageBackgrounds({})}>
                <i className="icon-add"></i>
              </button>
            </Tooltip>
          </aside>
        )}
      </header>

      {collections.length ? (
        <>
          <article className="table-box animate">
            <table className="table">
              <thead className="table__header">
                <tr>
                  <th>
                    <Dropdown buttonName={'Collection Name'} buttonExtraClass={'button--filter'}>
                      <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                        <li
                          className={`panel-dropdown__item ${tableFilter.order === 'name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleOrderBy('name', 'ASC');
                          }}
                        >
                          Ascending
                        </li>
                        <li
                          className={`panel-dropdown__item ${tableFilter.order === 'name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                          onClick={() => {
                            handleOrderBy('name', 'DESC');
                          }}
                        >
                          Descending
                        </li>
                      </ul>
                    </Dropdown>
                  </th>
                  <th>
                    <Dropdown buttonName={'Created On'} buttonExtraClass={'button--filter'}>
                      <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                        <li
                          className={`panel-dropdown__item ${
                            tableFilter.order === 'created_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                          }`}
                          onClick={() => {
                            handleOrderBy('created_at', 'ASC');
                          }}
                        >
                          Ascending
                        </li>
                        <li
                          className={`panel-dropdown__item ${
                            tableFilter.order === 'created_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                          }`}
                          onClick={() => {
                            handleOrderBy('created_at', 'DESC');
                          }}
                        >
                          Descending
                        </li>
                      </ul>
                    </Dropdown>
                  </th>
                  <th># Of Backgrounds</th>
                  {canManagePriceSheet && <th>Action</th>}
                </tr>
              </thead>

              <tbody className="table__body">
                {requesting && !collections.length ? (
                  <tr>
                    <td colSpan="5">
                      <TableLoader rows={4} />
                    </td>
                  </tr>
                ) : (
                  <>
                    {collections.map((collection) => (
                      <tr key={collection.id}>
                        <td data-header="Collection" className="text--capitalize text--nowrap">
                          {collection.name}
                        </td>
                        <td data-header="Created On" className="text--capitalize">
                          {moment(collection.created_at).format('MM/DD/YY')}
                        </td>
                        <td data-header="# of Backgrounds" className="text--capitalize">
                          {collection.background_count}
                        </td>
                        {canManagePriceSheet && (
                          <td data-header="Action" className="flex">
                            <Tooltip title="Edit Collection" position="bottom" arrow="true" distance="30">
                              <button
                                className="button button--clean button--small"
                                name="button"
                                type="button"
                                onClick={() => handleManageBackgrounds(collection)}
                              >
                                <i className="icon-gear" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Delete Collection" position="bottom" arrow="true" distance="30">
                              <button className="button button--clean button--small" name="button" type="button" onClick={() => handleDeleteShow(collection)}>
                                <i className="icon-trash" />
                              </button>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </article>
          {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
        </>
      ) : (
        <aside className="flex column middle center panel panel--secondary panel--tall">
          {canManagePriceSheet ? (
            <>
              <h2>
                Add your first background collection{' '}
                <button className="button button--link" type="button" onClick={handleManageBackgrounds}>
                  here
                </button>{' '}
                .
              </h2>
            </>
          ) : (
            <p className="text--bold">No collections found.</p>
          )}
        </aside>
      )}

      {/* Backgrounds Manager */}
      {showCollectionManager && <CollectionManager selectCollection={selectCollection} onManageBackgroundsToggle={handleManageBackgroundsToggle} />}

      {/* Delete modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text--left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete Collection</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to delete <strong>{selectCollection.name || 'this'}</strong> collection?
            </p>
            <p>
              Type <b>"delete"</b> to permanently delete this job.
            </p>
            <input
              className="input--block"
              type="text"
              name="delete"
              value={confirmDelete}
              onChange={({ target }) => setConfirmDelete(target.value)}
              maxLength="50"
            />
          </main>
          <footer className="center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={handleCollectionDelete}
              disabled={requesting || !(confirmDelete.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
      {/* Custom template splash */}
      <aside className={`modal ${showBackgroundsSplash ? '' : 'transparent'} storefront-backgrounds__splash`}>
        <div className="modal__box">
          <main className="flex nowrap modal__content storefront-backgrounds__splash-main">
            <figure className="storefront-backgrounds__splash-image">
              <img src={image_backgrounds_splash} className="hidden--sm" alt="color swatches" />
              <img src={storefront_backgrounds} className="hidden--lg" alt="color swatches" />
            </figure>
            <div className="storefront-backgrounds__splash-content">
              <h4 className="text--left text--tall">Backgrounds</h4>
              <p className="text--left">
                Have some design skills? You can now add your own backgrounds in PhotoDay! Before you get started, here are some important tips that will help
                you design your own themes.
              </p>
              <br></br>
              <ul className="text--left">
                <li>
                  Read our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/4873011-getting-started-with-photoday-s-backgrounds-feature"
                    target="_blank"
                    rel=" noopener noreferrer"
                  >
                    backgrounds
                  </a>{' '}
                  article to get started.
                </li>
                <li>
                  Learn about{' '}
                  <a href="https://support.photoday.io/en/articles/4157921-icc-profiles" target="_blank" rel=" noopener noreferrer">
                    color profiles
                  </a>{' '}
                  and{' '}
                  <a href="https://support.photoday.io/en/articles/4157848-monitor-calibration" target="_blank" rel=" noopener noreferrer">
                    calibrating your monitor
                  </a>
                  .
                </li>
                <li>
                  Read and agree to our{' '}
                  <a href="https://www.photoday.com/backgrounds-terms-of-use" target="_blank" rel=" noopener noreferrer">
                    backgrounds policy
                  </a>
                  .
                </li>
              </ul>

              <footer className="flex column middle storefront-backgrounds__splash-footer">
                <fieldset>
                  <input
                    id="acceptPolicy"
                    className="hidden"
                    type="checkbox"
                    name="acceptPolicy"
                    checked={acceptPolicy}
                    onChange={() => setAcceptPolicy(!acceptPolicy)}
                  />
                  <label htmlFor="acceptPolicy" className="label--checkbox label--lighter label--clean">
                    I’ve read and agree to PhotoDay’s backgrounds policy.
                  </label>
                </fieldset>
                <button className="button button--medium" name="button" type="button" disabled={!acceptPolicy} onClick={handleAcceptBackgroundPolicy}>
                  Continue
                </button>
              </footer>
            </div>
          </main>
        </div>
      </aside>
    </section>
  );
};

export default Backgrounds;
