import { FC, ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationList } from './actions';

// Plugins
import { Tooltip } from 'react-tippy';

// Components
import AddNewModal from './AddNewModal';
import AddEditModal from './AddEditModal';
import DeleteModal from './DeleteModal';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Types
import { Organization, TableFilter } from '@/types';

const List: FC = () => {
  const dispatch = useDispatch();

  const { studio: usersStudio } = useSelector((state: any) => state.users);
  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { id: studioId } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const { organizations, pagination, requesting } = useSelector((state: any) => state.organizations);

  const [showAddNewModal, setShowAddNewModal] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [showSearchClearButton, setShowSearchClearButton] = useState<boolean>(false);

  // Helper functions
  const fetchOrganizations = ({ studioId, searchParams }: { studioId: string; searchParams?: TableFilter }): void => {
    dispatch(getOrganizationList({ studioId, searchParams: { order: 'created_at', dir: 'DESC', ...searchParams } }));
  };

  // UI Handlers
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => setSearchString(event.target.value);
  const handleSearch = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setShowSearchClearButton(true);
    fetchOrganizations({ studioId, searchParams: { search: searchString } });
  };
  const handleSearchClear = (): void => {
    setSearchString('');
    setShowSearchClearButton(false);
    fetchOrganizations({ studioId });
  };

  const handleOrganizationEdit = (selectedOrganization: Organization): void => {
    setShowAddEditModal(true);
    setSelectedOrganization(selectedOrganization);
  };
  const handleOrganizationDelete = (selectedOrganization: Organization): void => {
    setShowDeleteModal(true);
    setSelectedOrganization(selectedOrganization);
  };

  const handleAddNewModalToggle = (): void => setShowAddNewModal(!showAddNewModal);
  const handleAddEditModalClose = (): void => setShowAddEditModal(false);
  const handleDeleteModalClose = (): void => setShowDeleteModal(false);

  const handlePagination = (page: number = 0): void => {
    fetchOrganizations({ studioId, searchParams: { page, ...(searchString ? { search: searchString } : {}) } });
  };

  useEffect(() => {
    fetchOrganizations({ studioId });
  }, []);

  return (
    <>
      <header className="organizations__header">
        <h1 className="text--nomargin">Organizations</h1>
      </header>

      <main>
        {/* Filter */}
        <aside className="flex between">
          <form className="mb-20" onSubmit={handleSearch}>
            <fieldset className="fieldset--clean">
              <input type="search" name="search" placeholder="Search" maxLength={50} value={searchString} onChange={handleSearchChange} />
              <button
                className={`button button--clear button--small ${showSearchClearButton ? '' : 'hidden'}`}
                name="clear"
                type="button"
                onClick={handleSearchClear}
              >
                Clear
              </button>
              <button className="button button--icon" name="button" type="submit">
                <i className="icon-search" />
              </button>
            </fieldset>
          </form>
          <button className="button" name="add" type="button" onClick={handleAddNewModalToggle}>
            Add New
          </button>
        </aside>

        {/* Table */}
        <article className="table-box">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th>Name</th>
                <th>Number of Jobs</th>
                <th>Contact Name(s)</th>
                <th>Contact Email(s)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan={5}>
                    <TableLoader rows={12} rowHeight={60} />
                  </td>
                </tr>
              ) : organizations.length > 0 ? (
                <>
                  {organizations.map((item: Organization) => (
                    <tr className="animate" key={item.id}>
                      <td data-header="Name">
                        <Link to={`/organizations/${item.id}`}>
                          {item.name} {item.reporting_code && <small className="text--block text--italic">({item.reporting_code})</small>}
                        </Link>
                      </td>
                      <td data-header="Number of Jobs">
                        {item.jobs_count ? <Link to={{ pathname: '/jobs', search: `?organization=${item.id}` }}>{item.jobs_count}</Link> : <span>-</span>}
                      </td>
                      <td data-header="Contact Name(s)">
                        {item.contacts.length
                          ? item.contacts.map(
                              (contact, index, array) => `${contact.first_name ?? '-'} ${contact.last_name ?? ''}${index === array.length - 1 ? '' : ', '}`
                            )
                          : '-'}
                      </td>
                      <td data-header="Contact Email(s)">
                        {item.contacts.length
                          ? item.contacts.map((contact, index, array) => `${contact.email ?? '-'}${index === array.length - 1 ? '' : ', '}`)
                          : '-'}
                      </td>
                      <td data-header="Actions">
                        <Tooltip {...{ title: 'Edit Organization' }}>
                          <button className="button button--clean" name="edit" type="button" onClick={() => handleOrganizationEdit(item)}>
                            <i className="icon-gear" />
                          </button>
                        </Tooltip>
                        <Tooltip {...{ title: 'Delete Organization' }}>
                          <button className="button button--clean" name="delete" type="button" onClick={() => handleOrganizationDelete(item)}>
                            <i className="icon-trash" />
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr className="text--center">
                  <td colSpan={6}>No organizations were found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </article>
        {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
      </main>

      {showAddNewModal && <AddNewModal studioId={studioId} onModalClose={handleAddNewModalToggle} />}
      {showAddEditModal && (
        <AddEditModal
          organizationId={selectedOrganization?.id ?? ''}
          organizationName={selectedOrganization?.name ?? ''}
          organizationReportingCode={selectedOrganization?.reporting_code ?? ''}
          organizationNotes={selectedOrganization?.notes ?? ''}
          onModalClose={handleAddEditModalClose}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          organizationId={selectedOrganization?.id ?? ''}
          organizationName={selectedOrganization?.name ?? ''}
          onModalClose={handleDeleteModalClose}
        />
      )}
    </>
  );
};

export default List;
