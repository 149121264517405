import { Component } from 'react';
import { connect } from 'react-redux';

import { updateMyAccountRequest } from '../../actions';
import { normalizePhone } from '@/utils/formatters';

import AccountForm from './AccountForm';

const mapStateToProps = (state) => {
  const { requesting, account } = state.users;
  return { account, requesting };
};
const mapDispatchToProps = { updateMyAccountRequest };

class Account extends Component {
  saveAccount = (values) => this.props.updateMyAccountRequest(values);

  render() {
    const { account, requesting, loading } = this.props;
    const { first_name, last_name, phone, email } = account || {};

    return (
      <AccountForm
        onSubmit={this.saveAccount}
        requesting={requesting}
        loading={loading}
        initialValues={{
          first_name,
          last_name,
          phone: (phone && normalizePhone(phone)) || '',
          email
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
