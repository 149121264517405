import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Plugins
import Select from 'react-select';
import { startCase, capitalize } from 'lodash';
import { NumericFormat } from 'react-number-format';

// Redux
import { createOrderResolution } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { formatCurrency } from '@/utils/displayFormats';

function RefundRequest() {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    studio: { name: studioName, email: studioEmail }
  } = useSelector((state) => state.login);

  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [information, setInformation] = useState({
    studioName: studioName || '',
    studioEmail: studioEmail || '',
    customerName: '',
    customerEmail: '',
    orderNumber: '',
    labUsed: '',
    contactReason: '',
    trackingNumber: '',
    reasonRefundOtherDescription: '',
    purchaseType: '',
    cancelOrderReason: '',
    refundAmount: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInformation(Object.assign({}, information, { [name]: value }));
  };

  const handleSelectChange = (option, name) => {
    if (option) {
      return setInformation(Object.assign({}, information, { [name]: option }));
    }

    setInformation(Object.assign({}, information, { [name]: '' }));
  };

  const handleRequiredfields = () => {
    if (!information.contactReason) {
      return setIsSubmitDisabled(true);
    }

    return setIsSubmitDisabled(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = {};

    Object.keys(information).forEach((field) => {
      const property = information[field];

      if (property) {
        fields[setFieldNumber(field)] = property?.value ? property.value : property;
      }
    });

    dispatch(createOrderResolution({ fields }, () => setSubmitSuccessful(true)));
  };

  const setFieldNumber = (field) => {
    switch (true) {
      case field === 'studioName':
        return 'field_126293736';
      case field === 'studioEmail':
        return 'field_126293737';
      case field === 'customerName':
        return 'field_126293738';
      case field === 'customerEmail':
        return 'field_126293739';
      case field === 'orderNumber':
        return 'field_126293740';

      case field === 'labUsed':
        return 'field_126293741';
      case field === 'contactReason':
        return 'field_126293742';
      case field === 'purchaseType':
        return 'field_126293745';
      case field === 'refundAmount':
        return 'field_126293743';

      case field === 'reasonRefundOtherDescription':
        return 'field_126293746';
      case field === 'trackingNumber':
        return 'field_126293756';
      case field === 'cancelOrderReason':
        return 'field_126293754';

      default:
        return;
    }
  };

  const handleSubmitMessageHide = () => {
    Object.keys(information).forEach((field) => {
      if (field === 'purchaseType') {
        setInformation((prevState) => {
          return { ...prevState, purchaseType: 'product' };
        });
      } else {
        setInformation((prevState) => {
          return { ...prevState, [field]: '' };
        });
      }
    });

    setSubmitSuccessful(false);
  };

  useEffect(() => {
    handleRequiredfields();
  }, [information]);

  useEffect(() => {
    // Pre-populate fileds if search params are found
    const searchParams = new URLSearchParams(location.search);

    if (searchParams) {
      const purchaseType = searchParams.get('purchase_type') || '';

      setInformation({
        ...information,
        customerName: searchParams.get('customer_name') || '',
        customerEmail: searchParams.get('customer_email') || '',
        orderNumber: searchParams.get('order_number') || '',
        labUsed: searchParams.get('lab_used') || '',
        purchaseType: { label: capitalize(startCase(purchaseType.replace('_', ' '))), value: purchaseType },
        refundAmount: formatCurrency(searchParams.get('refund_amount')) || ''
      });
    }
  }, []);

  return (
    <div className="order-resolution">
      {!submitSuccessful && (
        <>
          <header className="resources-container__header">
            <h2 className="text--nomargin">Refund Request Form</h2>
            <a href="https://www.photoday.com/refund-policy" target="_blank" rel="noopener noreferrer">
              Refund Policy
            </a>
          </header>

          <form className="panel panel--section" onSubmit={handleSubmit}>
            <div className="flex flex-row">
              <div className="flex-6">
                <label htmlFor="studioName" className="required">
                  Studio Name
                </label>
                <input className="input--block" type="text" name="studioName" value={information.studioName} onChange={handleInputChange} required />
              </div>
              <div className="flex-6">
                <label htmlFor="studioEmail" className="required">
                  Studio Email
                </label>
                <input className="input--block" type="email" name="studioEmail" value={information.studioEmail} onChange={handleInputChange} required />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-6">
                <label htmlFor="customerName" className="required">
                  Customer Name
                </label>
                <input className="input--block" type="text" name="customerName" value={information.customerName} onChange={handleInputChange} required />
              </div>
              <div className="flex-6">
                <label htmlFor="customerEmail" className="required">
                  Customer Email
                </label>
                <input className="input--block" type="email" name="customerEmail" value={information.customerEmail} onChange={handleInputChange} required />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-6">
                <label htmlFor="orderNumber" className="required">
                  Order Number
                </label>
                <input className="input--block" type="text" name="orderNumber" value={information.orderNumber} onChange={handleInputChange} required />
              </div>
              <div className="flex-6">
                <label htmlFor="purchaseType" className="required">
                  Purchase Type
                </label>
                <Select
                  className="select"
                  classNamePrefix="select"
                  name="purchaseType"
                  required={true}
                  value={information.purchaseType}
                  options={[
                    { label: 'Product', value: 'product' },
                    { label: 'Advance Pay', value: 'advance_pay' }
                  ]}
                  onChange={(value) => handleSelectChange(value, 'purchaseType')}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-6">
                <label htmlFor="refundAmount" className="required">
                  Refund Amount
                </label>
                <NumericFormat
                  className="input--block"
                  name="refundAmount"
                  prefix={'$'}
                  allowNegative={false}
                  decimalScale={2}
                  required={true}
                  value={information.refundAmount}
                  onValueChange={({ value }) => handleInputChange({ target: { name: 'refundAmount', value } })}
                />
              </div>
              <div className="flex-6">
                <label htmlFor="contactReason" className="required">
                  Reason for Refund
                </label>
                <Select
                  className="select"
                  classNamePrefix="select"
                  name="contactReason"
                  required={true}
                  value={information.contactReason}
                  options={[
                    { label: 'Unhappy with photos', value: 'unhappy_quality_prints' },
                    { label: 'Wrong items were delivered', value: 'wrong_item_delivered' },
                    { label: 'Package never arrived', value: 'package_never_arrived' },
                    { label: 'Subject was never photographed', value: 'subject_never_photographed' },
                    { label: 'Order was cancelled', value: 'cancel_order' },
                    { label: 'Other', value: 'other' }
                  ]}
                  onChange={(value) => handleSelectChange(value, 'contactReason')}
                />
              </div>
            </div>

            <div className="flex">
              {information?.contactReason?.value === 'package_never_arrived' && (
                <div className=" flex-12">
                  <label htmlFor="trackingNumber">Tracking Number</label>
                  <input className="input--block" type="text" name="trackingNumber" value={information.trackingNumber} onChange={handleInputChange} />
                </div>
              )}

              {information?.contactReason?.value === 'other' && (
                <div className=" flex-12">
                  <label htmlFor="reasonRefundOtherDescription">Please explain the reason for the refund</label>
                  <textarea type="text" name="reasonRefundOtherDescription" value={information.reasonRefundOtherDescription} onChange={handleInputChange} />
                </div>
              )}

              {information?.contactReason?.value === 'cancel_order' && (
                <>
                  <div className=" flex-12">
                    <label htmlFor="cancelOrderReason">Reason for cancelling</label>
                    <textarea type="text" name="cancelOrderReason" value={information.cancelOrderReason} onChange={handleInputChange} />
                  </div>
                  <br />
                  <p className=" flex-12  order-resolution__text-warning">Orders that are already processed and/or shipped may not be cancelled.</p>
                </>
              )}
            </div>

            <footer>
              <button type="submit" className="button" disabled={isSubmitDisabled}>
                Submit
              </button>
            </footer>
          </form>
        </>
      )}

      {submitSuccessful && (
        <div className="order-resolution__complete">
          <h2 className="bold-font">We're on it!</h2>
          <br />
          <p>Thanks for your request for order resolution. Our support team is on the case. We'll be in touch shortly.</p>
          <br />
          <p>
            If you've requested a refund, check out this{' '}
            <a href="https://support.photoday.io/en/articles/3307299-how-do-i-make-a-refund-request" target="_blank" rel="noopener noreferrer">
              article
            </a>{' '}
            for more information.
          </p>

          <footer>
            <button className="button" onClick={handleSubmitMessageHide}>
              Go Back
            </button>
          </footer>
        </div>
      )}
    </div>
  );
}

export default RefundRequest;
