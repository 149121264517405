import { Component } from 'react';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import Security from './Security';
import Information from './Information';
import Notifications from './Notifications';
import PrivateRoute from '@/components/Shared/PrivateRoute';

import './style.css';

const mapStateToProps = (state) => ({
  user: state.login.user
});

const mapDispatchToProps = {};

class Account extends Component {
  state = {
    section: 'Account Information',
    sidebarIsOpen: false
  };

  componentDidMount = () => {
    this.setSectionTitle(this.props.location.pathname);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSectionTitle(this.props.location.pathname);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'information' && this.state.section !== 'Account Information') {
      this.setState({
        section: 'Account Information'
      });
    }

    if (section === 'security' && this.state.section !== 'Account Security') {
      this.setState({
        section: 'Account Security'
      });
    }

    if (section === 'notifications' && this.state.section !== 'Notification Settings') {
      this.setState({
        section: 'Notification Settings'
      });
    }
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  render() {
    const { user } = this.props;
    const roles = (user && user.roles) || [];

    return (
      <>
        <aside className="flex-3 flex-12-md sidebar-container">
          <header className="flex middle between sidebar-header">
            <h2 className="text--nomargin">Account</h2>
            <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
              {this.state.section}
            </button>
          </header>

          <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
            <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/account/information`}>
              Account Information
            </NavLink>
            <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={'/user/account/security'}>
              Account Security
            </NavLink>
            {roles.includes('view_sales') && (
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={'/user/account/notifications'}>
                Notification Settings
              </NavLink>
            )}
          </nav>
        </aside>

        <section className="flex-9 flex-12-md account__section">
          <Switch>
            <Redirect exact from="/user/account" to="/user/account/information" />
            <PrivateRoute exact path="/user/account/information" component={Information} />
            <PrivateRoute exact path="/user/account/security" component={Security} />
            <PrivateRoute exact path="/user/account/notifications" component={Notifications} requiredPermission={'view_sales'} />
          </Switch>
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
