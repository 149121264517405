import { FC, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { updatePricesheetRequest } from './actions';

// Plugins
import { Tooltip } from 'react-tippy';
import { NumericFormat } from 'react-number-format';

// Helpers
import { errorToast } from '@/utils/toast';
import { formatCurrency } from '@/utils/displayFormats';
import { convertToCents, convertToText } from '@/utils/currency';

// Images
import imageBasicRetouching from '@/assets/images/addons-basic-retouching.png';

// Styles
import './pricesheet-detail.css';

// Types
import { PriceSheet } from '@/types';

// Types
interface ImageAddonsDetailProps {
  pricesheet: PriceSheet;
  pricesheetsRequesting: boolean;
}

const ImageAddonsDetail: FC<ImageAddonsDetailProps> = ({ pricesheet, pricesheetsRequesting }) => {
  const dispatch = useDispatch();

  const {
    id: pricesheetId,
    pd_fee: pricesheetPhotoDayFee,
    basic_retouch_enabled: basicRetouchingEnabled,
    basic_retouch_price_cents: basicRetouchingPriceCents
  } = pricesheet;

  // Basic Retouching
  const basicRetouchingUnitCostCents = 225;
  const [basicRetouchingRetailPriceCents, setBasicRetouchingRetailPriceCents] = useState<number>(basicRetouchingPriceCents);
  const [basicRetouchingMarkupCents, setBasicRetouchingMarkupCents] = useState<number>(basicRetouchingRetailPriceCents - basicRetouchingUnitCostCents);

  const handleRetailPriceChange = (addonType: string, retailPriceValue: string) => {
    const retailPriceValueCents = convertToCents(retailPriceValue) || 0;

    // Basic Retouching
    if (addonType === 'basic-retouching') {
      setBasicRetouchingRetailPriceCents(retailPriceValueCents);
      setBasicRetouchingMarkupCents(retailPriceValueCents - basicRetouchingUnitCostCents);
    }
  };

  const handleRetailPriceSave = (addonType: string, retailPriceValue: string) => {
    const retailPriceValueCents = convertToCents(retailPriceValue) || 0;

    // Basic Retouching
    if (addonType === 'basic-retouching' && retailPriceValueCents !== basicRetouchingPriceCents) {
      if (retailPriceValueCents < basicRetouchingUnitCostCents) {
        errorToast(`Minimum retail price must be ${convertToText(basicRetouchingUnitCostCents, '$')} or higher.`);
      } else {
        dispatch(updatePricesheetRequest({ id: pricesheetId, basic_retouch_price_cents: basicRetouchingRetailPriceCents }));
      }
    }
  };

  return (
    <section className="pricesheet-section pricesheet-section--active animate">
      <article className="pricesheet-section__table pricesheet-section__table--secondary">
        <table className="table">
          <thead className="table__header">
            <tr>
              <th>Add-on</th>
              <th>Description</th>
              <th className="text--nowrap">
                <Tooltip {...{ title: 'Cost set by lab', position: 'top', trigger: 'mouseenter', arrow: true }}>
                  Unit Cost
                  <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-question-circle-o" />
                  </a>
                </Tooltip>
              </th>
              <th className="text--nowrap">
                <Tooltip {...{ title: 'Retail Price minus Unit Cost', position: 'top', trigger: 'mouseenter', arrow: true }}>
                  Markup
                  <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-question-circle-o" />
                  </a>
                </Tooltip>
              </th>
              <th className="text--nowrap">
                <Tooltip {...{ title: 'Amount displayed on price sheet', position: 'top', trigger: 'mouseenter', arrow: true }}>
                  Retail Price
                  <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-question-circle-o" />
                  </a>
                </Tooltip>
              </th>
              <th className="text--nowrap">
                <Tooltip {...{ title: 'PhotoDay Fee', position: 'top', trigger: 'mouseenter', arrow: true }}>
                  PhotoDay Fee
                  <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-question-circle-o" />
                  </a>
                </Tooltip>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {/* Basic Retouching */}
            <tr>
              <td {...{ headers: 'Add-on' }}>
                <figure>
                  <img className="pricesheet-section__addon-image" src={imageBasicRetouching} alt={'Basic Retouch'} width="84" height="54" />
                </figure>
              </td>
              <td {...{ headers: 'Description' }} className="text--normal">
                <small>
                  Retouching magically softens skin, reduces red-eye, acne, unwanted blemishes and more! Available for individual poses, group photos, and
                  images with graphics.
                </small>
              </td>
              <td {...{ headers: 'Unit Cost' }}>{convertToText(basicRetouchingUnitCostCents, '$')}</td>
              <td {...{ headers: 'Markup' }}>{convertToText(basicRetouchingMarkupCents > 0 ? basicRetouchingMarkupCents : 0, '$')}</td>
              <td {...{ headers: 'Retail Price' }}>
                <NumericFormat
                  className={`input--block input--clean ${basicRetouchingMarkupCents < 0 ? 'input--invalid' : ''}`}
                  prefix={'$'}
                  decimalScale={2}
                  allowNegative={false}
                  value={convertToText(basicRetouchingRetailPriceCents)}
                  onValueChange={({ value }) => handleRetailPriceChange('basic-retouching', value)}
                  onBlur={({ target }: any) => handleRetailPriceSave('basic-retouching', target.value)}
                />
              </td>
              <td {...{ headers: 'PhotoDay Fee' }}>
                {isNaN(basicRetouchingRetailPriceCents) || isNaN(pricesheetPhotoDayFee)
                  ? '-'
                  : `$${formatCurrency(Math.round(basicRetouchingRetailPriceCents * (pricesheetPhotoDayFee / 100)))}`}
              </td>
              <td {...{ headers: 'Action' }}>
                <input
                  id="basicRetouchingSwitch"
                  className="hidden"
                  type="checkbox"
                  checked={basicRetouchingEnabled}
                  onChange={({ target }) => dispatch(updatePricesheetRequest({ id: pricesheetId, basic_retouch_enabled: target.checked }))}
                />
                <label
                  className={`label-switch label-switch--small ${pricesheetsRequesting ? 'disabled' : ''}`}
                  htmlFor="basicRetouchingSwitch"
                  data-loading-dark={pricesheetsRequesting}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    </section>
  );
};

export default ImageAddonsDetail;
