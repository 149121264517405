import qs from 'qs';

const VITE_STRIPE_ENDPOINT = import.meta.env.VITE_STRIPE_ENDPOINT;
const VITE_STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID;
const VITE_STUDIO_URL = import.meta.env.VITE_STUDIO_URL;

export const getStripeCode = (props) => {
  return qs.parse(props.location.search).code;
};

export const getStripeAuthUri = () => {
  const redirectUrl = VITE_STRIPE_ENDPOINT.replace('http:', 'https:');
  const params = qs.stringify({
    response_type: 'code',
    scope: 'read_write',
    client_id: VITE_STRIPE_CLIENT_ID,
    redirect_uri: `${VITE_STUDIO_URL}stripe/oauth/connect`
  });
  return `${redirectUrl}oauth/authorize?${params}`;
};

export const getStripeClientId = (state) => {
  const {
    login: { entities }
  } = state;
  const studio = Object.values(entities.studio)[0] || {};
  return studio.stripe_user_id;
};

export const getUserStripeClientId = (state) => {
  const { studio } = state.users;
  const { stripe_user_id } = studio || {};
  return stripe_user_id;
};

export const getUserStripeClientEmail = (state) => {
  const { studio } = state.users;
  const { stripe_user_email } = studio || {};
  return stripe_user_email;
};
